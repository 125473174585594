import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { TestService } from '../../services/test.service';
import { BaseComponent } from '../../modules/shared/base.component';
import { UserService } from '../../services/user/user.service';
import { User } from '../../../types';
import { MatTableDataSource } from '@angular/material/table';
import { config } from '../../config';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { QuestionnaireModalComponent } from './questionnaire-modal/questionnaire-modal.component';

declare let $: any;

@Component({
  selector: 'app-new-test',
  templateUrl: './new-test.component.html',
  styleUrls: ['./new-test.component.css'],
})
export class NewTestComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  config = config;
  qrModal = false;
  hasDevices = false;
  hasPermission = false;

  isSaving = false;
  isSucceeded = false;
  isFailed = false;

  kitForm: FormGroup;
  userData: User | null;

  displayedColumns: string[] = ['serialNumber', 'createdAt', 'id'];
  data: any = [];
  totalRecords: any;
  limit = 7;
  pageNo = 0;
  selectedTestKit?: { id?: number; questionnaireResponse?: any };
  dataSource = new MatTableDataSource<any>(this.data);
  currentSort: { sortBy: any; sortOrder: string };

  constructor(
    private testService: TestService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    super();

    this.kitForm = new FormGroup({
      kitCtrl: new FormControl('OIA', [
        Validators.required,
        Validators.pattern('^OIA[0-9]{1,8}$'),
      ]),
    });

    this.kitForm.get('kitCtrl').valueChanges.subscribe((value) => {
      const prefix = 'OIA';
      if (this.isEmptyOrValidPrefix(value)) {
        this.setKitFormControlValue(prefix);
      } else if (!value.startsWith(prefix)) {
        this.setKitFormControlValue(prefix + value);
      }
    });

    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        if (params.kitId) {
          this.kitForm.get('kitCtrl').setValue(params.kitId);
          this.registerKitNo();
        }
      })
    );

    this.subscriptions.add(
      this.userService.getUserDataObservable().subscribe((d) => {
        this.userData = d;
      })
    );
  }

  ngOnInit() {
    this.getRegisterKits();

    this.subscriptions.add(
      this.kitForm.valueChanges.subscribe(() => {
        this.isSucceeded = false;
        this.isFailed = false;
      })
    );
  }

  private setKitFormControlValue(newValue: string) {
    this.kitForm.patchValue({ kitCtrl: newValue }, { emitEvent: false });
  }

  private isEmptyOrValidPrefix(value: string): boolean {
    return value === '' || value === 'OI' || value === 'O';
  }

  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const keyPressed = event.key;
    if (!allowedKeys.includes(keyPressed)) {
      event.preventDefault();
    }
  }

  getHasDevice(value: boolean) {
    this.hasDevices = value;
  }

  getHasPermission(value: boolean) {
    this.hasPermission = value;
  }

  qrScanned(event: { kitId: string }) {
    this.kitForm.setValue({ kitCtrl: event.kitId });
    this.registerKitNo(true);
  }

  toggleQrModal() {
    $('qr-scanner').modal('hide');
    this.qrModal = false;
  }

  openQuestionnaireModal() {
    const dialogRef = this.dialog.open(QuestionnaireModalComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        homeTestKitId: this.selectedTestKit?.id,
        questionnaireResponse:
          this.selectedTestKit?.questionnaireResponse || {},
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        Swal.fire({
          type: 'success',
          title: 'Success!',
          text: 'The questionnaire saved successfully',
          confirmButtonText: 'OK',
        });

        await this.getRegisterKits();
        this.selectedTestKit = undefined;
      }
    });
  }

  handleSelectTestKit(homeTestkit) {
    this.selectedTestKit = homeTestkit;
    this.openQuestionnaireModal();
  }

  registerKitNo(fromScanner?: boolean) {
    this.isSucceeded = false;
    this.qrModal = false;

    if (!this.kitForm.valid && !fromScanner) {
      return;
    }

    const kitId = this.kitForm.value.kitCtrl;

    if (kitId && kitId.length > 4) {
      this.subscriptions.add(
        this.testService.registerKitRefNumber(kitId).subscribe(
          (res) => {
            this.isSaving = false;
            this.isSucceeded = true;
            this.selectedTestKit = res;
            this.getRegisterKits();
            this.openQuestionnaireModal();
          },
          () => {
            this.isSaving = false;
            this.isFailed = true;
          }
        )
      );
    }
  }

  ngOnDestroy() {
    this.cdr.detach();
    super.ngOnDestroy();
  }

  getRegisterKits() {
    const query = {
      pageSize: this.limit,
      pageOffset: this.pageNo,
      sortBy: 'id',
      sortOrder: 'DESC',
    };

    this.testService.getRegisterKits(query).subscribe(
      (registerKits) => {
        this.data = registerKits?.results;
        this.dataSource = new MatTableDataSource(this.data);
        this.totalRecords = registerKits?.totalCount;
      },
      (error) => { }
    );
  }

  goToPage(pageIndex: number) {
    this.pageNo = pageIndex;
    this.getRegisterKits();
  }

  isPageShow(pageNumber: number): boolean {
    const totalPages = this.getTotalPages();
    const pageNo = this.pageNo;

    if (pageNumber === pageNo
      || pageNumber === pageNo - 1
      || pageNumber === pageNo + 1
      || (pageNo === 0 && pageNumber === 2)
      || (pageNo === totalPages - 1 && pageNumber === pageNo - 2)) {
      return true;
    }

    return false;
  }

  goToPreviousPage() {
    this.pageNo = this.pageNo - 1;
    this.getRegisterKits();
  }

  goToNextPage() {
    this.pageNo = this.pageNo + 1;
    this.getRegisterKits();
  }

  getPageNumbers(): number[] {
    const totalPages = this.getTotalPages();
    return Array(totalPages)
      .fill(0)
      .map((_, i) => i);
  }

  getTotalPages(): number {
    return Math.ceil(this.totalRecords / this.limit);
  }

  pageEvents(event: any) {
    this.pageNo = event.pageIndex;
    this.getRegisterKits();
  }
}
