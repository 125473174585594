<div class="container">
  <div class="row">
    <mat-stepper #stepper>
      <!-- Step - 1 -->
      <mat-step [stepControl]="firstFormGroup" label="Questionnaire Type" state="step1">
        <div class="col-12">
          <div class="row justify-content-md-center">
            <mat-card class="result col-7" style="padding: 25px">
              <!-- Step Title -->
              <mat-card-subtitle>
                <div class="row justify-content-md-center">
                  <div class="col-12" style="text-align: start">
                    Choose the type of Questionnaire you would like to generate
                    from the options below
                  </div>
                </div>
              </mat-card-subtitle>

              <!-- Step Content Form -->
              <mat-card-content style="margin-top: 25px">
                <div class="row justify-content-md-center step-1-question"
                  *ngFor="let question of stepOneQuestions; let i = index">
                  <div class="col-12">
                    <section class="example-section" *ngIf="
                        question &&
                        question.description &&
                        question.description.length
                      ">
                      <mat-checkbox class="example-margin" [(ngModel)]="question.isChecked"
                        (change)="checkValue(question, i)">
                        <span [innerHtml]="question.description" style="
                            color: #333;
                            font-weight: 400;
                            font-size: 14px;
                            display: inline-block;
                            white-space: pre-line;
                          "></span>
                        <span *ngIf="question.name == 'test-based'">
                          {{selectedReportDate.test_date| date : "dd-MM-yyyy h:mm"}}</span>
                      </mat-checkbox>
                    </section>

                    <!-- Selection List -->
                    <mat-form-field appearance="outline" *ngIf="
                        question && question.options && question.options.length
                      ">
                      <mat-select placeholder="Select Profile">
                        <mat-option *ngFor="let option of question.options">{{
                          option.name
                          }}</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <!-- When answer is already collected -->
                    <mat-form-field appearance="outline" *ngIf="question && question.answer && question.answer">
                      <mat-select placeholder="{{ question.answer }}" disabled>
                        <mat-option default>{{ question.answer }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="col-12">
          <div class="row justify-content-md-end">
            <button mat-stroked-button class="action-button cancel" (click)="goToBack('dashboard')">
              Back to Dashboard
            </button>
            <button mat-stroked-button class="action-button next" (click)="typeSelected()">
              Next
            </button>
          </div>
        </div>
      </mat-step>

      <!-- Step-2 -->

      <mat-step label="Prompt Writing" state="step3" *ngIf="!isOWNSpecific">
        <div class="col-12">
          <div class="row justify-content-md-center" style="min-height: 60vh">
            <mat-card class="result col-7">
              <!-- Step Title -->
              <mat-card-subtitle style="color: #000000">
                <div class="row justify-content-md-center">
                  <div class="col-12" style="text-align: start">
                    <span [innerHTML]="stepTwoTitle"></span>
                  </div>
                </div>
              </mat-card-subtitle>
              <mat-card-content style="margin-top: 25px">
                <div class="row justify-content-md-center" style="margin: 0px">
                  <mat-list style="width: 100%" *ngIf="isProfileSpecific">
                    <mat-list-item *ngFor="let userInfo of stepTwoInfo.userInfo" style="
                        background-color: #f9f9f9 !important;
                        margin-bottom: 10px;
                      ">
                      <mat-icon matListItemIcon style="margin-right: 8px; color: #a8a8a8" *ngIf="userInfo.icon">{{
                        userInfo.icon }}</mat-icon>
                      <div matListItemTitle style="
                          font-size: 14px;
                          margin-right: 5px;
                          color: #333333;
                        " *ngIf="userInfo.name">
                        {{ userInfo.name }}:
                      </div>
                      <div matListItemLine *ngIf="userInfo.key" style="color: #333333">
                        {{ userInfo.key }}
                      </div>
                    </mat-list-item>
                  </mat-list>

                  <mat-list style="width: 100%" *ngIf="isTestSpecific">
                    <mat-list-item *ngFor="let userInfo of stepTwoInfo.testInfo" style="
                        background-color: #f9f9f9 !important;
                        margin-bottom: 10px;
                      ">
                      <mat-icon matListItemIcon style="margin-right: 8px; color: #a8a8a8" *ngIf="userInfo.icon">{{
                        userInfo.icon }}</mat-icon>
                      <div matListItemTitle style="
                          font-size: 14px;
                          margin-right: 5px;
                          color: #333333;
                        " *ngIf="userInfo.name">
                        {{ userInfo.name }}:
                      </div>
                      <div matListItemLine *ngIf="userInfo.key" style="color: #333333">
                        {{ userInfo.key }}
                      </div>
                    </mat-list-item>
                  </mat-list>

                  <div class="col-12" style="padding-left: 0px">
                    <p *ngIf="isTestSpecific" style="text-align: left !important">
                      Blood count test results to be used from September 30,2022
                    </p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="result col-5">
              <!-- <h3>{{editPromptStatus}}</h3> -->
              <!-- <mat-card-subtitle>
                                {{stepTwoPrompt}}
                            </mat-card-subtitle> -->
              <mat-card-subtitle>
                <textarea matInput cols="65" rows="18" [value]="stepTwoPrompt">
                </textarea>
              </mat-card-subtitle>
              <div class="row" style="position: absolute; bottom: 0px; color: #a8a8a8">
                <div class="col-1">
                  <i class="material-icons">info</i>
                </div>
                <div class="col-11">
                  <span id="txt1" style="
                      font-family: 'Open Sans';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    ">
                    This is a system generated prompt, to edit click “Edit
                    Prompt”</span>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="col-12">
          <div class="row justify-content-md-end">
            <button mat-stroked-button class="action-button cancel" (click)="goToBack('dashboard')">
              Back to Dashboard
            </button>
            <button mat-stroked-button class="action-button cancel" matStepperPrevious>
              Cancel
            </button>
            <button mat-stroked-button class="action-button edit" *ngIf="!editPromptStatus" (click)="editPrompt()">
              Edit Prompt
            </button>
            <button mat-stroked-button class="action-button next" (click)="generate()">
              Generate
            </button>
          </div>
        </div>
      </mat-step>

      <!-- Step-3 -->
      <mat-step label="Generated Questionnaires" state="step4">
        <!-- Edit Mode -->

        <div id="surveyCreator" *ngIf="
            (stepper.selectedIndex === 2 || stepper.selectedIndex === 1) &&
            model &&
            !previewMode &&
            !publishQuestion
          ">
          <survey-creator [model]="model"></survey-creator>
        </div>
        <!-- View Mode -->
        <div id="surveyView" *ngIf="
            (stepper.selectedIndex === 2 || stepper.selectedIndex === 1) &&
            model &&
            previewMode &&
            surveyModel &&
            !publishQuestion
          ">
          <survey [model]="surveyModel"></survey>
        </div>

        <div class="row" *ngIf="stepper.selectedIndex === 2 && loadingQuestion" style="min-height: 350px !important">
          <div class="col-12 text-center" style="margin: auto">
            <img src="../../../assets/gpt/loder.gif" />
            <p style="
                color: var(--secondary-black-500, #a8a8a8);
                font-family: Open Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              ">
              Generating Questionnaires...
            </p>
          </div>
        </div>

        <div class="row" *ngIf="(stepper.selectedIndex === 2 || stepper.selectedIndex === 1) && publishQuestion">
          <div class="col-12 text-center">
            <div class="row" style="background-color: #e1f6f5">
              <div class="col-12">
                <p style="
                    margin-top: 15px;
                    color: var(--status-success, #00978d);
                    font-family: Montserrat;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                  ">
                  Published Successfully!
                </p>
                <p style="
                    color: var(--secondary-black-600, #777);
                    margin-bottom: 10px;
                    font-family: Open Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  ">
                  Your Questionnaires Published Successfully and sent to your
                  subscribers
                </p>
              </div>
            </div>
            <div class="row" style="margin-top: 30px; margin-bottom: 20px">
              <div class="col-12 text-center">
                <p style="
                    color: var(--secondary-black-700, #333);
                    text-align: center;
                    font-family: Open Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  ">
                  Are these AI generated questionnaires helpful for you?
                </p>
                <button mat-stroked-button class="action-button" style="
                    border-radius: 6px;
                    background: var(--secondary-black-100, #f5f5f5);
                  ">
                  <mat-icon class="material-symbols-outlined" style="color: #00978d">thumb_up</mat-icon>
                  Yes
                </button>
                <button mat-stroked-button class="action-button" style="
                    border-radius: 6px;
                    background: var(--secondary-black-100, #f5f5f5);
                  ">
                  <mat-icon style="color: #e35f6c">thumb_down_alt</mat-icon>
                  No
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 text-center" style="margin-top: 20px">
            <button mat-stroked-button class="action-button">
              Back to dashboard
            </button>
            <button mat-stroked-button class="action-button next">
              Submit Your Feedback
            </button>
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
          <div class="col-3">
            <div class="row justify-content-md-start">
              <section class="example-section" style="margin-top: 20px;" *ngIf="!loadingQuestion && !publishQuestion">
                <mat-checkbox class="example-margin" [(ngModel)]="isTemplate">Save as template</mat-checkbox>
              </section>
            </div>
          </div>
          <div class="col-9">
            <div class="row justify-content-md-end">
              <button mat-stroked-button matStepperPrevious class="action-button cancel" *ngIf="!publishQuestion"
                (click)="goToBack()">
                Cancel
              </button>
              <button mat-stroked-button class="action-button edit"
                *ngIf="!previewMode && !loadingQuestion && !publishQuestion" (click)="preview()">
                Preview as a Subscriber
              </button>
              <button mat-stroked-button class="action-button edit" *ngIf="previewMode && !publishQuestion"
                (click)="editMode()">
                Back to Edit Mode
              </button>
              <button mat-stroked-button class="action-button next" *ngIf="!loadingQuestion && !publishQuestion"
                (click)="publish()">
                Publish Questionnaire
              </button>
            </div>
          </div>
        </div>
      </mat-step>

      <!-- Icons Change on Stepper -->
      <ng-template matStepperIcon="step1">
        <mat-icon>ballot</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="step2">
        <mat-icon>people_alt</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="step3">
        <mat-icon>offline_bolt</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="step4">
        <mat-icon>generating_tokens</mat-icon> </ng-template>list
    </mat-stepper>
  </div>
</div>