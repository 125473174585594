<div class="row m-0 p-0 flex-row-reverse">
  <div class="col-12 col-md-7 d-flex flex-wrap align-content-center justify-content-center py-4" id="custom-section">
    <div class="col-12 m-0 p-0">
      <div style="margin: auto">
        <ngx-ui-loader></ngx-ui-loader>
        <div class="col-12">
          <div class="algocyte_logo_square mx-auto" (click)="goTo(['/home'])">
            <img src="assets/algocyte/logo.png" alt="company-logo" class="img-fluid" />
          </div>
        </div>

        <div class="col-12">
          <div class="title mx-auto margin-bottom-20">
            {{ 'common.forgotPassword' | translate }}
          </div>
        </div>

        <form [formGroup]="form" id="form" *ngIf="!invalidResetPasswordLink && !successMessage" (ngSubmit)="onSubmit()"
          class="mx-auto" style="max-width:515px; margin:auto;">
          <mat-form-field appearance="outline">
            <mat-label for="email">
              {{ 'common.email' | translate }}
            </mat-label>
            <input matInput id="email" type="email" formControlName="email"
              placeholder="{{ 'common.email' | translate }}" />
            <mat-error class="margin-bottom-15" *ngIf="
                form.get('email').errors &&
                form.get('email').hasError('required')
              ">
              {{ 'common.emailMandatory' | translate }}
            </mat-error>
            <mat-error class="margin-bottom-15" *ngIf="
                form.get('email').errors &&
                form.get('email').hasError('pattern')
              ">
              {{ 'common.inValidEmail' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label for="password">{{
              'common.password' | translate
              }}</mat-label>

            <input matInput id="password" [type]="isPasswordHidden ? 'password' : 'text'" formControlName="password"
              placeholder="{{ 'common.password' | translate }}" />

            <button type="button" mat-icon-button matSuffix (click)="isPasswordHidden = !isPasswordHidden"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="isPasswordHidden"
              style="color: gray !important; background-color: #00000000">
              <mat-icon>{{
                !isPasswordHidden ? 'visibility_off' : 'visibility'
                }}</mat-icon>
            </button>
            <mat-error class="margin-bottom-15" *ngIf="
                form.get('password').errors &&
                form.get('password').hasError('required')
              ">
              {{ 'common.passwordMandatory' | translate }}
            </mat-error>
            <mat-error class="margin-bottom-10" *ngIf="
                form.get('password').errors &&
                form.get('password').hasError('invalidPassword')
              ">
              {{ 'common.passwordInvalid' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label for="cpassword">{{
              'common.password' | translate
              }}</mat-label>

            <input matInput id="cpassword" [type]="isPasswordTwoHidden ? 'password' : 'text'"
              formControlName="cpassword" placeholder="{{ 'common.confirmPassword' | translate }}" />

            <button type="button" mat-icon-button matSuffix (click)="isPasswordTwoHidden = !isPasswordTwoHidden"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="isPasswordTwoHidden"
              style="color: gray !important; background-color: #00000000">
              <mat-icon>{{
                !isPasswordTwoHidden ? 'visibility_off' : 'visibility'
                }}</mat-icon>
            </button>
            <mat-error class="margin-bottom-10" *ngIf="
                form.get('cpassword').errors &&
                form.get('cpassword').hasError('passwordsNotMatch')
              ">
              {{ 'common.confirmPasswordValid' | translate }}
            </mat-error>
            <mat-error class="margin-bottom-10" *ngIf="
                form.get('cpassword').errors &&
                form.get('cpassword').hasError('required')
              ">
              {{ 'common.confirmPasswordMandatory' | translate }}
            </mat-error>
          </mat-form-field>

          <p class="error" *ngIf="error">{{ error }}</p>

          <!-- <mat-form-field> -->
          <div class="w-100 text-center margin-top-10">
            <button class="text-uppercase" mat-button class="mx-auto" type="submit" [disabled]="!form.valid">
              {{ 'common.submitBtnText' | translate }}
            </button>
            <app-resource-links></app-resource-links>
          </div>
          <!-- </mat-form-field> -->


        </form>

        <div class="col-12 text-center" *ngIf="invalidResetPasswordLink">
          <div class="text-center mt-4" style="max-width: 515px; margin: auto">
            <div class="mb-2">
              {{ invalidResetPasswordLink }}
            </div>
            <div>
              <a routerLink="/auth/signin" class="back-to-signin-link">Back to Sign In</a>
            </div>
            <app-resource-links></app-resource-links>
          </div>
        </div>

        <div class="col-12 text-center" *ngIf="successMessage">
          <div class="text-center mt-4" style="max-width:515px; margin:auto;">
            <div class="mb-2">
              {{successMessage}}
            </div>
            <div class="mb-2">
              <a routerLink="/auth/signin" class="back-to-signin-link">Back to Sign In</a>
            </div>
            <div class="w-100 text-center">
              <app-resource-links></app-resource-links>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-5" style="padding: 0">
    <app-auth-aside welcomeText="{{ 'signIn.infoStatement' | translate }}"
      welcomeSubText="{{ 'signIn.infoStatement1' | translate }}" ctaText=" {{ 'common.signUp' | translate }}"
      ctaUrl="/auth/signup">
    </app-auth-aside>
  </div>
</div>