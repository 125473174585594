import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import swal from 'sweetalert2';

import { TestService } from './services/test.service';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SelectedSubscriberService } from './services/selected-subscriber/selected-subscriber.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.getAuthorisedUserDataObservable().pipe(
      take(1),
      map((user) => {
        const isOnAuthRoute = state.url.includes('/auth/');
        const isOnVerificationPage = state.url.includes('auth/verify');
        const shouldRedirectToVerify =
          user && !user.emailVerified && !isOnVerificationPage;
        const shouldRedirectToHome =
          user && isOnAuthRoute && user.emailVerified;

        const currentUser = this.userService?.user;

        if (shouldRedirectToVerify) {
          this.router.navigate(['/auth/verify']);
          return false;
        } else if (shouldRedirectToHome) {
          this.router.navigate([route.queryParams?.redirect || '/']);
          return false;
        } else if (!user && !isOnAuthRoute) {
          this.router.navigate(['/auth/signin'], {
            queryParams: { redirect: state.url },
          });
          return false;
        }

        if (
          currentUser?.primaryRole === 2001 && state.url.includes('dashboard') &&
          currentUser?.id === JSON.parse(localStorage.getItem('patientId'))
        ) {
          this.router.navigate(['/my-subscribers']);
          return false;
        }

        return true;
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ClinicianGuard implements CanActivate {
  constructor(
    private testService: TestService,
    private userService: UserService,
    private selectedSubscriberService: SelectedSubscriberService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.userService.getUserDataObservable(),
      this.selectedSubscriberService.getUserDataObservable(),
    ]).pipe(
      take(1),
      map(([currentUser, selectedUser]) => {

        const userRole = currentUser?.primaryRole;

        if ((userRole === 2001 || userRole === 3001) && JSON.parse(localStorage.getItem('UUID')) === JSON.parse(localStorage.getItem('patientId'))) {

          (userRole === 2001) ? this.router.navigate(['/my-subscribers']) : this.router.navigate(['/my-accounts']);

          Promise.all([
            this.testService.getTranslation('messages.attention'),
            this.testService.getTranslation('messages.patientSelectMsg'),
            this.testService.getTranslation('common.myPatients'),
          ])
            .then((res: any[]) => {
              swal.fire({
                type: 'warning',
                title: res[0],
                text: res[1],
                timer: 3000,
                showConfirmButton: true,
                confirmButtonColor: '#00978d',
                confirmButtonText: res[2],
              }).then((result) => {
                if (result.value) {
                  (userRole === 2001) ? this.router.navigate(['/my-subscribers']) : this.router.navigate(['/my-accounts']);
                }
                else {
                  return false
                }
              }).catch(() => {
                return false;
              })
            })
            .catch((error) => {
              return false;
            });
          return false;
        } else {
          return true;
        }
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ClinicalRouteGuard implements CanActivate {
  constructor(
    private testService: TestService,
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.userService.getUserDataObservable().pipe(
      filter((user) => user !== null),
      take(1),
      map((user) => {
        if (!user) {
          this.router.navigate(['/']);
          return false;
        }

        const userRole = user?.primaryRole;
        const patientID = user?.userProfile?.patientId;

        if (
          route.url &&
          route.url.length &&
          route.url[0]['path'] == 'my-subscribers' &&
          userRole !== 2001 &&
          userRole !== 3001
        ) {
          this.router.navigate(['/access-denied']);
          return false;
        } else if (
          route.url &&
          route.url.length &&
          route.url[0]['path'] == 'my-accounts' &&
          userRole !== 1001
        ) {
          this.router.navigate(['/access-denied']);
          return false;
        } else if (
          route.url &&
          route.url.length &&
          route.url[0]['path'] != 'my-accounts' &&
          route.url[0]['path'] != 'my-subscribers' &&
          userRole === 1001 &&
          (patientID === null || patientID === 'null')
        ) {
          Promise.all([
            this.testService.getTranslation('messages.attention'),
            this.testService.getTranslation('messages.patientSelectMsg'),
            this.testService.getTranslation('common.myPatients'),
          ])
            .then((res: any[]) => {
              swal.fire({
                type: 'warning',
                title: res[0],
                text: res[1],
                timer: 3000,
                showConfirmButton: true,
                confirmButtonColor: '#00978d',
                confirmButtonText: res[2],
              });
            })
            .catch((error) => {
              this.router.navigate(['/']);
              return false;
            });
          this.router.navigate(['/']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
