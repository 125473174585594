import { Injectable } from '@angular/core';
import { Organisation } from '../../../types';

import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { UserService } from '../user/user.service';
import { SelectedSubscriberService } from '../selected-subscriber/selected-subscriber.service';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private selectedSubscriberService: SelectedSubscriberService
  ) { }

  getUserOrganisations(): Observable<Organisation[]> {
    return this.apiService.getUsersOrganisation(this.userService.user.id);
  }

  getSelectedPatientOrganisations(): Observable<Organisation[]> {
    return this.apiService.getUsersOrganisation(this.selectedSubscriberService.user.id);
  }
}
