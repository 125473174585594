import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ECommorceService {

  constructor(public http: HttpClient) { }

  getCouponList(params) {
    return this.http.get(`https://dev.algosom.in/ecom/subscription/coupon?email=${params.email}`);
  }

  getCouponDetails(params) {
    return this.http.get(`https://dev.algosom.in/ecom/subscription/coupon/management?email=${params.email}&code=${params.code}`);
  }

  createCoupon() {
    const data = {
      "expires_at": "2022-03-01T00:00:00",
      "user_type": "employer",
      "bought_by": 1,
      "max_redeem_count": 15,
      "redeem_count": 5,
      "is_redeemable": true,
      "package": 1
    };
    return this.http.post("https://dev.algosom.in/ecom/subscription/coupon", data);
  }

  activateCoupon(data){
    return this.http.post("https://dev.algosom.in/ecom/subscription/coupon/activate", data);
  }
}
