import { Component, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';

import { UserService } from 'src/app/services/user/user.service';
import { ApiService } from '../../services/api.service';
import { Organisation, Role } from '../../../types';
import { BaseComponent } from '../../modules/shared/base.component';
import { RoleService } from '../../services/user/role.service';
import { SelectedSubscriberService } from 'src/app/services/selected-subscriber/selected-subscriber.service';

@Component({
  templateUrl: './test-data.component.html',
  styleUrls: ['./test-data.component.css'],
})
export class TestDataComponent extends BaseComponent implements OnInit {
  patientId: string;
  associatedOrganisations: Organisation[] = [];
  currentRole: Role;
  isOrganisationLoading = false;
  isRoleLoading = false;
  isBloodTestLoading = false;

  sampleCountControl: FormControl = new FormControl(1, [
    Validators.required,
    Validators.min(1),
    Validators.max(35),
  ]);
  isProfileCompleted: boolean;

  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private _apiService: ApiService,
    private selectedSubscriberService: SelectedSubscriberService,
  ) {
    super();
  }

  ngOnInit() {

    const userDataSubscription = this.userService.getUserDataObservable().subscribe(() => {
      if (this.roleService.isProvider) {
        const selectedSubscriberSubscription = this.selectedSubscriberService.getUserDataObservable().subscribe((userData) => {
          this.setUserData(userData.userProfile);
          const userProfile: any = userData.userProfile;
          const userRoles = userProfile?.user?.roles || [];
          this.currentRole = userRoles.includes('provider') ? 'provider' : userRoles.includes('subscriber') ? 'subscriber' : null;
        });
        this.subscriptions.add(selectedSubscriberSubscription);
      } else {
        this.setUserData(this.userService.user.userProfile);
        this.currentRole = this.roleService.currentRole;
      }
    });

    this.subscriptions.add(userDataSubscription);

    this.fetchOrganisations();
  }

  private setUserData(userData: any): void {
    this.patientId = userData.patientId;
    this.isProfileCompleted = this.userService.isProfileComplete(userData);
  }

  fetchOrganisations() {
    this.isOrganisationLoading = true;
    this.subscriptions.add(
      this._apiService
        .associatedOrganisations(this.userService.user.id)
        .subscribe({
          next: (orgs) => {
            this.associatedOrganisations = orgs;
            this.isOrganisationLoading = false;
          },
          error: () => {
            this.isOrganisationLoading = false;
          },
        })
    );
  }

  addSubscriberToOrganisation() {
    this.isOrganisationLoading = true;
    this.subscriptions.add(
      this._apiService
        .addSubscriberToOrganisation(1, this.userService.user.id)
        .subscribe(() => this.fetchOrganisations())
    );
  }

  removeSubscriberToOrganisation(id: number) {
    this.isOrganisationLoading = true;
    this.subscriptions.add(
      this._apiService
        .removeSubscriberFromOrganisation(id, this.userService.user.id)
        .subscribe(() => this.fetchOrganisations())
    );
  }

  escalateUserToProvider() {
    this.isRoleLoading = true;
    this.subscriptions.add(
      this.roleService
        .updateRole('provider')
        .pipe(
          switchMap(() =>
            this.userService.refreshUserProfile(this.userService.user.id)
          )
        )
        .subscribe({
          next: () => {
            this.isRoleLoading = false;
          },
          error: () => {
            this.isRoleLoading = false;
          },
        })
    );
  }

  uploadBloodTestResults() {
    const count = this.sampleCountControl.value;
    this.isBloodTestLoading = true;
    this.subscriptions.add(
      this._apiService.testUploadBloodTestResult(count).subscribe({
        next: () => {
          this.isBloodTestLoading = false;
        },
        error: () => {
          this.isBloodTestLoading = false;
        },
      })
    );
  }

  getOrganisationButtonDisabled(): boolean {
    return !!this.associatedOrganisations.find((org) => org.id === 1);
  }

  getEscalateToProviderButtonDisabled(): boolean {
    return this.roleService.isProvider || !this.getOrganisationButtonDisabled();
  }
}
