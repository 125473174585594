import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare var Pace: any;

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  reportRequest: EventEmitter<any> = new EventEmitter();
  constructor(public http: HttpClient) {}
}
