import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { commonConfig } from 'src/app/config/common/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { config } from '../../../config';
import { Subscription } from 'rxjs';
import {
  confirmPasswordValidator,
  passwordValidator,
} from '../../shared/validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnDestroy, OnInit {
  token: string;
  form: FormGroup;

  isPasswordHidden = true;
  isPasswordTwoHidden = true;
  error: string;
  config: any = config;
  invalidResetPasswordLink: string;
  private subscriptions: Subscription = new Subscription();
  successMessage: string;

  constructor(
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(commonConfig.pattern.email),
      ]),
      password: new FormControl('', [Validators.required, passwordValidator]),
      cpassword: new FormControl('', [
        Validators.required,
        confirmPasswordValidator,
      ]),
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParams.subscribe(async (res) => {
        if (res.token) {
          this.ngxService.start();
          this.token = res.token;
          await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { token: undefined },
            queryParamsHandling: "merge",
          });
        }
        else {
          (!this.token) ? this.invalidResetPasswordLink = 'Invalid password reset link. You can request a new password reset link.' : this.ngxService.stop();
        }
      })
    );

    this.subscriptions.add(
      this.form.get('password').valueChanges.subscribe(() => this.form.get('cpassword').updateValueAndValidity({ emitEvent: false }))
    )

    this.subscriptions.add(
      this.form.get('cpassword').valueChanges.subscribe(() => this.form.get('password').updateValueAndValidity({ emitEvent: false }))
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goTo(route) {
    this.router.navigate([route]);
  }

  async onSubmit() {
    try {
      const { email, password } = this.form.value;

      this.error = null;
      this.ngxService.start();
      if (this.isFormValid()) {
        const payload = {
          email,
          password,
          token: this.token
        }
        const result = await this.authService.resetPassword(payload);
        this.successMessage = result['message'] || ""
        this.ngxService.stop();
      } else {
        this.ngxService.stop();
        return;
      }
    } catch (error) {
      error && error.error
        ? this.handleError(error.error)
        : this.handleError(error);
    }
  }

  isFormValid(): boolean {
    return this.form.valid;
  }

  handleError(error) {
    this.ngxService.stop();
    this.error = error.message;
  }
}
