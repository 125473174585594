import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from '../../services/api.service';
import Swal from 'sweetalert2';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
})
export class ApiComponent implements OnInit {
  constructor(
    public router: ActivatedRoute,
    private ngRouter: Router,
    private ngxService: NgxUiLoaderService,
    private _apiService: ApiService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.checkToken();
  }

  checkToken() {
    this.router.queryParams.subscribe(async (params) => {
      const { token, action } = params;
      const user = this.userService?.user;
      const redirectUrl =
        user?.primaryRole === 2001 ? '/my-subscribers' : '/dashboard';
      this.ngxService.start();
      if (token && action) {
        try {
          if (action === 'provider-link') {
            return await this._apiService
              .acceptProviderLinkRequest(token)
              .toPromise();
          }
          if (action === 'subscriber-link') {
            return await this._apiService
              .acceptSubscriberLinkRequest(token)
              .toPromise();
          }
        } catch (e) {
          console.error(e);
          const err = JSON.parse(e.error);
          Swal.fire({
            html: err?.message,
            showConfirmButton: true,
            confirmButtonText: 'OK',
          });
        } finally {
          this.userService
            .fetchFullUserData(user?.id)
            .subscribe({
              next: () => this.ngRouter.navigate([redirectUrl]),
              error: () => this.ngRouter.navigate([redirectUrl]),
            });
          this.ngxService.stop();
        }
      } else {
        this.ngRouter.navigate([redirectUrl]);
        this.ngxService.stop();
      }
    });
  }
}
