import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { TestService } from "../../../services/test.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import _ from "lodash";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
interface TempObject {
  QID: string;
  AID: string[];
  FEEDBACK: string[];
  HEALTH_ID: string | null;
  KIT_ID: string | null;
  UPDATE: string;
}
declare var $: any;
const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1 ||
  window.navigator.userAgent.indexOf("safari/") > -1;
@Component({
  selector: "app-import-results",
  templateUrl: "./import-results.component.html",
  styleUrls: ["./import-results.component.css"],
})
export class ImportResultsComponent implements OnInit {
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = true;
  public deviceId: string;
  public displayCaptureReportDialogBox: boolean = false;
  public videoOptions: MediaTrackConstraints = {
    width: { ideal: 1024 },
    height: { ideal: 576 },
  };
  healthId;
  mydate =
    new Date().getHours() < 13 ? new Date(new Date().setHours(13)) : new Date();
  today = new Date(
    this.mydate.getFullYear(),
    this.mydate.getMonth(),
    this.mydate.getDate(),
    13,
    0
  );
  allCountsAsArray = [];
  allQuestionAsArray = [];
  width: any = 500;
  height: any = 500;
  uploadUrl: string;
  postOcrQuestionForm: FormGroup;
  dateTime2: any;
  oldView = false;
  // public errors: WebcamInitError[] = [];
  public errors: any = [];
  public webcamImage: WebcamImage = null;
  public trigger: Subject<void> = new Subject<void>();
  public nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  public imageSrc = "";
  imageSrcFile = "";
  attacheedCams: any = [];
  public viewerOptions: any = {
    navbar: false,
    toolbar: false,
  };
  testDate;
  fileType: any;
  method;
  count = 0;
  isClicked = true;
  isPermitted: boolean = true;
  userRole = JSON.parse(localStorage.getItem("roleId"));
  suggestedData: any;
  capturedFile: any;
  cameraView: boolean = false;
  fileToUpload: any;
  webcamImageFile: File;
  batch;
  time_stamp;
  showSnapShotImage: any;
  actionPerformedByCaptureButton: any;
  actionPerformedByDragAndDrop: any;
  showCameraPreviewWithWarningPage: boolean;
  constructor(
    private testService: TestService,
    public change: ChangeDetectorRef,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private dateFilter: DatePipe,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    console.log(this.mydate);
  }

  ngOnInit() {
    if (!this.showWebcam) {
      // this.askCameraPermission();
    }
    this.onResize();
    // this.askCameraPermission();
    this.camClicked(0);
    $(window).resize(() => {
      this.onResize();
    });
  }
  askCameraPermission() {
    this.errors.push({
      message: "Switch on the camera.",
    });
    console.log(this.errors);

    this.change.detectChanges();
  }
  // }

  getTimeStamp(timestamp) {
    return this.dateFilter.transform(timestamp, "HH:mm dd-MMM-yyyy");
  }

  async camClicked(i) {
    // console.log("CAlled");

    if (this.count === i) {
      this.isClicked = true;
      await WebcamUtil.getAvailableVideoInputs().then(
        (mediaDevices: MediaDeviceInfo[]) => {
          this.attacheedCams = mediaDevices;
          console.log(this.attacheedCams);

          this.multipleWebcamsAvailable =
            mediaDevices && mediaDevices.length > 1;
          console.log(this.multipleWebcamsAvailable);
        }
      );
      this.count++;
      this.onResize();
    }
  }

  ngOnDestroy() {
    this.change.detach();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  deleteFile(option) {
    if (option === "webcam") {
      this.webcamImage = undefined;
    }
    this.showSnapShotImage = false;
    this.change.detectChanges();
  }

  handleInputChange(e) {
    console.log(e, e.dataTransfer);

    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else if (e.target) {
      this.fileToUpload = e.target.files[0];
      file = e.target.files[0];
    } else if (e.length) {
      file = e[0];
      this.fileToUpload = e[0];
    } else {
      file = undefined;
    }
    if (file) {
      const pattern = /image-*|^application/;
      console.log("file.type ====>", file.type);
      // this.fileType = file.type;
      const reader = new FileReader();
      if (!file.type.match(pattern)) {
        alert("invalid format");
        return;
      }
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
    } else {
      this.imageSrc = undefined;
      this.imageSrcFile = undefined;
    }
  }
  async _handleReaderLoaded(e) {
    const reader = e.target;
    this.imageSrc = await reader.result;
    this.showSnapShotImage = true;
    this.actionPerformedByCaptureButton = false;
    this.actionPerformedByDragAndDrop = true;
    console.log("this.imageSrc ------>", this.imageSrc);
    this.imageSrcFile = reader.result.split(",").reverse()[0];
    // this.imageSrcFile = reader.result.split(',').reverse()[0];
    // this.fileToUpload = e.target
  }

  handleFileExtension(event) {
    console.log("Extension", event);
    this.fileType = event;
  }

  async btnUpload_onclick(option) {
    let formData: FormData = new FormData();
    console.log("this.imageSrcFile ===>", this.imageSrcFile);
    console.log("this.fileToUpload ===>", this.fileToUpload);
    if (this.actionPerformedByCaptureButton) {
      option = "webCam";
    }
    if (this.actionPerformedByDragAndDrop) {
      option = "fileUpload";
    }

    this.ngxService.start();
    const body = {
      // image: '',
      type: this.fileType,
    };
    switch (option) {
      case "webCam":
        // body.image = this.webcamImage.imageAsBase64; ///Old approad of base 64
        body["image"] = this.webcamImageFile;
        formData.append("image", this.webcamImageFile);
        // this.capturedFile = this.webcamImage.imageAsBase64;

        body.type = "JPEG";
        formData.append("type", "JPEG");
        this.method = "OCR WEBCAM ENTRY";
        break;
      case "fileUpload":
        body["image"] = this.imageSrcFile;
        formData.append("image", this.fileToUpload);
        formData.append("type", this.fileType);
        this.method = "OCR UPLOAD ENTRY";
        break;
      default:
        body["image"] = undefined;
        break;
    }
    console.log(body);
    // const blob = this.b64toBlob(b64Data, contentType);

    if (body["image"]) {
      console.log("body.image ===>", body, formData);

      this.testService.sendFileToServer(formData).subscribe(
        async (res: any) => {
          this.batch = res.batch;
          this.time_stamp = res.time_stamp;
          this.suggestedData = JSON.parse(JSON.stringify(res));
          delete this.suggestedData.RDATE;
          delete this.suggestedData.batch;
          delete this.suggestedData.time_stamp;
          this.allCountsAsArray = [];
          for (const key of Object.keys(res)) {
            if (key !== "RDATE" && key !== "batch" && key !== "time_stamp") {
              const val = res[key];
              if (val !== -1) {
                await this.allCountsAsArray.push({ label: key, value: val });
              }
            }
          }
          this.ngxService.stop();
          // $('#postOcrModal').modal('show');
        },
        (err) => {
          this.ngxService.stop();
          console.log(err);
        }
      );
    }
  }

  menualClicked() {
    this.ngxService.start();
    this.testService.getAnalytesForManualEntry().subscribe(
      async (res: any) => {
        console.log(res);
        this.allCountsAsArray = [];
        for (const [key, val] of Object.entries(res)) {
          if (res.hasOwnProperty(key)) {
            await this.allCountsAsArray.push({
              label: key,
              value: val === -1 ? "" : val,
            });
          }
        }
        this.method = "Manual Web Entry";
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  submitTestResult(allCountsAsArray) {
    console.log(allCountsAsArray);
    const sequence = [
      "RBC",
      "WBC",
      "NEUT",
      "EOS",
      "BASO",
      "LYMPH",
      "MONO",
      "HGB",
      "PLT",
      "MCV",
      "MCH",
      "MCHC",
      "HCT",
    ];
    const newObj: any = {};
    const str = {};
    const missingAnalytes = [];
    sequence.forEach((cell, index) => {
      const countIndex = allCountsAsArray.findIndex(item => item.label === cell);
      if (countIndex !== -1) {
        const value = allCountsAsArray[countIndex].value;
        if (value === "") {
          missingAnalytes.push(cell);
          str[cell] = -1;
        } else {
          str[cell] = Number(value);
        }
        newObj.suggestedData = this.suggestedData;
        newObj.batch = this.batch;
        newObj.time_stamp = this.time_stamp;
        newObj.updatedData = str;
        newObj.date = this.testDate
          ? this.dateFilter.transform(this.testDate, "yyyy/MM/dd HH:mm:ss")
          : this.dateFilter.transform(this.today, "yyyy/MM/dd HH:mm:ss");
        newObj.method = this.method;
      }
    });
    if (missingAnalytes.length) {
      Promise.all([
        this.testService.getTranslation('messages.attentionMissingAnalyte'),
        this.testService.getTranslation('messages.missingAnalyteInfo'),
        this.testService.getTranslation('common.cancelBtnText'),
        this.testService.getTranslation('messages.successSubmit')
      ]).then((res: any) => {
        Swal.fire({
          title: res[0],
          text: missingAnalytes.join(", ") + res[1],
          type: "warning",
          showCancelButton: true,
          cancelButtonText: res[2],
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: res[3],
        }).then((result) => {
          if (result.value) {
            this.ngxService.start();
            this.testService.commitTheFinalResult(newObj).subscribe(
              (res: any) => {
                console.log(res);
                this.healthId = res.health_id;
                this.ngxService.stop();
                $("#postOcrModal").modal("hide");
                this.getQuestions();
                // this.router.navigate(['dashboard']);
              },
              (err) => {
                this.ngxService.stop();
                console.log(err);
              }
            );
          }
        });
      });
    } else {
      this.ngxService.start();
      this.testService.commitTheFinalResult(newObj).subscribe(
        (res: any) => {
          console.log(res);
          this.healthId = res.health_id;
          this.ngxService.stop();
          $("#postOcrModal").modal("hide");
          this.getQuestions();
          // this.router.navigate(['dashboard']);
        },
        (err) => {
          this.ngxService.stop();
          console.log(err);
        }
      );
    }
  }

  getQuestions() {
    this.ngxService.start();
    this.testService.getQuestionaryInfo(3).subscribe(
      (res) => {
        console.log(res);
        this.ngxService.stop();
        this.allQuestionAsArray = JSON.parse(JSON.stringify(res));
        this.initQuestionForm(this.allQuestionAsArray);
        $("#questionModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  initQuestionForm(clinicalQuestions) {
    this.postOcrQuestionForm = new FormGroup(
      this.getControls(clinicalQuestions)
    );
  }

  getControls(controllsArray) {
    const controls = {};
    controllsArray.forEach(control => {
      controls[control.formControlName] = new FormControl(
        control.givenAns ? control.givenAns : ""
      );
    });
    return controls;
  }

  submitAnswer(postOcrQuestionForm) {
    const data = [];
    for (let i = 0; i < postOcrQuestionForm.length; i++) {
      const entry = postOcrQuestionForm[i];
      const control = this.postOcrQuestionForm.controls[entry.formControlName];

      if (
        control.touched &&
        ((entry.givenAns !== "" && entry.givenAns.length !== 0) ||
          (entry.givenAns.length &&
            entry.givenAns[0] !== undefined &&
            entry.givenAns[0] !== ""))
      ) {
        const temp: TempObject = {
          QID: entry.qid,
          AID: entry.suggestedAns.length
            ? typeof entry.givenAns === "string" && entry.givenAns !== ""
              ? [entry.givenAns]
              : entry.givenAns[0] === undefined
                ? [""]
                : entry.givenAns
            : [],
          FEEDBACK: entry.suggestedAns.length
            ? []
            : typeof entry.givenAns === "number"
              ? [String(entry.givenAns)]
              : [entry.givenAns],
          HEALTH_ID: this.healthId || null,
          KIT_ID: null,
          UPDATE: "0",
        };
        data.push(temp);
      }
    }
    this.ngxService.start();
    this.testService.saveQuestionaryInfo("qaTest", data).subscribe(
      (res) => {
        console.log(res);
        this.ngxService.stop();
        this.allCountsAsArray = [];
        this.capturedFile = "";
        this.suggestedData = null;
        $("#questionModal").modal("hide");
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  dateTimeChangeEve(event) {
    console.log(event);
  }

  onResize(event?: Event) {
    console.log(document.getElementById("camView"));

    const win = !!event ? (event.target as Window) : window;
    if (document.getElementById("camView")) {
      if ($(window).width <= 767) {
        this.width = document.getElementById("camView").offsetWidth;
        this.height = document.getElementById("camView").offsetHeight;
      } else {
        this.width = document.getElementById("camView").offsetWidth;
        this.height = document.getElementById("camView").offsetHeight;
      }
      this.change.detectChanges();
    }
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    console.log(directionOrDeviceId);
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.log("received webcam image", webcamImage);
    const blob = this.b64toBlob(webcamImage.imageAsBase64, "image/jpeg");
    var file = this.blobToFile(blob, "capturedImage.jpeg");
    console.log("BLBO", file);
    this.webcamImage = webcamImage; //Old base64 string
    this.webcamImageFile = file;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
    console.log("active device: " + deviceId);
  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  };

  enableCamera(event) {
    console.log(event);

    if (event.checked) {
      this.showWebcam = true;
      this.cameraView = true;
      this.onResize();
      // this.ngOnInit();
      this.errors = [];
      // this.change.detectChanges();
    } else {
      this.cameraView = false;
      this.showWebcam = false;
      this.deviceId = null;
      this.errors.push({
        message: "Switch on the camera.",
      });
      this.change.detectChanges();
    }
  }

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    const byteCharactersLength = byteCharacters.length;
    for (let offset = 0; offset < byteCharactersLength; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const sliceLength = slice.length;
      const byteNumbers = new Array(sliceLength);
      for (let i = 0; i < sliceLength; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  captureTestReport() {
    const permissionName = "camera" as PermissionName;
    navigator.permissions
      .query({ name: permissionName })
      .then(({ state }) => {
        if (state === "granted") {
          this.showCameraPreviewWithWarningPage = false;
          this.displayCaptureReportDialogBox = true;
          this.cameraView = true;
          this.showWebcam = true;
          console.log("GRANTED");
        }
        if (state === "denied") {
          console.log("Drinerd");

          this.showCameraPreviewWithWarningPage = true;
        }
        console.log(state);
      })
      .catch((err) => {
        console.log("Error in navigator");
      });
    this.displayCaptureReportDialogBox = true;
    this.cameraView = true;
    this.showWebcam = true;
    // console.log("showWebcam", this.showWebcam);
    // console.log("webcamImage", this.webcamImage);
  }

  async onHideWithCapturePreview() {
    this.displayCaptureReportDialogBox = false;
    this.cameraView = false;
    this.showWebcam = false;
  }

  saveImageAndPreview() {
    console.log(this.webcamImage);
    this.onHideWithCapturePreview();
    this.displayCaptureReportDialogBox = false;
    this.showSnapShotImage = true; // To show image before Upload
    // Start Analyze Button flag
    this.actionPerformedByCaptureButton = true;
    this.actionPerformedByDragAndDrop = false;
  }
}
