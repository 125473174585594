import { User as FBUser } from 'firebase/auth';

export type FBUserWithId = {
  userId: number;
} & FBUser;

export interface RegisterUserPayload {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export type User = {
  id: number;
  createdAt: string;
  firebaseId: string;
  roles: Role[];
  primaryRole: number;
  userProfile: UserProfile;
  isProfileComplete?: boolean;
};

export type UserProfile = {
  firstName: string | null;
  lastName: string | null;
  patientId: string | null;
  dateOfBirth: string | null;
  gender: string | null;
  countryOfBirth: string | null;
  ethnicity: string | null;
  email: string;
  address: {
    firstLineAddress: string | null;
    secondLineAddress: string | null;
    city: string | null;
    region: string | null;
    postalCode: string | null;
    country: string | null;
  };
  userId: number;
};

export type Role = 'subscriber' | 'provider' | 'admin';

export type AuthorisedUser = {
  id: number;
  userProfile: {
    firstName: string;
    lastName: string;
  };
};

export type AuthorisedByUser = {
  id: number;
  userProfile: UserProfile;
};

export type UserLinkingCode = {
  code: number;
  expirationDate: Date;
};

export type Organisation = {
  id: number;
  name: string;
};

export type Provider = {
  id: number;
  userId: number;
  user: ProviderUser;
  organisationId: number;
  organisation: Organisation;
  subscribers: Subscriber[];
  name?: string;
};

export type ProviderWithStatus = Provider & { status?: 'pending' | 'accepted' };

export type ProviderUser = {
  userProfile: ProviderUserProfile;
};

export type ProviderUserProfile = {
  firstName: string;
  lastName: string;
};

export type PagedQueryResponse<T> = {
  results: T[];
  totalCount: number;
  pageOffset: number;
  pageSize: number;
};

export type Subscriber = {
  id: number;
  profile: UserProfile;
};

export type LinkRequest = {
  requestId: number;
  token: number;
};

export type ResetPasswordRequest = {
  email: string;
  password: string;
  token: string;
};

export type ClinicalReferenceEnums = {
  treatmentStatus: string[];
  diagnosisType: string[];
  diagnosedBy: string[];
  smokingStatus: string[];
  cigarettesPerDay: string[];
  alcoholStatus: string[];
  conditionTypes: string[];
  gender: string[];
  ethnicity: string[];
};

export type Option = {
  label: string;
  value: string;
};

export enum TagEntityType {
  SYSTEM_DEFINED = 'system-defined',
  USER_CREATED = 'user-created',
}

export type Tag = {
  id: number;
  text: string;
  type?: TagEntityType;
  userId?: number;
};

export type HealthEventQueryParams = {
  userId?: number;
  date?: string;
  dateRangeStart?: string;
  dateRangeEnd?: string;
};

export type CreateHealthEventBody = {
  eventDate?: string;
  dateRangeStart?: string;
  dateRangeEnd?: string;
  wellnessRating: number;
  painRating: number;
  energyRating: number;
  details?: string;
  eventTypeId: string;
}

export type HealthEventType = {
  id: string;
  name: string;
}

export type HealthEvent = {
  userId: number;
  id: number;
  eventDate?: string;
  dateRangeStart?: string;
  dateRangeEnd?: string;
  wellnessRating: number;
  painRating: number;
  energyRating: number;
  details?: string;
  createdAt: string;
  eventTypes: HealthEventType[];
};

