import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { from, Observable, throwError } from 'rxjs/';
import { map, catchError, switchMap } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';
import { getAuth, User } from 'firebase/auth';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  logout: EventEmitter<any> = new EventEmitter();

  constructor(private ngxService: NgxUiLoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isJsonRequest(req)) {
      return this.handleJsonRequest(req, next);
    } else if (this.isChinoApiRequest(req)) {
      return this.handleChinoApiRequest(req, next);
    } else {
      return this.handleAuthRequest(req, next);
    }
  }

  private isJsonRequest(req: HttpRequest<any>): boolean {
    return req.url.includes('.json');
  }

  private handleJsonRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event) => event),
      catchError(this.handleErrors)
    );
  }

  private isChinoApiRequest(req: HttpRequest<any>): boolean {
    return req.url.includes('https://consenta.test.chino.io/api');
  }

  private handleChinoApiRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({
      setHeaders: {
        Authorization:
          'Basic ' +
          btoa(
            '94eefd7f-9dec-495f-b594-e237d0f4e6c2:98637879-4a03-4952-ae73-b572be3d0967'
          ),
      },
    });
    return next.handle(clonedRequest).pipe(
      map((event) => event),
      catchError(this.handleErrors)
    );
  }

  private handleAuthRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.getFirebaseToken()).pipe(
      switchMap((token) => {
        const headers = {
          Client: environment.client,
          ...(token && { Authorization: `Bearer ${token}` }),
        };

        const cloned = req.clone({
          setHeaders: headers,
        });

        return next.handle(cloned);
      }),
      catchError(this.handleErrors)
    );
  }

  private handleErrors(error: HttpErrorResponse): Observable<never> {
    console.error(error);
    return throwError(error);
  }

  public displaySweetAlert(message: string): void {
    Swal.fire({
      html: message,
      showConfirmButton: true,
      confirmButtonText: 'OK',
    });
  }

  public displayLogoutAlert(
    text: string,
    html: string,
    btnText: string
  ): Promise<string> {
    this.ngxService.start();
    this.ngxService.stopAll();
    return Swal.fire({
      text,
      html,
      showConfirmButton: true,
      confirmButtonText: btnText,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        Auth.signOut();
        return 'IF CLICK';
      } else {
        return 'ELSE';
      }
    });
  }

  private getCurrentUser(): User | null {
    const auth = getAuth();
    return auth.currentUser;
  }

  private async getFirebaseToken(): Promise<string | null> {
    const user = this.getCurrentUser();
    if (user) {
      return user.getIdToken();
    }
    return null;
  }
}
