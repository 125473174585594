import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TestService } from '../../services/test.service';

@Component({
  selector: 'alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.css', '../../app.component.css'],
})
export class AlertBannerComponent {
  @Input()
  ctaUrl = '';
  @Input()
  ctaText = '';
  @Input()
  text = '';

  constructor(
    public router: Router,
    private testService: TestService
  ) {}

  doRouting(path) {
    this.testService.doRouting([path]);
  }
}
