import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ECommorceService } from "src/app/services/e-commorce/e-commorce.service";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";
import { config } from "../../config";

@Component({
  selector: "app-subscription-management",
  templateUrl: "./subscription-management.component.html",
  styleUrls: ["./subscription-management.component.css"],
})
export class SubscriptionManagementComponent implements OnInit {
  config = config;

  loggedInUser: any = JSON.parse(localStorage.getItem("loggedInUser"));

  packageData: any;

  displayedColumns: string[] = [
    "actions",
    "fullName",
    "inviteEmailAddress",
    "registeredEmailAddress",
    "sendLink",
    "status",
    "date",
  ];
  // sample data format for coupon list data :: start
  subscriptionData: any[] = [
    {
      actions: "Resend/Delete",
      fullName: "Haily Smith",
      inviteEmailAddress: "hsmith@acme.com",
      registeredEmailAddress: "",
      sendLink: "https://www.algocyte.com",
      status: "Link Sent",
      date: "2/2/22",
    },
    {
      actions: "Actions",
      fullName: "Julie Marsh",
      inviteEmailAddress: "jmarsh@acme.com",
      registeredEmailAddress: "jm*****@***.com",
      sendLink: "",
      status: "Subscribe via Link",
      date: "2/15/22",
    },
    {
      actions: "Actions",
      fullName: "Julie Marsh",
      inviteEmailAddress: "jmarsh@acme.com",
      registeredEmailAddress: "jm*****@***.com",
      sendLink: "",
      status: "Subscribe via Link",
      date: "2/15/22",
    },
  ];
  // sample data format for coupon list data :: end

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private translate: TranslateService,
    private datePipe: DatePipe,
    private eCommorce: ECommorceService,
    public customPaginatorIntl: MatPaginatorIntl
  ) {
    this.getCouponDetails();
  }

  ngOnInit() {
  }

  // getCouponDetails :: start
  getCouponDetails() {
    this.eCommorce
      .getCouponDetails({ email: this.loggedInUser.email, code: "HAHJS" })
      .subscribe((data) => {
        this.setTableDataSource(data);
      });
  }
  // getCouponDetails :: end

  // setCouponList to table :: start
  setTableDataSource(data) {
    this.packageData = data.coupon;
    this.dataSource = new MatTableDataSource(data.invites);
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 1000);
  }
  // setCouponList to table :: end
}
