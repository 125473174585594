export let registeredRegion = [
  {
    name: "UK",
    config: {
      dateFormateShortV2: "dd MMM yyyy",
      dateFormateShort: "dd-MM-yyyy",
      dateFormatMid: "dd-MMM-yyyy",
      dateFormatLong: "dd-MMMM-yyyy",
      timeFormateShort: "h:mm a",
      dateTimeFormateShort: "dd-MM-yyyy h:mm:ss",
      dateTimeFormateShortV2: "dd MMM yyyy h:mm:ss",
      dateTimeFormateMid: "dd-MMM-yyyy h:mm",
      dateTimeFormateMidA: "dd-MMM-yyyy h:mm a",
      dateTimeFormateLong: "dd-MMMM-yyyy h:mm:ss a",
    },
  }
]; 
