<mat-icon [inline]="true" class="page-header-button-icon" [matBadge]="notificationCount"
  [matBadgeHidden]="notificationCount < 1" (click)="toggleList()">
  notifications
</mat-icon>

<!-- <div  style="position: absolute;"> -->
<mat-list class="notification-wrapper" id="list" *ngIf="list">
  <mat-list-item *ngFor="let notification of notifications">
    <a mat-line href="{{notification.reportPath}}" target="_blank"
      (click)="decreaseNotificationCount(notification.reportDate)">
      {{notification.patientName}}
      {{notification.reportDate | date: config.regionConfig.dateFormatMid}}
    </a>
  </mat-list-item>
</mat-list>
<!-- </div> -->