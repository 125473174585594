<div class="container pb-3">
  <div class="">
    <table class="table-responsive w-100" cellspacing="0" cellpadding="7">
      <tr>
        <td colspan="2" valign="top">
          <h2>{{ 'about.pageTitle' | translate }}</h2>
        </td>
      </tr>
      <tr valign="top">
        <td>
          <a>
            <!-- Remove Link with Task AFE-1073 [routerLink]="['/help']"-->
            <img
              class="img-fluid"
              width="100"
              src="assets/help-img/instructions-img.png"
              alt=""
              style="width: 80px; height: 60px"
            />
          </a>
        </td>
        <td>
          <p>
            {{ 'about.infoStatement' | translate }}
          </p>
        </td>
      </tr>
      <tr valign="top">
        <td>
          <img
            class="img-fluid"
            width="100"
            src="assets/help-img/oia-add-img.png"
            alt=""
            style="width: 80px; height: 60px"
          />
        </td>
        <td>
          <p>
            Oxford Immune Algorithmics Ltd.<br />
            6 Richfield Place<br />
            12 Richfield Avenue<br />
            Reading RG1 8EQ<br />
            United Kingdom
          </p>
          <!-- <p [innerHTML]="'about.oiaAddress' | translate"></p> -->
          <br />
          <!-- <p> {{ "footer.evalutionText" | translate }} </p> -->
          <!-- <p>{{ "about.ivdStatement" | translate }}</p> -->
        </td>
      </tr>
      <tr valign="top">
        <td>
          <img
            class="img-fluid"
            width="100"
            src="assets/help-img/ce ukca.png"
            alt=""
            style="width: 80px; height: 60px"
          />
        </td>
        <td>
          <!-- <p>For evaluation purposes only, Formative Usability Evaluation</p> -->
          <p>
            In compliance with European Commission Directive 98/79/EC for
            General IVD device and UK Medical Devices Regulations 2002 and for
            general IVD device.
          </p>
          <!-- <p>Made in the United Kingdom</p> -->
          <!-- <p [innerHTML]="'about.ckuaStatement' | translate"></p> -->
        </td>
      </tr>
      <tr valign="top">
        <td>
          <img
            class="img-fluid"
            src="assets/help-img/ivd-b.png"
            alt=""
            style="height: 60px; width: 80px"
          />
        </td>
        <td>
          <p>Made in the United Kingdom</p>
          <!-- {{ "about.ivdStatement" | translate }} -->
        </td>
      </tr>
      <tr valign="top">
        <td>
          <img
            class="img-fluid"
            src="assets/help-img/EC-REP.png"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>
          <span>CEpartner4U BV</span>
          <br />
          <span>Esdoornlaan 13</span>
          <br />
          <span>3951 DB Maarn</span>
          <br />
          <span>The Netherlands</span>
          <br />
          <a
            target="_blank"
            href="https://www.cepartner4u.com"
            style="color: #007bff !important"
            >www.CEpartner4U.com</a
          >
        </td>
      </tr>
      <tr valign="top">
        <td>
          <img
            class="img-fluid"
            width="100"
            src="assets/help-img/version-img.png"
            alt=""
            style="width: 80px; height: 60px"
          />
        </td>
        <td>
          <p>Algocyte<sup>&#169;</sup> Platform</p>
          <!-- {{ "about.refStatement" | translate }} -->
        </td>
      </tr>
      <tr valign="top">
        <td>
          <img
            class="img-fluid"
            width="100"
            src="assets/help-img/sys-label-img.png"
            alt=""
            style="width: 80px; height: 60px"
          />
        </td>
        <td>
          <!-- <p>Version: {{ config.version }}</p> -->
          <p>
            {{
              'about.lotStatement'
                | translate: { version: config.appVersion }
            }}
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="2" valign="top">
          <!-- <p>
            Where the CE marking appears against results within this system this
            means that the results fulfils the provisions of the European
            Commission (EC) Directive 93/42/EEC (Medical Device Directive) for
            Class I devices.
          </p>
          <p>
            This system has been developed under the quality management system
            (QMS) of Oxford Immune Algorithmics Ltd.
          </p> -->
          <!-- <p>
            {{ "about.noticeStatement" | translate }}
          </p> -->
          <p>
            {{ 'about.noticeStatement1' | translate }}
          </p>
        </td>
      </tr>
    </table>
  </div>
</div>
