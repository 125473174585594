// Config Data

export let commonConfig = {
  colors: {
    // green: "#73d1cb", // New
    // pink: "#ff94c3", // New
    // yellow: "#ffd794", // New
    green: "#43b5ac", // Old
    pink: "#f3543f", // Old
    yellow: "#ffbe65", // Old
    blue: "#0b5093",
    greenLight: "#717171",
    green1: "#599bdd",
    green2: "#717171",
    green3: "#cccccc",
    green4: "#4578ac",
    green5: "#cccbcb",
  },
  pattern: {
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,64}$/,
  },
  month: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  weekday: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  trendTooltip: `<table class="legend-table mt-2" cellpadding="5"
          style="font-style: normal;font-weight: 400;font-size: 12px;line-height: 16px;">
          <tbody>
            <!-- up -->
            <tr>
              <td>
                <img src="./../../../assets/dashboard/info/2x_lb_u_l.png" height="20px" width="20px">
              </td>
              <td class="donout-info-text">
                Test Result is higher than previous result
              </td>
            </tr>
            <!-- down -->
            <tr>
              <td>
                <img src="./../../../assets/dashboard/info/2x_lb_d_l.png" height="20px" width="20px">
              </td>
              <td class="donout-info-text">
                Test Result is lower than previous result
              </td>
            </tr>
            <!-- stable -->
            <tr>
              <td>
                <img src="./../../../assets/dashboard/info/2x_lb_s_l.png" height="20px" width="20px">
              </td>
              <td class="donout-info-text">
                Test Result is same as previous result
              </td>
            </tr>
          </tbody>
        </table>`,
  referenceTooltip: `<table class="legend-table mt-2" cellpadding="5">
          <tbody>
            <tr>
              <td>
                <img src="./../../../../assets/dashboard/circle-solid-green.svg" style="width:10px; hight:10px;">
              </td>
              </td>
              <td class="donout-info-text">
                Personal reference range
              </td>
            </tr>
            <tr>
              <td>
                <img src="./../../../../assets/dashboard/circle-solid-yellow.svg" style="width:10px; hight:10px;">
              </td>
              <td class="donout-info-text">
                Outside personal reference range
              </td>
            </tr>
            <tr>
              <td>
                <img src="./../../../../assets/dashboard/circle-solid-red.svg" style="width:10px; hight:10px;">
              </td>
              <td class="donout-info-text">
                Outside population range
              </td>
            </tr>
          </tbody>
        </table>`,
  scoreChangeTooltip: `<table class="legend-table mt-2" cellpadding="5">
          <tbody>
            <tr>
              <td>
                <img src="./../../../../assets/dashboard/circle-solid-grey.svg" style="width:10px; hight:10px; color:#cecece;">
              </td>
              </td>
              <td class="donout-info-text">
                Minimal Changes- Less than 5%
              </td>
            </tr>
            <tr>
              <td>
                <img src="./../../../../assets/dashboard/next.png" style="width:10px; hight:10px;">
              </td>
              <td class="donout-info-text">
                Minimal Change- Between 5% to 10%
              </td>
            </tr>
            <tr>
              <td>
                <img src="./../../../../assets/dashboard/right.png" style="width:10px; hight:10px;">
              </td>
              <td class="donout-info-text">
                Moderate Change- Between 10% to 20%
              </td>
            </tr>
            <tr>
              <td>
                <img src="./../../../../assets/dashboard/arrow.png" style="width:10px; hight:10px;">
              </td>
              <td class="donout-info-text">
                Large Change- More than 20%
              </td>
            </tr>
          </tbody>
        </table>`,
  cellColors: [
    "#FF65A1",
    "#43B5AC",
    "#FFBE65",
    "#FF77AE",
    "#56C0B8",
    "#FFC777",
    "#FF81B5",
    "#60C6BE",
    "#FFCD81",
    "#FF8CBD",
    "#6BCCC6",
    "#FFD38C",
    "#FF94c3",
    "#73D1CB",
    "#FFD794",
    "#FFA4CC",
    "#88D8D3",
    "#FFDDA4",
    "#FFB4D5",
    "#9DDFDB",
    "#FFE3B4",
    "#FFCAE1",
    "#B9E8E5",
    "#FFEBCA",
    "#FFDFED",
    "#D5F1EF",
    "#FFF3DF",
  ],
  markers: [
    "square",
    "circle",
    "diamond",
    "triangle",
    "star5",
    "star6",
    "star7",
    "star8",
    "rpoly5",
    "gear5",
    "gear6",
    "gear7",
    "gear8",
  ],
  genders: [
    { text: "Not Known", value: 1 },
    { text: "Male", value: 2 },
    { text: "Female", value: 3 },
    { text: "Not Specified", value: 4 },
  ],
  ageGroups: [
    { text: "0-10 yrs", value: "0-10" },
    { text: "10-20 yrs", value: "10-20" },
    { text: "20-30 yrs", value: "20-30" },
    { text: "30-40 yrs", value: "30-40" },
    { text: "40-50 yrs", value: "40-50" },
    { text: "50-60 yrs", value: "50-60" },
    { text: "60-70 yrs", value: "60-70" },
    { text: "70-80 yrs", value: "70-80" },
    { text: "80-90 yrs", value: "80-90" },
  ],
};
