import { Injectable } from '@angular/core';

import { Role, User } from '../../../types';
import { ApiService } from '../api.service';
import { UserService } from './user.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {}

  get user(): User | null {
    return this.userService.user;
  }

  get roles(): Role[] {
    return this.user?.roles || [];
  }

  get isSubscriber(): boolean {
    return this.roles.includes('subscriber');
  }

  get isProvider(): boolean {
    return this.roles.includes('provider');
  }

  get isAdmin(): boolean {
    return this.roles.includes('admin');
  }

  get currentRole(): Role {
    if (this.isAdmin) {
      return 'admin';
    }
    if (this.isProvider) {
      return 'provider';
    }
    return 'subscriber';
  }

  updateRole(role: Role): Observable<any> {
    if (!this.user) {
      return of();
    }
    return this.apiService.testUpdateUserRole(this.user.id, role);
  }

  mapRoleToLegacyRoleId = (roles: Role[]): number => {
    const roleMapping: { [roleName: string]: number } = {
      admin: 3001,
      provider: 2001,
      subscriber: 1001,
    };

    for (const roleName of Object.keys(roleMapping)) {
      if (roles.some((role) => role === roleName)) {
        return roleMapping[roleName];
      }
    }
  };
}
