import { Injectable, EventEmitter, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { config } from '../config';
import { RoleService } from '../services/user/role.service';
import { Observable, of } from 'rxjs';
import { UserService } from './user/user.service';
import { TestConfigName } from './test-kit/types';

@Injectable({
  providedIn: 'root',
})
export class TestService {
  uuid = config.UUID;
  hid = JSON.parse(localStorage.getItem('dateSelected'))
    ? JSON.parse(localStorage.getItem('dateSelected'))
    : null;
  ptid = localStorage.getItem('patientId')
    ? localStorage.getItem('patientId')
    : this.uuid;
  profile = JSON.parse(localStorage.getItem('testProfile'))
    ? JSON.parse(localStorage.getItem('testProfile')).id
    : null;
  patient: EventEmitter<any> = new EventEmitter();
  testProfile: EventEmitter<any> = new EventEmitter();
  profileModification: EventEmitter<any> = new EventEmitter();
  loginSuccess: EventEmitter<any> = new EventEmitter();
  signUpSuccess: EventEmitter<any> = new EventEmitter();
  verifyUser: EventEmitter<any> = new EventEmitter();
  goTologin: EventEmitter<any> = new EventEmitter();
  forgotPasswordEnable: EventEmitter<any> = new EventEmitter();
  forgotPassword: EventEmitter<any> = new EventEmitter();
  verifyUserEnable: EventEmitter<any> = new EventEmitter();
  guestUser: EventEmitter<any> = new EventEmitter();
  removePatient: EventEmitter<any> = new EventEmitter();
  getNotification: EventEmitter<any> = new EventEmitter();
  isUserVerified: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private ngZone: NgZone,
    private routing: Router,
    private roleService: RoleService,
    private userService: UserService,
    private _translate: TranslateService
  ) {
    this.loginSuccess.subscribe((res: boolean) => {
      if (res) {
        this.uuid = JSON.parse(localStorage.getItem('UUID'));
        if (!this.roleService.isProvider) {
          this.ptid = localStorage.getItem('patientId');
        }
      }
    });

    this.signUpSuccess.subscribe((res: boolean) => {});

    this.verifyUserEnable.subscribe((res: boolean) => {});

    this.verifyUser.subscribe((res: boolean) => {});

    this.forgotPassword.subscribe((res: boolean) => {});

    this.forgotPasswordEnable.subscribe((res: boolean) => {});

    this.goTologin.subscribe((res: boolean) => {});

    this.isUserVerified.subscribe((data: boolean) => {});
  }

  doRouting(path, option?: {}) {
    this.ngZone.run(() => {
      this.routing.navigate(path, option);
    });
  }

  setTestProfile(profile) {
    localStorage.setItem('testProfile', JSON.stringify(profile));
    this.profile = JSON.parse(localStorage.getItem('testProfile')).id;
    this.testProfile.emit(true);
  }

  getQuery(time?, testOption?, freqOption?, filter?) {
    let timeParams = '';
    let tId = '';
    let freq = '';
    let dateFilter = '';
    const testprofileid = `&testprofileid=${this.profile}`;
    if (time) timeParams = `&time=${time}`;
    if (testOption) tId = `&tid=${testOption}`;
    if (freqOption) freq = `&freq=${freqOption}`;
    if (filter)
      dateFilter = `&start_date=${
        filter.start_date ? filter.start_date : null
      }&end_date=${filter.end_date ? filter.end_date : null}`;
    switch (JSON.parse(localStorage.getItem('roleId'))) {
      case 1001:
        return `ptid=${this.ptid}${timeParams}${tId}${freq}${testprofileid}${dateFilter}`;

      case 2001:
        return `uuid=${this.uuid}&ptid=${this.ptid}${timeParams}${tId}${freq}${testprofileid}${dateFilter}`;

      case 3001:
        return `mid=${this.uuid}&ptid=${this.ptid}${timeParams}${tId}${freq}${testprofileid}${dateFilter}`;

      default:
        return ``;
    }
  }

  sendFileToServer(data) {
    return of(data);
  }

  requestDispatchSeries(dates, testOption?, freqOption?) {
    return of(dates);
  }

  deleteDispatchRequests(data) {
    return of(data);
  }

  getDispatchRequests() {
    return of();
  }

  getAllReferenceRanges() {
    return of([]);
  }

  getAnalytesForManualEntry() {
    return of();
  }

  commitTheFinalResult(data) {
    return of(data);
  }

  getQuestionaryInfo(qCategoryId, healthId?) {
    return of({ healthId, qCategoryId });
  }

  saveQuestionaryInfo(tag, data, qCategoryId?) {
    return of({ tag, data, qCategoryId });
  }

  registerKitRefNumber(kitId: string): Observable<any> {
    return this.http.post(`${config.baseApiv2Url}/home-test-kits`, {
      serialNumber: kitId,
      userId: this.userService.user?.id,
      testType: TestConfigName.FBC,
    });
  }

  updateTestKitQuestionnaire(id: number, questionnaire): Observable<any> {
    return this.http.post(`${config.baseApiv2Url}/home-test-kits/${id}/questionnaire`, questionnaire);
  }

  getRegisterKits(query: {
    pageSize: number;
    pageOffset: number;
    sortBy: string;
    sortOrder: string;
  }): Observable<any> {
    const { pageSize, pageOffset, sortOrder, sortBy } = query;

    return this.http.get(
      `${config.baseApiv2Url}/home-test-kits?pageSize=${pageSize}&pageOffset=${pageOffset}&sortBy=${sortBy}&sortOrder=${sortOrder}`
    );
  }

  getAllTestProfiles() {
    return of();
  }

  getAllAnalytes() {
    return of();
  }

  deleteTestProfile(profileId) {
    return of(profileId);
  }

  addOrUpdateProfile(data) {
    return of(data);
  }

  profileModificationEvent() {
    this.profileModification.emit(true);
  }

  getAdaptiveData() {
    return of();
  }

  getSuggestAddressLocation(data) {
    return this.http.get(
      `https://r79oritvc0.execute-api.eu-west-1.amazonaws.com/Prod/geocode/api/autocomplete/autocomplete?q=${
        data.enteredPinCode ? data.enteredPinCode : null
      } &in=countryCode:${
        data.selectedCountryCode ? data.selectedCountryCode : null
      };city:${data.selectedCity ? data.selectedCity : ''}&limit=20`
    );
  }

  validateGuestAndGetData(formData) {
    return this.http.post(
      `https://api.in.algosom.com/algosom-smartreports/webview/validate`,
      formData,
      {
        headers: {
          'x-api-key': 'MHybHeD7xI4gIqeB79aFvaWPTLm3UhJj9XQ2A2Ws',
          'Accept-Encoding': 'gzip, deflate, br',
        },
      }
    );
  }

  getTranslation(key, extraVal?) {
    return new Promise((resolve, reject) => {
      this._translate.get(key, extraVal).subscribe((res) => {
        return resolve(res);
      });
    });
  }
}
