<div class="container p-5">
  <div class="row justify-content-center">
    <h2 style="font-size: 42px; text-align: center">
      {{ 'home.brandStatement' | translate }}
    </h2>
  </div>
  <div class="row justify-content-center">
    <p style="color: gray; text-align: center">
      {{ 'home.brandStatement1' | translate }}
    </p>
  </div>
  <br />
  <br />
  <div class="row justify-content-center">
    <p style="color: gray">{{ 'home.brandStatement2' | translate }}</p>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-wrap align-content-center">
        <div class="w-100 d-block text-center">
          <button
            class="btn btn-outline-success d-block m-auto"
            (click)="goTo('/auth/signup')"
          >
            {{ 'home.signUpBtnText' | translate }}
          </button>
          {{ 'home.alreadyAcInfo' | translate }}
          <a
            class="btn btn-link"
            href="javascript:void(0)"
            (click)="goTo('/auth/signin')"
          >
            {{ 'home.loginBtnText' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
