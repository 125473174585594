import { HeaderComponent } from "./general/header/header.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";
import { RecaptchaModule } from "ng-recaptcha";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { FooterComponent } from "./general/footer/footer.component";
import { CommonModule } from "@angular/common";
import { DataTablesModule } from "angular-datatables";
import { NewTestComponent } from "./report/new-test/new-test.component";
import { SettingsComponent } from "./general/settings/settings.component";
import { HomeComponent } from "./general/home/home.component";
import { WebcamModule } from "ngx-webcam";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadDndComponent } from "./general/file-upload-dnd/file-upload-dnd.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { MyEventsComponent } from "./report/my-events/my-events.component";
import { BarRatingModule } from "ngx-bar-rating";
import { DatePipe } from "@angular/common";
import { MatTableExporterModule } from "mat-table-exporter";
import { AboutComponent } from "./general/about/about.component";
import { HelpComponent } from "./general/help/help.component";
import { AuthInterceptor } from "./services/interceptor";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "ng-pick-datetime-ex";
import { TestProfileComponent } from "./user/test-profile/test-profile.component";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { FoldingPlotComponent } from "./report/folding-plot/folding-plot.component";
import { GraphComponent } from "./report/all-reports/graph/graph.component";
import { ImportResultsComponent } from "./report/all-reports/import-results/import-results.component";
import { DashboardComponent } from "./report/dashboard/dashboard.component";
import { DemographicInfoFormComponent } from "./user/account/demographic-info-form.component";
import { ClinicianProfileComponent } from "./user/clinician-profile/clinician-profile.component";
import { MyPatientsComponent } from "./user/my-patients/my-patients.component";

import { QrScannerComponent } from "./report/new-test/qr-scanner/qr-scanner.component";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { DialogModule } from "primeng/dialog";
import { MyfilterPipe } from "./pipes/myfilter.pipe";
import { NgPipesModule } from "ngx-pipes";
import { InfoMessageComponent } from "./utility/info-message/info-message.component";
import { SmartReportComponent } from "./report/smart-report/smart-report.component";

import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { ReportModule } from "./report/report.module";
import { ChartModule } from "./utility/chart/chart.module";
import { ECommerceModule } from "./e-commerce/e-commerce.module";
import { BetaComponent } from "./utility/beta/beta.component";

import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from "@angular/material/autocomplete";
import { MAT_SELECT_SCROLL_STRATEGY } from "@angular/material/select";
import { Overlay, BlockScrollStrategy } from "@angular/cdk/overlay";

import { NotificationsModule } from "./utility/notifications/notifications.module";
import { UserModule } from "./user/user.module";

import { ArraySortPipe } from "./pipes/sort-pipe";

import { SlickCarouselModule } from "ngx-slick-carousel";
import { MAT_MENU_SCROLL_STRATEGY } from "@angular/material/menu";
import { MAT_DATEPICKER_SCROLL_STRATEGY } from "@angular/material/datepicker";
import { SurveyCreatorModule } from "survey-creator-angular";
import { SurveyModule } from "survey-angular-ui";
import { TestDataComponent } from "./user/test-data/test-data.component";
import { AuthModule } from "./modules/auth/auth.module";
import { SharedModule } from "./modules/shared/shared.module";
import { LayoutWithHeaderComponent } from "./components/layout/layoutWithHeader/layoutWithHeader.component";
import { LayoutWithOutHeaderComponent } from "./components/layout/layoutWithoutHeader/layoutWithoutHeader.component";
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import { AlertBannerComponent } from './general/alert-banner/alert-banner.component';
import { ApiComponent } from './general/api/api.component';
import { QuestionnaireModalComponent } from './report/new-test/questionnaire-modal/questionnaire-modal.component';

declare global {
  interface Document {
    documentMode?: any;
  }
}

export const scannerModule: any[] = [ZXingScannerModule];

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

export function initAppFactory(authService: AuthService, userService: UserService) {
  return () => authService.initializeFirebase().then((userId) => {
    // subscribe to further changes
    userService.subscribeToAuthChanges();

    // fetch initial user data
    if (userId) {
      return userService.fetchFullUserData(userId).toPromise();
    }
  }).catch((ex) => console.error(`initAppFactory: ${ex.message}`));
}


@NgModule({
  declarations: [
    AppComponent,
    LayoutWithHeaderComponent,
    LayoutWithOutHeaderComponent,
    ArraySortPipe,
    FooterComponent,
    NewTestComponent,
    QuestionnaireModalComponent,
    SettingsComponent,
    HomeComponent,
    FileUploadDndComponent,
    MyEventsComponent,
    AboutComponent,
    HelpComponent,
    TestProfileComponent,
    FoldingPlotComponent,
    GraphComponent,
    ImportResultsComponent,
    DashboardComponent,
    ApiComponent,
    DemographicInfoFormComponent,
    ClinicianProfileComponent,
    TestDataComponent,
    MyPatientsComponent,
    QrScannerComponent,
    HeaderComponent,
    MyfilterPipe,
    InfoMessageComponent,
    SmartReportComponent,

    BetaComponent,
    AlertBannerComponent,
  ],
  imports: [
    AuthModule,
    SharedModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    DataTablesModule,
    WebcamModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    NgxUiLoaderModule,
    NgPipesModule,
    ZXingScannerModule,
    NgSelectModule,
    ECommerceModule,
    BarRatingModule,
    MatTableExporterModule,
    NgIdleKeepaliveModule.forRoot(),
    DialogModule,
    ChartModule,
    NotificationsModule,
    ReportModule,
    UserModule,
    AmplifyAngularModule,
    RecaptchaModule,
    SurveyCreatorModule,
    SurveyModule,
    SlickCarouselModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  exports: [],
  providers: [
    AmplifyService,
    DialogModule,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFactory,
      deps: [AuthService, UserService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    {
      provide: MAT_DATEPICKER_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: "always" },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
