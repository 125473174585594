import { Component, OnInit } from '@angular/core';
import { config } from "../../../config";

@Component({
  selector: 'app-resource-links',
  templateUrl: './resource-links.component.html',
  styleUrls: ['./resource-links.component.css']
})
export class ResourceLinksComponent implements OnInit {
  config: any = config;

  constructor() { }

  ngOnInit(): void {
  }

  downloadHelpPdf() {
    window.open("/assets/Algocyte Platform v1.8 Instructions for use.pdf");
  }

  downloadQuickStartGuide() {
    window.open("/assets/Algocyte Platform v1.8 Quick Start Guide.pdf");
  }

}
