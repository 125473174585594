import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { config } from '../../../config';
import { commonConfig } from '../../../config/common/common';
import { TestService } from '../../../services/test.service';
import { ReportsService } from '../../../services/reports/reports.service';
import { MatSnackBar } from '@angular/material/snack-bar';
declare global { interface Navigator { msSaveBlob?: (blob: any, defaultName?: string) => boolean } }
declare var Pace: any;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  config = config;
  list: boolean = false;
  // fileName:string="";
  notificationCount: number = 0;
  @Input() notificationData = new EventEmitter();
  notifications: any = [];
  // notifications: any = [
  //   {
  //     healthId: 1111,
  //     label: "4thDecember2022",
  //     fileUrl: "https://app.eu.algocyte.com/"
  //   },
  //   {
  //     healthId: 1112,
  //     label: "5thDecember2022",
  //     fileUrl: "https://app.eu.algocyte.com/"
  //   },
  //   {
  //     healthId: 1113,
  //     label: "6thDecember2022",
  //     fileUrl: "https://app.eu.algocyte.com/"
  //   },
  //   {
  //     healthId: 1114,
  //     label: "8thDecember2022",
  //     fileUrl: "https://app.eu.algocyte.com/"
  //   }
  // ];

  constructor(
    private changeRef: ChangeDetectorRef,
    private _testService: TestService,
    private _reportService: ReportsService,
    private _snackBar: MatSnackBar
  ) {
    _testService.getNotification.subscribe((response) => {
      // if (response) this.notifications.push(JSON.parse(response));
      console.log("this.notifications :: ", this.notifications);
      this.notifications.push(response);
      localStorage.setItem("report", JSON.stringify(this.notifications));
      // this.notificationCount = this.notifications.length;
      this.getNotificationCount(this.notifications);
      // this._snackBar.dismiss();
      // this.getGenerateReport(JSON.parse(response)['reportPath']);
    });

  }

  ngOnInit() {
    console.log("this.notifications :: ", this.notifications);
    this.notifications = localStorage.getItem('report') ? JSON.parse(localStorage.getItem('report')) : [];
    // this.notificationCount = this.notifications.length;
    this.getNotificationCount(this.notifications);
    this.changeRef.detectChanges();
  }

  toggleList() {
    if (!this.list) {
      $('list').addClass('d-block');
      this.list = true;
    } else if (this.list) {
      $('list').addClass('d-none');
      this.list = false;
    }
  }

  //make notification  is read.
  decreaseNotificationCount(reportDate) {
    // if (this.notificationCount !== 0) this.notificationCount--;
    this.notifications.forEach((element) => {
      if (element.reportDate === reportDate) element.isRead = true;
    });
    localStorage.setItem("report", JSON.stringify(this.notifications));
    this.getNotificationCount(this.notifications);
    this.changeRef.detectChanges();

  }

  //check how much notifications are read or not according to that set Notification Count
  getNotificationCount(notificationArray) {
    notificationArray.forEach(element => {
      if (!element.isRead) {
        this.notificationCount++;
      }
    });
  }

  // getGenerateReport(reportFileUrl) {
  //   this._reportService.getGenerateReport(reportFileUrl).subscribe((response: any) => {
  //     console.log("response", response);
  //     if (response && response.exists) {
  //       if (response && response.url) {
  //         this.notifications.push({
  //           reportPath: `${response.url['url']}`,
  //           patientName: localStorage.getItem('patient') ? JSON.parse(localStorage.getItem('patient'))['forename'] + "_" + JSON.parse(localStorage.getItem('patient'))['surname'] : " ",
  //           reportDate: response.url['expires_at']
  //         });
  //         this.downloadPDF(response.url['url']);
  //       }
  //     }
  //   });
  // }

  // downloadPDF(fileUrl) {
  //   fetch(fileUrl, {
  //     method: 'GET',
  //   }).then(resp => resp.blob())
  //     .then(blob => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.style.display = 'none';
  //       a.href = url;
  //       a.download = this.fileName+"Algocyte-Report"; // the filename you want
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       /* Now stop the loading progress bar START */
  //       Pace.stop();
  //       /* Now stop the loading progress bar END */
  //     })
  // }

}
