<div
  *ngIf="selectedResultSet"
  class="col-12 order-first order-md-last no-padding with-border"
>
  <div class="bar-section">
    <div class="row no-margin-right">
      <div class="desc-immunescore" id="desc-immunescore-focus">
        <mat-card class="mat-elevation-z8">
          <p
            class="disclimer"
            [innerHTML]="'dashboard.summaryModalTitle' | translate"
          ></p>
          <p
            [innerHTML]="
              'dashboard.summaryModalNonAdaptiveTestDescription' | translate
            "
          ></p>
          <a
            download="Immune_Score_White_Paper.pdf"
            class="btn border mt-2 w-100 text-uppercase"
            href="assets/Immune Score White Paper.pdf"
          >
            <i class="mr-2 fas fa-cloud-download-alt"></i>
            {{ 'dashboard.downloadMoreDetails' | translate }}
          </a>
        </mat-card>
      </div>

      <div id="tooltip-div" class="col-12">
        <div class="row">
          <div class="col-11 text-center d-none d-md-block title-column">
            <p class="title">{{ getTitle(selectedResultSet) }}</p>
          </div>

          <div class="col-1 d-none d-md-block no-padding">
            <div class="padding-5">
              <button
                style="float: right !important"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
              >
                <mat-icon>menu</mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="before">
                <button
                  *ngFor="let option of reportOptions"
                  mat-menu-item
                  (click)="performAction(option.action)"
                >
                  <mat-icon>{{ option.icon }}</mat-icon>
                  <span>{{ option.type }}</span>
                </button>
              </mat-menu>
            </div>
          </div>

          <mat-icon
            class="material-icons-round padding-bottom-5 padding-top-5 font-12 display-score d-none d-xs-block d-sm-block"
            style="margin-left: -12px"
            [ngClass]="isExpanded ? 'padding=right-5' : ''"
            (click)="toggleExpanded()"
          >
            {{ !isExpanded ? 'arrow_forward_ios' : 'arrow_back_ios' }}
          </mat-icon>
        </div>
        <div class="row text-center">
          <app-bar
            class="col-12 margin-bottom-10 margin-top-5"
            [barData]="chartData"
          ></app-bar>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!selectedResultSet"
  class="d-flex align-items-center justify-content-center w-100 with-border"
>
  <h5>No Health Data Found</h5>
</div>

<div class="col-12 bar-chart-loader" *ngIf="isLoading">
  <ngx-skeleton-loader
    count="1"
    appearance="circle"
    [theme]="{
      width: '100%',
      height: '345px',
      'border-radius': '10px',
      float: 'center'
    }"
  ></ngx-skeleton-loader>
</div>
