import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineComponent } from './line/line.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModules } from 'src/app/modules/shared/angular-material.module';
import { MatTableExporterModule } from 'mat-table-exporter';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CachedHttpTranslationLoader } from 'src/app/modules/shared/cachedTranslateLoader';
import { HttpClient } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DonutComponent } from './donut/donut.component';
import { BarComponent } from './bar/bar.component';
import { MixComponent } from './mix/mix.component';

@NgModule({
  declarations: [LineComponent, DonutComponent, BarComponent, MixComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    AppMaterialModules,
    MatTableExporterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CachedHttpTranslationLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
      useDefaultLang: true,
      isolate: true,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [LineComponent, DonutComponent, BarComponent, MixComponent],
})
export class ChartModule {}
