import { Component, OnInit, ViewChild } from "@angular/core";
import { SurveyCreatorModel, SurveyQuestionEditorDefinition } from "survey-creator-core";
import { Model, Serializer } from "survey-core";
import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import { MatStepper } from '@angular/material/stepper';
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { commonConfig } from "./../../config/common/common";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { GptService } from "./../../services/gpt/gpt.service";
import Swal from "sweetalert2";
import { error } from "console";

const creatorOptions = {
  showLogicTab: false,
  showPropertyGrid: false,
  isAutoSave: true,
  showToolbox: false,
  haveCommercialLicense: true,
};

@Component({
  selector: "app-survey-creator",
  templateUrl: "./survey-creator.component.html",
  styleUrls: ["./survey-creator.component.css"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class SurveyCreatorComponent implements OnInit {
  model: SurveyCreatorModel;
  @ViewChild('stepper') stepper!: MatStepper;

  stepTwoTitle = "";
  stepOneQuestions: any = []
  selectedPatinet: any = JSON.parse(localStorage.getItem("patient"));
  isTemplate: boolean = false
  stepTwoInfo: any = {
    title: "",
    userInfo: [
      {
        icon: "account_circle",
        key: `${this.selectedPatinet.forename} ${this.selectedPatinet.surname}`,
        name: "Name",
      },
      {
        icon: "transgender",
        key: commonConfig.genders[`${this.selectedPatinet.Gender}`].text,
        name: "Gender",
      },
      {
        icon: "cake",
        key: `${new Date().getFullYear() -
          new Date(this.selectedPatinet.DOB).getFullYear()
          } Years`,
        name: "Age",
      },
      {
        icon: "local_hospital",
        key: "Medical Conditions",
        name: "",
      },
      { key: "Gastro-oesophageal reflux disease (GORD)" },
      { key: "Diabetes (type-2)" },
      { key: "Foetal alcohol syndrome" },
      { key: "Urinary tract infection (UTI)" },
      {
        icon: "smoking_rooms",
        key: "Smoking: 1 pack / per day",
        name: "Smoking",
      },
      {
        icon: "local_bar",
        key: "14 units / per week",
        name: "Drinking",
      },
    ],
    testInfo: [
      {
        icon: "account_circle",
        key: `${this.selectedPatinet.forename} ${this.selectedPatinet.surname}`,
        name: "Name",
      },
      {
        icon: "transgender",
        key: commonConfig.genders[`${this.selectedPatinet.Gender}`].text,
        name: "Gender",
      },
    ],
  };

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ["", Validators.required],
  });

  secondFormGroup = this._formBuilder.group({
    secondCtrl: ["", Validators.required],
  });

  isLinear: boolean = false;
  isProfileSpecific: boolean = false;
  isTestSpecific: boolean = false;
  isHistorySpecific: boolean = false;
  isOWNSpecific: boolean = false;
  isOldSpecific: boolean = false;
  stepTwoPrompt: string;
  sanitizedDescription: SafeHtml;
  request_id: any;
  editPromptStatus: boolean = false;
  suggestions: any;
  formFields: any;
  previewMode: boolean = false;
  surveyModel: Model;
  loadingQuestion: boolean;
  publishQuestion: boolean;
  formTitle: any;
  questionnaireTitle: any;
  selectedReportDate: any;
  ptid: any;
  hid: any;
  constructor(
    private router: ActivatedRoute,
    private _formBuilder: FormBuilder, private route: Router, private sanitizer: DomSanitizer, private _gptService: GptService) {


    this.ptid = this.router.snapshot.paramMap.get("patient")
    this.hid = this.router.snapshot.paramMap.get("testId")

    // All Dates for fbc
    const fbcDates = JSON.parse(localStorage.getItem('fbc_dates'))

    // Find index form all dates
    const index = fbcDates.findIndex(o => o.health_id === this.hid);

    this.selectedReportDate = fbcDates[index]

    this._gptService.getGptTypes().subscribe((types: any) => {
      this.stepOneQuestions = types;
    }, (error: any) => {
      console.log("error", error)
    })

  }

  ngOnInit() {

  }


  ngAfterViewInit() {

  }

  alertResults(sender) {
    console.log(sender.data)
    const results = JSON.stringify(sender.data);
    console.log(results)
  }

  viewQuestionnaire() {
    const survey = new Model(this.formFields);
    survey.onComplete.add(this.alertResults);
    this.surveyModel = survey;
  }


  createQuestionnaire(defaultJson) {

    const creator = new SurveyCreatorModel(creatorOptions);
    const rawDescription = '<p>This is the <strong>description</strong> for Question 1.</p>';
    this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(rawDescription);

    // "rating", 
    // "ranking",
    // "panel",
    // "paneldynamic",

    // const list = ["file", "image", "imagepicker", "matrix", "matrixdynamic", "html", "expression", "multipletext", "signaturepad", "matrixdropdown"]

    // list.forEach((item) => {
    //   creator.toolbox.removeItem(item);
    // })

    creator.text = JSON.stringify(defaultJson);

    creator.saveSurveyFunc = (saveNo: number, callback: Function) => {
      // If you use localStorage:
      console.log("creator", creator.text)
      this.formFields = creator.text
      // window.localStorage.setItem("survey-json", creator.text);
      // callback(saveNo, true);

      // If you use a web service:
      // this.formFields = creator
    };

    creator.haveCommercialLicense = true;
    this.model = creator;

    this.loadingQuestion = false
  }


  checkValue(question, index) {
    console.log("question.name ++++=", question.name)
    if (question.isChecked) {
      // Current Is Selected
      this.stepOneQuestions.forEach((que, i) => {
        if (index != i) {
          // console.log(" Que must not be selected ", que.isChecked);
          if (que.isChecked) que.isChecked = false;
        } else {
          if (question.name == "profile-based") {
            this.questionnaireTitle = question.title
            // //Profile Base
            this.isProfileSpecific = true;
            this.isTestSpecific = false;
            this.isOWNSpecific = false;
            this.stepTwoTitle = `< b > Profile - based < /b> questionnaire are only based on the profile data. Click
                                        “Generate” to launch the questionnaire`;
          } else if (question.name == "test-based") {
            // Test Base
            this.isOWNSpecific = false
            this.questionnaireTitle = question.title
            this.isProfileSpecific = false;
            this.isTestSpecific = true;
            this.stepTwoTitle = `<b> Test - based </b> questionnaire will consider the profile of the patient as well as the test listed below.Confirm the details then click on “Generate” to launch the questionnaire`;
          } else if (question.name == "design-based") {
            this.isProfileSpecific = false;
            this.questionnaireTitle = question.title
            this.isTestSpecific = false;
            this.isOWNSpecific = true;
            this.isOldSpecific = false;
            this.isHistorySpecific = false;
            this.stepTwoPrompt = "Under Development";
            this.stepTwoTitle = `Under Development`;
          }
          else {
            Swal.fire({
              type: 'warning',
              title: "This type is currently under development",
              text: "Choose the type of Questionnaire you would like to generate from the options",
            });
          }
        }
      });
    } else {
      this.isProfileSpecific = false;
      this.isTestSpecific = false;
      this.isOWNSpecific = false;
      this.isOldSpecific = false;
      this.isHistorySpecific = false;
    }

    console.log(question, index);
  }

  goToBack(location) {

    if (location === 'dashboard') {
      // GoTo the Dashboard
      this.route.navigate(["/" + "dashboard"]);
    }
    else if (location === 'previous') {
      alert(" Go back to last step ")
    }
    else {
      alert(" Please,Let me know, Where you want's to go?")
    }
  }

  edit() {
    // GoTo the Dashboard
  }

  getCurrentStepIndex() {
    if (this.stepper && this.stepper.selectedIndex === 2) {
      console.log('Current Step Index:', this.stepper.selectedIndex);
      return true
    }
    else {
      return false
    }
  }

  typeSelected() {

    let isGoNext = false

    if (this.stepOneQuestions) {
      console.log(this.stepOneQuestions)
      this.stepOneQuestions.forEach((event: any) => {
        if (event.isChecked) {
          isGoNext = event.name
          this.formTitle = event.title
        }
        else {

        }
      })
    }

    if (isGoNext) {
      console.log("***** Type :::::::", isGoNext)

      if (isGoNext === 'design-based') {
        // Create Request Id `for this
        // this.stepper.next()
        // this.generate(true)
        this.createRequestId(isGoNext)
        // alert("hey hey s")
      }
      else {
        // alert(isGoNext)
        if (isGoNext === "profile-based") {
          // Go to prompt screen
          console.log(" ------------------------------- Select as profile based ------------------------------- ")
          this.createRequestId(isGoNext)
          // this.stepper.next()
        }
        else if (isGoNext === 'template-based') {
          console.log(" ------------------------------- Select as template based ------------------------------- ")
          // no need for now
        }
        else if (isGoNext === 'test-based') {
          console.log(" ------------------------------- Select as test based ------------------------------- ")
          // Go to prompt screen
          this.createRequestId(isGoNext)
          // this.stepper.next()
        }
        else {
          console.log(" ------------------------------- Check isGoNext for  ------------------------------- ", isGoNext)
        }
      }
    }
    else {
      Swal.fire({
        type: 'error',
        title: "Please, Select type",
        text: "Choose the type of Questionnaire you would like to generate from the options",
        showConfirmButton: true,
        confirmButtonText: "Retry"
      });

      return false;
    }
  }

  createRequestId(type) {

    console.log("Hello See this", {
      "requester_id": JSON.parse(localStorage.getItem('loggedInUser')).UUID,
      "subscriber_id": JSON.parse(localStorage.getItem('patient')).UUID,
      "type_id": type
    })


    console.log("...............................")
    let data;
    if (type == 'test-based') {
      data = {
        "requester_id": JSON.parse(localStorage.getItem('loggedInUser')).UUID,
        "subscriber_id": JSON.parse(localStorage.getItem('patient')).UUID,
        "type_id": type,
        "health_id": this.hid
      }
    }
    else if (type == 'profile-based') {
      data = {
        "requester_id": JSON.parse(localStorage.getItem('loggedInUser')).UUID,
        "subscriber_id": JSON.parse(localStorage.getItem('patient')).UUID,
        "type_id": type
      }
    }
    if (type === 'design-based') {
      // Create Own Questionary
      data = {
        "requester_id": JSON.parse(localStorage.getItem('loggedInUser')).UUID,
        "subscriber_id": JSON.parse(localStorage.getItem('patient')).UUID,
        "type_id": type
      }
    }
    else {
      // alert("Hey")
      // data = {
      //   "requester_id": JSON.parse(localStorage.getItem('loggedInUser')).UUID,
      //   "subscriber_id": JSON.parse(localStorage.getItem('patient')).UUID,
      //   "type_id": type
      // }
    }


    console.log("data as request ", data)

    this
      ._gptService
      .getNewRequest(data)
      .subscribe((response: any) => {

        console.log("Response", response)

        this.request_id = response.request_id

        if (type == "design-based") {
          this.stepper.next()
          this.formFields = {
            "title": this.questionnaireTitle,
            "pages": [
              {
                "elements": [
                  {
                    "type": "text",
                    "name": "Please enter your question here"
                  }
                ]
              }
            ]
          };

          console.log(this.formFields)

          this.createQuestionnaire(this.formFields)
        }
        else {
          this._gptService.getPrompt({
            "requester_id": JSON.parse(localStorage.getItem('loggedInUser')).UUID,
            "subscriber_id": JSON.parse(localStorage.getItem('patient')).UUID,
            "request_id": response.request_id,
            "real_time": 1
          }).subscribe((prompts: any) => {
            console.log("prompts", prompts)
            this.stepTwoPrompt = prompts.prompt
            this.stepper.next()
          }, (error: any) => {
            console.error("ERROR in getting prompts", error)
          })

        }


      }, (error: any) => {
        console.log("ERROR in getting request id", error)
      })

  }


  editPrompt() {
    alert(" edit prompt ")
    this.editPromptStatus = true
  }

  async generate(withoutRequestId?) {

    this.loadingQuestion = true
    this.stepper.next()
    console.log(withoutRequestId)
    if (!withoutRequestId) {

      this
        ._gptService
        .getSuggestions({
          "requester_id": JSON.parse(localStorage.getItem('loggedInUser')).UUID,
          "subscriber_id": JSON.parse(localStorage.getItem('patient')).UUID,
          "request_id": this.request_id,
          "real_time": 1
        })
        .subscribe(async (suggestions: any) => {
          this.suggestions = suggestions
          this.formFields = await this.suggestedQuestionnaireToSurveyCreator(this.suggestions.suggested_questionnaire)
          this.formFields.title = this.questionnaireTitle
          this.createQuestionnaire(this.formFields)
        }, (error: any) => {
          console.error(" ERROR ", error)
        })

    }
    else {

      const surveyJson = {
        "title": this.questionnaireTitle,
        "logoPosition": "right",
        "pages": [
          {
            "elements": [
              {
                "type": "text",
                "name": "demo input"
              }
            ]
          }
        ]
      };

      console.log(surveyJson)

      this.createQuestionnaire(surveyJson)
    }


  }

  async suggestedQuestionnaireToSurveyCreator(suggestions) {

    console.log("suggestions", suggestions)


    let questions = []

    suggestions.forEach((suggestion: any, index: number) => {
      console.log(suggestion)
      questions.push({
        "title": suggestion.question,
        "type": "comment",
        "name": `question${index + 1}`,
        "hypothesis": suggestion.hypothesis,
        "prognosis": suggestion.prognosis,
        "score": suggestion.score
        // "description": suggestion. 
      })
    })


    console.log("questions", questions)

    let form = {
      title: "",
      description: "",
      pages: [
        {
          elements: questions
        }
      ]
    }

    return form
  }


  preview() {
    this.previewMode = true
    console.log("this mode", this.model)
    this.viewQuestionnaire()
    console.group(" Preview Created Forms ");
    console.log(" Get From Object from LocalStorage ")
    console.groupEnd();
  }

  editMode() {
    this.previewMode = false
    console.group(" editMode ");
    console.log(" editMode ")
    console.groupEnd();
  }

  publish() {
    console.group(" Publish Created Forms ");
    console.log(" yash ", this.formFields)

    let data = {
      "request_id": this.request_id,
      "requester_id": JSON.parse(localStorage.getItem('loggedInUser')).UUID,
      "subscriber_id": JSON.parse(localStorage.getItem('patient')).UUID,
      "submitted_version": this.formFields
    }


    if (!this.isTemplate) {
      // Not Save as Template
      this
        ._gptService
        .postSubmission(data)
        .subscribe((success: any) => {
          this.publishQuestion = true
          console.log(success)
          console.groupEnd();
        }, (error) => {
          console.log("ERROR", error)
        })
    }
    else {
      // Not Save as Template
      // "requester_id": JSON.parse(localStorage.getItem('loggedInUser')).UUID,

      let data = {
        "type_id": "template-based",
        "template": this.formFields
      }

      this._gptService.postTemplate(JSON.parse(localStorage.getItem('loggedInUser')).UUID, data)
        .subscribe((success) => {
          this.publishQuestion = true
          this.isTemplate = false
          console.log(success)
          console.groupEnd();
        }, (error) => {
          console.log("ERROR", error)
        })
    }
  }
}
