import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './general/home/home.component';
import { DashboardComponent } from './report/dashboard/dashboard.component';
import { AuthGuard, ClinicianGuard, ClinicalRouteGuard } from './auth.guard';
import { MyPatientsComponent } from './user/my-patients/my-patients.component';
import { ClinicianProfileComponent } from './user/clinician-profile/clinician-profile.component';
import { AboutComponent } from './general/about/about.component';
import { HelpComponent } from './general/help/help.component';
import { TestProfileComponent } from './user/test-profile/test-profile.component';
import { TestDataComponent } from './user/test-data/test-data.component';

import { FoldingPlotComponent } from './report/folding-plot/folding-plot.component';
import { AuthenticationComponent } from './modules/auth/authentication/authentication.component';
import { SurveyCreatorComponent } from './report/survey-creator/survey-creator.component';
import { ReportComponent } from './report/report/report.component';
import { QuestionnaireComponent } from './report/questionnaire/questionnaire.component';
import { SettingsComponent } from './general/settings/settings.component';
import { MyEventsComponent } from './report/my-events/my-events.component';
import { AccountComponent } from './user/account/account.component';
import { LayoutWithHeaderComponent } from './components/layout/layoutWithHeader/layoutWithHeader.component';
import { LayoutWithOutHeaderComponent } from './components/layout/layoutWithoutHeader/layoutWithoutHeader.component';
import { AccessDeniedComponent } from './general/access-denied/access-denied.component';
import { ApiComponent } from './general/api/api.component';
import { NewTestComponent } from './report/new-test/new-test.component';
import { MaintenanceComponent } from './general/maintenance/maintenance.component';

const routes: Routes = [
  { path: 'maintenance', component: MaintenanceComponent },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    component: AuthenticationComponent,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutWithHeaderComponent,
    children: [
      {
        path: 'api',
        component: ApiComponent,
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'test-data',
        canActivate: [ClinicianGuard],
        component: TestDataComponent,
      },
      {
        path: 'dashboard',
        canActivate: [ClinicianGuard],
        component: DashboardComponent,
      },
      {
        path: 'new-report',
        component: ReportComponent,
        canActivate: [ClinicianGuard],
      },
      {
        path: 'new-report/:target',
        component: ReportComponent,
        canActivate: [ClinicianGuard],
      },
      {
        path: 'new-report/:target/:scoreType',
        component: ReportComponent,
        canActivate: [ClinicianGuard],
      },
      {
        path: 'create-questionnaire/:patient/:testId',
        canActivate: [ClinicalRouteGuard],
        component: SurveyCreatorComponent,
      },
      {
        path: 'questionnaire',
        canActivate: [ClinicalRouteGuard],
        component: QuestionnaireComponent,
      },
      {
        path: 'followups',
        canActivate: [ClinicalRouteGuard],
        component: QuestionnaireComponent,
      },
      {
        path: 'settings',
        canActivate: [ClinicianGuard],
        component: SettingsComponent,
      },
      {
        path: 'kit/register',
        canActivate: [ClinicianGuard],
        component: NewTestComponent,
      },
      {
        path: 'events',
        canActivate: [ClinicianGuard],
        component: MyEventsComponent,
      },
      {
        path: 'account/subject',
        canActivate: [ClinicianGuard],
        component: AccountComponent,
      },
      {
        path: 'account/:role',
        canActivate: [],
        component: ClinicianProfileComponent,
      },
      {
        path: 'my-subscribers',
        canActivate: [ClinicalRouteGuard],
        component: MyPatientsComponent,
      },
      {
        path: 'my-accounts',
        canActivate: [ClinicalRouteGuard],
        component: MyPatientsComponent,
      },
      {
        path: 'test-profiles',
        canActivate: [ClinicalRouteGuard],
        component: TestProfileComponent,
      },
      {
        path: 'folding-plots',
        canActivate: [],
        component: FoldingPlotComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
      },
    ],
  },
  {
    path: '',
    component: LayoutWithOutHeaderComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'help',
        component: HelpComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'auth/signin' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
