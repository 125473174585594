import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

import { ResultSetWithScores } from '../../../services/resultSet/types';
import { BaseComponent } from '../../../modules/shared/base.component';
import { forkJoin } from 'rxjs';
import { TestKitService } from '../../../services/test-kit/testKit.service';
import { TestConfig } from '../../../services/test-kit/types';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scores-overview',
  templateUrl: './scores-overview.component.html',
  styleUrls: ['./scores-overview.component.css'],
})
export class ScoresOverviewComponent extends BaseComponent implements OnInit {
  @Input() resultSets: ResultSetWithScores[] = [];
  @Input() isLoading: boolean;
  testTypes: TestConfig[] = [];
  selectedTestType: TestConfig;
  selectedResultSet: ResultSetWithScores;
  showScoreDetails: boolean = true;
  isLoadingTests: boolean;
  reportOptions = [
    { type: 'Individual Report', icon: 'insert_chart_outlined', action: "navigateToReportPage('individual')" },
    { type: 'Tabular Report', icon: 'article', action: "navigateToReportPage('table')" },
    { type: 'Trends Graph', icon: 'insert_chart', action: "navigateToReportPage('graph')" },
    { type: 'Folding Plot', icon: 'insights', action: "navigateToReportPage('folding-plots')" },
    { type: 'Algocyte Report', icon: 'picture_as_pdf', action: 'generateNewReport()' },
  ];

  constructor(private readonly testKitService: TestKitService, private readonly router: Router) {
    super();
  }

  ngOnInit(): void {
    const lastSelectedResultId = JSON.parse(localStorage.getItem('resultId'))
    const lastSelectedResultIndex = this.resultSets.findIndex(obj => obj.id === lastSelectedResultId);
    const latestResultIndex = lastSelectedResultIndex > -1 ? lastSelectedResultIndex : this.resultSets?.length - 1 || 0

    this.selectedResultSet = this.resultSets[latestResultIndex];

    if (this.selectedResultSet.id !== lastSelectedResultId) {
      localStorage.setItem('resultId', JSON.stringify(this.selectedResultSet.id))
    }

    this.isLoadingTests = true;
    this.subscriptions.add(
      forkJoin([this.testKitService.getAvailableTestTypes()]).subscribe({
        next: ([testTypes]) => {
          this.isLoadingTests = false;
          this.testTypes = testTypes;
          this.selectedTestType = this.testTypes[0];
        },
        error: () => {
          this.isLoadingTests = false;
        },
      })
    );
  }

  performAction(action: string) {
    const newAction = `this.${action}`
    eval(newAction);
  }

  navigateToReportPage(path: string) {
    const testType = this.selectedTestType?.name;
    switch (path) {
      case 'graph':
        this.router.navigate(['/new-report/' + path + '/' + testType]);
        break;
      case 'table':
        this.router.navigate(['/new-report/' + path + '/' + testType]);
        break;
      case 'individual':
        this.router.navigate(['/new-report/' + path + '/' + testType]);
        break;
      case 'folding-plots':
        this.router.navigate(['/' + path]);
        break;
      case 'events':
        this.router.navigate(['/' + path]);
        break;
      default:
        this.router.navigate(['/' + path]);
        break;
    }
  }

  generateNewReport() {

  }

  getPreviousResultSet(): ResultSetWithScores | undefined {
    if (!this.selectedResultSet) {
      return undefined;
    }
    const index = this.resultSets.indexOf(this.selectedResultSet);

    return this.resultSets[index - 1];
  }

  onResultSetSelect(selected: ResultSetWithScores) {
    this.selectedResultSet = selected;
  }

  onTestTypeSelect(testType: TestConfig) {
    this.selectedTestType = testType;
  }

  toggleScoreDetails(state: boolean) {
    this.showScoreDetails = state;
  }
}
