import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule } from "ngx-pipes";
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { AppMaterialModules } from '../modules/shared/angular-material.module';
import { CachedHttpTranslationLoader } from '../modules/shared/cachedTranslateLoader';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxUiLoaderModule } from 'ngx-ui-loader'
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SubscriptionManagementComponent } from './subscription-management/subscription-management.component';
import { OrderKitComponent } from './order-kit/order-kit.component';

@NgModule({
  declarations: [SubscriptionsComponent, SubscriptionManagementComponent, OrderKitComponent],
  imports: [
    BrowserModule,
    CommonModule,
    NgxUiLoaderModule,
    BrowserAnimationsModule,
    NgPipesModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModules,
    MatTableExporterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CachedHttpTranslationLoader,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
      useDefaultLang: true,
      isolate: true,
      // missingTranslationHandler: {
      //   provide: MissingTranslationHandler,
      //   useClass: MyMissingTranslationHandler,
      // },
    }),
  ]
})
export class ECommerceModule { }
