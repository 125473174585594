<h2 mat-dialog-title>{{ 'reports.addTagTitle' | translate }}</h2>
<mat-divider class="full-width-divider"></mat-divider>

<mat-dialog-content class="my-3">
  <form [formGroup]="form">
    <mat-form-field class="mb-3">
      <mat-spinner *ngIf="isLoadingTags" diameter="20"></mat-spinner>

      <mat-label>{{ 'reports.selectTagsPlaceholder' | translate }}</mat-label>

      <mat-chip-list #tagsChipList aria-label="Tags selection">
        <mat-chip
          *ngFor="let tagCtrl of selectedTags.controls; let i = index"
          (removed)="removeTag(i)"
        >
          {{ tagCtrl.value.text }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          #tagOptionsInput
          [matAutocomplete]="selectedTagAutocomplete"
          [matChipInputFor]="tagsChipList"
          (focus)="openAutocompletePanel()"
          readonly
        />
      </mat-chip-list>

      <mat-autocomplete
        #selectedTagAutocomplete="matAutocomplete"
        (optionSelected)="selectTag($event)"
      >
        <mat-option *ngFor="let tag of filteredTagOptions" [value]="tag">
          {{ tag.text }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <mat-chip-list #chipList [selectable]="!isUpdatingTag">
        <mat-chip
          *ngFor="let tagCtrl of customTags.controls; let i = index"
          (removed)="removeCustomTag(i)"
          [removable]="!isUpdatingTag"
          [selectable]="!isUpdatingTag"
        >
          {{ tagCtrl.value.text }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          #customTagInput
          placeholder="{{ 'reports.customTagsPlaceholder' | translate }}"
          formControlName="tagInput"
          [disabled]="isUpdatingTag"
          [matChipInputFor]="chipList"
          [matChipInputAddOnBlur]="true"
          [matAutocomplete]="customTagAutocomplete"
          (focus)="openCustomAutocompletePanel()"
          (matChipInputTokenEnd)="addCustomTag($event)"
        />

        <mat-spinner
          *ngIf="isUpdatingTag"
          diameter="10"
          class="ml-3"
        ></mat-spinner>
      </mat-chip-list>
      <mat-autocomplete
        #customTagAutocomplete="matAutocomplete"
        (optionSelected)="selectCustomTag($event)"
      >
        <mat-option *ngFor="let tag of filteredCustomTagOptions" [value]="tag">
          {{ tag.text }}
        </mat-option>
      </mat-autocomplete>

      <mat-hint align="end" style="font-size: 11px">{{
        'reports.customTagsHint' | translate
      }}</mat-hint>
    </mat-form-field>
  </form>

  <div class="d-flex align-items-center mt-2">
    <button
      class="btn mr-3"
      style="background-color: #00978d; color: #fff"
      (click)="onSave()"
      [disabled]="isSavingTags || isUpdatingTag"
    >
      Save
    </button>

    <mat-spinner *ngIf="isSavingTags" diameter="20"></mat-spinner>
    <div *ngIf="saveTagsError" class="text-danger">
      {{ saveTagsError | translate }}
    </div>
  </div>
</mat-dialog-content>

<mat-divider class="full-width-divider"></mat-divider>

<mat-dialog-actions class="d-flex justify-content-end">
  <button mat-raised-button class="btn btn-secondary" (click)="onClose()">
    Close
  </button>
</mat-dialog-actions>
