<div class="container pt-5">
  <h1 class="western" class="text-center">Algocyte R1 - Instructions for Use</h1>
  <h2 class="western">Introduction</h2>
  <p>Thank you for purchasing Algocyte<sup>&reg;</sup> Release 1 (R1), a service from Oxford Immune Algorithmics Ltd.
  </p>
  <p>Algocyte R1 comprises two main features:</p>
  <ol>
    <li class="western">Full Blood Count tests using self-collection kits, which can be conducted by users in the
      comfort of their own home or in a clinical setting.</li>
    <li class="western">A software tool that allows Full Blood Count (FBC) test results and other potentially related
      subject data to be collected and aggregated for reporting both graphically and as data for further processing.
    </li>
  </ol>
  <p>In addition, the software component of Algocyte will display the subject&rsquo;s Immune Score, which encapsulates
    in a single view the immune system status based on an algorithm operating on historical FBC test result data.</p>

  <h2 class="western">Intended Use</h2>
  <p>Algocyte R1 is a data portal and analytical platform. It allows data input from a range of sources into the
    platform, mathematical transformation of the data and subsequent display of the data in a variety of formats. The
    primary purpose of the product is to display the data in a range of accessible formats, including graphics, tables
    and text. The user of the product will have the option of defining the analytical and display parameters, for
    example which data to view or whether to display raw data, arithmetic means or rolling averages over a desired
    period of time. Algocyte will therefore enable the user to view longitudinal trends in data, for example over time.
  </p>
  <p>The data input into Algocyte R1 can be related to a number of fields and industries. The primary intended
    application of Algocyte R1 is to import and display data relating to blood results obtained from accredited medical
    laboratories. The user will be able to input data relating to blood parameters, such as red and white blood cell
    counts, and display the data in the desired format as described earlier. Blood test data imported into Algocyte R1
    could also include other blood test results including blood serum results of electrolytes and other components such
    as blood enzyme levels. All data imported into Algocyte R1 will have been generated by accredited laboratories.</p>
  <table class="table table-responsive w-auto" cellspacing="0" cellpadding="5">
    <tbody>
      <tr>
        <td>&nbsp;<img class="img-fluid" src="assets/help-img/instructions-img.png" alt="" /></td>
        <td>
          <p>Please read this instruction manual thoroughly before using Algocyte R1.&nbsp; Please keep these
            instructions for future reference.&nbsp;</p>
        </td>
      </tr>
    </tbody>
  </table>
  <h1 class="western">Safety Information</h1>
  <table class="table table-responsive w-auto" cellspacing="0" cellpadding="5">
    <tbody>
      <tr>
        <td colspan="2" valign="top">
          <h2 class="western">Safety Instructions</h2>
        </td>
      </tr>
      <tr>
        <td>&nbsp;<img class="img-fluid" src="assets/help-img/safety-img.png" alt="" /></td>
        <td>
          <p>Warning:&nbsp; Indicates a potentially hazardous situation which, if not avoided, could result in patient
            death or serious impact to the health of patients.</p>
          <p><strong><span style="color: #ff0000;">This section is not relevant to Algocyte R1.</span></strong></p>
        </td>
      </tr>
      <tr>
        <td colspan="2" valign="top">
          <h2 class="western pt-3">Cautions</h2>
        </td>
      </tr>
      <tr>
        <td><img class="img-fluid" src="assets/help-img/cautions-img.png" alt="" />&nbsp;</td>
        <td>
          <p>Caution:&nbsp; Indicates a potentially hazardous situation which, if not avoided, may result in minor or
            moderate impact to the health of patients.</p>
          <p><strong><span style="color: #ff0000;">This section is not relevant to Algocyte R1.</span></strong></p>
        </td>
      </tr>
    </tbody>
  </table>
  <h2 class="western">General Precautions</h2>
  <p>Algocyte R1 is not a diagnostic or therapeutic device and there are no procedure-related safety requirements.
    Medical professionals are to decide what data they make available to the user. Medical professionals are also
    advised not to take any part of the plots or immune score to replace any existing blood test screening. The immune
    score is only presented to the medical professional for decision support and only if the medical professional
    decides to include the immune score in the patient's dashboard Algocyte makes it available to them.</p>
  <h2 class="western">Pre-Use Requirements and Dependencies</h2>
  <p>The use of Algocyte R1 requires access to a desktop or mobile computing device. Desktop machines require a modern
    Internet browser and Android and Apple iOS (for example iPhone) devices require installation of the application from
    the Google Play or Apple App Store respectively. All platforms require third party cookies to be allowed for proper
    operation, and certain features in Algocyte R1 will not operate unless access is given to the built-in camera and
    location services. Use of full blood count home testing kits will be dependent on a subscription being purchased for
    these services, also available separately from Oxford Immune Algorithmics Ltd.</p>
  <h1 class="western">Software Installation, Upgrade and Configuration</h1>
  <h2 class="western">Use on a desktop device</h2>
  <p>Algocyte R1 can be accessed by navigating to the website at
    <a style="color: #0563c1 !important;" href="https://www.algocyte.com/">https://www.algocyte.com</a>.
    The application includes user
    sign-up, and users are encouraged to complete their profiles and grant access to the camera and location services in
    order to have the best experience from using Algocyte R1.</p>
  <h2 class="western">Use on a mobile device</h2>
  <p>Algocyte R1 can be downloaded from the Google Play store for Android devices and the Apple App Store for iOS
    devices.&nbsp;Users are encouraged to complete their profiles and grant access to the camera and location services
    in order to get the most use out of Algocyte R1.</p>


  <h1>Step by Step Instructions</h1>
  <h2 class="western">Algocyte<sup>&reg;</sup> Release 1</h2>
  <p>Several variants of Algocyte are available. Each variant provides largely the same functionality albeit with a
    different screen layouts.</p>
  <p>A web application using a desktop internet browser</p>
  <p>An Android mobile application available via the Google Play Store</p>
  <p>An Apple mobile application available via the Apple App Store.</p>
  <p>The application comprises several pages that provide access to all functionality. These are outlined in the
    following sections.</p>
  <h4 class="western">My subscribers</h4>
  <p>In order to use Algocyte to access a specific subject user&rsquo;s health data, that subject user must be selected
    from the list of subject users that are linked to the clinician&rsquo;s account. The my subscribers page allows the
    clinician to perform that selection through use of a drop-down menu. See figure 1.</p>
  <p class="text-center"><img src="assets/help-img/my-patient.png" alt="" />&nbsp;</p>
  <p class="text-center"><strong>Fig. 1</strong> &ndash; <em>My subscribers page</em></p>
  <h4 class="western">Dashboard</h4>
  <p>This page gives a graphical representation of the subject&rsquo;s health data, showing two high-level views:</p>
  <p>The subject&rsquo;s Immune Score, which encapsulates in a single view the immune system status based on an
    algorithm operating on historical FBC test result data.</p>
  <p>The subject&rsquo;s last FBC test result displayed as a bar-chart complete with a reference range for each analyte.
  </p>
  <p>See figure 2.</p>
  <p class="text-center"><strong><img src="assets/help-img/dashboard-page.png" alt="" /></strong></p>
  <p class="text-center"><strong>Fig. 2</strong> &ndash; <em>Dashboard page</em></p>
  <h4 class="western">Reports</h4>
  <p>The Reports page provides three views on the subject&rsquo;s health data and represents the most granular level of
    available detail.</p>
  <h5 class="western">Blood Test Results &ndash; trends</h5>
  <p>This view comprises two separate line graphs that together give a detailed visualisation of the FBC test result
    analytes over time.</p>
  <ul>
    <li>The first graph gives RBC and total WBC and differentiated WBC counts</li>
    <li>the second shows RBC, platelet, MCH, MCHC, Haemoglobin, Mean Cell Volume and Haematocrit count.</li>
  </ul>
  <p>Each analyte can either be included or excluded, and both graphs can display the reference range for each of these
    analytes if only one analyte is selected for display. This is to avoid cluttering the chart with multiple reference
    ranges that might overlap; see figure 3.</p>
  <p class="text-center"><img src="assets/help-img/bloodtest-trands.png" alt="" />&nbsp;</p>
  <p class="text-center"><strong>Fig. 3</strong> &ndash; <em>Blood Test Results &ndash; trends view</em></p>
  <h5 class="western">Blood Test Results &ndash; Tabular</h5>
  <p>This view gives a text-based report of the subject&rsquo;s health data, and is laid out with one row per test
    result. The number of results given per page is configurable between 10 and 100 to best suit the clinician&rsquo;s
    needs. See figure 4 for an example table.</p>
  <p>All 13 analytes are represented and there is an additional column to the right of the table which shows any
    additional data that may have associated with a given blood test using &ldquo;tags&rdquo;.</p>
  <p>Lastly the clinician may export the health data shown in the table in any of three formats: Excel, Comma Separated
    Variable (CSV) or Javascript Object Notation (JSON). The motivation behind exporting data would usually be for
    further processing outside of the Algocyte environment.</p>
  <p class="text-center"><img src="assets/help-img/bloodtest-tabular.png" alt="" />&nbsp;</p>
  <p class="text-center"><strong>Fig. 4</strong> &ndash; <em>Blood Test Results &ndash; Tabular view</em></p>
  <h5 class="western">Individual Blood Test Result</h5>
  <p>The final view is a detailed report of a single test result complete with any additional data that may have
    associated with a given blood test using &ldquo;tags&rdquo;. Once the date for the required blood test(s) has been
    selected, the results for that date are displayed (see figure 5). The test result is shown in a column of data, with
    each datum representing a given analyte, along with the reference range for each analyte. If more than one blood
    test was administered on the selected date each test result is given its own column.</p>
  <p>This view may also be used to &ldquo;tag&rdquo; extra data onto a given test result. Typically the subject would
    tag their test results with information that the clinician has requested. See Figure 6 for an example of tagging.
  </p>
  <p class="text-center">&nbsp;<img src="assets/help-img/bloodtest-individual.png" alt="" /></p>
  <p class="text-center"><strong>Fig. 5</strong> &ndash; <em>Individual Blood Test Result view</em></p>
  <p class="text-center">&nbsp;<img src="assets/help-img/tag-annotation.png" alt="" /></p>
  <p class="text-center"><strong>Fig. 6</strong> &ndash; <em>Example of tagging a test result</em></p>
  <h4 class="western">Events</h4>
  <p>Closely related to tags are &ldquo;events&rdquo;, which are also additional data usually submitted by the subject
    but are not associated with a given blood test. The purpose of events is to log information such as vaccinations,
    treatments or a fever having been suffered. Multiple events may be logged, and both pre-defined events and free-text
    input is supported.</p>
  <p>See Figure 7 for an example of logging events.</p>
  <p class="text-center"><strong><img src="assets/help-img/my-events-page.png" alt="" /></strong></p>
  <p class="text-center"><strong>Fig. 7</strong> &ndash; <em>Example of logging events</em></p>
  <h4 class="western">Patient Profile</h4>
  <ol>
    <li>Details about the subject may be determined by turning to the Patient Profile page, which is broken down into
      three sections:</li>
    <li>Clinician Information, which allows the subject to link to a given clinician; see figure 8.</li>
    <li>Demographic information on the subject; see figure 9.</li>
    <li>Clinical information, which contains medical history and other details; see figure 10.</li>
  </ol>
  <p class="text-center"><strong><img src="assets/help-img/clinician-info.png" alt="" /></strong></p>
  <p class="text-center"><strong>Fig. 8</strong> &ndash; <em>Subject Clinician Information</em></p>
  <p class="text-center">&nbsp;<img src="assets/help-img/demographic-info.png" alt="" /></p>
  <p class="text-center"><strong>Fig. 9</strong> &ndash; <em>Subject Demographic Information</em></p>
  <p class="text-center"><strong><img src="assets/help-img/clinical-info.png" alt="" /></strong></p>
  <p class="text-center"><strong>Fig. 10</strong> &ndash; <em>Subject Clinical Information</em></p>
  <h4 class="western">New Test</h4>
  <p>Full blood count tests are the primary source of a subject&rsquo;s health data set stored and managed in Algocyte.
    There are two main sources of FBC data to be loaded into Algocyte:</p>
  <ol>
    <li>Home test kits that are dispatched to the subject as part of the Algocyte service.</li>
    <li>Third party (non-Algocyte) pathology laboratory FBC test results.</li>
  </ol>
  <p>Whichever option is chosen, the subject is asked to complete a questionnaire to capture other information relating
    to the subject on the day of their test; see figure 15.</p>
  <h5 class="western">Home test kit</h5>
  <p>When a home test kit is received by the subject it contains information that must be submitted to Algocyte to
    associate it with the subject. This is done by selecting the Register Kit tab on the New Test page and submitting
    the required information, as shown in figure 11.</p>
  <p class="text-center"><img src="assets/help-img/home-test-kit-reg.png" alt="" />&nbsp;</p>
  <p class="text-center"><strong>Fig. 11</strong> &ndash; <em>Home kit registration</em></p>
  <h5 class="western">Upload of pathology laboratory test results</h5>
  <p>A photographic or scanned image of a pathology laboratory test result may be uploaded to Algocyte using either of
    the <em>Using Webcam</em> or <em>Using file-upload</em> options, as shown in figures 12 and 13 respectively.</p>
  <p class="text-center"><strong><img src="assets/help-img/webcam-upload.png" alt="" /></strong></p>
  <p class="text-center"><strong>Fig. 12</strong> &ndash; <em>Upload of FBC test report using web camera</em></p>
  <p class="text-center">&nbsp;<img src="assets/help-img/file-upload.png" alt="" /></p>
  <p class="text-center"><strong>Fig. 13</strong> &ndash; <em>Upload of FBC test report using scanned document</em></p>
  <p class="text-center"><strong><img src="assets/help-img/correctionOfCounts.png" alt="" /></strong></p>
  <p class="text-center"><strong>Fig. 14</strong> &ndash; <em>Correction of analyte count values</em></p>
  <p class="text-center"><img src="assets/help-img/after-test-qa.png" alt="" />&nbsp;</p>
  <p class="text-center"><strong>Fig. 15</strong> &ndash; <em>Post-test questionnaire</em></p>
  <h1 class="western">Troubleshooting</h1>
  <h2 class="western">I can't take an image of a third party pathology laboratory report</h2>
  <p>Algocyte R1 requires access to the built-in camera to take images of printed pathology laboratory reports. If this
    feature is required, access to the built-in camera must be granted to the application.</p>
  <h2 class="western">Algocyte R1 isn't recording the weather conditions at my location</h2>
  <p>Algocyte R1 can use a third party web service to determine the weather conditions at the location of the
    user.&nbsp;If this feature is required, access to the device's location services must be granted to the application.
  </p>
  <h2 class="western">Algocyte R1 isn't operating correctly on my desktop machine</h2>
  <p>In order for Algocyte R1 to operate correctly, the browser&rsquo;s privacy settings must be set to allow first
    party and third party cookies to be enabled.</p>
  <h2 class="western">My blood test results don't look right</h2>
  <p>Algocyte R1 records the absolute count of white blood cells, whereas some laboratory reports give these figures as
    percentages of the white blood cell count. The user is given an opportunity to check and amend a third party
    pathology laboratory report after uploading into Algocyte R1. These values should be checked carefully to ensure the
    integrity of your health data stored in Algocyte R1.</p>
  <h2 class="western">Technical Specifications</h2>
  <p>Algocyte R1 comprises software only. It requires user-supplied desktop or mobile (Android or iOS) computing
    equipment to operate.</p>
  <h2 class="western">Regulatory Information</h2>
  <p>Algocyte R1 is a Class I Software as a Medical Device compliant with the following regulations and standards:</p>
  <p><strong>Medical Devices Directive 93/42/EEC</strong></p>
  <p>&nbsp;<img src="assets/help-img/cemark-img.png" alt="" class="img-fluid" style="max-height: 50px;" /></p>

</div>