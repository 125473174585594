import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TestService } from '../../services/test.service';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { config } from '../../config';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { distinctUntilChanged, filter, findIndex, tap } from 'rxjs/operators';
import { Subscription, fromEvent } from 'rxjs';
import { HealthEventService } from '../../services/healthEvent/healthEvent.service';
import { User } from '../../../types';
import { SelectedSubscriberService } from '../../services/selected-subscriber/selected-subscriber.service';
import { UserService } from 'src/app/services/user/user.service';

declare const $: any;

@Component({
  selector: 'app-my-events',
  templateUrl: './my-events.component.html',
  styleUrls: ['./my-events.component.css'],
})
export class MyEventsComponent implements OnInit {
  config = config;
  eventForm: FormGroup;
  selectedSubscriber: User | null;
  eventList = [];
  today = new Date();

  rangeFormGroup = new FormGroup({
    start: new FormControl(null, Validators.required),
    end: new FormControl(null, Validators.required),
  });

  events: any;
  selectedEvents: any = [];
  searchText = '';
  displayedColumns: string[] = [
    'eventTypes',
    'eventDate',
    'wellnessRating',
    'painRating',
    'energyRating',
    'action',
  ];
  elementData: any = [];
  allEvents: any = [];
  limit = 7;
  pageNo = 0;
  dataSource = new MatTableDataSource<any>(this.elementData);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('select') select: MatSelect;
  @ViewChild('search') input: ElementRef;
  private subscription: Subscription = new Subscription();
  eventDetails: any;
  loading = false;
  totalRecords = 0;
  formValidStatus = true;
  currentSort: any = { sortBy: 'eventDate', sortOrder: 'desc' };
  allSelected = true;
  dateRangeStart: any;
  dateRangeEnd: any;
  userData: User | null;
  minEventDate: Date;

  constructor(
    private _testService: TestService,
    private dateFilter: DatePipe,
    private ngxService: NgxUiLoaderService,
    private healthEventService: HealthEventService,
    private selectedSubscriberService: SelectedSubscriberService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.selectedSubscriberService.getUserDataObservable().subscribe((d) => {
      if (d) {
        this.selectedSubscriber = d;
        this.getHealthEvents();
      }
    });

    this.subscription.add(
      this.userService.getUserDataObservable().subscribe((d) => {
        this.userData = d;
      })
    );

    this.getEventTypes()
      .then((response) => {
        this.createForm({
          formFields: this.healthEventService.getHealthEventForm(),
          events: response,
        });
      })
      .catch((error) => {
        console.error(' ERROR ::: GET DATA FOR THE PAGE', error);
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        distinctUntilChanged(),
        tap(() => {
          this.filterEvents();
        })
      )
      .subscribe();
  }

  initEventForm(formFields) {
    this.eventForm = new FormGroup(this.getControls(formFields));
  }

  getControls(controllsArray) {
    const controls = {};
    controllsArray.forEach((control) => {
      if (control.required) {
        controls[control.formControlName] = new FormControl('', Validators.required);
      } else {
        controls[control.formControlName] = new FormControl();
      }
    });
    return controls;
  }

  createForm({ formFields, events }) {
    if (this.eventList && !this.eventList.length) {
      Object.keys(formFields).forEach((key) => {
        const formField = formFields[key];

        formField.formControlName = key;

        if (key == 'eventTypes') {
          formField.suggestedAns = events;
          formField.multiple = true;
        } else formField.suggestedAns = [];

        formField.givenAns = [];

        if (key != 'id' && key != 'createdAt' && key != 'patient_id')
          this.eventList.push(formField);
      });

      this.initEventForm(this.eventList);
    }
  }

  createEventHorizons(data) {
    $('#create-event-horizons').attr('disabled', true);
    const submittedData = {};

    data.forEach((d) => {
      if (d.formControlName === 'eventDate') {
        submittedData[d.formControlName] = this.dateFilter.transform(
          d.givenAns[0],
          'yyyy-MM-dd'
        );
      } else {
        submittedData[d.formControlName] = d.givenAns;
      }
    });

    Promise.all([
      this._testService.getTranslation('messages.success'),
      this._testService.getTranslation('events.eventCreated'),
      this._testService.getTranslation('messages.error'),
      this._testService.getTranslation('events.eventFailed'),
      this._testService.getTranslation('common.okBtnText'),
    ]).then((keyResult: any) => {
      this.healthEventService
        .createHealthEvent({
          ...submittedData,
          userId: this.selectedSubscriber.id,
        })
        .subscribe(
          async () => {
            this.getHealthEvents();
            await this.closeModal('create');
            this.ngxService.stop();
            Swal.fire({
              type: 'success',
              title: keyResult[0],
              text: keyResult[1],
              confirmButtonText: keyResult[4],
            });
            this.eventForm.reset();
          },
          (error) => {
            console.error(
              'ERROR ::: created Event Horizons Response ::: ',
              error
            );
            Swal.fire({
              type: 'error',
              title: keyResult[2],
              text: keyResult[3],
              confirmButtonText: keyResult[4],
            });
            this.eventForm.reset();
          }
        );
    });
  }

  getEventTypes() {
    return new Promise((resolve, reject) => {
      this.healthEventService.getHealthEventTypes().subscribe(
        (response: any) => {
          if (response) {
            this.events = response;
            return resolve(response);
          } else {
            return resolve([]);
          }
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }

  async openModal(type) {
    switch (type) {
      case 'create':
        if (!this.getEventInfo) this.eventForm.reset();
        $('#open-event-modal').modal('show');
        break;
      case 'view':
        $('#open-view-event-modal').modal('show');
        break;
      case 'remove':
        $('#open-remove-confirmation-event-modal').modal('show');
        break;
      default:
        break;
    }
  }

  async closeModal(type) {
    this.formValidStatus = true;
    switch (type) {
      case 'create':
        this.eventForm.reset();
        this.viewEventInformation(null);
        $('#open-event-modal').modal('hide');
        break;
      case 'view':
        $('#open-view-event-modal').modal('hide');
        this.eventForm.reset();
        this.viewEventInformation(null);
        break;
      case 'remove':
        this.viewEventInformation(null);
        $('#open-remove-confirmation-event-modal').modal('hide');
        break;
      default:
        break;
    }
  }

  isValidCreateEvent() {
    this.formValidStatus =
      this.eventForm && this.eventForm.status === 'VALID' ? false : true;

    return this.formValidStatus;
  }

  async viewEvent(event_horizon_id, index?) {
    this.viewEventInformation(this.elementData[index]);
    this.openModal('view');
  }

  async viewEventInformation(eventDetails) {
    this.eventDetails = JSON.parse(JSON.stringify(eventDetails));
  }

  async editEvent(event_horizon_id, index?) {
    this.viewEventInformation(this.elementData[index]);
    this.updateValues(this.elementData[index]);
    this.openModal('create');
  }

  updateValues(eventDetails: any) {
    this.eventForm.setValue({
      eventTypes: eventDetails.eventTypes.map((event) => event.id),
      eventDate: eventDetails.eventDate,
      details: eventDetails.details,
      wellnessRating: eventDetails.wellnessRating,
      energyRating: eventDetails.energyRating,
      painRating: eventDetails.painRating,
    });
  }

  async deleteEvent(event_horizon_id, index?) {
    $('#remove-event-horizons').attr('disabled', false);
    this.viewEventInformation(this.elementData[index]);
    this.openModal('remove');
  }

  async removeEvent(id) {
    this.loading = true;
    $('#remove-event-horizons').attr('disabled', true);
    this.healthEventService.deleteHealthEvent(id).subscribe(
      (removed: any) => {
        this.getHealthEvents();
        this.viewEventInformation(null);
        this.closeModal('remove');
      },
      (error: any) => {
        console.error(' ERROR ', error);
      }
    );
  }

  async updateEventHorizons(data, id: number) {
    this.loading = true;

    const submittedData = {};
    data.forEach((d) => {
      if (d.formControlName === 'eventDate') {
        submittedData[d.formControlName] = this.dateFilter.transform(
          d.givenAns[0],
          'yyyy-MM-dd'
        );
      } else {
        submittedData[d.formControlName] = d.givenAns;
      }
    });
    this.healthEventService.updateHealthEvent(id, submittedData).subscribe(
      () => {
        this.closeModal('create');
        this.viewEventInformation(null);
        this.getHealthEvents();
      },
      (error) => {
        console.log(' ERROR ', error);
      }
    );
  }

  getEventInfo(event_id, type) {
    if (this.events && this.events.length) {
      const foundEvent = this.events.find(o => o.id === event_id);
      return foundEvent[type];
    }
  }

  getHealthEvents(
    options: {
      date?: string;
      dateRangeStart?: string;
      dateRangeEnd?: string;
    } = {}
  ) {
    this.loading = true;
    this.healthEventService
      .getHealthEvents({ userId: this.selectedSubscriber.id, ...options })
      .subscribe((events: any) => {
        this.loading = false;
        this.pageNo = 0;
        this.allEvents = JSON.parse(JSON.stringify(events));
        this.minEventDate = new Date(this.allEvents.reduce((minDate, event) => {
          return new Date(event.eventDate) < new Date(minDate) ? event.eventDate : minDate;
        }, events[0].eventDate))
        this.totalRecords = this.allEvents?.length;

        this.elementData = this.paginateAndSort(this.allEvents, this.pageNo, this.limit, this.currentSort);
        this.dataSource = new MatTableDataSource<any>(this.elementData);
      });
  }

  paginateAndSort(array, pageNo, limitPerPage, currentSort) {
    const { sortBy, sortOrder } = currentSort;
    array.sort((a, b) => {
      const valueA = this.getValue(a, sortBy);
      const valueB = this.getValue(b, sortBy);
      let comparison = 0;
      if (valueA === null || valueB === null) {
        comparison = valueA === null ? -1 : 1;
      } else if (sortBy == 'eventDate') {
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);
        comparison = dateA.getTime() - dateB.getTime();
      } else if (typeof valueA === 'string' && typeof valueB === 'string') {
        comparison = valueA.localeCompare(valueB);
      } else {
        comparison = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      }
      return sortOrder === 'desc' ? -comparison : comparison;
    });

    const startIndex = pageNo * limitPerPage;
    const endIndex = startIndex + limitPerPage;

    return array.slice(startIndex, endIndex);
  }

  getValue(obj, path) {
    const keys = path.split('.');
    let value = obj;
    for (const key of keys) {
      if (value && value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        return null;
      }
    }
    return value;
  }

  isSelectedEventPresent(eventDetails) {
    return this.selectedEvents.some((selectedEventId) =>
      eventDetails.eventTypes.some((event) => event.id === selectedEventId)
    );
  }

  filterEvents(pageNumber = 0) {
    const filteredEvents = this.allEvents.filter((ev) => {
      const isSelected = this.isSelectedEventPresent(ev) || this.allSelected;

      if (isSelected) {
        const isNameMatch = ev.eventTypes.some((event) => {
          const eventName = this.getEventInfo(event.id, 'name')?.toLowerCase();
          const inputValue = this.input.nativeElement.value?.toLowerCase();
          return eventName?.includes(inputValue) || !inputValue;
        });

        return isNameMatch;
      }

      return false;
    });

    this.pageNo = pageNumber;
    this.elementData = this.paginateAndSort(filteredEvents, this.pageNo, this.limit, this.currentSort);
    this.dataSource = new MatTableDataSource<any>(this.elementData);
    this.totalRecords = filteredEvents.length;
  }

  pageEvents(event: any) {
    this.limit = event.pageSize;
    this.filterEvents(event.pageIndex);
  }

  sortData(event: any) {
    const { active, direction } = event;
    this.currentSort = {
      sortBy: active,
      sortOrder: direction,
    };

    this.pageNo = 0;

    this.elementData = this.paginateAndSort(this.allEvents, this.pageNo, this.limit, this.currentSort);
    this.dataSource = new MatTableDataSource<any>(this.elementData);
  }

  goToPage(pageIndex: number) {
    this.filterEvents(pageIndex);
  }

  goToPreviousPage() {
    this.filterEvents(this.pageNo - 1);
  }

  goToNextPage() {
    this.filterEvents(this.pageNo + 1);
  }

  getPageNumbers(): number[] {
    const totalPages = this.getTotalPages();
    return Array(totalPages)
      .fill(0)
      .map((_, i) => i);
  }

  getTotalPages(): number {
    return Math.ceil(this.totalRecords / this.limit);
  }

  isPageShow(pageNumber: number): boolean {
    const totalPages = this.getTotalPages();
    const pageNo = this.pageNo;

    if (pageNumber === pageNo
      || pageNumber === pageNo - 1
      || pageNumber === pageNo + 1
      || (pageNo === 0 && pageNumber === 2)
      || (pageNo === totalPages - 1 && pageNumber === pageNo - 2)) {
      return true;
    }

    return false;
  }

  isSelected(event: any): boolean {
    const selectedEventTypes = this.eventForm.value.eventTypes || [];
    return selectedEventTypes.includes(event.id);
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
    this.filterEvents();
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.filterEvents();
  }

  onRatingChange(value, id) {
    if (value) {
      const currentBar = document.getElementById(id);
      const currentBarUnits = currentBar.getElementsByClassName('br-units');

      for (let i = 0; i < currentBarUnits[0].childNodes.length; i++) {
        if (value - 1 > i) {
          const element: any = currentBarUnits[0].childNodes[i];
          element.classList.remove('br-active');
          element.classList.remove('br-selected');
        }
      }
    }
  }

  onMouseOver(event, id) {
    const value = this.eventForm.value[id]
      ? parseInt(this.eventForm.value[id])
      : null;

    setTimeout(() => {
      const currentBar = document.getElementById(id);
      const currentBarUnits = currentBar.getElementsByClassName('br-units');

      for (let i = 0; i < currentBarUnits[0].childNodes.length; i++) {
        const element: any = currentBarUnits[0].childNodes[i];
        if (value) {
          if (value - 1 != i) {
            if (
              element.classList &&
              element.classList.contains('br-selected')
            ) {
              element.classList.remove('br-selected');
            }

            if (element.classList && element.classList.contains('br-active')) {
              element.classList.remove('br-active');
            }
          } else {
            element.classList.add('br-selected');
          }
        } else if (
          element.classList &&
          element.classList.contains('br-active')
        ) {
          element.classList.remove('br-active');
        }
      }
    }, 3);
  }

  onMouseOut(event, id) {
    if (this.eventForm.value[id]) {
      setTimeout(() => {
        const currentBar = document.getElementById(id);
        const currentBarUnits = currentBar.getElementsByClassName('br-units');

        for (let i = 0; i < currentBarUnits[0].childNodes.length; i++) {
          if (this.eventForm.value[id] - 1 > i) {
            const element: any = currentBarUnits[0].childNodes[i];
            if (element.classList && element.classList.contains('br-active')) {
              element.classList.remove('br-active');
            }

            if (
              element.classList &&
              element.classList.contains('br-selected')
            ) {
              element.classList.remove('br-selected');
            }
          }
        }
      }, 3);
    }
  }

  async applyDate(status) {
    if (status) {
      this.dateRangeStart = this.rangeFormGroup.value.start
        ? await this.paddedDate(this.rangeFormGroup.value.start)
        : null;

      this.dateRangeEnd = this.rangeFormGroup.value.end
        ? await this.paddedDate(this.rangeFormGroup.value.end)
        : null;
    } else {
      this.rangeFormGroup.setValue({
        end: null,
        start: null,
      });
      this.dateRangeStart = null;
      this.dateRangeEnd = null;
    }
    this.getHealthEvents({
      ...(this.dateRangeStart ? { dateRangeStart: this.dateRangeStart } : {}),
      ...(this.dateRangeEnd ? { dateRangeEnd: this.dateRangeEnd } : {}),
    });
  }

  async paddedDate(selectedDate) {
    const tempDate = new Date(selectedDate);

    const date =
      tempDate.getDate() < 10 ? '0' + tempDate.getDate() : tempDate.getDate();
    const month =
      tempDate.getMonth() < 9
        ? '0' + (tempDate.getMonth() + 1)
        : tempDate.getMonth() + 1;
    const year = tempDate.getFullYear();
    return `${year}-${month}-${date}`;
  }
}
