import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkedAccountInformationComponent } from './linked-account-information/linked-account-information.component';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModules } from '../modules/shared/angular-material.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CachedHttpTranslationLoader } from '../modules/shared/cachedTranslateLoader';
import { HttpClient } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AccountComponent } from './account/account.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { PersonalInfoFormComponent } from './account/personal-info-form/personal-info-form.component';
import { ProviderFormComponent } from './account/provider-form/provider-form.component';
import { ProviderFormItemComponent } from './account/provider-form/provider-form-item/provider-form-item.component';


@NgModule({
  declarations: [AccountComponent, LinkedAccountInformationComponent, PersonalInfoFormComponent, ProviderFormComponent, ProviderFormItemComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModules,
    NgxSkeletonLoaderModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: CachedHttpTranslationLoader,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
      useDefaultLang: true,
      isolate: true,
      // missingTranslationHandler: {
      //   provide: MissingTranslationHandler,
      //   useClass: MyMissingTranslationHandler,
      // },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserModule {}
