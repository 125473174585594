import { Component, OnInit } from '@angular/core';
import { config } from '../../config';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit {
  config = config;

  constructor() {}

  ngOnInit() {}
}
