<div style="font-size: 1em">{{ 'registerKit.modalTitle' | translate }}</div>
<div style="font-size: 1em" class="mb-2">
  {{ 'registerKit.modalSubTitle' | translate }}
</div>
<mat-divider class="full-width-divider"></mat-divider>

<mat-dialog-content class="my-3">
  <div class="mb-1">
    <div class="mb-1">{{ 'registerKit.isRequestedByDoctor' | translate }}</div>
    <mat-radio-group [(ngModel)]="currentResponses.isRequestedByDoctor"
      (change)="handleFieldChange($event, 'isRequestedByDoctor')" required>
      <mat-radio-button class="mr-3" [value]="true">
        {{ 'common.yes' | translate }}
      </mat-radio-button>
      <mat-radio-button class="mr-3" [value]="false">
        {{ 'common.no' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="mb-1">
    <div class="mb-1">{{ 'registerKit.hadRigorousExercise' | translate }}</div>
    <mat-radio-group [(ngModel)]="currentResponses.hadRigorousExercise"
      (change)="handleFieldChange($event, 'hadRigorousExercise')" required>
      <mat-radio-button class="mr-3" [value]="true">
        {{ 'common.yes' | translate }}
      </mat-radio-button>
      <mat-radio-button class="mr-3" [value]="false">
        {{ 'common.no' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="mb-1">
    <div class="mb-1">{{ 'registerKit.hadAlcohol' | translate }}</div>
    <mat-radio-group [(ngModel)]="currentResponses.hadAlcohol" (change)="handleFieldChange($event, 'hadAlcohol')"
      required>
      <mat-radio-button class="mr-3" [value]="true">
        {{ 'common.yes' | translate }}
      </mat-radio-button>
      <mat-radio-button class="mr-3" [value]="false">
        {{ 'common.no' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="mb-1">
    <mat-form-field>
      <mat-label>{{ 'registerKit.bloodTestPurpose' | translate }}</mat-label>
      <mat-select [value]="currentResponses.bloodTestPurpose"
        (selectionChange)="handleFieldChange($event, 'bloodTestPurpose')" required>
        <mat-option *ngFor="let item of bloodTestPurposeOptions" [value]="item">
          {{ 'registerKit.' + item | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mb-1">
    <label>{{ 'registerKit.wellnessRating' | translate }}</label>
    <bar-rating [theme]="'square'" [max]="10" [id]="'wellnessRating'" [(ngModel)]="currentResponses.wellnessRating"
      (rateChange)="onRatingChange($event)" (mouseover)="onMouseOver($event)" (mouseout)="onMouseOut($event)"
      required></bar-rating>
  </div>
  <div class="mb-1">
    <mat-form-field>
      <input matInput [value]="currentResponses.newSymptoms || ''"
        (change)="handleFieldChange($event.target, 'newSymptoms')"
        placeholder="{{ 'registerKit.newSymptoms' | translate }}" autocomplete="off" />
    </mat-form-field>
  </div>
  <div class="mb-1">
    <mat-form-field>
      <input matInput placeholder="{{ 'registerKit.newMedications' | translate }}"
        [value]="currentResponses.newMedications || ''" (change)="handleFieldChange($event.target, 'newMedications')"
        autocomplete="off" />
    </mat-form-field>
  </div>
  <div class="mb-1">
    <mat-form-field>
      <input matInput placeholder="{{ 'registerKit.newProcedures' | translate }}"
        [value]="currentResponses.newProcedures || ''" (change)="handleFieldChange($event.target, 'newProcedures')"
        autocomplete="off" />
    </mat-form-field>
  </div>
  <div class="mb-1">
    <mat-form-field>
      <input matInput [value]="currentResponses.travelDestinations || ''"
        (change)="handleFieldChange($event.target, 'travelDestinations')"
        placeholder="{{ 'registerKit.travelDestinations' | translate }}" autocomplete="off" />
    </mat-form-field>
  </div>
  <div class="mb-1">
    <mat-form-field>
      <input matInput [value]="currentResponses.additionalInformation || ''"
        (change)="handleFieldChange($event.target, 'additionalInformation')"
        placeholder="{{ 'registerKit.additionalInformation' | translate }}" autocomplete="off" />
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-divider class="full-width-divider"></mat-divider>

<mat-dialog-actions class="d-flex justify-content-start">
  <button mat-raised-button class="btn mr-3 btn-secondary" (click)="onClose()">
    Skip
  </button>
  <div class="d-flex align-items-center">
    <button class="btn btn-primary" mat-raised-button (click)="onSave()" [disabled]="isSavingResponses || isInValid()">
      Submit
    </button>

    <mat-spinner *ngIf="isSavingResponses" diameter="20"></mat-spinner>
  </div>
</mat-dialog-actions>