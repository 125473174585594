import { AdaptiveScoreRecordDto } from '../score/types';
import { TestConfigName } from '../test-kit/types';

export enum TagType {
  UserCreated = 'user-created',
  SystemDefined = 'system-defined',
}

export interface Tag {
  id: number;
  text: string;
  type: TagType;
  userId: number;
}

export interface BloodResultData {
  source: string;
  controlId: string;
  analyteResults: AnalyteResult[];
  observationDate: string;
}

export interface BloodResult {
  id: number;
  createdAt: string;
  processedAt: null | string;
  labId: number;
  results: BloodResultData;
  userId: number;
  observationDate: string;
  tags: Tag[];
}

export interface ResultSetResponse {
  adaptiveScores: AdaptiveScoreRecordDto[];
  id: number;
  createdAt: string;
  processedAt: string | null;
  labId: number;
  results: Results;
  userId: number;
  testType: TestConfigName;
  observationDate: string;
  tags: Tag[];
}

export interface ResultSetWithScores extends ResultSetResponse {
  nonAdaptiveScore: number;
  adaptiveScores: AdaptiveScoreRecordDto[];
  essentialAnalytes: string[];
}

interface Results {
  source: string;
  controlId: string;
  analyteResults: AnalyteResult[];
  observationDate: string;
}

export interface AnalyteResult {
  unit: string;
  timestamp: string;
  analyteKey: string;
  producerId: string;
  analyteName: string;
  probability: null | any;
  abnormalFlag: string;
  analyteValue: number;
  referenceRange: string;
  datetimeOfAnalysis: null | any;
  responsibleObserver: string;
  natureOfAbnormalTest: string;
  datetimeOfObservation: string;
}

export interface getResultSetInput {
  dateRangeStart?: string;
  dateRangeEnd?: string;
  userId: number;
}

export enum BloodTestPurposeType {
  ROUTINE_MONITORING = 'ROUTINE_MONITORING',
  FOLLOW_UP = 'FOLLOW_UP',
  NEW_SYMPTOMS = 'NEW_SYMPTOMS',
  GENERAL_MONITORING = 'GENERAL_MONITORING',
  OTHER = 'OTHER',
}
export interface QuestionnaireResponse {
  id: number;
  isRequestedByDoctor: boolean;
  hadRigorousExercise: boolean;
  hadAlcohol: boolean;
  bloodTestPurpose: BloodTestPurposeType;
  wellnessRating: number;
  newSymptoms: string;
  newMedications: string;
  newProcedures: string;
  travelDestinations: string;
  additionalInformation: string;
}
