import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DatePipe } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { config } from "../../config";
import { CommonService } from "../../services/common.service";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { TranslateService } from "@ngx-translate/core";
import { TestService } from "../../services/test.service";
import { ResultSetService } from 'src/app/services/resultSet/resultSet.service';
import { SelectedSubscriberService } from "src/app/services/selected-subscriber/selected-subscriber.service";
import { User } from '../../../types';
import { HealthEventService } from "src/app/services/healthEvent/healthEvent.service";

declare const zingchart: any;
export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD MMMM YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
const analytesList = [
  'RBC',
  'HCT',
  'MCV',
  'HGB',
  'MCH',
  'PLT',
  'WBC',
  'NEUT',
  'LYMPH',
  'MONO',
  'EOS',
  'BASO',
];
@Component({
  selector: "app-folding-plot",
  templateUrl: "./folding-plot.component.html",
  styleUrls: ["./folding-plot.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FoldingPlotComponent implements OnInit {
  deviceType: string;
  selectedSubscriber: User | null;
  config = config;
  selectedPlot = "date";
  selectedPlotDate: any;
  selectedPlotEndDate: any;
  plotLength: number;
  selectedAnalyte = [];
  allTestReports = [];
  dates: any = [];
  sDate;
  startIndex;
  maxDate: any;
  minDate: any;
  minDateEnd: any;
  allAnalytes: any = analytesList.sort()
  allDates;
  allRanges;
  rangeData;
  analyteValueToCompare;
  selectedAnalyteForCompare;
  comparator;
  daysSelected: any[] = [];
  daysSelectedString;
  displayGraph = false;
  isError = false;
  errMsg: any;
  events: any;
  triggerOptions = [
    {
      value: "date",
      text: "foldingPlot.date",
      canView: true,
    },
    {
      value: "dateSeries",
      text: "foldingPlot.dateSeries",
      canView: true,
    },
    {
      value: "event",
      text: "foldingPlot.event",
      canView: true,
    },
    {
      value: "analyte",
      text: "foldingPlot.analyteValue",
      canView: true,
    }
  ]
  selectedEvent: any;
  resultDates: any;
  allEvents: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private testService: TestService,
    private dateFilter: DatePipe,
    private _commonService: CommonService,
    private healthEventService: HealthEventService,
    private resultSetService: ResultSetService,
    private translate: TranslateService,
    private selectedSubscriberService: SelectedSubscriberService) {

    this.getEventTypes()
    this.deviceType = this._commonService.getDeviceType();
    this.selectedSubscriberService.getUserDataObservable().subscribe((d) => {
      if (d) {
        this.selectedSubscriber = d;
        this.getResultsSets();
        this.getHealthEvents();
      }
    });
  }

  ngOnInit() {
  }

  analyteChange(event) {
    this.selectedAnalyteForCompare = event["value"];
  }

  eventChange(event) {
    this.selectedEvent = event?.value;
  }

  selectedTriggerChanged() {
    this.selectedPlotDate = undefined;
    this.selectedPlotEndDate = undefined;
    this.plotLength = undefined;
    this.selectedAnalyte = [];
    this.sDate = undefined;
    this.startIndex = undefined;
    this.allDates = undefined;
    this.analyteValueToCompare = undefined;
    this.selectedAnalyteForCompare = undefined;
    this.comparator = undefined;
    this.daysSelected = [];
    this.daysSelectedString = undefined;
    this.displayGraph = false;
    zingchart.exec("foldPlot", "destroy");
  }

  onChangeStartDate(event) {
    this.minDateEnd = new Date(event.target.value);
  }

  generateSeriesDate(graphData, chunkedDates) {
    let seriesData = [];

    graphData.forEach(async (data, index) => {
      seriesData[index] = {};
      seriesData[index].values = [];
      seriesData[index].dataCustomToken = [];
      const selectedAnalyteLength = this.selectedAnalyte.length || 0;
      const graphDataLength = graphData.length || 0
      seriesData[index].text = this.dateFilter.transform(chunkedDates[index >= graphDataLength / selectedAnalyteLength ? index - graphDataLength / selectedAnalyteLength : index][0], "dd MMM yyyy");
      data.map(async (val, i) => {
        if (val !== null) {
          if (!isNaN(val.value)) {
            seriesData[index]["data-analyte"] = val.analyte;
            seriesData[index]["data-unit"] = this.rangeData[this.rangeData.findIndex(item => item.analytes === val.analyte)].unit;
            seriesData[index].dataCustomToken.push(this.dateFilter.transform(val.date.replace(" ", "T"), "dd MMM yyyy HH:mm"));
            seriesData[index].values.push([Number((val.day + (new Date(val.date.replace(" ", "T")).getHours() * 60 + new Date(val.date.replace(" ", "T")).getMinutes()) / 1440).toFixed(2)), val.value]);
          }
        }
      });
    });

    seriesData = seriesData.filter((s) => s.values.length > 0);
    this.generateGraph(seriesData);
  }

  generateDatasetForGraph(graphData, chunkedDates, analyte) {
    chunkedDates.forEach((chunkedDate, i) => {
      chunkedDate.forEach((singleDate, day) => {
        let innerFound = false;
        for (const test of this.allTestReports) {
          if (test.date.includes(chunkedDate[day])) {
            (graphData[i] && graphData[i].length) ? graphData[i].push({ day, date: test.date, value: Number(test[analyte]), analyte })
              : (graphData[i] = [{ day, date: test.date, value: Number(test[analyte]), analyte }]);
            innerFound = true;
          }
        }
        if (!innerFound) graphData[i] && graphData[i].length ? graphData[i].push(null) : (graphData[i] = [null]);
      });
    });
    return graphData;
  };

  isSelected = (event: any) => {
    event = new Date(event);
    const date = event.getFullYear() + "-" + ("00" + (event.getMonth() + 1)).slice(-2) + "-" + ("00" + event.getDate()).slice(-2);
    return this.daysSelected.find((x) => x === date) ? "selected" : null;
  };

  select(event: any, calendar: any) {
    event = new Date(event);
    const date = event.getFullYear() + "-" + ("00" + (event.getMonth() + 1)).slice(-2) + "-" + ("00" + event.getDate()).slice(-2);
    (this.daysSelected.findIndex((x) => x === date) < 0) ? this.daysSelected.push(date) : this.daysSelected.splice(this.daysSelected.findIndex((x) => x === date), 1);
    this.daysSelectedString = this.daysSelected.sort().join(", ");
    calendar.updateTodaysDate();
  }

  generateGraph(seriesData) {
    let myConfig;
    const translationsText = [this.testService.getTranslation("foldingPlot.date"), this.testService.getTranslation("foldingPlot.plotStartDates"), this.testService.getTranslation("foldingPlot.day"), this.testService.getTranslation("foldingPlot.daysOfCycle"), this.testService.getTranslation("foldingPlot.units")]

    Promise.all(translationsText).then((res: any[]) => {
      myConfig = {
        type: "line",
        plot: {
          aspect: "spline",
          stacked: false,
          tooltip: {
            text: `<p>${res[0]}: %data-custom-token</p><p>%data-analyte: %v %data-unit</p>`,
            "html-mode": true,
          },
        },
        legend: {
          "border-width": 2,
          "border-color": "#686868",
          "border-radius": "5px",
          padding: this.deviceType == "xs" ? "0px" : "10%",
          layout: this.deviceType == "xs" ? "1x3" : "10x1",
          align: this.deviceType == "xs" ? "center" : "right",
          "vertical-align": this.deviceType == "xs" ? "top" : "middle",
          header: { text: res[1] },
          "max-items": this.deviceType == "xs" ? 3 : 10,
          overflow: "page",
          "page-off": {
            "background-color": "grey",
            alpha: 0.5,
          },
          "page-on": {
            "background-color": "#686868",
            shadow: true,
            "shadow-distance": 3,
          },
        },
        scaleX: {
          values: Array.from(
            Array(this.plotLength),
            (x, index) => `${res[2]}${index}`
          ),
          item: { "font-angle": -45 },
          label: { text: res[3] },
          zooming: true,
        },
        scaleY: {
          progression: "log",
          "log-base": 10,
          label: { text: res[4] },
        },
        preview: {
          adjustLayout: true,
          live: true,
        },
        series: seriesData,
      };
      this.displayGraph = true;
      setTimeout(() => {
        this.ngxService.stop();
        zingchart.TOUCHZOOM = "pinch";
        zingchart.render({
          id: "foldPlot",
          data: myConfig,
          height: 500,
          width: "100%",
        });
      }, 100);
    });
  }

  scaleChange(event: MatSlideToggleChange) {
    this.testService.getTranslation("foldingPlot.units").then((res) => {
      const chartConfigUpdate = {
        data: {
          scaleY: {
            progression: event.checked ? "line" : "log",
            label: { text: res },
          },
        },
      }
      if (!event.checked) chartConfigUpdate.data.scaleY["log-base"] = 10

      zingchart.exec("foldPlot", "modify", chartConfigUpdate);
    });
  }

  async prepareDateChunks(startDate?, endDate?) {
    if (this.selectedPlot === 'dateSeries' || this.selectedPlot === 'analyte' || this.selectedPlot === 'event') {
      const chunkedDates = [];
      const getDates = (startDate, endDate) => {
        const dates = [];
        const addDays = function (days) {
          const date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };
        while (startDate < endDate) {
          dates.push(this.dateFilter.transform(startDate, "yyyy-MM-dd"));
          startDate = addDays.call(startDate, 1);
        }
        return dates.slice(0, this.plotLength);
      };
      this.daysSelected.forEach((day, index) => {
        chunkedDates.push(getDates(new Date(day), new Date(this.daysSelected[index + 1] || new Date())));
      });
      return chunkedDates;
    }
    else if (this.selectedPlot === 'date') {
      this.allDates = [];
      const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => { return arr.slice(i * size, i * size + size); });
      const currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        this.allDates.push(this.dateFilter.transform(currentDate, "yyyy-MM-dd"));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return chunk(this.allDates, this.plotLength);
    }
  }

  async generateDateSeriesPlotGraph() {
    const chunkedDates = await this.prepareDateChunks();
    const startDate = new Date(chunkedDates[0][0]);
    const endDate = new Date(chunkedDates[`${chunkedDates.length - 1}`][0]);
    await this.getResultsSets({ startDate, endDate });
    const data = [];

    this.selectedAnalyte.forEach(async (analyte) => {
      data.push(...this.generateDatasetForGraph([], chunkedDates, analyte));
    });
    this.generateSeriesDate(data, chunkedDates);
  }

  async generateDatePlotGraph() {
    const startDate = new Date(this.selectedPlotDate);
    const endDate = this.selectedPlotEndDate ? new Date(this.selectedPlotEndDate) : new Date(this.maxDate);
    const chunkedDates = await this.prepareDateChunks(startDate, endDate);
    await this.getResultsSets({ startDate, endDate })
    const data = [];
    this.selectedAnalyte.forEach(async (analyte) => {
      data.push(...this.generateDatasetForGraph([], chunkedDates, analyte));
    });
    this.generateSeriesDate(data, chunkedDates);
  }

  async generateEventTriggerPlot() {
    const selectedEventIds = this.selectedEvent.map(event => event.id);
    const filteredEvents = this.allEvents.filter(event =>
      event.eventTypes.some(eventType => selectedEventIds.includes(eventType.id))
    );
    this.daysSelected = filteredEvents.map(event => event.eventDate);
    this.daysSelected.sort();
    this.generateDateSeriesPlotGraph()
  }

  async generateAnalytePlotGraph() {
    const allDates = this.allTestReports.filter(test => {
      const query = (this.comparator === "lthen") ? Number(test[this.selectedAnalyteForCompare.analytes]) < this.analyteValueToCompare : Number(test[this.selectedAnalyteForCompare.analytes]) > this.analyteValueToCompare;
      if (query) { return test.date; }
    });
    this.daysSelected = allDates.map((a) => a.date);
    const chunkedDates = await this.prepareDateChunks();
    const startDate = new Date(this.minDate);
    const endDate = new Date(this.maxDate);
    await this.getResultsSets({ startDate, endDate })
    const data = [];
    this.selectedAnalyte.forEach(async (analyte) => {
      data.push(...this.generateDatasetForGraph([], chunkedDates, analyte));
    });
    this.generateSeriesDate(data, chunkedDates);
  }

  async getResultsSets(type?) {
    try {
      this.ngxService.start()

      const filters = {
        dateRangeStart: null,
        dateRangeEnd: null,
        userId: this.selectedSubscriber.id,
      }

      if (type) {
        filters['dateRangeStart'] = type.startDate ? new Date(type.startDate).toISOString().split('T')[0] : null
        filters['dateRangeEnd'] = type.endDate ? new Date(type.endDate).toISOString().split('T')[0] : null
      }
      else {
        filters['dateRangeStart'] = this.selectedPlotDate ? new Date(this.selectedPlotDate).toISOString().split('T')[0] : null
        filters['dateRangeEnd'] = this.selectedPlotEndDate ? new Date(this.selectedPlotEndDate).toISOString().split('T')[0] : null
      }

      const results = await this.resultSetService.getResultSets(filters);
      const transformededTestResults = this.resultSetService.transformBloodResults(results);

      const labels = [];
      this.allTestReports = JSON.parse(JSON.stringify(transformededTestResults));
      this.allTestReports.forEach((test) => {
        labels.push(test.date.replace(" ", "T"));
      });

      this.dates = JSON.parse(JSON.stringify(labels));

      if (!type) {
        this.minDate = new Date(this.dates[0]);
        this.maxDate = new Date(this.dates[this.dates.length - 1]);
        this.rangeData = this.resultSetService.transformAnalyteRanges(results);
        this.allRanges = this.resultSetService.transformReferenceRanges(results);
        this.resultDates = JSON.parse(JSON.stringify(labels));
      }
      this.ngxService.stop()
    }
    catch (error) {
      this.isError = true;
      this.ngxService.stop();
    }

  }

  isShowFoldingPlot() {
    switch (this.selectedPlot) {
      case 'date':
        return !this.selectedPlotDate || !this.plotLength || (this.selectedAnalyte && this.selectedAnalyte.length <= 0);
      case 'dateSeries':
        return this.daysSelected.length === 0 || !this.plotLength || (this.selectedAnalyte && this.selectedAnalyte.length <= 0);
      case 'analyte':
        return !this.plotLength || !this.comparator || !this.analyteValueToCompare || !this.selectedAnalyteForCompare || (this.selectedAnalyte && this.selectedAnalyte.length <= 0);
      case 'event':
        return !this.plotLength || !this.selectedEvent || (this.selectedAnalyte && this.selectedAnalyte.length <= 0);
      default:
        return true
    }
  }

  getEventTypes() {
    return new Promise((resolve, reject) => {
      this.healthEventService.getHealthEventTypes().subscribe(
        (response: any) => {
          if (response) {
            this.events = response;
            return resolve(response);
          } else {
            return resolve([]);
          }
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }

  getHealthEvents(
    options: {
      date?: string;
      dateRangeStart?: string;
      dateRangeEnd?: string;
    } = {}
  ) {
    this.healthEventService
      .getHealthEvents({ userId: this.selectedSubscriber.id, ...options })
      .subscribe((events: any) => {
        this.allEvents = events;
      });
  }
}
