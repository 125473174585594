import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { TestService } from './services/test.service';
import { config } from './config';
import { Subscription } from 'rxjs';
import { SelectedSubscriberService } from './services/selected-subscriber/selected-subscriber.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  config: any = config;

  subscription: Subscription = new Subscription();

  constructor(
    public router: Router,
    private changeRef: ChangeDetectorRef,
    private _testService: TestService,
    private renderer: Renderer2,
    private el: ElementRef,
    private selectedSubscriberService: SelectedSubscriberService
  ) {
    this.loadPreviousSelectedPatient();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.changeRef.detach();
  }

  ngOnInit() {
    if (environment.maintenanceMode) {
      this.router.navigate(['/maintenance']);
    }

    if (environment.cookieYesAppId && environment.cookieYesAppId.length) {
      this.loadCookieYes();
    }
  }

  loadPreviousSelectedPatient() {
    if (localStorage.getItem('patientId')) {
      this.selectedSubscriberService.setSelectedSubscriber(
        JSON.parse(localStorage.getItem('patientId'))
      );
    }
  }

  loadCookieYes() {
    const script = this.renderer.createElement('script');
    script.id = 'cookieyes';
    script.type = 'text/javascript';
    script.src = `https://cdn-cookieyes.com/client_data/${environment.cookieYesAppId}/script.js`;

    this.renderer.appendChild(this.el.nativeElement, script);
  }

  ngAfterViewInit() {
    this._testService.isUserVerified.emit(true);
  }
}
