import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthAsideComponent } from './auth-aside/auth-aside.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from '../../services/auth/auth.service';
import { ResourceLinksComponent } from './resource-links/resource-links.component';

const authRoutes: Routes = [
  {
    path: "signin",
    component: SignInComponent,
  },
  {
    path: "signup",
    component: SignUpComponent,
  },
  {
    path: "verify",
    component: VerifyUserComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  }
];

@NgModule({
  declarations: [
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AuthAsideComponent,
    AuthenticationComponent,
    VerifyUserComponent,
    ResourceLinksComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(authRoutes),
    SharedModule
  ],
  exports: [
    RouterModule
  ]
})
export class AuthModule { }