<ngx-ui-loader></ngx-ui-loader>

<div class="container">
  <div class="row" style="margin-bottom: 20px">
    <div class="col-md-10 col-lg-10 col-12" style="text-align: left">
      <h2 class="title-text">
        {{ isProvider() ? 'My Subscribers' : 'My Accounts' }}
      </h2>
    </div>

    <div class="col-md-2 col-lg-2 col-12">
      <button mat-raised-button class="add-event" href="javascript:void(0)" id="open-patient-linking-modal"
        (click)="openPatientLinkingModal()" [disabled]="!userData.isProfileComplete" data-backdrop="static"
        data-keyboard="false">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="add"></mat-icon>
        Link Subscriber
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search by Name or ID" [(ngModel)]="searchText"
          (ngModelChange)="handleSearchChange()" #search />
      </mat-form-field>
    </div>
    <div class="col-12 col-md-3">
      <mat-form-field appearance="outline">
        <mat-select #select [(ngModel)]="selectedAgeGroups" placeholder="Age Group"
          (selectionChange)="changeAgeGroupSelection()" multiple>
          <div class="select-all">
            <mat-checkbox [(ngModel)]="allSelectedForAgeGroup" [ngModelOptions]="{ standalone: true }"
              (change)="toggleAllSelection('ageGroup')">Select All</mat-checkbox>
          </div>
          <mat-option value="30-40" [value]="ageGroup.value" *ngFor="let ageGroup of ageGroups">
            <span class="ng-value-label">{{ ageGroup.text }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12 col-md-3">
      <mat-form-field appearance="outline">
        <mat-select #select2 [(ngModel)]="selectedGenders" placeholder="Select Gender"
          (selectionChange)="changeGenderSelection()" multiple>
          <div class="select-all">
            <mat-checkbox [(ngModel)]="allSelectedForGender" [ngModelOptions]="{ standalone: true }"
              (change)="toggleAllSelection('gender')">Select All</mat-checkbox>
          </div>
          <mat-option [value]="gender.value" *ngFor="let gender of genders">
            <span class="ng-value-label">{{ gender.label }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mb-4 d-none d-md-block d-lg-block">
      <div class="mat-elevation-z8" [hidden]="!authorisedByUsers">
        <table mat-table [dataSource]="authorisedByUsers" matSort (matSortChange)="sortData($event)">
          <ng-container matColumnDef="firstName">
            <th mat-header-cell mat-sort-header sortActionDescription="Sort by Name" *matHeaderCellDef>
              Name
            </th>
            <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null">
              <mat-icon class="select-check-box-icon" [hidden]="!isSelected(element.id)"
                (click)="clearPatientFromStorage($event)">check_circle</mat-icon>
              <mat-icon class="material-icons-outlined select-check-box-icon" [hidden]="isSelected(element.id)"
                (click)="selectPatient(element)">check_circle</mat-icon>
              <span>
                {{ element.userProfile.firstName }}
                {{ element.userProfile.lastName }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="dateOfBirth">
            <th mat-header-cell mat-sort-header sortActionDescription="Sort by Date of Birth" *matHeaderCellDef>
              Date Of Birth
            </th>
            <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null"
              class="date">
              {{
              (element.userProfile.dateOfBirth | date: 'dd MMM, yyyy') ?? '--'
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="patientId">
            <th mat-header-cell *matHeaderCellDef>Subscriber ID</th>
            <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null">
              {{ element.userProfile.patientId ?? '--' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="test_name">
            <th mat-header-cell *matHeaderCellDef>Test Name</th>
            <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null">
              {{ element.test_name ?? '--' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="latestTestDate">
            <th mat-header-cell mat-sort-header sortActionDescription="Sort by Test Date" *matHeaderCellDef>
              Latest Test Date
            </th>
            <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null"
              class="date">
              {{
              (element?.resultSet?.observationDate | date: 'dd MMM, yyyy') ??
              '--'
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="immunescore">
            <th mat-header-cell mat-sort-header sortActionDescription="Sort by Score" *matHeaderCellDef>
              Score
            </th>
            <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null">
              <p *ngIf="element?.resultSet?.nonAdaptiveScore > 0.1" class="score-td">
                {{ element?.resultSet?.nonAdaptiveScore | number: '1.2-2' }}
              </p>
              <p *ngIf="!element?.resultSet?.nonAdaptiveScore" class="score-td">
                --
              </p>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="after">
                <button mat-menu-item *ngIf="!isSelected(element.id)" (click)="
                    !isSelected(element.id) ? selectPatient(element) : null
                  ">
                  <mat-icon>check</mat-icon>
                  <span>Select Subscriber</span>
                </button>
                <button mat-menu-item *ngIf="isSelected(element.id)" (click)="clearPatientFromStorage($event)">
                  <mat-icon class="material-symbols-outlined">remove_circle_outline</mat-icon>
                  <span>Deselect Subscriber</span>
                </button>
                <button mat-menu-item *ngIf="element.id !== userData.id" (click)="unlinkSubscriber(element)">
                  <mat-icon class="material-symbols-outlined">link_off</mat-icon>
                  <span>Unlink Subscriber</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div class="mat-paginator-container">
          <div class="mat-paginator-range-label">
            {{ totalRecords }} Results Found
          </div>
          <div class="mat-paginator-navigation">
            <button mat-button class="custom-page" [disabled]="pageNo === 0 || totalRecords < 7"
              (click)="goToPreviousPage()">
              <mat-icon>arrow_left</mat-icon>
              Prev </button>
            <button mat-button class="custom-page" [visible]="isPageShow(pageNumber)"
              *ngFor="let pageNumber of getPageNumbers()" [disabled]="pageNumber === pageNo"
              (click)="goToPage(pageNumber)">
              {{ pageNumber + 1 }}
            </button>
            <button mat-button class="custom-page" [disabled]="(pageNo === getTotalPages() - 1) || totalRecords <= 7"
              (click)="goToNextPage()">
              Next <mat-icon>arrow_right</mat-icon>
            </button>
          </div>
        </div>
        <mat-paginator style="visibility: hidden; display: none" [pageSize]="limit" [pageIndex]="pageNo"
          [length]="totalRecords" (page)="pageEvents($event)">
        </mat-paginator>
      </div>
    </div>

    <div class="col-6 mat-paginator-container d-block d-md-none d-lg-none">
      <p style="margin-top: 12px; margin-left: 7px; font-weight: 700">
        {{ totalRecords }} Results Found
      </p>
    </div>
    <div class="col-6 mat-paginator-container d-block d-md-none d-lg-none">
      <mat-form-field appearance="outline">
        <mat-label style="font-weight: 700">Current Page No.</mat-label>
        <mat-select #select2 [(ngModel)]="pageNo" placeholder="Pages">
          <mat-option (click)="goToPage(pageNumber)" *ngFor="let pageNumber of getPageNumbers()" [value]="pageNumber">
            <span class="ng-value-label" [class.disabled]="pageNo === getTotalPages() - 1">{{ pageNumber + 1 }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="example-container mb-4 mat-elevation-z8 d-block d-md-none d-lg-none">
      <table mat-table [dataSource]="authorisedByUsers" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="firstName">
          <th mat-header-cell mat-sort-header sortActionDescription="Sort by Name" *matHeaderCellDef>
            Name
          </th>
          <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null">
            <mat-icon class="select-check-box-icon" [hidden]="!isSelected(element.id)"
              (click)="clearPatientFromStorage($event)">
              check_circle
            </mat-icon>
            <mat-icon class="material-icons-outlined select-check-box-icon" [hidden]="isSelected(element.id)"
              (click)="selectPatient(element)">check_circle</mat-icon>
            <span>
              {{ element.userProfile.firstName }}
              {{ element.userProfile.lastName }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="dateOfBirth">
          <th mat-header-cell mat-sort-header sortActionDescription="Sort by Date of Birth" *matHeaderCellDef>
            Date Of Birth
          </th>
          <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null"
            class="date">
            {{
            (element.userProfile.dateOfBirth | date: 'dd MMM, yyyy') ?? '--'
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="patientId">
          <th mat-header-cell *matHeaderCellDef>Patient ID</th>
          <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null">
            {{ element.userProfile.patientId ?? '--' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="test_name">
          <th mat-header-cell *matHeaderCellDef>Test Name</th>
          <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null">
            {{ element.test_name ?? '--' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="latestTestDate">
          <th mat-header-cell mat-sort-header sortActionDescription="Sort by Test Date" *matHeaderCellDef>
            Latest Test Date
          </th>
          <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null"
            class="date">
            {{
            (element?.resultSet?.observationDate | date: 'dd MMM, yyyy') ??
            '--'
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="immunescore">
          <th mat-header-cell mat-sort-header sortActionDescription="Sort by Score" *matHeaderCellDef>
            Score
          </th>
          <td mat-cell *matCellDef="let element" (click)="!isSelected(element.id) ? selectPatient(element) : null">
            <p *ngIf="element?.resultSet?.nonAdaptiveScore > 0.1" class="score-td">
              {{ element?.resultSet?.nonAdaptiveScore | number: '1.2-2' }}
            </p>
            <p *ngIf="!element?.resultSet?.nonAdaptiveScore" class="score-td">
              --
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let i = index" style="text-align: right">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="after">
              <button mat-menu-item *ngIf="!isSelected(element.id)" (click)="
                  !isSelected(element.id) ? selectPatient(element) : null
                ">
                <mat-icon>check</mat-icon>
                <span>Select Subscriber</span>
              </button>
              <button mat-menu-item *ngIf="isSelected(element.id)" (click)="clearPatientFromStorage($event)">
                <mat-icon class="material-symbols-outlined">remove_circle_outline</mat-icon>
                <span>Deselect Subscriber</span>
              </button>
              <button mat-menu-item (click)="unlinkSubscriber(element)">
                <mat-icon class="material-symbols-outlined">link_off</mat-icon>
                <span>Unlink Subscriber</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>

<div class="modal fade p-0" id="patientToPatientLink" tabindex="-1" role="dialog"
  aria-labelledby="patientToPatientLinkTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="patientToPatientLinkTitle">
          {{ 'myPatient.manageAccountLinking' | translate }}
        </h5>
      </div>
      <div class="modal-body">
        <form>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'myPatient.enterCodeLabel' | translate }}</mat-label>
            <input matInput id="patientOtp" [formControl]="patientOtp" (keypress)="onlyNumberKey($event)" />
          </mat-form-field>
        </form>
      </div>
      <div class="modal-footer justify-content-between">
        <div>
          <button mat-button *ngIf="config.featureFlags.isInviteSubscriber" id="invite-subscriber" class="inviteBtn"
            (click)="toggleModal('invitePatient', true)">
            <mat-icon [inline]="true">add</mat-icon>
            {{ 'myPatient.inviteSubscriber' | translate }}
          </button>
          <button mat-button id="create-managed-account" class="d-none"
            (click)="toggleModal('createManagedAccount', true)">
            <mat-icon [inline]="true">add</mat-icon>
            {{ 'myPatient.createConnectedAccount' | translate }}
          </button>
        </div>
        <div>
          <button type="button" id="cancle-flow" mat-stroked-button class="mr-1" (click)="cancelFlow(true)">
            {{ 'common.closeText' | translate }}
          </button>
          <button type="button" id="link-text" mat-flat-button [disabled]="isOtpInserted(patientOtp)"
            class="mr-1 algocyteBtn" (click)="searchPatient()">
            {{ 'common.linkText' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="claimPatient" tabindex="-1" role="dialog" aria-labelledby="claimPatientTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title-text text-center" id="claimPatientTitle">
          Link Subscriber
        </h5>
        <mat-icon class="material-symbols-outlined" style="cursor: pointer" (click)="cancelFlow(true)"
          aria-hidden="false" aria-label="Cancel Linking Subscriber" fontIcon="close"></mat-icon>
      </div>
      <div class="modal-body">
        <section *ngIf="isManager" class="mb-3">
          <p>Search for subscriber by NHS Number</p>
          <ng-select placeholder="{{ 'myPatient.selectClinician' | translate }}" [items]="allClinicians"
            bindLabel="fullName" [formControl]="clinicianUUID">
            <ng-template ng-option-tmp let-item="item">
              {{ 'myPatient.clinicianNameText' | translate }} :
              {{ item.fullName }}
            </ng-template>
          </ng-select>
        </section>
        <section class="mb-3">
          <div style="display: flex; align-items: center; margin-bottom: 15px">
            <mat-icon>search</mat-icon>
            <div style="
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              ">
              Search for patient by NHS Number
            </div>
          </div>
          <div class="row">
            <div class="form-group col-8">
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput id="patientNHS" [formControl]="patientNHS" type="text" placeholder="{{
                    'myPatient.nhsNumberPlaceholder' | translate
                  }}" autocomplete="off" (keyup)="patientFound = false" />
              </mat-form-field>
            </div>
            <div class="col-4">
              <button mat-stroked-button type="button" id="searchText-patientFound" style="
                  width: 100% !important;
                  min-width: unset !important;
                  height: 45px;
                " (click)="searchPatient()" [disabled]="getButtonDisablility() || patientFound">
                {{ 'common.searchText' | translate }}
              </button>
            </div>
          </div>
        </section>
        <section *ngIf="patientFound">
          <h6>
            {{ 'myPatient.searchResultText' | translate }}
          </h6>
          <div class="row m-0">
            <mat-card class="example-card w-100 mat-elevation-z8">
              <mat-card-content>
                <h6>
                  {{ 'myPatient.nhs' | translate }}:
                  {{ retrivedPatient.profile.patientId }}
                </h6>
                <h6>
                  {{ 'myPatient.nameText' | translate }}:
                  {{ retrivedPatient.profile.firstName }}
                  {{ retrivedPatient.profile.lastName }}
                </h6>
                <h6>
                  {{ 'myPatient.dobText' | translate }}:
                  {{
                  retrivedPatient.profile.dateOfBirth
                  | date: config.regionConfig.dateFormatMid
                  }}
                </h6>
                <h6>
                  {{ 'myPatient.address' | translate }}:
                  {{ retrivedPatient.address }}
                </h6>
                <hr class="text-danger" />
                <span *ngIf="
                    retrivedPatient.cidSetAt === null &&
                    retrivedPatient.oqid === null
                  ">
                  <h6 class="text-danger">
                    {{ 'myPatient.linkPatientError' | translate }}
                  </h6>
                </span>
                <ng-container *ngIf="
                    retrivedPatient.cidSetAt !== null &&
                      !retrivedPatient.isLinked;
                    else elseTemplate
                  ">
                  <ng-container *ngIf="isManager; else elseTemplateInner1">
                    <h6>
                      {{ 'myPatient.linkClinicianConfirmation' | translate }}
                    </h6>
                  </ng-container>
                  <ng-template #elseTemplateInner1>
                    <h6>
                      {{ 'myPatient.linkPatientConfirmation' | translate }}
                    </h6>
                  </ng-template>
                </ng-container>
                <ng-template #elseTemplate>
                  <ng-container *ngIf="isManager; else elseTemplateInner2">
                    <h6>
                      {{ 'myPatient.unLinkClinicianConfirmation' | translate }}
                    </h6>
                  </ng-container>
                  <ng-template #elseTemplateInner2>
                    <h6>
                      {{ 'myPatient.unLinkPatientConfirmation' | translate }}
                    </h6>
                  </ng-template>
                </ng-template>
              </mat-card-content>
              <mat-card-actions class="d-flex align-items-center float-right">
                <div *ngIf="linkPatientError" class="text-danger mr-3">
                  {{ linkPatientError | translate }}
                </div>

                <button id="linkText" mat-button (click)="
                    retrivedPatient.linked ? unlinkPatient() : linkPatient()
                  ">
                  <span *ngIf="retrivedPatient.linked">{{ 'common.unLinkText' | translate }}
                  </span>
                  <span *ngIf="!retrivedPatient.linked">{{ 'common.linkText' | translate }}
                  </span>
                </button>

                <button mat-button id="cancelText" (click)="cancelFlow()">
                  {{ 'common.cancelText' | translate }}
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        </section>
      </div>
      <div class="modal-footer text-center justify-content-end">
        <button mat-button type="button" id="invite-sub-btn" *ngIf="config.featureFlags.isInviteSubscriber"
          style="margin-left: 10px" [disabled]="getButtonDisablility() || !patientFound">
          + Invite Subscriber
        </button>
        <button mat-stroked-button type="button" id="closeText-patientFound" (click)="cancelFlow(true)">
          {{ 'common.closeText' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="invitePatient" tabindex="-1" role="dialog" aria-labelledby="invitePatientTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content p-2">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="invitePatientTitle">
          {{ 'myPatient.inviteSubscriber' | translate }}
        </h5>
      </div>
      <div class="modal-body">
        <form>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'myPatient.enterSubscriberEmailLabel' | translate }}
            </mat-label>
            <input matInput id="patientEmail" [formControl]="patientEmail"
              placeholder="{{ 'myPatient.enterEmail' | translate }}" />
          </mat-form-field>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" mat-stroked-button class="mr-1" id="invite-patient"
          (click)="toggleModal('invitePatient', false)">
          {{ 'common.closeText' | translate }}
        </button>
        <button type="button" mat-flat-button class="mr-1 algocyteBtn" id="send-invitation" (click)="sendInvitation()">
          {{ 'myPatient.sendInvitation' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>