<div class="container">
  <div class="row">
    <div class="col-12">
      <h3 class="my-5">{{'subscriptions.mySubscriptions'| translate }}</h3>
      <button mat-button class="btn btn-primary my-2" (click)="createCoupon()">
        Create Coupon
      </button>
      <mat-form-field class="my-2">
        <mat-label class="my-1">{{'subscriptions.enterCoupon' | translate}}</mat-label>
        <input matInput class="my-1 w-100" type="text" [(ngModel)]="couponCode"
          placeholder="{{'subscriptions.enterCoupon'| translate }}">
      </mat-form-field>
      <button mat-button class="btn btn-primary my-2" (click)="activateCoupon()" [disabled]="couponCode === ''">
        {{'common.saveBtnText'| translate }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <table mat-table matSort [dataSource]="dataSource" class="w-100">

        <ng-container matColumnDef="coupon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'subscriptions.coupon'| translate}} </th>
          <td mat-cell *matCellDef="let element">{{ element.coupon }}</td>
        </ng-container>

        <ng-container matColumnDef="couponName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element">{{ element.couponName }}</td>
        </ng-container>

        <ng-container matColumnDef="purchased">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'subscriptions.purchased'| translate}} </th>
          <td mat-cell *matCellDef="let element">{{ element.purchased }}</td>
        </ng-container>

        <ng-container matColumnDef="available">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'subscriptions.available'| translate}} </th>
          <td mat-cell *matCellDef="let element">{{ element.available }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
      </table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>

    </div>
  </div>
</div>