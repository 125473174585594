import { Component, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AuthService } from "src/app/services/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { config } from "../../../config";
import { Subscription } from "rxjs";

@Component({
  selector: "app-verify-user",
  templateUrl: "./verify-user.component.html",
  styleUrls: ["./verify-user.component.css"],
})
export class VerifyUserComponent implements OnInit {
  resendVerificationStatus: string;
  verifyError: string;
  isVerifySuccess: boolean;
  config = config;
  subscription = new Subscription();

  constructor(
    private ngxService: NgxUiLoaderService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.route.queryParams.subscribe(async (res) => {
        if (res.token) {
          await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { token: undefined },
            queryParamsHandling: "merge",
          });

          this.ngxService.start();
          try {
            this.isVerifySuccess = await this.authService.verifyUser(res.token);
          } catch (error) {
            this.verifyError =
              error.error?.message || "Token is invalid or expired";
          }
          this.ngxService.stop();
        }
      })
    );
  }

  async resendVerificationEmail(event: Event) {
    this.ngxService.start();
    event.preventDefault();
    try {
      await this.authService.resendVerificationEmail();
      this.resendVerificationStatus =
        "Email sent. Please check your inbox or contact support if you do not receive it.";
    } catch (error) {
      this.resendVerificationStatus = error.message;
    }

    this.ngxService.stop();
  }

  doRouting(path) {
    this.router.navigate(path);
  }
}
