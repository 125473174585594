<!doctype html>
<html>
  <body>
    <div style="font-family: 'Open Sans', sans-serif; text-align: center">
      <div style="margin-bottom: 20px">
        <img
          src="assets/maintenanceLogo.png"
          alt="Algocyte"
          title="Algocyte"
          style="max-width: 400px; width: 100%"
        />
      </div>
      <div>
        Algocyte is currently down for scheduled maintenance, we will be back
        soon
      </div>
    </div>
  </body>
</html>
