<div class="container text-center">
  <div class="scanner-shell" [hidden]="!(hasDevices && hasPermission)">
    <zxing-scanner [torch]="torchEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
      [formats]="formatsEnabled" [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
      (camerasFound)="onCamerasFound($event)" (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
  </div>

  <ng-container>
    <div *ngIf="hasPermission===false">
      <i class="fa-3x fas fa-camera"></i>
      <h2 class="text-title">
        {{'registerKit.permission'| translate }}
        <!-- Waiting for permissions. -->
      </h2>
      <p class="text-subtitle">
        {{'registerKit.noCameraMsg'| translate }}
        <!-- If your device does not has cameras, no permissions will be asked. -->
      </p>
      <p class="text-subtitle">
        {{'registerKit.securityError'| translate }}
        <!-- This may be caused by some security error. -->
      </p>
    </div>
    <div *ngIf="hasDevices===false && hasPermission===null">
      <i class="fa-3x fas fa-camera"></i>
      <h2 class="text-title">
        {{'registerKit.noDeviceMsg'| translate }}
        <!-- No devices were found. -->
      </h2>
      <p class="text-subtitle">
        {{'registerKit.noMediaMsg'| translate }}
        <!-- I believe your device has no media devices attached to. -->
      </p>
    </div>
  </ng-container>
</div>