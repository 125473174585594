import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { TestService } from "src/app/services/test.service";
import Swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-test-profile",
  templateUrl: "./test-profile.component.html",
  styleUrls: ["./test-profile.component.css"],
})
export class TestProfileComponent implements OnInit {
  allAnalytes = [];
  dupAllAnalytes = [];
  selectedProfile = JSON.parse(localStorage.getItem("testProfile"))
    ? JSON.parse(localStorage.getItem("testProfile"))
    : { viewValue: "", analytes: [], id: null };
  profiles = [];
  isEditOrAdd = false;
  previousProfile: any;
  filterargs = '';
  newSelectedAnalytes = [];
  testingSelected: any = null;
  testingProfileSelected: any = [];
  isChangedInEdit = false;
  allGroups: any = [];
  previousAnaylites = [];

  isEditMode: any = false;
  isProfileSelected: boolean =
    JSON.parse(localStorage.getItem("testProfile")) &&
      JSON.parse(localStorage.getItem("testProfile")).viewValue
      ? true
      : false;
  isEditing: boolean = false;
  isAdding: boolean = false;
  constructor(
    private testService: TestService,
    private ngxService: NgxUiLoaderService,
    private change: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    testService.testProfile.subscribe((res: boolean) => {
      if (res) {
        this.selectedProfile = JSON.parse(localStorage.getItem("testProfile"));
        this.editProfie();
      }
    });
  }

  ngOnInit() {
    this.getallTestProfiles();
    this.getAllAnalytes();
  }

  getAllAnalytes() {
    this.ngxService.start();
    this.testService.getAllAnalytes().subscribe(
      (res: any) => {
        console.log(res);
        this.allGroups = res.GROUPS;
        this.allAnalytes = res.ANALYTES;
        this.editProfie();
        const arr = [];
        const allArr = [];

        this.ngxService.stop();
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }

  getallTestProfiles(added?) {
    // this.ngxService.start();
    this.testService.getAllTestProfiles().subscribe(
      (res) => {
        console.log(res, added);
        this.profiles = JSON.parse(JSON.stringify(res));
        // this.testingSelected = this.profiles[this.profiles.length - 1].id
        if (added) {
          this.selectedProfile = this.profiles[this.profiles.length - 1];
          this.isProfileSelected = true;
          this.isAdding = false;
        }
        console.log(this.selectedProfile, this.isProfileSelected);
        // this.ngxService.stop();
      },
      (err) => {
        console.log(err);
        // this.ngxService.stop();
      }
    );
  }

  radoiSelectionChanged(event) {
    console.log(event);
    this.profiles.forEach((pro) => {
      if (pro.id === event.value) {
        this.selectedProfile = pro;
      }
    });
    console.log("this.selectedProfile", this.selectedProfile);
  }

  async editProfie() {
    console.log("called");
    await this.clearGroupSelection();
    this.previousProfile = JSON.parse(JSON.stringify(this.selectedProfile));
    this.isEditOrAdd = true;

    if (this.selectedProfile.id != null) {
      // this.isAdd =false;
      let groupIds = this.allGroups.map((o) => {
        return o.AID;
      });

      groupIds.forEach((group, index) => {
        const filterGrpAnalytes = this.allAnalytes.filter(singleAnalyte => singleAnalyte.ANALYTE_GROUP_ID == group);

        const checker = filterGrpAnalytes.every(v => this.selectedProfile.analytes.includes(v.ANALYTE_KEY));
        const checkerAny = filterGrpAnalytes.some(v => this.selectedProfile.analytes.includes(v.ANALYTE_KEY));

        if (checker) {
          this.allGroups[index]["selected"] = true;
          this.allGroups[index]["indeterminate"] = false;
        } else if (checkerAny) {
          this.allGroups[index]["selected"] = false;
          this.allGroups[index]["indeterminate"] = true;
        } else {
          this.allGroups[index]["selected"] = false;
          this.allGroups[index]["indeterminate"] = false;
        }

        const grpAnalytes = this.allAnalytes.map(o => o.ANALYTE_KEY);
      });
    }
  }
  clearGroupSelection() {
    this.allGroups.forEach(group => {
      delete group.selected;
      delete group.indeterminate;
    });
  }

  cancleOp() {
    this.selectedProfile = this.previousProfile;
    this.isEditOrAdd = false;
  }

  async addProfile() {
    this.previousProfile = JSON.parse(JSON.stringify(this.selectedProfile));
    this.selectedProfile = { viewValue: "", analytes: [], id: null };
    await this.clearGroupSelection();

    this.isEditMode = true;
    this.isAdding = true;
    this.isEditing = false;
  }

  demo(id, i) {
    const index = this.profiles.findIndex(profile => profile.id === id);
    this.testingProfileSelected = this.profiles[index];
    this.selectedProfile = JSON.parse(JSON.stringify(this.profiles[index]));

    this.isAdding = false;
    this.isEditing = false;
    this.isProfileSelected = true;
    this.isEditMode = false;

    this.editProfie();
  }

  trash() {
    console.log("Trash clicked");
  }

  // async saveOp() {
  saveOp() {
    const filtered = this.allAnalytes
      .filter(item => this.selectedProfile.analytes.includes(item.ANALYTE_KEY))
      .map(item => item.ANALYTE_ID);
    const data: any = {};
    if (this.selectedProfile.id === null) {
      data.profile = this.selectedProfile.viewValue;
      data.id = this.selectedProfile.id;
      data.analytes = filtered;
      data.Update = "0";
      console.log(data);
    } else if (this.selectedProfile.id !== null) {
      data.profile = this.selectedProfile.viewValue;
      data.id = this.selectedProfile.id;
      data.analytes = filtered;
      data.Update = "1";
      console.log(data);
    }
    if (data) {
      this.ngxService.start();
      this.testService.addOrUpdateProfile(data).subscribe(
        (res) => {
          console.log(res);
          this.testService.profileModificationEvent();
          this.isEditOrAdd = false;
          this.isEditMode = false;
          this.isProfileSelected = true;
          this.isEditing = true;
          this.isEditing = false;


          this.ngxService.stop();
          Promise.all([
            this.testService.getTranslation('messages.added'),
            this.testService.getTranslation('messages.profileAdded'),
            this.testService.getTranslation('messages.updated'),
            this.testService.getTranslation('messages.profileUpdated'),
            this.testService.getTranslation("common.okBtnText")
          ]).then((res: any[]) => {
            if (data.Update === "0") {
              Swal.fire({
                type: 'success',
                title: res[0],
                text: res[1],
                showConfirmButton: true,
                confirmButtonText: res[4]
              });

              this.getallTestProfiles(true);
            } else {
              Swal.fire({
                type: 'success',
                title: res[2],
                text: res[3],
                showConfirmButton: true,
                confirmButtonText: res[4]
              });
              if (
                localStorage.getItem("testProfile") &&
                JSON.parse(localStorage.getItem("testProfile")).id ===
                this.selectedProfile.id
              ) {
                localStorage.setItem("testProfile", JSON.stringify(this.selectedProfile));
              }
              console.log();

              this.getallTestProfiles();
            }
          });
        },
        (err) => {
          console.log(err);
          this.ngxService.stop();
        }
      );
    }
  }

  compareFn() {
    return Object.is(this.previousProfile, this.selectedProfile);
  }

  deleteProfile(profileId) {
    Promise.all([
      this.testService.getTranslation('messages.areYouSure'),
      this.testService.getTranslation('messages.notRevert'),
      this.testService.getTranslation('common.cancelBtnText'),
      this.testService.getTranslation('common.confirmBtnText'),
      this.testService.getTranslation('messages.delete'),
      this.testService.getTranslation('messages.profileDeleted'),
      this.testService.getTranslation("common.okBtnText")
    ]).then((keyResult: any[]) => {
      console.log("keyResult ::", keyResult);
      Swal.fire({
        title: keyResult[0],
        text: keyResult[1],
        showCancelButton: true,
        cancelButtonText: keyResult[2],
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: keyResult[3],
        focusCancel: true,
      }).then((result) => {
        if (result.value) {
          this.ngxService.start();
          this.testService.deleteTestProfile(profileId).subscribe(
            (res) => {
              console.log(res);
              this.testService.profileModificationEvent();
              this.ngxService.stop();
              if (localStorage.getItem("testProfile") && JSON.parse(localStorage.getItem("testProfile")).id === profileId) {
                this.selectedProfile = this.selectedProfile = {
                  viewValue: "",
                  analytes: [],
                  id: null,
                };
                localStorage.setItem(
                  "testProfile",
                  JSON.stringify(this.selectedProfile)
                );
              } else if (
                localStorage.getItem("testProfile") &&
                JSON.parse(localStorage.getItem("testProfile")).id !== profileId
              ) {
                this.selectedProfile = JSON.parse(
                  localStorage.getItem("testProfile")
                );
              } else {
                this.selectedProfile = { viewValue: "", analytes: [], id: null };
              }
              console.log("In delete ==>", this.selectedProfile);
              this.clearGroupSelection();
              this.isAdding = false;
              this.isEditMode = false;
              this.isProfileSelected = false;

              Swal.fire({
                type: 'success',
                title: keyResult[4],
                text: keyResult[5],
                showConfirmButton: true,
                confirmButtonText: keyResult[6]
              });
              this.getallTestProfiles();
            },
            (err) => {
              console.log(err);
              this.ngxService.stop();
            }
          );
        }
      });
    });
  }

  /**
   * New Implemention of check anaylte
   */

  analyteChange(analyteGroupName, singleAnalyte, event, index, analyteIndex) {
    const analyteIn = this.selectedProfile.analytes.indexOf(
      singleAnalyte.ANALYTE_KEY
    );
    analyteIn == -1
      ? this.selectedProfile.analytes.push(singleAnalyte.ANALYTE_KEY)
      : this.selectedProfile.analytes.splice(analyteIn, 1);

    let targetArr = this.allAnalytes.filter(item => item.ANALYTE_GROUP_ID === analyteIndex);

    let checker = targetArr.every((v) =>
      this.selectedProfile.analytes.includes(v.ANALYTE_KEY)
    );
    let checkerAny = targetArr.some((v) =>
      this.selectedProfile.analytes.includes(v.ANALYTE_KEY)
    );

    if (checker) {
      this.allGroups[index]["selected"] = true;
      this.allGroups[index]["indeterminate"] = false;
    } else if (checkerAny) {
      this.allGroups[index]["selected"] = false;
      this.allGroups[index]["indeterminate"] = true;
    } else {
      this.allGroups[index]["selected"] = false;
      this.allGroups[index]["indeterminate"] = false;
    }
    console.log(this.selectedProfile.analytes);
    console.log(this.allGroups[index]);
    this.change.detectChanges();
  }

  getCheckedValue(analyte) {
    return this.selectedProfile.analytes.includes(analyte);
  }

  markAllAnalytics(event, index, groupId) {
    console.log(event, index, groupId);

    let targetArr: any = [];
    try {
      targetArr = this.allAnalytes.filter(o => o.ANALYTE_GROUP_ID == groupId);
      if (event.checked) {
        targetArr = targetArr.map((o) => {
          return o.ANALYTE_KEY;
        });
        this.allGroups[index]["selected"] = true;
        this.allGroups[index]["indeterminate"] = false;

        this.selectedProfile.analytes = [
          ...this.selectedProfile.analytes,
          ...targetArr,
        ];
        this.selectedProfile.analytes = [...new Set(this.selectedProfile.analytes)];
      } else {
        this.allGroups[index]["selected"] = false;
        this.allGroups[index]["indeterminate"] = false;
        let allAnalytics = this.allAnalytes.map((o) => {
          return o.ANALYTE_KEY;
        });
        targetArr.slice().forEach(analite => {
          let indx = this.selectedProfile.analytes.indexOf(analite.ANALYTE_KEY);
          if (indx !== -1) {
            this.selectedProfile.analytes.splice(indx, 1);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  enableEditing() {
    this.isAdding = false;
    this.isEditMode = true;
    this.isEditing = true;
    this.isProfileSelected = false;

    this.editProfie();
    console.log(
      this.newSelectedAnalytes,
      this.newSelectedAnalytes == this.testingProfileSelected.groups,
      this.testingProfileSelected.groups
    );
  }

  saveNewAnalyitcs() {
    console.log(this.newSelectedAnalytes, this.testingProfileSelected);
  }

  cancelChanges() {
    // this.isEditMode = false;
    if (this.isAdding) {
      this.selectedProfile = { viewValue: "", analytes: [], id: null };
      this.isAdding = false;
      this.isEditMode = false;
      this.clearGroupSelection();
    } else {
      console.log(this.newSelectedAnalytes);
      this.selectedProfile = this.previousProfile;
      this.editProfie();

      this.isAdding = false;
      this.isEditMode = false;
      this.isEditing = false;
      this.isProfileSelected = true;
    }
  }

  checkNgModel() {
    console.log(this.selectedProfile);
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterargs = filterValue;
    console.log("filterargs :: ", this.filterargs)
  }
}
