<div class="row m-0 p-0 flex-row-reverse">
  <div class="col-12 col-md-7 d-flex flex-wrap align-content-center justify-content-center py-4" id="custom-section">
    <div class="col-12 m-0 p-0">
      <div style="margin:auto;">

        <ngx-ui-loader></ngx-ui-loader>

        <div class="col-12">
          <div class="algocyte_logo_square mx-auto" (click)="doRouting(['/home'])">
            <img src="assets/algocyte/logo.png" alt="company-logo" class="img-fluid" />
          </div>
        </div>

        <div class="col-12">
          <div class="sign_in_text mx-auto margin-bottom-20">
            {{'common.signIn' | translate}}
          </div>
        </div>

        <form [formGroup]="signInForm" id="signInForm" (ngSubmit)="onSignIn()" class="mx-auto"
          style="max-width:515px; margin:auto;">

          <mat-form-field appearance="outline">
            <mat-label for="email"> {{'common.email' | translate}} </mat-label>
            <input matInput id="email" type="email" formControlName="email" placeholder="{{'common.email' | translate}}" />
            <mat-error class="margin-bottom-15" *ngIf="signInForm.get('email').errors && signInForm.get('email').hasError('required')">
              {{'common.emailMandatory' | translate}}
            </mat-error>
            <mat-error class="margin-bottom-15" *ngIf="signInForm.get('email').errors && signInForm.get('email').hasError('pattern')">
              {{ 'common.inValidEmail'| translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label for="password">{{'common.password' | translate}}</mat-label>
            <input matInput id="password" [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="{{'common.password' | translate}}" />
            <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" style="color: gray !important;">
              <mat-icon>{{ !hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
            <mat-error class="margin-bottom-15" *ngIf="signInForm.get('password').errors && signInForm.get('password').hasError('required')">
              {{'common.passwordMandatory' | translate}}
            </mat-error>
          </mat-form-field>

          <p class="error" *ngIf="error">{{error}}</p>

          <div class="d-block text-right margin-bottom-20">
            <a routerLink="/auth/forgot-password" style="
                      margin: 1px 0px 4px 4px !important;
                      font-family: Open-Sans;
                      font-size: 14px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.36;
                      text-decoration: underline;
                      letter-spacing: normal;
                      cursor: pointer;            
                      color: rgb(0, 151, 141) !important;">
              {{'common.forgotPassword' | translate}}
            </a>
          </div>

          <div class="w-100 text-center">
            <button class="create-account text-uppercase" mat-button class="mx-auto" type="submit"
              [disabled]="!signInForm.valid">
              {{'common.signIn' | translate}}
            </button>
            <app-resource-links></app-resource-links>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-5" style="padding: 0">
    <app-auth-aside welcomeText="{{'signIn.infoStatement' | translate}}"
      welcomeSubText="{{'signIn.infoStatement1' | translate}}" ctaText=" {{'common.signUp' | translate}}"
      ctaUrl="/auth/signup">
    </app-auth-aside>
  </div>

</div>