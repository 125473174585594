import { Component, Input, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormGroup, Validators, FormBuilder, ValidatorFn, AbstractControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { UserService } from "src/app/services/user/user.service";
import { User } from "src/types";
import { Organisation, Role } from '../../../types';
import { ApiService } from "src/app/services/api.service";
import { switchMap } from "rxjs/operators";
import { SelectedSubscriberService } from 'src/app/services/selected-subscriber/selected-subscriber.service';

@Component({
  selector: "app-clinician-profile",
  templateUrl: "./clinician-profile.component.html",
  styleUrls: ["./clinician-profile.component.css"],
})
export class ClinicianProfileComponent implements OnInit {
  form: FormGroup;
  isUpdatingProfile = false;
  isSucceeded = false;
  isFailed = false;
  associatedOrganisations: Organisation[] = [];
  isOrganisationLoading = false;
  userData: User = null;
  private subscription: Subscription;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private _apiService: ApiService,
    private selectedSubscriberService: SelectedSubscriberService
  ) {

    this.subscription = this.userService
      .getUserDataObservable()
      .subscribe((d) => {
        this.userData = d;
      });
  }

  ngOnInit(): void {
    this.fetchOrganisations();
    this.initForm();
    this.subscription.add(
      this.userService.getUserDataObservable().subscribe((d) => {
        this.userData = d
      })
    );
  }

  fetchOrganisations() {
    this.isOrganisationLoading = true;
    this.subscription.add(
      this._apiService
        .associatedOrganisations(this.userService.user.id)
        .subscribe({
          next: (orgs) => {
            this.associatedOrganisations = orgs;
            this.isOrganisationLoading = false;
            if (this.associatedOrganisations.length) {
              this.form.patchValue({
                organisationId: this.associatedOrganisations[0].id,
                organisationName: this.associatedOrganisations[0].name,
              })
            }
          },
          error: () => {
            this.isOrganisationLoading = false;
          },
        })
    );
  }

  onOrgChange(name: string) {
    this.form.get('organisationName').setValue(name, { emitEvent: false });
  }

  onSubmit() {
    const { userId, firstName, lastName, dateOfBirth } = this.form.value;

    this.form.markAsPristine();

    const data = {
      firstName: firstName.trim(),
      lastName: lastName.trim()
    };

    this.subscription.add(
      this.userService
        .updateUserProfile(userId, data)
        .pipe(switchMap(() => this.userService.refreshUserProfile(userId)))
        .subscribe({
          next: () => {

            const subscribeId = JSON.parse(localStorage.getItem('patientId'))
            const currentUserId = JSON.parse(localStorage.getItem('UUID'))
            this.userService.fetchFullUserData(currentUserId).subscribe(() => {
              this.selectedSubscriberService.setSelectedSubscriber(subscribeId)
            })
            this.isUpdatingProfile = false;
            this.isSucceeded = true;
          },
          error: () => {
            this.isUpdatingProfile = false;
            this.isFailed = true;
          },
        })
    );
  }

  initForm() {
    const { userProfile } = this.userData;

    this.form = this.fb.group({
      userId: [this.userData.id],
      email: [{ value: userProfile.email, disabled: true }, [Validators.required]],
      firstName: [userProfile.firstName, [Validators.required, this.noNumbersValidator()]],
      lastName: [userProfile.lastName, [Validators.required, this.noNumbersValidator()]],
      organisationId: [this.associatedOrganisations[0]?.id],
      organisationName: [this.associatedOrganisations[0]?.name]
    });

    this.form.get('firstName').valueChanges.subscribe(value => {
      this.removeNumbersFromControl('firstName', value);
    });

    this.form.get('lastName').valueChanges.subscribe(value => {
      this.removeNumbersFromControl('lastName', value);
    });
  }

  private removeNumbersFromControl(controlName: string, value: string) {
    if (value) {
      const sanitizedValue = value.replace(/\d/g, '');
      if (sanitizedValue !== value) {
        this.form.get(controlName).setValue(sanitizedValue, { emitEvent: false });
      }
    }
  }

  private noNumbersValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = /\d/.test(control.value);
      return forbidden ? { 'noNumbers': { value: control.value } } : null;
    };
  }

}
