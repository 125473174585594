<ngx-ui-loader></ngx-ui-loader>
<div class="container">
  <div class="mt-5 mb-5">
    <div class="pt-3 pt-md-5">
      <h3 class="text-center pb-3" *ngIf="oldView">
        {{'importResults.title'| translate }}
        <span class="camera-switch"><b>{{'importResults.enableCamera'| translate }}</b>
          <mat-slide-toggle class="example-margin" color="accent" [(ngModel)]="cameraView"
            (change)="enableCamera($event)">
          </mat-slide-toggle>
        </span>
      </h3>
      <h3 class="text-left pb-3">{{'importResults.pageTitle'| translate }}</h3>
      <div *ngIf="oldView" class="border">
        <ul class="nav nav-tabs nav-fill scrollable-tabs">
          <!-- <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#kit">Register Kit</a>
            </li> -->
          <li class="nav-item" (click)="camClicked(0)">
            <a class="nav-link active" data-toggle="tab" href="#webcam">{{'importResults.webcam'| translate }}</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#fileUpload">{{'importResults.fileUpload'| translate
              }}</a>
          </li>
          <!-- <li class="nav-item" (click)="menualClicked()">
              <a class="nav-link" data-toggle="tab" href="#manual">Manual Entry</a>
            </li> -->
        </ul>
        <div class="tab-content">
          <div id="webcam" class="tab-pane active p-3"><br>
            <!-- <mat-form-field class="col-12 col-md-6">
                <input matInput [owlDateTime]="webCamDt" [(ngModel)]="testDate" [max]="mydate" autocomplete="off"
                  placeholder="{{'importResults.selectDateTime' |  translate }}">
                <span [owlDateTimeTrigger]="webCamDt"
                  style="position: absolute;top: 0;bottom: 0;left: auto;right: 10px;cursor: pointer;">
                  <i class="fa fa-calendar"></i>
                </span>
                <owl-date-time #webCamDt pickerMode="dialog" [startAt]="today"></owl-date-time>
              </mat-form-field> -->
            <div class="row justify-content-center">
              <div class="col-md-8 col-sm-12" style="position: relative;">
                <!-- <div id="camView">
                    <webcam id="enableWebCam" [width]="width" [height]="height" [trigger]="triggerObservable"
                      (imageCapture)="handleImage($event)" *ngIf="showWebcam && !webcamImage"
                      [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                      [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
                      (initError)="handleInitError($event)">
                    </webcam>
                    <div class="snapshot" style="position: relative;" *ngIf="webcamImage">
                      <img class="img-fluid" [src]="webcamImage.imageAsDataUrl" ngxViewer [viewerOptions]="viewerOptions" />
                      <span title="Remove Image"
                        style="position: absolute;top: 0px;right: 0;left: 0; bottom: 0; z-index: 1;cursor: pointer;color: #dc3545;height: 60px;width: 60px;text-align: center;background-color: rgba(255,255,255,0.6);border-radius: 50%;margin: auto;"
                        (click)="deleteFile('webcam')"><i class="fa fa-trash" aria-hidden="true"
                          style="font-size: 30px;top: 0;bottom: 0;left: 0;right: 0;margin: auto;position: absolute;height: 30px;width: 30px;line-height: 30px;"></i></span>
                    </div>
                  </div> -->
                <div style="position: absolute; top: 0; left: 30px;" *ngIf="errors && errors.length > 0">
                  <h4>Error:</h4>
                  <ul *ngFor="let error of errors">
                    <li class="text-danger">{{error.message}}</li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <button class="btn btn-success mb-3" (click)="triggerSnapshot();" [disabled]="!deviceId">
                  {{'importResults.takeASnapshot'| translate }}
                </button><br>
                <button id="btnUpload" class="btn btn-success mb-3" [disabled]="!webcamImage"
                  (click)="btnUpload_onclick('webCam')">
                  {{'importResults.uploadToServer'| translate }}
                </button><br>
                <ng-select placeholder="{{'importResults.selectWebCams'| translate }}" [items]="attacheedCams"
                  bindLabel="label" bindValue="deviceId" [(ngModel)]="deviceId" (change)="showNextWebcam(deviceId)">
                  <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                  </ng-template>
                </ng-select>
                <!-- Please uncomment this and verfiy it with mutlipul camera -->
                <!-- <mat-form-field class="mt-1 w-100" >
                    <mat-label>{{'importResults.selectWebCams'| translate }}</mat-label>
                    <mat-select [value]="deviceId" (selectionChange)="showNextWebcam(deviceId)">
                      <mat-option *ngFor="let item of attacheedCams" [value]="item">
                        {{ item.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
              </div>
            </div>
          </div>
          <div id="fileUpload" class="container tab-pane fade p-3"><br>
            <mat-form-field class="col-12 col-md-6">
              <input matInput [owlDateTime]="fileUploadDt" [(ngModel)]="testDate" [max]="mydate" autocomplete="off"
                placeholder="{{'importResults.selectDateTime' |  translate }}">
              <span [owlDateTimeTrigger]="fileUploadDt"
                style="position: absolute;top: 0;bottom: 0;left: auto;right: 10px;cursor: pointer;">
                <i class="fa fa-calendar"></i>
              </span>
              <owl-date-time #fileUploadDt pickerMode="dialog" [startAt]="today"></owl-date-time>
            </mat-form-field>
            <div class="row justify-content-center">
              <div class="col-md-8 col-sm-12">
                <app-file-upload-dnd projectId="100" sectionId="107" maxFiles="1" maxSize="10"
                  fileExt="JPG, JPEG, PNG, PDF, DOC, DOCX" (uploadedFile)="handleInputChange($event)"
                  (fileExtension)="handleFileExtension($event)"></app-file-upload-dnd>
              </div>
              <div class="col-md-4 col-sm-12">
                <button id="btnUpload" class="btn btn-success" [disabled]="!imageSrc"
                  (click)="btnUpload_onclick('fileUpload')">
                  {{'importResults.uploadToServer'| translate }}
                </button>
              </div>
            </div>
          </div>
          <!-- <div id="manual" class="container tab-pane fade p-3"><br>
              <form *ngIf="allCountsAsArray && allCountsAsArray.length">
                <div class="input-group form-group col-12 col-md-6 mb-3">
                  <input name="menualEntryDt" class="form-control" [owlDateTime]="menualEntryDt" [(ngModel)]="testDate"
                    [max]="mydate" autocomplete="off" placeholder="{{'importResults.selectDateTime' |  translate }}">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" [owlDateTimeTrigger]="menualEntryDt"><i
                        class="fa fa-calendar"></i></button>
                  </div>
                  <owl-date-time #menualEntryDt pickerMode="dialog" [startAt]="today"></owl-date-time>
                </div>
                <div class="row m-0">
                  <div class="form-group col-12 col-md-6" *ngFor="let count of allCountsAsArray">
                    <label>{{count.label}}</label>
                    <input type="text" class="form-control" name="{{count.label}}" [(ngModel)]="count.value"
                      placeholder="Enter the cell count">
                  </div>
                  <div class="form-group col-12">
                    <button type="button" class="btn btn-success" (click)="submitTestResult(allCountsAsArray)">Upload to
                      Server</button>
                  </div>
                </div>
              </form>
            </div> -->
        </div>
      </div>

      <table class="table table-bordered w-100">
        <tr>
          <td width="50%" style="padding: 5px; text-align: left;">
            <fieldset class="border">
              <legend style="font-size: 10px;" class="w-auto">
                {{'importResults.selectDateTime'| translate }}</legend>
              <!-- <input class="w-100 input-legend" maxlength="24" [(ngModel)]="selectedProfile.viewValue"
                  [ngModelOptions]="{standalone: true}" [disabled]="!isEditMode" placeholder="Create new profile" type="text"> -->
              <mat-form-field class="col-12 col-md-12">
                <input matInput [owlDateTime]="webCamDt" [(ngModel)]="testDate" [max]="mydate" autocomplete="off"
                  placeholder="{{'importResults.selectDateTime' |  translate }}">
                <span [owlDateTimeTrigger]="webCamDt"
                  style="position: absolute;top: 0;bottom: 0;left: auto;right: 10px;cursor: pointer;">
                  <i class="fa fa-calendar"></i>
                </span>
                <owl-date-time #webCamDt pickerMode="dialog" [startAt]="today"></owl-date-time>
              </mat-form-field>
            </fieldset>
          </td>
          <td>
            <strong style="font-size: 24px;">{{'importResults.scannedReport'| translate }}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <div *ngIf="!showSnapShotImage">
              <div class="bg-light" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
                <div class="w-75 h-50 bg-white shadow">
                  <div class="button-report">
                    <button (click)="captureTestReport()" class="btn p-2 text-white" style="background-color: #00978d;">
                      <i class="fa fa-camera" aria-hidden="true"></i>
                      {{'importResults.clickToCaptureReport'| translate }}
                    </button>
                  </div>
                  <p class="text-center text-muted">
                    {{'importResults.or'| translate }}
                  </p>
                  <div
                    style="border: 2px dotted grey; width: 80%; position: relative;left: 0; right: 0; cursor: pointer; margin: auto;"
                    class="text-center p-4">
                    <div><i class="fa fa-cloud text-success" aria-hidden="true"></i></div>
                    <app-file-upload-dnd projectId="100" sectionId="107" maxFiles="1" maxSize="10"
                      fileExt="JPG, JPEG, PNG, PDF, DOC, DOCX" (uploadedFile)="handleInputChange($event)"
                      (fileExtension)="handleFileExtension($event)"></app-file-upload-dnd>
                    <!-- <div>Upload Test Device from local device.</div>
                      <div>(JPG or PNG with size 1mb max)</div> -->
                  </div>
                  <p class="px-5 pt-1 text-left">{{'importResults.sizeNote'| translate }}</p>
                </div>
              </div>
            </div>
            <div style="position: relative;" *ngIf="showSnapShotImage">
              <img [src]="webcamImage? webcamImage.imageAsDataUrl : imageSrc" style="height:100vh" class="img-thumbnail"
                alt="blood_report">
              <span title="Remove Image"
                style="position: absolute;top: 0px;right: 0;left: 0; bottom: 0; z-index: 1;cursor: pointer;color: #dc3545;height: 60px;width: 60px;text-align: center;background-color: rgba(255,255,255,0.6);border-radius: 50%;margin: auto;"
                (click)="deleteFile('webcam')"><i class="fa fa-trash" aria-hidden="true"
                  style="font-size: 30px;top: 0;bottom: 0;left: 0;right: 0;margin: auto;position: absolute;height: 30px;width: 30px;line-height: 30px;"></i></span>
            </div>
          </td>
          <td style="text-align: left;">
            <div class="col-md-12 formContainer">
              <div *ngIf="allCountsAsArray && allCountsAsArray.length" class="modal-body">
                <form>
                  <div class="row">
                    <div class="form-group col-12 col-md-6" *ngFor="let count of allCountsAsArray">
                      <label>{{count.label}}</label>
                      <input type="text" class="form-control" name="{{count.label}}" [(ngModel)]="count.value"
                        placeholder="{{'importResults.analyteMissing'| translate }}">
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class=" p-2 text-right ">
              <button [disabled]="!showSnapShotImage" (click)="btnUpload_onclick('webCam')" class="btn text-white"
                style="background-color: #00978d; width: 150px;">
                <i class="fas fa-sync" aria-hidden="true"></i>
                {{'importResults.startAnalyze'| translate }}
              </button>
            </div>
          </td>
          <td>
            <div class="text-right p-2">
              <button type="button" class="btn text-white" [disabled]=" allCountsAsArray.length === 0"
                style="background-color: #00978d; width: 180px;" (click)="submitTestResult(allCountsAsArray)">
                {{'importResults.confirmToUpload'| translate }}
              </button>
            </div>
          </td>
        </tr>
      </table>
      <!-- <div class="row border">
          <div class="col-md-6">
            <div class="row p-1">
            </div>
            <div class="row">
              <div class="col-md-12 ">
              </div>
            </div>
          </div>
          <div class="col-md-6 border p-0">
            <div class="row">
              <div class="col-md-12">
                <div class="p-4 border-bottom">
                </div>
              </div>
            </div>
            <div class="row">
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="  border border-bottom-0">
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- <div class="row">
          <div class="col-md-6 border border-top-0">
            <div class=" p-2 text-right ">
              <button [disabled]="!showSnapShotImage" (click)="btnUpload_onclick('webCam')" class="btn text-white"
                style="background-color: #00978d; width: 150px;"><i class="fas fa-sync" aria-hidden="true"></i> START
                ANALYZE</button>
            </div>
          </div>
          <div class="col-md-6  border border-top-0">
            <div class="text-right p-0">
              <button type="button" class="btn text-white mt-2" [disabled]=" allCountsAsArray.length === 0" style="background-color: #00978d; width: 180px;"
                (click)="submitTestResult(allCountsAsArray)">CONFIRM TO UPLOAD</button>
            </div>
          </div>
        </div> -->
    </div>

    <div class="modal fade" id="postOcrModal" tabindex="-1" role="dialog" aria-labelledby="postOcrModalTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="postOcrModalTitle">
              {{'importResults.bloodCellCounts'| translate }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form *ngIf="allCountsAsArray && allCountsAsArray.length">
              <div class="row">
                <div class="form-group col-12 col-md-6" *ngFor="let count of allCountsAsArray">
                  <label>{{count.label}}</label>
                  <input type="text" class="form-control" name="{{count.label}}" [(ngModel)]="count.value"
                    placeholder="{{'importResults.analyteMissing'| translate }}">
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{'common.closeBtnText'| translate }}
            </button>
            <button type="button" class="btn btn-primary" (click)="submitTestResult(allCountsAsArray)">
              {{'common.saveBtnText'| translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="questionModal" tabindex="-1" role="dialog" aria-labelledby="questionModalTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="questionModalTitle">
              {{'importResults.answerInfoMsg'| translate }}
            </h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> -->
          </div>
          <div class="modal-body" *ngIf="allQuestionAsArray && allQuestionAsArray.length">
            <form [formGroup]="postOcrQuestionForm">
              <div class="form-group col-12" *ngFor="let question of allQuestionAsArray; let i = index;">
                <div *ngIf="question.inputType === 'boolean'">
                  <label>{{question.description}}</label><br>
                  <label class="form-check-inline" *ngFor="let item of question.suggestedAns">
                    <input class="form-check-input" type="radio" formControlName="{{question.formControlName}}"
                      value="{{item.aid}}" [(ngModel)]="question.givenAns">{{item.label_2}}
                  </label>
                </div>
                <div *ngIf="question.inputType==='range'">
                  <label><i class="fas fa-question-circle mr-1"></i>{{question.description}}</label>
                  <bar-rating formControlName="{{question.formControlName}}" [theme]="'square'" [max]="10"
                    [(ngModel)]="question.givenAns"></bar-rating>
                </div>
                <ng-select [placeholder]="question.description" [items]="question.suggestedAns"
                  [multiple]="question.multiple === 'true' ? true : false" bindLabel="label_2" bindValue="aid"
                  [closeOnSelect]="true" formControlName="{{question.formControlName}}"
                  *ngIf="question.inputType==='select'" [(ngModel)]="question.givenAns">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label">{{item.label_2}}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </ng-template>
                  <div class="row">
                    <ng-template ng-option-tmp let-item="item">
                      {{item.label_2}}
                    </ng-template>
                  </div>
                </ng-select>
                <mat-form-field class="example-full-width" *ngIf="question.inputType === 'text'">
                  <mat-label>{{question.description}}</mat-label>
                  <input matInput [attr.maxlength]="question.inputLength" formControlName="{{question.formControlName}}"
                    [(ngModel)]="question.givenAns" [placeholder]="question.description" autocomplete="off">
                </mat-form-field>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-primary" [disabled]="postOcrQuestionForm.invalid"
                  (click)="submitAnswer(allQuestionAsArray)">
                  {{'common.submitBtnText'| translate }}
                </button>
              </div>
            </form>
          </div>
          <!-- <div class="modal-footer">
              <div class="text-danger pull-right">*all fields are mandatory!</div>
            </div> -->
        </div>
      </div>
    </div>

    <p-dialog header="{{'importResults.captureTestReport'| translate }}" (onHide)="onHideWithCapturePreview()"
      [(visible)]="displayCaptureReportDialogBox" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000">
      <div *ngIf="!showCameraPreviewWithWarningPage;else elseBlock">
        <div id="camView">
          <webcam id="enableWebCam" [width]="width" [height]="height" [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)" *ngIf="showWebcam && !webcamImage"
            [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions"
            (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
          </webcam>
          <div class="snapshot" style="position: relative;" *ngIf="webcamImage">
            <img class="img-fluid" [src]="webcamImage.imageAsDataUrl" ngxViewer [viewerOptions]="viewerOptions" />
            <!-- <span title="Remove Image"
                        style="position: absolute;top: 0px;right: 0;left: 0; bottom: 0; z-index: 1;cursor: pointer;color: #dc3545;height: 60px;width: 60px;text-align: center;background-color: rgba(255,255,255,0.6);border-radius: 50%;margin: auto;"
                        (click)="deleteFile('webcam')"><i class="fa fa-trash" aria-hidden="true"
                          style="font-size: 30px;top: 0;bottom: 0;left: 0;right: 0;margin: auto;position: absolute;height: 30px;width: 30px;line-height: 30px;"></i></span> -->
          </div>
        </div>
        <ng-template pTemplate="footer">
          <div *ngIf="!showCameraPreviewWithWarningPage" class="text-center">
            <button (click)="triggerSnapshot();" *ngIf="!webcamImage" [disabled]="!deviceId" class="btn text-white "
              style="background-color: #00978d; width: 250px;"><i class="fa fa-camera" aria-hidden="true"></i>
              {{'importResults.capture'| translate }}
            </button>
          </div>
          <button (click)="deleteFile('webcam')" *ngIf="webcamImage" class="btn border " style="color: #00978d;"><i
              class="fa fa-reply" aria-hidden="true"></i> {{'importResults.reTake'| translate }}</button>
          <button (click)="saveImageAndPreview()" *ngIf="webcamImage" class="btn text-white "
            style="background-color: #00978d">
            <i class="fa fa-floppy" aria-hidden="true"></i>
            {{'common.saveBtnText'| translate }}
          </button>
        </ng-template>
      </div>
      <ng-template #elseBlock>
        <div class="row">
          <div class="col-md-12 text-center"
            style="height: 65vh; display: flex; justify-content: center; align-items: center; flex-flow: column;">
            <div class="p-2" id="cameraIcon">
              <i class="fa fa-camera fa-3x" aria-hidden="true"></i>
            </div>
            <div class="p-2">
              {{'importResults.permission'| translate }}
            </div>
            <div class="p-2">
              <small class="text-muted">
                {{'importResults.permissionMessage'| translate }}
              </small>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dialog>

  </div>
</div>