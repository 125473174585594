<ngx-ui-loader></ngx-ui-loader>
<div class="container py-3">
  <h1>Test data configuration</h1>

  <p>Patient ID: {{ patientId }}</p>

  <div *ngIf="!isOrganisationLoading">
    Associated organisations:
    <span
      ><strong *ngIf="!associatedOrganisations.length">N/A</strong>
      <button
        *ngIf="!getOrganisationButtonDisabled()"
        (click)="addSubscriberToOrganisation()"
        class="btn text-white"
        style="background-color: #00978d; width: 250px; margin-left: 8px"
      >
        Associate with organisation
      </button>
    </span>
    <ul>
      <li *ngFor="let t of associatedOrganisations">
        <strong>{{ t.name }}</strong>
        <button
          class="btn btn-danger ml-3"
          (click)="removeSubscriberToOrganisation(t.id)"
        >
          Remove
        </button>
      </li>
    </ul>
  </div>
  <div class="px-2 pt-2" *ngIf="isOrganisationLoading">
    <ngx-skeleton-loader
      count="1"
      appearance="circle"
      [theme]="{
        width: '100%',
        height: '50px',
        'border-radius': '10px',
        margin: '0px 0px'
      }"
    >
    </ngx-skeleton-loader>
  </div>

  <div *ngIf="!isRoleLoading">
    Current role: <strong>{{ currentRole }}</strong>
    <button
      *ngIf="!getEscalateToProviderButtonDisabled()"
      (click)="escalateUserToProvider()"
      class="btn text-white"
      style="background-color: #00978d; width: 250px; margin-left: 8px"
    >
      Escalate To Provider
    </button>
  </div>
  <div class="px-2 pt-2" *ngIf="isRoleLoading">
    <ngx-skeleton-loader
      count="1"
      appearance="circle"
      [theme]="{
        width: '100%',
        height: '50px',
        'border-radius': '10px',
        margin: '0px 0px'
      }"
    >
    </ngx-skeleton-loader>
  </div>

  <div *ngIf="!isProfileCompleted" class="d-flex align-items-center">
    <p
      style="font-size: 12px; color: red; margin-bottom: 0px; margin-top: 15px"
    >
      Your profile is incomplete. Please complete it to gain full access.
    </p>
  </div>
  <div *ngIf="!isBloodTestLoading" class="d-flex align-items-center">
    <mat-form-field class="w-auto">
      <input
        matInput
        placeholder="Sample count"
        [formControl]="sampleCountControl"
        type="number"
      />
    </mat-form-field>

    <button
      class="btn text-white ml-3"
      style="background-color: #00978d; width: 250px"
      [matTooltip]="
        !isProfileCompleted
          ? 'Complete Your Profile First'
          : 'Upload blood test results'
      "
      [disabled]="!sampleCountControl.valid || !isProfileCompleted"
      (click)="uploadBloodTestResults()"
    >
      Upload blood test results
    </button>
  </div>
  <div class="px-2 pt-2" *ngIf="isBloodTestLoading">
    <ngx-skeleton-loader
      count="1"
      appearance="circle"
      [theme]="{
        width: '100%',
        height: '50px',
        'border-radius': '10px',
        margin: '0px 0px'
      }"
    >
    </ngx-skeleton-loader>
  </div>
</div>
