<div class="container-fluid" style="
    margin: 0px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  ">
  <router-outlet style="position: absolute"></router-outlet>

  <div class="footer-content" *ngIf="!router.url.includes('auth')">
    <app-footer></app-footer>
  </div>

  <div class="modal fade" id="splashModal" tabindex="-1" role="dialog" aria-labelledby="splashModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="splashModalTitle" *ngIf="config">
            {{ config.appName }}
          </h5>
        </div>
        <div class="modal-body">
          <app-about></app-about>
        </div>
        <div class="modal-footer text-center">
          <button id="splashModalClose" type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>