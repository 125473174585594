import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AuthorisedByUser,
  AuthorisedUser,
  FBUserWithId,
  LinkRequest,
  PagedQueryResponse,
  Provider,
  Role,
  Subscriber,
  User,
  UserProfile,
} from '../../../types';

import { config } from '../../config';
import { BehaviorSubject, Subscription, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ApiService } from '../api.service';
import { SelectedSubscriberService } from '../selected-subscriber/selected-subscriber.service';

const defaultMembersData = {
  totalCount: 0,
  pageSize: 0,
  pageOffset: 0,
  results: [],
};

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  private subscription: Subscription;

  private user: FBUserWithId;

  private providerData = new BehaviorSubject<Provider | null>(null);
  private membersData =
    new BehaviorSubject<PagedQueryResponse<Subscriber> | null>(
      defaultMembersData
    );
  private providers = new BehaviorSubject<Provider[]>([]);

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private selectedSubscriberService: SelectedSubscriberService
  ) {
    this.subscription = this.authService
      .getAuthorisedUserDataObservable()
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.fetchMyProvider();
          this.fetchProviders();
        } else {
          this.providerData.next(null);
          this.providers.next([]);
        }
      });
  }

  getProviderDataObservable() {
    return this.providerData.asObservable();
  }

  getProvidersObservable() {
    return this.providers.asObservable();
  }

  fetchMyProvider() {
    return forkJoin({
      provider: this.apiService.getMyProviderData(),
    })
      .pipe(
        map(
          ({ provider }): Provider => ({
            ...provider,
          })
        )
      )
      .subscribe(
        (provider) => {
          this.providerData.next(provider);
          this.fetchMembers();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  fetchMembers() {
    this.apiService
      .getProviderSubscribers(this.providerData.value.id)
      .subscribe(
        (result) => {
          this.membersData.next(result);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  fetchProviders() {
    return this.getUserProviders().subscribe(
      (providers) => {
        this.providers.next(providers);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getUserProviders(): Observable<Provider[]> {
    return this.apiService.getProvidersBySubscriberId(this.user.userId);
  }

  getSelectedPatientProviders(): Observable<Provider[]> {
    return this.apiService.getProvidersBySubscriberId(this.selectedSubscriberService.user.id);
  }

  createLinkRequest(userId: number) {
    const promise = new Promise(
      (resolve: (linkRequest: LinkRequest) => void, reject) => {
        const providerId = this.providerData.getValue()?.id;
        if (providerId === null) {
          resolve(null);
        }

        this.apiService
          .createSubscriberLinkRequest(providerId, userId)
          .subscribe(
            (linkRequest) => {
              resolve(linkRequest);
            },
            (error) => {
              console.error(error);
              reject(error);
            }
          );
      }
    );

    return promise;
  }
}
