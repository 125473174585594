import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import FileSaver from 'file-saver';
import * as moment from 'moment';
// declare const Pace: any;
// import * as $ from 'jquery';

import { ResultSetWithScores } from '../../../services/resultSet/types';
import { TestConfig } from '../../../services/test-kit/types';
import { Router } from '@angular/router';
import { IRangeData } from '../../../services/score/types';
import { referenceRangeData } from '../smartReportData';
import { ScoreService } from '../../../services/score/score.service';
import { ApiService } from '../../../services/api.service';

interface Score {
  availableAnalytes: string[];
  analyteMapping: AnalyteMapping;
  rangeData: IRangeData;
  score: number;
}

interface AnalyteMapping {
  [key: string]: string;
}

interface IAnalyteReferenceData {
  referenceUnit: { [key: string]: string };
  referenceRange: { [key: string]: string };
}

interface IChartData {
  chartId: string;
  isHorizontal: boolean;
  score: Score;
  data: {
    analytes: string[];
  };
  labels: string[];
  values: number[];
  lows: number[];
  highs: number[];
  allRanges: IAnalyteReferenceData;
}

@Component({
  selector: 'app-analytes-graph',
  templateUrl: './analytes-graph.component.html',
  styleUrls: ['./analytes-graph.component.css'],
})
export class AnalytesGraphComponent implements OnInit, OnChanges {
  @Input() selectedResultSet: ResultSetWithScores;
  @Input() selectedTestType: TestConfig;
  @Input() testTypes: TestConfig[];
  @Input() isLoading: boolean;
  @Input() isExpanded: boolean;

  @Output() onTestTypeSelect = new EventEmitter<TestConfig>();
  @Output() onExpand = new EventEmitter<boolean>();

  chartData: IChartData;
  reportOptions = [
    {
      type: 'Algocyte Report',
      icon: 'picture_as_pdf',
      action: 'generateNewReport()',
    },
    {
      type: 'Individual Report',
      icon: 'insert_chart_outlined',
      action: "navigateToReportPage('individual')",
    },
    {
      type: 'Trends Graph',
      icon: 'insert_chart',
      action: "navigateToReportPage('graph')",
    },
    {
      type: 'Tabular Report',
      icon: 'article',
      action: "navigateToReportPage('table')",
    },
    {
      type: 'Folding Plot',
      icon: 'insights',
      action: "navigateToReportPage('folding-plots')",
    },
  ];

  constructor(
    private readonly router: Router,
    private apiService: ApiService,
    private readonly scoreService: ScoreService
  ) { }

  ngOnInit(): void {
    if (this.selectedResultSet) {
      this.setChartData();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedResultSet?.currentValue) {
      this.setChartData();
    }
  }

  performAction(action: string) {
    const newAction = `this.${action}`
    eval(newAction);
  }

  setChartData() {
    const rangeData = this.scoreService.calculateRangeData(
      this.selectedResultSet.adaptiveScores,
      this.selectedResultSet.results.analyteResults
    );
    const referenceRange = this.selectedResultSet.results.analyteResults.reduce(
      (result, next) => ({ ...result, [next.analyteKey]: next.referenceRange }),
      {}
    );

    this.chartData = {
      chartId: 'barChartContainer',
      isHorizontal: false,
      data: {
        analytes: referenceRangeData.analyteKeys,
      },
      score: {
        availableAnalytes: this.selectedResultSet.essentialAnalytes,
        analyteMapping: referenceRangeData.dictionary,
        rangeData,
        score: this.selectedResultSet.nonAdaptiveScore,
      },
      labels: this.selectedResultSet.essentialAnalytes,
      values: this.selectedResultSet.adaptiveScores.map((x) => x.value),
      lows: this.selectedResultSet.adaptiveScores.map(
        (x) => x.personalRangeMin
      ),
      highs: this.selectedResultSet.adaptiveScores.map(
        (x) => x.personalRangeMax
      ),
      allRanges: {
        referenceRange,
        referenceUnit: referenceRangeData.referenceUnit,
      },
    };
  }

  getTitle(resultSet: ResultSetWithScores): string {
    const formattedDate = moment(resultSet.observationDate).format(
      'DD MMM YYYY hh:mm A'
    );

    return `${formattedDate} | ${resultSet.results.source} | ${resultSet.testType}`;
  }

  handleTabChange(testType: TestConfig) {
    this.onTestTypeSelect.emit(testType);
  }

  toggleExpanded() {
    this.onExpand.emit(!this.isExpanded);
  }

  navigateToReportPage(path: string) {
    const testType = this.selectedTestType?.name;

    switch (path) {
      case 'graph':
        this.router.navigate(['/new-report/' + path + '/' + testType]);
        break;

      case 'table':
        this.router.navigate(['/new-report/' + path + '/' + testType]);
        break;

      case 'individual':
        this.router.navigate(['/new-report/' + path + '/' + testType]);
        break;

      case 'folding-plots':
        this.router.navigate(['/' + path]);
        break;

      case 'events':
        this.router.navigate(['/' + path]);
        break;

      default:
        this.router.navigate(['/' + path]);
        break;
    }
  }

  async generateNewReport() {
    if (this.selectedResultSet) {
      const data = await this.apiService
        .generateResultSetReport(this.selectedResultSet.id)
        .toPromise()
        .then((response: any) => {
          const blob = new Blob([response], {
            type: 'application/pdf',
          });

          FileSaver.saveAs(blob, 'algocyte_report.pdf');
        });
    }
  }

  // _openSnackBar(message) {
  //   this.snackBar.open(message, '', {
  //     panelClass: ['algocyte-snackbar'],
  //     horizontalPosition: 'end',
  //     verticalPosition: 'top',
  //     duration: 3000,
  //   });
  //   $('snack-bar-container').css({
  //     'min-width': 'fit-content',
  //     width: 'fit-content',
  //     'border-radius': '10px',
  //     'margin-top': '50px',
  //     'background-color': '#353a40',
  //     color: '#f7fbff',
  //     padding: '3px 16px',
  //   });
  // }
}
