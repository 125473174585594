export const registeredDomains = [
  {
    region: 'UK',
    dev_name: 'DEV-UK',
    name: 'http://localhost:42000',
  },
  {
    region: 'UK',
    dev_name: 'DEV-UK',
    name: 'https://oia-webapp-dev2.vercel.app',
  },
  {
    region: 'UK',
    dev_name: 'PROD-UK',
    name: 'https://prod-oia-webapp.vercel.app',
  },
];
