import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification/notification.component';
import { AppMaterialModules } from '../../modules/shared/angular-material.module';

@NgModule({
  declarations: [NotificationComponent],
  imports: [
    CommonModule,
    AppMaterialModules,
  ],
  exports:[
    NotificationComponent
  ]
})
export class NotificationsModule { }
