import { Injectable } from '@angular/core';


declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }


  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }


  getDeviceType() {
    const screenWidth = window.innerWidth;
    if (screenWidth && screenWidth <= 576) { return 'xs' }
    else if (screenWidth && screenWidth <= 768) { return "sm" }
    else if (screenWidth && screenWidth <= 992) { return "md" }
    else if (screenWidth && screenWidth <= 1200) { return "lg" }
  }
}
