import { Injectable } from '@angular/core';
import { User, UserProfile } from '../../../types';

import { config } from '../../config';
import { BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ApiService } from '../api.service';
import { UserService } from '../user/user.service';
import { MappedProfile } from 'src/app/modules/auth/sign-in/sign-in.component';

@Injectable({
  providedIn: 'root',
})
export class SelectedSubscriberService {
  private readonly API_URL = `${config.baseApiv2Url}/users`;
  private subscription: Subscription;

  private userData = new BehaviorSubject<User | null>(null);

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.subscription = this.userService
      .getUserDataObservable()
      .subscribe((d) => {
        this.userData.next(d);
      });
  }

  getUserDataObservable() {
    return this.userData.asObservable();
  }

  removeSelectedSubscriber() {
    localStorage.setItem('patientId', JSON.parse(localStorage.getItem('UUID')))
    this.userService.getUserDataObservable().subscribe((d) => {
      this.userData.next(d);
    });
  }

  get user(): User | null {
    return this.userData.getValue();
  }

  setSelectedSubscriber(userId: number) {

    return forkJoin({
      user: this.apiService.getUser(userId),
      userProfile: this.apiService.getUserProfile(userId),
    })
      .pipe(
        map(
          ({ user, userProfile }): User => ({
            ...user,
            userProfile,
            isProfileComplete: true,
          })
        )
      )
      .subscribe(
        (user) => {
          localStorage.setItem('patientId', JSON.stringify(userId));
          localStorage.setItem('patient', JSON.stringify(this.mapUserProfileToLegacyUser(user.userProfile)));
          this.userData.next(user);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  mapUserProfileToLegacyUser(profile: UserProfile): MappedProfile {
    return {
      forename: profile.firstName,
      surname: profile.lastName,
      DOB: profile.dateOfBirth,
    };
  }
}
