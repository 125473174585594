<div class="row m-0 p-0 flex-row-reverse">
  <div class="col-12 col-md-7 d-flex flex-wrap align-content-center justify-content-center" id="custom-section"
    style="max-height: 100%">
    <div class="row" id="custom-section2">
      <div class="col-12" *ngIf="!isManaged">
        <div class="algocyte_logo_square mx-auto" (click)="doRouting(['/home'])">
          <img src="assets/algocyte/logo.png" alt="company-logo" class="img-fluid" />
        </div>
      </div>

      <div class="col-12">
        <div class="sign_in_text mx-auto margin-bottom-20" *ngIf="!isManaged">
          {{ 'signUp.createAccount' | translate }}
        </div>
        <div class="sign_in_text mx-auto margin-bottom-20" *ngIf="isManaged">
          {{ 'signUp.createManagedAccount' | translate }}
        </div>
      </div>

      <form [formGroup]="signUpForm" id="signup-form" (ngSubmit)="onSignup()" class="mx-auto"
        style="max-width: 515px; margin: auto" *ngIf="!isManaged">
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label for="firstName">
                &nbsp; {{ 'signUp.firstName' | translate }} &nbsp;
              </mat-label>
              <input matInput id="firstName" type="text" formControlName="firstName"
                placeholder="{{ 'signUp.firstName' | translate }}" />
              <mat-error class="margin-bottom-10" *ngIf="
                  signUpForm.get('firstName').errors &&
                  signUpForm.get('firstName').hasError('required')
                ">
                {{ 'signUp.firstNameMandatory' | translate }}
              </mat-error>
              <mat-error class="margin-bottom-10" *ngIf="
                  signUpForm.get('firstName').errors &&
                  signUpForm.get('firstName').hasError('pattern')
                ">
                {{ 'signUp.firstNameValid' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label for="lastName">&nbsp; {{ 'signUp.lastName' | translate }} &nbsp;</mat-label>
              <input matInput id="lastName" type="text" formControlName="lastName"
                placeholder="{{ 'signUp.lastName' | translate }}" />
              <mat-error class="margin-bottom-10" *ngIf="
                  signUpForm.get('lastName').errors &&
                  signUpForm.get('lastName').hasError('required')
                ">
                {{ 'signUp.lastNameMandatory' | translate }}
              </mat-error>
              <mat-error class="margin-bottom-10" *ngIf="
                  signUpForm.get('lastName').errors &&
                  signUpForm.get('lastName').hasError('pattern')
                ">
                {{ 'signUp.lastNameValid' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label for="email">&nbsp; {{ 'common.email' | translate }} &nbsp;</mat-label>
              <input matInput id="email" type="text" formControlName="email"
                placeholder="{{ 'common.email' | translate }}" />
              <mat-error class="margin-bottom-10" *ngIf="
                  signUpForm.get('email').errors &&
                  signUpForm.get('email').hasError('required')
                ">
                {{ 'common.emailMandatory' | translate }}
              </mat-error>
              <mat-error class="margin-bottom-10" *ngIf="
                  signUpForm.get('email').errors &&
                  signUpForm.get('email').hasError('pattern')
                ">
                {{ 'common.inValidEmail' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <mat-form-field appearance="outline">
          <mat-label for="password">
            {{ 'common.password' | translate }}
          </mat-label>
          <input matInput id="password" [type]="hide ? 'password' : 'text'" [class.custom-password]="hide"
            formControlName="password" placeholder="{{ 'common.password' | translate }}" />
          <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide" style="color: gray !important">
            <mat-icon>{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
          <mat-error class="margin-bottom-10" *ngIf="
              signUpForm.get('password').errors &&
              signUpForm.get('password').hasError('required')
            ">
            {{ 'common.passwordMandatory' | translate }}
          </mat-error>
          <mat-error class="margin-bottom-10" *ngIf="
              signUpForm.get('password').errors &&
              signUpForm.get('password').hasError('invalidPassword')
            ">
            {{ 'common.passwordInvalid' | translate }}
          </mat-error>
        </mat-form-field>

        <app-info-message [key]="'password_info'" *ngIf="
            signUpForm.get('password').errors &&
            signUpForm.get('password').hasError('pattern')
          "></app-info-message>

        <mat-form-field appearance="outline">
          <mat-label for="cpassword">
            {{ 'common.confirmPassword' | translate }}
          </mat-label>
          <input matInput id="cpassword" [type]="chide ? 'password' : 'text'" formControlName="cpassword"
            placeholder="{{ 'common.confirmPassword' | translate }}" />
          <button mat-icon-button type="button" matSuffix (click)="chide = !chide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="chide" style="color: gray !important">
            <mat-icon>{{ chide ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
          <mat-error class="margin-bottom-10" *ngIf="
              signUpForm.get('cpassword').errors &&
              signUpForm.get('cpassword').hasError('required')
            ">
            {{ 'common.confirmPasswordMandatory' | translate }}
          </mat-error>
          <mat-error class="margin-bottom-10" *ngIf="
              signUpForm.get('cpassword').errors &&
              signUpForm.get('cpassword').hasError('passwordsNotMatch')
            ">
            {{ 'common.confirmPasswordValid' | translate }}
          </mat-error>
        </mat-form-field>

        <div class="row">
          <div class="col-12">
            <mat-checkbox formControlName="tandc" class="example-margin">
              {{ 'signUp.tncText' | translate }}
            </mat-checkbox>
          </div>
        </div>

        <p class="error" *ngIf="error">{{ error }}</p>

        <div class="w-100 text-center">
          <button mat-icon-button matPrefix class="mx-auto create-account text-uppercase" type="submit"
            [disabled]="!signUpForm.valid || isCreatingAccount">
            <div class="spin" [hidden]="!isCreatingAccount">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path
                  d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80"
                  fill="rgba(255, 255, 255, 0.6)" />
              </svg>
            </div>
            <span [hidden]="isCreatingAccount">
              {{ 'signUp.createAccount' | translate }}
            </span>
            <span [hidden]="!isCreatingAccount">
              {{ 'signUp.creatingAccount' | translate }}...
            </span>
          </button>
          <app-resource-links></app-resource-links>
        </div>
      </form>

      <!-- ------------------------------------- Create Manage Account :: Starts --------------------------------------------------------- -->
      <form [formGroup]="createManagedAccountForm" id="createManagedAccountForm" (ngSubmit)="submitManagedAccount()"
        class="mx-auto" style="max-width: 515px; margin: auto" *ngIf="isManaged">
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label for="firstName">
                &nbsp; {{ 'signUp.firstName' | translate }} &nbsp;
              </mat-label>
              <input matInput id="firstName" type="text" formControlName="firstName"
                placeholder="{{ 'signUp.firstName' | translate }}" />
              <mat-error class="margin-bottom-10" *ngIf="
                  createManagedAccountForm.get('firstName').errors &&
                  createManagedAccountForm.get('firstName').hasError('required')
                ">
                {{ 'signUp.firstNameMandatory' | translate }}
              </mat-error>
              <mat-error class="margin-bottom-10" *ngIf="
                  createManagedAccountForm.get('firstName').errors &&
                  createManagedAccountForm.get('firstName').hasError('pattern')
                ">
                {{ 'signUp.firstNameValid' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label for="lastName">&nbsp; {{ 'signUp.lastName' | translate }} &nbsp;</mat-label>
              <input matInput id="lastName" type="text" formControlName="lastName"
                placeholder="{{ 'signUp.lastName' | translate }}" />
              <mat-error class="margin-bottom-10" *ngIf="
                  createManagedAccountForm.get('lastName').errors &&
                  createManagedAccountForm.get('lastName').hasError('required')
                ">
                {{ 'signUp.lastNameMandatory' | translate }}
              </mat-error>
              <mat-error class="margin-bottom-10" *ngIf="
                  createManagedAccountForm.get('lastName').errors &&
                  createManagedAccountForm.get('lastName').hasError('pattern')
                ">
                {{ 'signUp.lastNameValid' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label for="email">&nbsp; {{ 'common.email' | translate }} &nbsp;</mat-label>
              <input matInput id="email" type="text" formControlName="email"
                placeholder="{{ 'common.email' | translate }}" />
              <mat-error class="margin-bottom-10" *ngIf="
                  createManagedAccountForm.get('email').errors &&
                  createManagedAccountForm.get('email').hasError('required')
                ">
                {{ 'common.emailMandatory' | translate }}
              </mat-error>
              <mat-error class="margin-bottom-10" *ngIf="
                  createManagedAccountForm.get('email').errors &&
                  createManagedAccountForm.get('email').hasError('pattern')
                ">
                {{ 'common.inValidEmail' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 justify-content-center">
            <button mat-button class="create-account text-uppercase" type="submit"
              [disabled]="!createManagedAccountForm.valid">
              {{ 'signUp.createAccount' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="col-12 col-md-5" style="padding: 0">
    <app-auth-aside welcomeText="{{ 'signUp.welcomeStatement' | translate }}"
      welcomeSubText="{{ 'signUp.infoStatement' | translate }}" ctaText=" {{ 'common.signIn' | translate }}"
      ctaUrl="/auth/signin">
    </app-auth-aside>
  </div>
</div>