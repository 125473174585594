import { Component, OnInit } from "@angular/core";
import {  Router } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-authentication",
  templateUrl: "./authentication.component.html",
  styleUrls: ["./authentication.component.css"],
})
export class AuthenticationComponent implements OnInit {
  constructor(public router: Router) {
  }

  ngOnInit() {
  }
}
