<div class="row" style="border: none; margin: 0px">
  <div class="col-md-6 d-none d-lg-block"></div>

  <div class="col-md-3 d-none d-lg-block">
    <div class="page-header-button-with-text" (click)="viewType()">
      <mat-icon
        [inline]="true"
        class="page-header-button-icon"
        *ngIf="allSelected"
        >calendar_view_day</mat-icon
      >
      <mat-icon
        [inline]="true"
        class="page-header-button-icon"
        *ngIf="!allSelected"
        >grid_view</mat-icon
      >
      <span class="page-header-button-text">{{
        allSelected ? 'Single Trend View' : 'Multiple Trend View'
      }}</span>
    </div>
  </div>

  <div class="col-2 d-block d-md-none">
    <div
      class="page-header-button"
      matTooltip="{{
        allSelected ? 'Single Trend View' : 'Multiple Trend View'
      }}"
      (click)="viewType()"
    >
      <mat-icon
        [inline]="true"
        class="page-header-button-icon"
        *ngIf="allSelected"
        >calendar_view_day</mat-icon
      >
      <mat-icon
        [inline]="true"
        class="page-header-button-icon"
        *ngIf="!allSelected"
        >grid_view</mat-icon
      >
    </div>
  </div>

  <div class="col-md-3 col-10 analytes-list">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'reports.selectAnalyte' | translate }}</mat-label>
      <mat-select
        *ngIf="allSelected"
        [value]="selectedAdaptiveAnalyte"
        (selectionChange)="renderAdaptiveCharts($event)"
        multiple
      >
        <mat-option *ngFor="let item of adaptiveAnalytes" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
      <mat-select
        *ngIf="!allSelected"
        [value]="selectedAdaptiveAnalyte"
        (selectionChange)="renderAdaptiveChart($event, chartId)"
      >
        <mat-option *ngFor="let item of adaptiveAnalytes" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="row" *ngIf="allSelected">
  <div
    class="col-md-6 col-12"
    [ngClass]="
      isSelected(select)
        ? i % 2 != 0
          ? 'col-md-12 float-left'
          : 'col-md-12'
        : 'col-md-6'
    "
    *ngFor="let select of selectedAdaptiveAnalytes; let i = index"
  >
    <div
      class="row"
      style="
        margin-left: 0px;
        margin-right: 0px;
        border: 1px solid #e3e3e3;
        margin-bottom: 15px;
        margin-top: 15px;
      "
    >
      <div class="col-12" id="{{ select }}_text">
        <p class="title-score text-capitalize">
          {{ fullNames[select] }} ({{ select }}) Trends
        </p>
      </div>
      <div
        class="col-12"
        style="
          background-color: #f9f9f9 !important;
          padding: 0px 0px 10px 10px;
          margin: 0px;
        "
      >
        <div class="row" style="margin: 0px" id="{{ select }}_select">
          <div
            class="legend-wrapper"
            (click)="selectTypes('actualValue', select)"
            [ngClass]="
              !getBorderColor('actualValue', select)
                ? 'legend-wrapper-un-selected'
                : 'legend-wrapper-selected'
            "
          >
            <div class="legend-text">
              <span class="legend-icon" style="color: #0b5093">&#9632;</span>
              <span> {{ 'reports.actualValue' | translate }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 trends-time" style="padding: 0px">
        <div id="{{ select }}_chartId" class="chart--container"></div>
      </div>
    </div>
  </div>
</div>

<div
  class="row"
  *ngIf="!allSelected"
  style="
    margin-left: 0px;
    margin-right: 0px;
    border: 1px solid #e3e3e3;
    margin-bottom: 10px;
    margin-top: 0px;
  "
>
  <div class="col-12">
    <p class="title-score text-capitalize">
      {{ fullNames[selectedAdaptiveAnalyte] }} ({{ selectedAdaptiveAnalyte }})
      Trends
    </p>
  </div>
  <div class="col-12" style="padding: 0px">
    <div
      class="row"
      style="
        background-color: #f9f9f9 !important;
        padding: 0px 0px 10px 10px;
        margin: 0px;
      "
    >
      <div
        class="legend-wrapper"
        (click)="selectType('actualValue', selectedAdaptiveAnalyte)"
        [ngClass]="
          !getBorderColor('actualValue')
            ? 'legend-wrapper-un-selected'
            : 'legend-wrapper-selected'
        "
      >
        <div class="legend-text">
          <span class="legend-icon" style="color: #0b5093">&#9632;</span>
          <span> {{ 'reports.actualValue' | translate }} </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 trends-time" style="padding: 0px">
    <div id="{{ chartId }}" class="chart--container" style="padding: 0px"></div>
  </div>
</div>
