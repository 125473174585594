import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.css']
})
export class InfoMessageComponent implements OnInit {
  @Input() key;
  messages: any = {}
  message: String = ''

  constructor() {

  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.getCurrentInfo()
  }

  getCurrentInfo() {

    this.messages = {
      'password_info': {
        title: 'The password must be at least a minimum of 8 and a maximum of 64 characters having at least one special character, capital letter, small letter, and number.',
        detail: 'Allowed Special Characters:  ' + `^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` + " ` "
      },
      'otp_info': {
        title: 'Enter 6 digit code (OTP). Which you received on your registered email',
      },
    }

    this.message = this.messages[this.key]
  }
}
