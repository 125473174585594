import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { config } from '../../config';
import { TestConfig, TestConfigFilter, TestConfigStatus } from './types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TestKitService {
  private readonly API_URL = `${config.baseApiv2Url}/test-kits`;

  constructor(private http: HttpClient) {}

  getAvailableTestTypes(filters?: TestConfigFilter): Observable<TestConfig[]> {
    let params = new HttpParams();

    if (filters?.version) {
      params = params.append('version', filters.version);
    }
    if (filters?.status) {
      params = params.append('status', filters.status);
    } else {
      params = params.append('status', TestConfigStatus.PUBLISHED);
    }

    return this.http.get<TestConfig[]>(`${this.API_URL}/test-config`, {
      params,
    });
  }
}
