import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TranslateService } from "@ngx-translate/core";
import { ECommorceService } from "src/app/services/e-commorce/e-commorce.service";
import swal from "sweetalert2";

@Component({
  selector: "app-subscriptions",
  templateUrl: "./subscriptions.component.html",
  styleUrls: ["./subscriptions.component.css"],
})
export class SubscriptionsComponent implements OnInit {
  loggedInUser: any = JSON.parse(localStorage.getItem("loggedInUser"));

  couponCode: String = "";

  displayedColumns: string[] = [
    "coupon",
    "couponName",
    "purchased",
    "available",
  ];
  // sample data format for coupon list data :: start
  // subscriptionData: any[] = [
  //   {
  //     "coupon": "HAHJS78",
  //     "couponName": "Covid AB - Employer Bundle",
  //     "purchased": "10",
  //     "available": "5"
  //   },
  //   {
  //     "coupon": "HAHJS79",
  //     "couponName": "Covid AB - Employer Bundle",
  //     "purchased": "12",
  //     "available": "8"
  //   },
  // ];
  // sample data format for coupon list data :: end

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private translate: TranslateService,
    private eCommorce: ECommorceService,
    public customPaginatorIntl: MatPaginatorIntl
  ) {
    this.getCouponList();
  }

  ngOnInit() {
  }

  getCouponList() {
    this.eCommorce
      .getCouponList({ email: this.loggedInUser.email })
      .subscribe((data) => {
        this.setTableDataSource(data);
      });
  }
  // getCouponList :: end

  // setCouponList to table :: start
  setTableDataSource(data) {
    this.dataSource = new MatTableDataSource(data.results);
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 1000);
  }
  // setCouponList to table :: end

  createCoupon() {
    this.eCommorce.createCoupon().subscribe((coupon) => {
      console.log("coupon created ::", coupon);
    });
  }

  // Activate coupon :: start
  activateCoupon() {
    console.log("couponCode :: ", this.couponCode);
    this.eCommorce
      .activateCoupon({ email: this.loggedInUser.email, code: this.couponCode })
      .subscribe((data) => {
        if (data && data["valid"]) {
          console.log("activateCoupon :: ", data);
          swal.fire({
            type: "success",
            title: "Activated",
            text: "Coupon code Activated Successfully.",
            confirmButtonText: this.translate.instant("common.okBtnText"),
          });
          this.getCouponList();
        }
      });
  }
  // Activate coupon :: start
}
