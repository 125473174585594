import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TestService } from '../../services/test.service';
import { ProviderWithStatus, User } from 'src/types';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { config } from '../../config';

import { combineLatest, Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { SelectedSubscriberService } from 'src/app/services/selected-subscriber/selected-subscriber.service';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD MMMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AccountComponent implements OnInit {
  config = config;

  today = new Date();
  demoGraphicQuestions = [];
  clinicianQuestions = [];
  delayAccessQuestions = [];
  orDemoGraphicQuestions = [];
  clinicalQuestions = [];
  demographicFormGroup: FormGroup;
  clinicianFormGroup: FormGroup;
  clinicalFormGroup: FormGroup;
  delayAccessFormGroup: FormGroup;
  userRole = JSON.parse(localStorage.getItem('roleId'));
  isClinician = false;
  canViewLinkedAccounts = true;
  allClinicians = [];
  filterClinician = [];
  accessDate = '5';
  mydate = new Date();
  defaultCountry: any;
  isCanada = true;
  autoCompleteData = {
    selectedCountryCode: '',
    selectedCity: '',
    enteredPinCode: '',
    selectedLocalityName: '',
  };
  postCode: any = [];
  postTown: any = [];
  localityName: any = [];
  suggestedLocations: any = [];
  maxDodDate = new Date();

  providers: ProviderWithStatus[] = [];

  userData: User = null;

  private subscription: Subscription = new Subscription();

  constructor(
    private ngxService: NgxUiLoaderService,
    private testService: TestService,
    private dateFilter: DatePipe,
    private userService: UserService,
    private selectedSubscriberService: SelectedSubscriberService
  ) {
    if (this.userRole === 2001) {
      this.isClinician = true;
    }

    this.subscription.add(
      combineLatest([
        this.selectedSubscriberService.getUserDataObservable(),
        this.userService.getUserDataObservable(),
      ]).subscribe({
        next: ([selectedUser, currentUser]) => {
          if (selectedUser) {
            this.userData = selectedUser;
            if (selectedUser?.id !== currentUser?.id) {
              this.canViewLinkedAccounts = false;
            }
          }
        },
      })
    );
  }

  initDemographicForm(demoGraphicQuestions) {
    this.demographicFormGroup = new FormGroup(
      this.getControls(demoGraphicQuestions)
    );
  }

  initClinicalForm(clinicalQuestions) {
    this.clinicalFormGroup = new FormGroup(this.getControls(clinicalQuestions));
  }

  initClinicianForm(clinicalQuestions) {
    this.clinicianFormGroup = new FormGroup(
      this.getControls(clinicalQuestions)
    );
  }

  initDelayAccessForm(delayAccessQuestions) {
    this.delayAccessFormGroup = new FormGroup(
      this.getControls(delayAccessQuestions)
    );
  }

  getControls(controllsArray) {
    const controls = {};
    controllsArray.forEach((control, index) => {
      controls[control.formControlName] = new FormControl(
        {
          value: control.givenAns
            ? control.multiple === 'true'
              ? control.givenAns
              : control.givenAns[0]
            : '',
          disabled:
            (control.formControlName.includes('clinicianId') ||
              control.formControlName.includes('subjectHospital')) &&
              controllsArray[index].givenAns &&
              controllsArray[index].givenAns[0] !== undefined
              ? true
              : false,
        },
        this.getValidators(control)
      );
    });
    return controls;
  }

  async ngOnInit() {
    this.setMaxDobDate();
  }

  setMaxDobDate() {
    this.maxDodDate.setFullYear(this.maxDodDate.getFullYear() - 18);
  }

  getArray(formControlName) {
    return this[formControlName];
  }

  delay(callback, ms) {
    let timer: any = 0;
    return function () {
      const context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  onChangeAddress(event, formControlName) {
    this.suggestedLocations = [];

    if (
      (formControlName === 'REGION' ||
        formControlName === 'postCode' ||
        formControlName === 'postTown') &&
      event
    ) {
      switch (formControlName) {
        case 'REGION':
          this.autoCompleteData.selectedCountryCode = event.label_1;
          this.isCanada = event.label_2 == 'Canada' ? true : false;
          break;
        case 'postCode':
          if (
            !this.autoCompleteData.selectedCountryCode &&
            !this.autoCompleteData.selectedCountryCode.length
          ) {
            this.autoCompleteData.selectedCountryCode = 'GBR';
          }
          this.autoCompleteData.enteredPinCode = event.target.value;
          break;
        case 'postTown':
          this.autoCompleteData.enteredPinCode = event.target.value;
          break;
      }
      if (
        this.autoCompleteData.selectedCountryCode &&
        this.autoCompleteData.selectedCountryCode.length &&
        this.autoCompleteData.enteredPinCode &&
        this.autoCompleteData.enteredPinCode.length > 1
      ) {
        if (
          !this.autoCompleteData.selectedCity &&
          !this.autoCompleteData.selectedLocalityName
        ) {
          this.getSuggestedLocations(this.autoCompleteData);
        } else if (
          this.autoCompleteData.selectedCity &&
          this.autoCompleteData.selectedCity.length
        ) {
          this.getSuggestedLocations(this.autoCompleteData);
        }
      }
    } else {
      if (formControlName == 'REGION') this.isCanada = false;
    }
  }

  getSuggestedLocations(data) {
    this.testService.getSuggestAddressLocation(data).subscribe((res) => {
      const rec = JSON.parse(JSON.stringify(res));

      if (rec.items) {
        this.suggestedLocations =
          rec.items.length > 0 ? rec.items.map((d, i) => d.address) : [];
      } else {
        this.suggestedLocations = [];
      }

      for (let i = 0; i < this.demoGraphicQuestions.length; i++) {
        if (
          this.demoGraphicQuestions[i].formControlName === 'postCode' ||
          this.demoGraphicQuestions[i].formControlName === 'postTown' ||
          this.demoGraphicQuestions[i].formControlName === 'localityName'
        ) {
          this.demoGraphicQuestions[i].suggestedAns = [];
        }
        this.suggestedLocations.forEach((element) => {
          if (this.demoGraphicQuestions[i].formControlName === 'postCode') {
            element.postalCode
              ? this.postCode.includes(element.postalCode)
                ? ''
                : this.postCode.push(element.postalCode)
              : '';
          }
          if (this.demoGraphicQuestions[i].formControlName === 'postTown') {
            element.city
              ? this.postTown.includes(element.city)
                ? ''
                : this.postTown.push(element.city)
              : '';
          }
          if (this.demoGraphicQuestions[i].formControlName === 'localityName') {
            element.street
              ? this.localityName.includes(element.street)
                ? ''
                : this.localityName.push(element.street)
              : '';
          }
        });
        if (
          this.demoGraphicQuestions[i].formControlName === 'postCode' ||
          this.demoGraphicQuestions[i].formControlName === 'postTown' ||
          this.demoGraphicQuestions[i].formControlName === 'localityName' ||
          this.demoGraphicQuestions[i].formControlName === 'REGION'
        ) {
          this.demoGraphicQuestions[i].suggestedAns.sort();
        }
      }
    });
  }

  addInGivenAns($event, question) {
    if (question.multiple === 'true') {
      question.givenAns.push($event.aid);
    }
  }

  clearItem(item, question) {
    this.autoCompleteData = {
      selectedCountryCode: '',
      selectedCity: '',
      selectedLocalityName: '',
      enteredPinCode: '',
    };
    this.postCode = [];
    this.postTown = [];
    this.localityName = [];
    if (question.multiple === 'true') {
      question.givenAns.splice(question.givenAns.indexOf(item.aid), 1);
    } else if (question.multiple === 'false') {
      question.givenAns.splice(0, question.givenAns.length);
    }
  }

  clearNHS(question, $event, i) {
    try {
      if (question.formControlName === 'patientId' && $event) {
        let submitedAns = Array.isArray($event) ? $event[0] : $event;
        submitedAns = submitedAns.replace(/-/g, '').replace(/ /g, '');
        if (Array.isArray($event)) question.givenAns[0] = submitedAns;
        else question.givenAns = submitedAns;
      }
    } catch (error) { }
  }

  getBindLabel(question) {
    if (
      question.qid === '12' ||
      question.qid === '17' ||
      question.qid === '20'
    ) {
      return 'label_3';
    }
    return 'label_2';
  }

  getClassForCol(question) {
    if (
      question.inputType === 'select' ||
      question.inputType === 'text' ||
      question.inputType === 'number'
    ) {
      if (
        question.formControlName === 'smokingStatus' ||
        question.formControlName === 'noOfCigrate' ||
        question.formControlName === 'noOfYearsHavingCigrate'
      ) {
        return 'col-md-4';
      }
      return 'col-md-6';
    }
    return;
  }

  getNumberPattern(inputLength) {
    let numberRegex;
    if (inputLength == '1') numberRegex = '^([1-9])$';
    else if (inputLength == '2') numberRegex = '^([1-9]|[1-9][0-9])$';
    else if (inputLength == '3')
      numberRegex = '^([1-9]|[1-9][0-9]|[1-9][0-9][0-9])$';

    return numberRegex;
  }

  getValidators(control) {
    if (control.formControlName === 'patientId') {
      return [Validators.maxLength(15)];
    } else if (control.inputType === 'number') {
      return [Validators.pattern(this.getNumberPattern(control.inputLength))];
    }
    return [];
  }
}
