import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router"

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.css']
})
export class LanguageSwitcherComponent implements OnInit {
  laguages = [
    { id: "en", viewValue: "English" },
    { id: "fr", viewValue: "French" }
  ];
  defaultLanguage = { id: "en", viewValue: "English" };
  userSelectedLanguageId: any = (localStorage.getItem('userSelectedLanguage')) ? localStorage.getItem('userSelectedLanguage') : this.defaultLanguage.id
  viewCase: any
  @Input() parentName: any;
  @Output() check = new EventEmitter();


  constructor(
    private translate: TranslateService,
    public router: ActivatedRoute,
    public change: ChangeDetectorRef
  ) {


    // For view active





    // When App refresh the select the languge as per the user previous selection to the browser Starts

    this.userSelectedLanguageId = (localStorage.getItem('userSelectedLanguage')) ? localStorage.getItem('userSelectedLanguage') : this.defaultLanguage.id
    this.translate.use(this.userSelectedLanguageId);

    // When App refresh the select the languge as per the user previous selection to the browser Ends

  }

  ngOnInit() {
    this.userSelectedLanguageId = (localStorage.getItem('userSelectedLanguage')) ? localStorage.getItem('userSelectedLanguage') : this.defaultLanguage.id
    this.translate.use(this.userSelectedLanguageId);

    if (this.parentName == 'sign_in' || this.parentName == 'sign_up' || this.parentName == 'verify_account') this.viewCase = 'A'
    else if (this.parentName == 'footer') this.viewCase = 'B'
    else if (this.parentName == 'header') this.viewCase = 'C'
    else if (this.parentName) alert(" Please ask to Yash about this " + this.parentName)
    else console.log(" Not A Valid Case  ", this.parentName)

  }



  ngAfterContentChecked() {
    this.userSelectedLanguageId = (localStorage.getItem('userSelectedLanguage')) ? localStorage.getItem('userSelectedLanguage') : this.defaultLanguage.id;
    this.change.detectChanges();
  } 

  // Change User Language Starts

  switchLanguage = (lang) => {
    this.check.emit(lang.value);
    localStorage.setItem('userSelectedLanguage', lang.value)
    this.translate.use(lang.value);
    this.change.detectChanges();
    this.userSelectedLanguageId = lang.value;
  }

  // Change User Language Ends

}
