<div class="demographic-info pt-5">
  <h2>
    <i class="fa fa-id-card mr-1"></i> {{'profilePage.demographicInformation'| translate }}
  </h2>
  <hr />

  <form [formGroup]="myForm" novalidate (ngSubmit)="save(myForm)" class="w-100" autocomplete="off">
        
    <div class="row" formArrayName="questionnaire">
      <div class="form-group col-12" *ngFor="let question of myForm['controls']['questionnaire']['controls'] | sort: 'order'; let i=index" [ngClass]="{ 'col-md-6': question.controls['formControlName'].value !== 'addressLine'}">
        <ng-container [formGroupName]="i">
            
            <!--<label [for]="question.controls.formControlName.value">{{question.controls['description'].value}}</label>-->
                
            <ng-container *ngIf="question.controls.inputType.value === 'date'">
              <mat-form-field class="example-full-width" *ngIf="question.controls['formControlName'].value === 'DOB'">
                <input matInput formControlName="FEEDBACK"
                  [placeholder]="question.controls['description'].value" [matDatepicker]="myDatepicker" autocomplete="off" [max]="today" />
                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="question.controls.inputType.value === 'autosuggestion'">
              **
            </ng-container>

            <ng-container *ngIf="question.controls.inputType.value === 'number'">
              <mat-form-field class="example-full-width" *ngIf="question.controls.inputType.value === 'text' || question.controls.inputType.value === 'number'">
                <mat-label>{{ question.controls['description'].value }}</mat-label>
                <input matInput [attr.maxlength]="question.controls.inputLength.value" type="{{ question.controls.inputType.value }}"
                  formControlName="FEEDBACK"
                  [placeholder]="question.controls.description.value" autocomplete="off"
                  [attr.min]="question.controls.inputType.value === 'number' ? 0 : 1" />
                <span matSuffix *ngIf="question.controls.inputType.value === 'number'">{{
                  question.controls.formControlName.value === "WEIGHT" ? "KG" : "CM"
                  }}</span>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="question.controls.inputType.value === 'text'">
              <mat-form-field class="example-full-width" *ngIf="question.controls.inputType.value === 'text' || question.controls.inputType.value === 'number'">
                <mat-label>{{ question.controls['description'].value }}</mat-label>
                <input matInput [attr.maxlength]="question.controls.inputLength.value" type="{{ question.controls.inputType.value }}"
                  formControlName="FEEDBACK"
                  [placeholder]="question.controls.description.value" autocomplete="off"
                  [attr.min]="question.controls.inputType.value === 'number' ? 0 : 1" />
                <span matSuffix *ngIf="question.controls.inputType.value === 'number'">{{
                  question.controls.formControlName.value === "WEIGHT" ? "KG" : "CM"
                  }}</span>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="question.controls.inputType.value === 'select'">
              <div>
                <select [id]="question.controls.formControlName.value" formControlName="AID" multiple>
                  <option *ngFor="let suggestedAnswer of question['controls']['suggestedAnswers']['controls'];" [value]="suggestedAnswer.controls.aid.value">
                    {{suggestedAnswer.controls['label'].value}}
                  </option>
                </select>
              </div>
            </ng-container>
            
            

        </ng-container>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-success" [disabled]="!myForm.valid">
          {{'common.saveBtnText'| translate }}
        </button>
      </div>
    </div>

</form>
  
  
  <!--<form [formGroup]="demographicFormGroup" class="w-100" autocomplete="off">
    <div class="row">
      <div class="form-group col-12" [ngClass]="{ 'col-md-6': question.formControlName !== 'addressLine'}"
        *ngFor="let question of demoGraphicQuestions">

        

        




        <mat-form-field class="example-full-width" *ngIf="question.inputType === 'autosuggestion'">
          <mat-label>{{ question.description }}</mat-label>
          <input matInput type="text" [attr.maxlength]="question.inputLength"
            formControlName="{{ question.formControlName }}" [(ngModel)]="question.givenAns"
            [placeholder]="question.description" [matAutocomplete]="auto" (keyup)="
    delay(onChangeAddress($event, question.formControlName), 500)
    " />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of getArray(question.formControlName)" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <ng-select [placeholder]="question.description" [(items)]="question.suggestedAns" bindLabel="label_2"
          bindValue="aid" formControlName="{{ question.formControlName }}" [(ngModel)]="
          question.multiple === 'true'
            ? question.givenAns
            : question.givenAns[0]
        " *ngIf="question.inputType === 'select' && ( question.formControlName != 'provinceField' || ( question.formControlName == 'provinceField' && isCanada) )"
          (change)="
          delay(onChangeAddress($event, question.formControlName), 500)
        " (clear)="clearItem($event, question)" (search)="
          delay(onChangeAddress($event, question.formControlName), 500)
        ">
          <ng-template ng-option-tmp let-item="item">
            {{ item.label_2 }}
          </ng-template>
        </ng-select>
      </div>

      
    </div>
  </form>-->
  
  
</div>