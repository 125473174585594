import { Component, OnInit, ViewChild } from '@angular/core';
import { GptService } from './../../services/gpt/gpt.service'
import { Model } from 'survey-core';
declare const $: any;
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { SurveyCreatorModel } from 'survey-creator-core';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'console';
const creatorOptions = {
  showLogicTab: false,
  showPropertyGrid: false,
  isAutoSave: true,
  haveCommercialLicense: true,
  showToolbox: false,
};

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css']
})
export class QuestionnaireComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  questionnaires: any = [];
  pageNo: any = 1
  limit: any = 7
  totalRecords: any;
  displayedColumns: string[] = ['title', 'requester_name', 'published_at', 'answered_at', 'action'];
  displayedColumns2: string[] = ['title', 'created_at', 'action'];
  surveyModel: Model;
  dataSource: MatTableDataSource<any>;
  currentSelected: any;
  total_pages: any;
  model: SurveyCreatorModel;
  currentPage: string;
  currentSort: { sort_by: any; sort_order: string; };
  currentMode = "questionnaires"

  constructor(private _gptService: GptService, private route: Router, private router: ActivatedRoute) {
    this.router.url.subscribe(url =>
      this.currentPage = url[0].path
    )

  }

  ngOnInit(): void {
    this.currentSort = {
      sort_by: "published_at",
      sort_order: "descending",
    };

    if (this.currentPage === 'followups') {
      this.currentMode === "questionnaires"
      this.getQuestionnairesSubscriber(this.pageNo)
    }
    else {

      if (this.currentMode === "questionnaires") {
        this.getQuestionnaires(this.pageNo)
      }
      else {
        alert("Need to work on this")
      }

    }
  }


  getQuestionnairesSubscriber(pageNo) {
    this.pageNo = pageNo
    // this.questionnaires = []
    this._gptService.getFormsSubscriber(pageNo, this.currentSort).subscribe((data: any) => {
      console.log(" Get Data", data)
      this.totalRecords = data.total_results
      console.log(this.pageNo)
      this.questionnaires = data.data || []
      this.dataSource = JSON.parse(JSON.stringify(this.questionnaires));

      console.log(" Get Data", this.dataSource)

    }, (error) => {
      console.log("ERROR in getQuestionnaires ", error)
    })
  }

  getTemplate(pageNo) {
    this.pageNo = pageNo
    // this.questionnaires = []
    this._gptService.getTemplates(pageNo, this.currentSort).subscribe((data: any) => {
      console.log(" Get Data", data)
      this.totalRecords = data.total
      console.log(this.pageNo)

      let templates = data.templates
      let newResponse = []
      for (var k in templates) {
        console.log(k, templates[k]);
        templates[k].id = k

        newResponse.push(JSON.parse(JSON.stringify(templates[k])))
      }

      this.questionnaires = newResponse || []
      this.dataSource = JSON.parse(JSON.stringify(this.questionnaires));

      console.log(" Get Data", this.dataSource)

    }, (error) => {
      console.log("ERROR in getQuestionnaires ", error)
    })
  }


  getQuestionnaires(pageNo) {
    this.pageNo = pageNo

    this._gptService.getForms(pageNo, this.currentSort).subscribe((data: any) => {
      console.log(" Get Data", data)
      this.totalRecords = data.total_results
      console.log(this.pageNo)
      // if (this.pageNo > 1) {
      // data.data.forEach(element => {
      //   console.log(element.questionnaire, typeof element.questionnaire, "element.questionnaire")
      //   if (element.questionnaire != null && element.questionnaire != undefined && Object.keys(element.questionnaire).length > 1) {
      //     this.questionnaires.push(element)
      //   }
      // });
      this.questionnaires = data.data || []

      this.dataSource = JSON.parse(JSON.stringify(this.questionnaires));

      console.log(" Get Data", this.dataSource)

    }, (error) => {
      console.log("ERROR in getQuestionnaires ", error)
    })
  }


  pageEvents(event) {
    console.log(event)
  }

  goToPage(pageNo) {
    this.pageNo = pageNo
    if (this.currentPage === 'followups') {
      this.getQuestionnairesSubscriber(this.pageNo)
    }
    else {
      this.getQuestionnaires(this.pageNo)
    }
  }


  alertResults(sender) {
    // const that = this
    console.log("alertResults", sender, sender.data, this.currentSelected)
    // const
    this.uploadAnswer(sender.data);
    // console.log(answer)
  }


  uploadAnswer(data) {
    // const that = this
    console.log("Submit User Answer !!!!!!")
    // // console.log("What user get from the server", this.currentSelected)
    // console.log("What user get from the server", this.currentSelected.questionnaire)
    // console.log("What user get from the server", this.currentSelected.questionnaire.pages)
    // console.log("What user get from the server", this.currentSelected.questionnaire.pages[0].elements)
    console.log("User Have Answered this", data)
    let answer = {
      "request_id": this.currentSelected.request_id,
      "requester_id": JSON.parse(localStorage.getItem('loggedInUser')).UUID,
      "subscriber_id": JSON.parse(localStorage.getItem('patient')).UUID,
      "answered_by": JSON.parse(localStorage.getItem('loggedInUser')).UUID,
    }

    console.log("******************************************************************")
    console.log("New Data for the next request", answer)

    answer['answer'] = data

    // let answers = []

    // console.log("this.currentSelected.questionnaire", typeof this.currentSelected.questionnaire, this.currentSelected.questionnaire)
    // this.currentSelected.questionnaire.pages[0].elements.forEach((question) => {
    //   for (const [key, value] of Object.entries(data)) {
    //     if (key === question.name) {
    //       question.answer = value
    //       // answers.push(question)
    //     }
    //   }
    // })


    console.log(answer)
    this
      ._gptService
      .postAnswer(answer)
      .subscribe((success: any) => {
        // this.pageNo = 1
        // this.getQuestionnaires(this.pageNo)
        this.getQuestionnairesSubscriber(this.pageNo)
        this.closeModal('view')
      }, (error) => {
        console.log(" error ", error)
        this.closeModal('view')
      })
  }

  viewSubmissionForm(info) {
    this.currentSelected = JSON.parse(JSON.stringify(info))
    $('#open-question').modal('show');
    const survey = new Model(this.currentSelected.questionnaire);
    survey.onComplete.add((sender, options) => {
      this.uploadAnswer(sender.data)
    })
    this.surveyModel = survey;
  }

  viewAnswerForm(info) {
    console.log(" answered ", info.answered)
    console.log(" questionnaire ", info.questionnaire)

    const survey = new Model(info.questionnaire);
    // You can delete the line below if you do not use a customized theme
    // survey.applyTheme(themeJson);
    survey.data = info.answered;
    survey.mode = "display";

    // survey.onComplete.add((sender, options) => {
    //   console.log(JSON.stringify(sender.data, null, 3));
    // });

    this.surveyModel = survey;
    $('#open-question').modal('show');
  }


  viewEditorForm(info) {


    if (this.currentMode === 'questionnaires') {
      this.currentSelected = JSON.parse(JSON.stringify(info))
      this.createQuestionnaire(this.currentSelected.questionnaire)
      $('#open-edit-question').modal('show');
    }
    else {
      this.currentSelected = JSON.parse(JSON.stringify(info))
      this.createQuestionnaire(this.currentSelected.question_template)
      $('#open-edit-question').modal('show');

    }

    // const survey = new Model(this.currentSelected.questionnaire);
    // survey.onComplete.add((sender, options) => {
    //   this.uploadAnswer(sender.data)
    // })
    // this.surveyModel = survey;
  }


  closeModal(type) {
    if (type === 'view') {
      $('#open-question').modal('hide');
    }
    else {
      $('#open-edit-question').modal('hide');
    }
  }

  createQuestionnaire(defaultJson) {

    console.log("defaultJson", typeof defaultJson, defaultJson)

    const creator = new SurveyCreatorModel(creatorOptions);

    // const rawDescription = '<p>This is the <strong>description</strong> for Question 1.</p>';
    // this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(rawDescription);

    // "rating", 
    // "ranking",
    // "panel",
    // "paneldynamic",

    // const list = ["file", "image", "imagepicker", "matrix", "matrixdynamic", "html", "expression", "multipletext", "signaturepad", "matrixdropdown"]

    // list.forEach((item) => {
    //   creator.toolbox.removeItem(item);
    // })
    creator.text = JSON.stringify(defaultJson);
    creator.haveCommercialLicense = true;

    this.model = creator;
  }

  sortData(event: any) {
    console.log(event);

    let sort_by;

    if (event.active === 'requester_name') {
      if (this.currentPage !== 'followups') {
        sort_by = "subscriber_name"
      }
      else {
        sort_by = event.active
      }
    }
    else {
      sort_by = event.active
    }

    this.currentSort = {
      sort_by: sort_by,
      sort_order: event.direction == "desc" ? "descending" : "ascending",
    };

    console.log(this.currentSort)
    this.pageNo = 0
    if (this.currentPage === 'followups') {
      this.getQuestionnairesSubscriber(this.pageNo)
    }
    else {
      this.getQuestionnaires(this.pageNo)
    }
  }

  goToPreviousPage() {

    this.pageNo--
    if (this.currentPage === 'followups') {
      this.getQuestionnairesSubscriber(this.pageNo)
    }
    else {
      this.getQuestionnaires(this.pageNo)
    }
  }


  goToNextPage() {
    this.pageNo++;
    if (this.currentPage === 'followups') {
      this.getQuestionnairesSubscriber(this.pageNo)
    }
    else {
      this.getQuestionnaires(this.pageNo)
    }
  }

  getPageNumbers(): number[] {
    const totalPages = this.getTotalPages();
    return Array(totalPages)
      .fill(0)
      .map((_, i) => i);
  }

  getTotalPages(): number {
    return Math.ceil(this.totalRecords / this.limit);
  }

  deleteResponse(element) {
    console.log("request id", element.request_id)
    console.log("deleted by", JSON.parse(localStorage.getItem('loggedInUser')).UUID)
    // {{url}}/algocytegpt/v1/answer?request_id=7305872a-4249-11ee-b4f6-acde48001122&deleted_by=0ba35991-d6eb-4ee0-adfb-ba01cde8bbdb

    this
      ._gptService
      .deleteResponse(element.request_id, JSON.parse(localStorage.getItem('loggedInUser')).UUID)
      .subscribe((success: any) => {
        this.getQuestionnairesSubscriber(this.pageNo)
      }, (error: any) => {
        console.log(" ERROR in delete answer ")
      })
  }

  changeMode(currentMode) {
    this.currentMode = (currentMode === 'questionnaires') ? 'template' : 'questionnaires'

    this.pageNo = 1

    if (this.currentMode == 'template') {
      this.getTemplate(this.pageNo)
    }
    else {
      this.getQuestionnaires(this.pageNo)
    }
  }
}
