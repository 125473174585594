<div class="linked-account-info">
  <h2 class="title-text">
    <i class="fas fa-clinic-medical mr-1"></i>Linked Account Information
  </h2>
  <p>
    This section is entirely optional. You can link your account to another
    Algocyte account (for instance to your spouse) allowing them access to view
    your records.
  </p>
  <hr />
  <form [formGroup]="linkedAccountFormGroup">
    <div class="row align-items-center">
      <div class="form-group col-md-5 col-12">
        <mat-form-field class="example-full-width">
          <mat-label>Code</mat-label>
          <input
            id="code"
            type="text"
            matInput
            formControlName="otpCode"
            placeholder="XFRA91820"
            readonly
          />
        </mat-form-field>
      </div>

      <div class="form-group col-md-5 col-12">
        <mat-form-field class="example-full-width">
          <mat-label>Code Expires in 12 Hours</mat-label>
          <input
            type="text"
            matInput
            id="validUntill"
            formControlName="validUntill"
            placeholder="Code Expires in 12 hours"
            readonly
          />
        </mat-form-field>
      </div>

      <div class="form-group col-md-2 col-12">
        <button
          type="button"
          class="btn btn-danger w-100"
          [disabled]="!userData.isProfileComplete"
          id="getOTP"
          (click)="getOTP()"
        >
          Get Code
        </button>
      </div>
    </div>
    <div class="px-2 pt-2" *ngIf="isLinkAccountLoad">
      <ngx-skeleton-loader
        count="1"
        appearance="circle"
        [theme]="{
          width: '100%',
          height: '150px',
          'border-radius': '10px',
          margin: '0px 0px'
        }"
      >
      </ngx-skeleton-loader>
    </div>

    <div *ngIf="authorisedUsers && authorisedUsers.length">
      <div
        class="row align-items-center"
        *ngFor="let linkedAccount of authorisedUsers; let i = index"
      >
        <div class="form-group col-md-5 col-12">
          <mat-form-field class="example-full-width">
            <mat-label>Linked Accounts</mat-label>
            <input
              type="text"
              id="linkedAccount-forename-{{ i }}"
              matInput
              value="{{ linkedAccount.userProfile.firstName }} {{
                linkedAccount.userProfile.lastName
              }}"
              disabled
            />
          </mat-form-field>
        </div>
        <div class="form-group col-md-5 col-12">
          <mat-form-field class="example-full-width">
            <mat-label>Linked at</mat-label>
            <input
              id="linkedAccount-linked_at-{{ i }}"
              type="text"
              matInput
              value="{{
                linkedAccount.authorisedAt
                  | date: config.regionConfig.dateTimeFormateShort
              }}"
              disabled
            />
          </mat-form-field>
        </div>
        <div class="form-group col-md-2 col-12">
          <button
            type="button"
            id="linkedAccount-remove-link-{{ i }}"
            class="btn btn-danger w-100"
            (click)="
              removeLinkedAccount(
                linkedAccount.id,
                linkedAccount.userProfile.firstName,
                linkedAccount.userProfile.lastName
              )
            "
          >
            {{ 'profilePage.removeLink' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
