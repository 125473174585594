import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
declare var $: any;
import { TestService } from "src/app/services/test.service";

@Component({
  selector: "app-file-upload-dnd",
  templateUrl: "./file-upload-dnd.component.html",
  styleUrls: ["./file-upload-dnd.component.css"],
})
export class FileUploadDndComponent implements OnInit {
  files = [];
  errors: Array<string> = [];
  dragAreaClass = "hidden"; dragAreaContent = "";
  width: any;
  height: any;
  extensionFile: any;
  @Input() projectId = 0;
  @Input() sectionId = 0;
  @Input() fileExt = "JPG, JPEG, PNG, PDF, DOC, DOCX";
  @Input() maxFiles = 1;
  @Input() maxSize = 10; // 5MB
  @Output() uploadedFile = new EventEmitter();
  @Output() fileExtension = new EventEmitter();
  file = [];
  videos = [];
  public viewerOptions: any = {
    navbar: false,
    toolbar: false,
  };

  constructor(private translate: TranslateService, private testSerive: TestService) {
    this.testSerive.getTranslation('importResults.dragFileText').then((res: string) => {
      this.dragAreaContent = res
    });
  }

  ngOnInit() {
    this.testSerive.getTranslation('importResults.dragFileText').then((res: string) => {
      this.dragAreaContent = res
    });
  }
  ngOnDestroy() {
    this.testSerive.getTranslation('importResults.dragFileText').then((res: string) => {
      this.dragAreaContent = res
    });

  }

  onFileChange(event) {
    const fileList = [];
    const fileLength = event.target.files.length;
    for (let i = 0; i < fileLength; i++) {
      fileList[i] = event.target.files[i];
    }
    this.doProcess(fileList);
  }

  @HostListener("dragover", ["$event"]) onDragOver(event) {
    Promise.all([this.testSerive.getTranslation('importResults.dragDropInfo'), this.testSerive.getTranslation('importResults.dropInfo')])
      .then((res: any[]) => {
        this.dragAreaClass = res[0];
        this.dragAreaContent = res[1];
      });
    event.preventDefault();
  }

  @HostListener("dragenter", ["$event"]) onDragEnter(event) {
    Promise.all([this.testSerive.getTranslation('importResults.dragDropInfo'), this.testSerive.getTranslation('importResults.dropInfo')])
      .then((res: any[]) => {
        this.dragAreaClass = res[0];
        this.dragAreaContent = res[1];
      });
    event.preventDefault();
  }

  @HostListener("dragend", ["$event"]) onDragEnd(event) {
    this.dragAreaClass = "hidden";
    this.testSerive.getTranslation('importResults.dragFileText').then((res: string) => {
      this.dragAreaContent = res
    });
    event.preventDefault();
  }

  @HostListener("dragleave", ["$event"]) onDragLeave(event) {
    this.dragAreaClass = "hidden";
    this.testSerive.getTranslation('importResults.dragFileText').then((res: string) => {
      this.dragAreaContent = res
    });
    event.preventDefault();
  }

  @HostListener("drop", ["$event"]) onDrop(event) {
    this.dragAreaClass = "dragarea";
    this.testSerive.getTranslation('importResults.dragFileText').then((res: string) => {
      this.dragAreaContent = res
    });
    event.preventDefault();
    event.stopPropagation();
    const fileList = [];
    const fileLength = event.dataTransfer.files.length;
    for (let i = 0; i < fileLength; i++) {
      fileList[i] = event.dataTransfer.files[i];
    }
    this.doProcess(fileList);
  }

  doProcess(files) {
    console.group("doProcess");
    console.log("files ==>", files);

    if (this.files.length <= 0) {
      this.files = files;
    } else {
      const fileLength = files.length;
      for (let i = 0; i < fileLength; i++) {
        this.files[this.files.length] = files[i];
      }
    }
    console.log(this.files);
    this.file = [];
    const filesLength = this.files.length;
    for (let i = 0; i < filesLength; i++) {
      if (
        this.files[i].type === "image/jpeg" ||
        this.files[i].type === "image/jpg" ||
        this.files[i].type === "image/png" ||
        this.files[i].type === "application/pdf" ||
        this.files[i].type === "application/msword" ||
        this.files[i].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        if (
          this.files[i].type === "application/pdf" ||
          this.files[i].type === "application/msword" ||
          this.files[i].type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          this.files[i]["referredAs"] = "file";
        }
        this.file.push(this.files[i]);
      } else {
        this.testSerive.getTranslation('importResults.fileExtensionValidation').then(res => {
          alert(res);
        });
      }
    }
    console.log("this.file ===>", this.file);

    for (let i = 0; i < this.file.length; i++) {
      let reader: any, target: EventTarget;
      reader = new FileReader();
      let j = 0;
      reader.onload = function (e: any) {
        $("#blah_" + j).attr("src", e.target.result);
        j = j + 1;
      };
      reader.readAsDataURL(this.file[i]);
    }
    console.groupEnd();
    this.saveFiles(this.files);
  }

  saveFiles(files) {
    console.group("saveFiles");
    console.log("Files ===>", files);

    this.errors = [];
    if (files.length > 0 && !this.isValidFiles(files)) {
      this.uploadedFile.emit([]);
      return;
    } else if (files.length > 0 && this.isValidFiles(files)) {
      this.uploadedFile.emit(files);
    } else {
      this.dragAreaClass = "hidden";
      this.uploadedFile.emit([]);
    }
    console.groupEnd();
  }
  private isValidFiles(files) {
    if (files.length > this.maxFiles) {
      // this.testSerive.getTranslation('importResults.fileUploadLengthError', { length: this.maxFiles }).then((res: string) => {
      //   this.errors.push(res);
      // });
      this.errors.push(this.translate.instant('importResults.fileUploadLengthError', { length: this.maxFiles }));
      this.files = [];
      return;
    }
    this.isValidFileExtension(files);
    return this.errors.length === 0;
  }
  private isValidFileExtension(files) {
    console.group("isValidFileExtension");
    console.log("this.fileExt ===>", this.fileExt);
    const extensions = this.fileExt.split(",").map(function (x) {
      return x.toLocaleUpperCase().trim();
    });
    for (let i = 0; i < files.length; i++) {
      const ext = files[i].name.toUpperCase().split(".").pop() || files[i].name;
      console.log("extensions ==>", extensions, "includes ==>", ext);
      this.extensionFile = ext;
      this.fileExtension.emit(ext);

      const exists = extensions.includes(ext);
      console.log("is extension exists ===>", exists);

      if (!exists) {
        // this.testSerive.getTranslation('importResults.fileExtenstionError', { fileName: files[i].name }).then((res: string) => {
        //   this.errors.push(res);
        // });
        this.errors.push(this.translate.instant('importResults.fileExtenstionError', { fileName: files[i].name }));
        this.files = [];
      }
      this.isValidFileSize(files[i]);
    }
  }
  private isValidFileSize(file) {
    const fileSizeinMB = file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100;
    if (size > this.maxSize) {
      // this.testSerive.getTranslation('importResults.fileUploadSizeError', { fileName: file.name, maxSize: this.maxSize, size: size }).then((res: string) => {
      //   this.errors.push(res);
      // })
      this.errors.push(this.translate.instant('importResults.fileUploadSizeError', { fileName: file.name, maxSize: this.maxSize, size: size }));
      this.files = [];
    }
  }
  deleteFile(file, index) {
    console.log(file);

    const x = this.files.indexOf(file);
    console.log(file);
    console.log(this.file[index], x);
    this.files.splice(x, 1);
    this.file.splice(index, 1);
    this.saveFiles(this.files);
  }
  deleteVideo(file, index) {
    const x = this.files.indexOf(file);
    console.log(file);
    console.log(this.file[index], x);
    this.files.splice(x, 1);
    this.videos.splice(index, 1);
    this.saveFiles(this.files);
  }
}
