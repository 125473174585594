<div class="container clionician-info">

  <h2>
    <i class="fa fa-user-md mr-1"></i> {{'profilePage.clinicianInformation'| translate }}
  </h2>
  <hr />

  <form [formGroup]="form" class="w-100" autocomplete="off">
    
    <div class="form-group row">
      <mat-form-field class="col-md-6 col-12">
        <input matInput formControlName="firstName" placeholder="{{ 'profilePage.firstNamePlaceholder' | translate }}"
          autocomplete="off" />
      </mat-form-field>

      <mat-form-field class="col-md-6 col-12">
        <input matInput formControlName="lastName" placeholder="{{ 'profilePage.lastNamePlaceholder' | translate }}"
          autocomplete="off" />
      </mat-form-field>

      <mat-form-field class="col-md-6 col-12">
        <input matInput readonly formControlName="email"
          placeholder="{{ 'profilePage.emailPlaceholder' | translate }}" />
      </mat-form-field>

      <div class="form-group col-md-6 col-12">
        <ng-select
          [placeholder]="'profilePage.organisation' | translate"
          [items]="associatedOrganisations"
          bindLabel="name"
          bindValue="id"
          formControlName="organisationId"
          (change)="onOrgChange($event.name)"
          [readonly]="!form.value.isNew"
        >
        </ng-select>
      </div>

      <div class="form-group col-12 d-flex align-items-center">
        
        <button mat-raised-button type="button" class="btn personalInfo-Save" [disabled]="form.invalid || !form.dirty"
          (click)="onSubmit()">{{ 'common.saveBtnText' | translate }}
        </button>

        <div *ngIf="isUpdatingProfile" class="d-flex align-items-center ml-3">
          <mat-spinner *ngIf="isUpdatingProfile" diameter="20"></mat-spinner>
          <div class="ml-2">{{ 'profilePage.loadingMessage' | translate }}</div>
        </div>

        <div *ngIf="isSucceeded" class="ml-3">
          <i class="fa fa fa-check-circle mr-1" style="color: green; font-size: 16px"></i>
          {{ 'profilePage.successMessage' | translate }}
        </div>

        <div *ngIf="isFailed" class="ml-3 text-danger">
          {{ 'profilePage.errorMessage' | translate }}
        </div>
      </div>
    </div>
  </form>
</div>