import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';

import { BaseComponent } from '../../../modules/shared/base.component';
import { TagService } from '../../../services/tags/tag.service';
import { Tag, TagEntityType } from '../../../../types';
import { UserService } from '../../../services/user/user.service';

type TDialogData = {
  resultSetId: number;
  tags: Tag[];
};

@Component({
  selector: 'app-tag-modal',
  templateUrl: './tag-modal.component.html',
  styleUrls: ['./tag-modal.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TagModalComponent extends BaseComponent implements OnInit {
  @ViewChild('tagOptionsInput', { read: MatAutocompleteTrigger })
  autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('customTagAutocomplete',{ read: MatAutocompleteTrigger })
  customAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('customTagInput')
  customTagInput: ElementRef;

  form: FormGroup;

  tagOptions: Tag[] = [];
  isLoadingTags = false;
  isUpdatingTag = false;
  isSavingTags = false;
  saveTagsError: string;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TagModalComponent>,
    private tagService: TagService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data: TDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedTags: this.fb.array([]),
      customTags: this.fb.array([]),
      tagInput: [''],
    });

    this.subscriptions.add(
      this.tagService.getTags().subscribe({
        next: (tags) => {
          this.isLoadingTags = false;
          this.tagOptions = tags;

          this.populateDefaultValues(this.data.tags);
        },
        error: () => {
          this.isLoadingTags = false;
        },
      })
    );
  }

  get selectedTags(): FormArray {
    return this.form.get('selectedTags') as FormArray;
  }

  get customTags(): FormArray {
    return this.form.get('customTags') as FormArray;
  }

  get filteredTagOptions(): Tag[] {
    const selectedTagIds = this.selectedTags.value.map((x) => x.id);

    return this.tagOptions.filter(
      (tag) =>
        !selectedTagIds.includes(tag.id) &&
        tag?.type !== TagEntityType.USER_CREATED
    );
  }

  get filteredCustomTagOptions(): Tag[] {
    const selectedTagIds = this.customTags.value.map((x) => x.id);

    return this.tagOptions.filter(
      (tag) =>
        !selectedTagIds.includes(tag.id) &&
        tag?.type !== TagEntityType.SYSTEM_DEFINED
    );
  }

  populateDefaultValues(tags: Tag[]): void {
    const systemTags = tags.filter(
      (tag) => tag.type === TagEntityType.SYSTEM_DEFINED
    );
    const userCreatedTags = tags.filter(
      (tag) => tag.type === TagEntityType.USER_CREATED
    );
    systemTags.forEach((tag) => {
      this.selectedTags.push(new FormControl(tag));
    });
    userCreatedTags.forEach((tag) => {
      this.customTags.push(new FormControl(tag));
    });
  }

  selectTag(event: MatAutocompleteSelectedEvent): void {
    this.saveTagsError = '';

    const value = event.option.value;

    if (this.selectedTags.value.indexOf(value) === -1) {
      this.selectedTags.push(new FormControl(value));
    }

    // keep panel opened
    setTimeout(() => this.autocompleteTrigger.openPanel());
  }

  selectCustomTag(event: MatAutocompleteSelectedEvent): void {
    this.saveTagsError = '';

    const value = event.option.value;

    if (this.customTags.value.indexOf(value) === -1) {
      this.customTags.push(new FormControl(value));
    }

    // keep panel opened
    setTimeout(() => this.customAutocompleteTrigger.openPanel());
  }

  removeTag(index: number): void {
    this.saveTagsError = '';
    this.selectedTags.removeAt(index);
  }

  removeCustomTag(index: number): void {
    this.saveTagsError = '';
    this.customTags.removeAt(index);
  }

  openAutocompletePanel() {
    if (this.autocompleteTrigger) {
      this.autocompleteTrigger.openPanel();
    }
  }

  openCustomAutocompletePanel() {
    if (this.customAutocompleteTrigger) {
      this.customAutocompleteTrigger.openPanel();
    }
  }

  addCustomTag(event: MatChipInputEvent): void {
    this.saveTagsError = '';
    this.isUpdatingTag = true;
    const input = event.chipInput.inputElement;
    const value = event.value;
    const isInList = this?.customTags?.value?.find(
      (val) => val?.text === value
    );
    if ((value || '').trim() && !isInList) {
      const newTag = {
        userId: this.userService.user.id,
        text: value.trim(),
        id: null,
      };
      this.subscriptions.add(
        this.tagService.createTag(newTag).subscribe({
          next: (createdTag) => {
            this.isUpdatingTag = false;
            this.customTags.push(new FormControl(createdTag));
            setTimeout(() => this.customTagInput.nativeElement.focus());

            // Reset the input value
            if (input) {
              input.value = '';
            }

            this.form.controls['tagInput'].setValue(null);
          },
          error: (error) => {
            this.saveTagsError =
              error.error?.message || 'reports.createTagError';
            this.isUpdatingTag = false;
          },
        })
      );
    }
    this.isUpdatingTag = false;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave() {
    this.saveTagsError = '';
    this.isSavingTags = true;

    const { customTags, selectedTags } = this.form.value;

    const tagsToAssign = [...customTags, ...selectedTags];

    this.subscriptions.add(
      this.tagService
        .assignTagsToResultSet(tagsToAssign, this.data.resultSetId)
        .subscribe({
          next: () => {
            this.isSavingTags = false;
            this.dialogRef.close(true);
          },
          error: (error) => {
            this.isSavingTags = false;
            this.saveTagsError =
              error.error?.message || 'reports.assignTagsError';
          },
        })
    );
  }
}
