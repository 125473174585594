<!-- file upload as drag and drop section start -->
<div draggable="true" ngClass="{{dragAreaClass}}">
	<div class="text-center overlay_dragarea" *ngIf="files.length<=0 || errors.length>0" (click)="file.click()"
		style="cursor: pointer;">
		<span for="addFile">{{'importResults.dragFileText'|translate}}</span>
		<input type="file" #file id="addFile" [multiple]="(maxFiles > 1)"
			accept=".jpg, .png, .jpeg, .mp4, .doc, .pdf, .docx" (change)="onFileChange($event)" style="display:none" />
	</div>
	<div class="row" *ngIf="errors.length<=0">
		<div *ngIf="files.length>0" style="display: contents;">
			<div *ngFor="let i of file; let x=index ">

				<div class="default_cover_images" style="position: relative;">

					<ng-container *ngIf="i.referredAs != 'file'; else fileType">
						<img *ngIf="i.referredAs != 'file'" #uploadedImg class="img-fluid" id="blah_{{x}}" src="#"
							alt="your image" ngxViewer [viewerOptions]="viewerOptions" />
						<span title="Remove Image"
							style="position: absolute;top: 0px;right: 0;left: 0; bottom: 0; z-index: 1;cursor: pointer;color: #dc3545;height: 60px;width: 60px;text-align: center;background-color: rgba(255,255,255,0.6);border-radius: 50%;margin: auto;"
							(click)="deleteFile(i, x)"><i class="fa fa-trash" aria-hidden="true"
								style="font-size: 30px;top: 0;bottom: 0;left: 0;right: 0;margin: auto;position: absolute;height: 30px;width: 30px;line-height: 30px;"></i></span>
					</ng-container>
					<ng-template #fileType>
						<p class="file-content">{{i.name}}
							<span (click)="deleteFile(i, x)">
								<i class="fa fa-trash fileTrashIcon" aria-hidden="true"></i>
							</span>
						</p>
					</ng-template>

				</div>

			</div>
		</div>
	</div>
</div>
<div class="row error" *ngIf="errors.length > 0">
	<ul>
		<li *ngFor="let err of errors">{{err}}</li>
	</ul>
</div>
<!-- file upload as drag and drop section end -->