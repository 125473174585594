<div class="header-container" *ngIf="userData">
  <div id="desktopMenu" class="navbars-fixed d-none d-lg-block" *ngIf="isLoggedin || isGuestUser">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark justify-content-end">
      <div class="container first-nav" style="justify-content: space-between">
        <div class="mr-auto instructions-for-use" style="color: #fff">
          <span (click)="downloadHelpPdf()" style="padding-right: 5px; cursor: pointer">
            <span class="icon colored"></span>
            &nbsp;
            <span>Instructions for Use</span>
          </span>
          <span> | </span>
          <span (click)="downloadQuickStartGuide()" style="padding-left: 5px; cursor: pointer">
            <span class="icon colored"></span>
            &nbsp;
            <span>Quick Start Guide</span>
          </span>
        </div>

        <div class="center" *ngIf="isGuestUser">
          <a class="mr-1 cursor-pointer" routerLink="/auth/signup">Sign Up</a>
        </div>
        <div class="center" *ngIf="isLoggedin && !isGuestUser">
          <span class="mr-1" style="color: #fff">{{ demoMessage }}</span>
          <span class="header-left-separator mr-1">|</span>
          <span class="mr-1" style="color: #fff">
            {{ feedbackMessage }}
            <a href="mailto:{{ feedbackMail }}">{{ feedbackMail }}</a>
          </span>
          <span class="header-left-separator mr-3">|</span>
          <span class="dropdown">
            <a class="mr-1 dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" href="javascript:void(0)">
              <i class="fa fa-user-circle mr-1"></i>
              {{ 'header.greeting' | translate }}
              <span *ngIf="currentUser">
                {{ currentUser.userProfile?.firstName }} {{ currentUser.userProfile?.lastName }}
              </span>
            </a>

            <div class="dropdown-menu">
              <mat-list>
                <mat-list-item *ngIf="isLoggedin && (isProvider || isAdmin)" (click)="goToLink('clinician')" routerLink="{{
                    isProvider ? '/account/clinician' : '/account/manager'
                  }}" style="cursor: pointer">
                  <mat-icon matListIcon><i class="material-icons">account_circle</i></mat-icon>
                  <p matLine>{{ 'menus.myDetails' | translate }}</p>
                </mat-list-item>

                <mat-list-item *ngIf="!(isProvider || isAdmin)" (click)="doRouting(['/account/subject'])"
                  style="cursor: pointer">
                  <mat-icon matListIcon><i class="material-icons">account_circle</i></mat-icon>
                  <p matLine>{{ 'menus.myDetails' | translate }}</p>
                </mat-list-item>

                <mat-list-item style="cursor: pointer" *ngIf="isLoggedin && isAdmin" (click)="
                    goToLink('test-profiles'); doRouting(['/test-profiles'])
                  ">
                  <mat-icon matListIcon><i class="material-icons">supervised_user_circle</i></mat-icon>
                  <p matLine>{{ 'menus.manageTestProfiles' | translate }}</p>
                </mat-list-item>

                <mat-list-item style="cursor: pointer" *ngIf="isLoggedin" (click)="downloadHelpPdf()">
                  <mat-icon matListIcon><i class="material-icons">help</i></mat-icon>
                  <p matLine>{{ 'menus.help' | translate }}</p>
                </mat-list-item>

                <mat-list-item style="height: auto !important; cursor: pointer" id="languageSwitcher">
                  <app-language-switcher *ngIf="childVisible" [parentName]="'header'" (check)="langChanged()">
                  </app-language-switcher>
                </mat-list-item>

                <mat-list-item>
                  <button (click)="logout(true)" mat-button style="
                      background-color: #e35f6c !important;
                      color: white !important;
                      width: 100% !important;
                      cursor: pointer !important;
                    ">
                    <mat-icon matListIcon><i class="material-icons">logout</i></mat-icon>
                    <span>Logout</span>
                  </button>
                </mat-list-item>
              </mat-list>
            </div>
          </span>
        </div>
      </div>
    </nav>

    <nav class="navbar navbar-expand-md navbar-light py-0 second-nav" style="background-color: white">
      <div class="container justify-content-center">
        <div class="row text-center">
          <a href="javascript:void(0)" class="navbar-brand p-0 cursor-pointer d-md-inline-block"
            (click)="doRouting(['/'])">
            <img src="assets/algocyte/logo.png" height="80" class="d-inline-block align-top" alt="" />
          </a>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
              <ng-container *ngFor="let item of navItems; let i = index">
                <li class="nav-item" [id]="item.class" [class]="item.class" [hidden]="item.hide" [ngClass]="
                    isMenuActive(
                      item.route,
                      item.children ? 'dashboard' : null,
                      item
                    )
                  " *ngIf="item.show">
                  <a class="nav-link" *ngIf="!item.children" (click)="!item.disabled ? doRouting([item.route]) : null"
                    [ngClass]="{ disabled: item.disabled }">
                    {{ item.label | translate }}
                  </a>

                  <a class="nav-link" *ngIf="item.children" [matMenuTriggerFor]="dashboard" (click)="
                      !item.disabled ? isAnyChildMenuRouteOpen(item) : null
                    " [ngClass]="{ disabled: item.disabled }">
                    {{ item.label | translate }}
                    <span> &#9207; </span>
                  </a>

                  <mat-menu #dashboard="matMenu" yPosition="above">
                    <button mat-menu-item *ngFor="let child of item.children" [hidden]="!child.show"
                      [class]="child.action" [ngClass]="isMenuActive(child.action, 'child')"
                      (click)="gotoReports(child.action)">
                      <mat-icon>{{ child.icon }}</mat-icon>
                      {{ child.label }}
                    </button>
                  </mat-menu>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <div id="mySidenav" class="sidenav font-CenturyG" style="z-index: 1001" *ngIf="isLoggedin">
    <div class="row p-0 m-0 cursor-pointer">
      <span class="pl-3" style="
          font-size: 32px;
          margin-top: 0.7rem !important;
          margin-bottom: 0.7rem !important;
        ">
        <i class="fa fa-user-circle"></i>
        <small *ngIf="currentUser">
          {{ currentUser.userProfile?.firstName }}
          {{ currentUser.userProfile?.lastName }}
        </small>
      </span>
    </div>

    <div class="no-padding menu-list-background-teal custom-padding-left-20">
      <ul class="list-group">
        <ng-container *ngFor="let menuItem of mobileNavItems">
          <li (click)="menuItem.action()" [hidden]="menuItem.hide" class="list-group-item" [class]="menuItem.class"
            [ngClass]="isMenuActive(menuItem.route)" *ngIf="menuItem.condition">
            <i [ngClass]="menuItem.iconClass"></i>
            {{ menuItem.label | translate }}
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <div id="main" class="oia-mobile-menu" *ngIf="isLoggedin">
    <div class="d-block d-lg-none" style="
        background-color: #fff !important;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        padding: 0px 5px 0px;
      ">
      <div class="form-row" style="min-height: 64px">
        <div class="col-2 d-flex flex-wrap align-content-center justify-content-start">
          <picture>
            <img class="img-fluid" src="assets/algocyte/logo.png" style="padding: 7% !important" />
          </picture>
        </div>
        <div class="col-8 d-flex flex-wrap align-content-center justify-content-start">
          <h5 class="text-capitalize mobile-nav-text">
            {{
            className?.replaceAll('-', ' ') === 'subject'
            ? 'Profile'
            : className?.replaceAll('-', ' ')
            }}
          </h5>
        </div>
        <div class="col-2 d-flex flex-wrap align-content-center justify-content-end">
          <mat-button class="btn" (click)="openNav()" style="padding: 2px">
            <mat-icon style="color: #a8a8a8">menu</mat-icon>
          </mat-button>
        </div>

        <!-- <div
          *ngIf="hideProfileSelection()"
          class="col-8 d-flex flex-wrap align-content-center justify-content-center"
        >
          <mat-form-field class="w-100">
            <mat-label>{{ 'menus.testProfile' | translate }}</mat-label>
            <mat-select
              [ngModel]="selectedProfile.id"
              [disabled]="isDisabledProfileChange()"
              (selectionChange)="profileSeceltionChanged($event)"
            >
              <mat-option value="null" selected>
                {{ message | async }}
              </mat-option>
              <mat-option *ngFor="let profile of profiles" [value]="profile.id">
                {{ profile.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div
          *ngIf="!hideProfileSelection()"
          class="col-8 d-flex flex-wrap align-content-center justify-content-center"
        ></div> -->
      </div>
    </div>
  </div>

  <alert-banner *ngIf="!userData.isProfileComplete" ctaText="{{ 'subHeader.incompleteProfile.cta' | translate }}"
    ctaUrl="account/subject" text="{{ 'subHeader.incompleteProfile.main' | translate }}">
  </alert-banner>

  <div class="d-none d-lg-block">
    <div class="row m-0 text-center elevation-z8 w-100 pt-2 justify-content-center align-items-center bg-light"
      style="min-height: 82px" *ngIf="isSelectedProfileDisplay()" [hidden]="isHidden">
      <h6 class="patient-info d-md-inline-block">
        <span class="patient-info" style="padding: 0px" *ngIf="isProvider">
          {{ 'common.subscriber' | translate }}
        </span>
        {{ 'subHeader.patientName' | translate }}:
        {{ displayValueInBanner('name') }}
      </h6>

      <h6 class="patient-info d-md-inline-block">
        <span class="patient-info" style="padding: 0px" *ngIf="!isSubscriber">
          {{ 'common.subscriber' | translate }} {{ 'common.dob' | translate }}:
        </span>

        <span class="patient-info" style="padding: 0px" *ngIf="isSubscriber">
          {{ 'subHeader.patientDob' | translate }}:
        </span>

        <span class="patient-info" *ngIf="displayValueInBanner('dateOfBirth'); else elseTemplate">
          {{
          userData.userProfile.dateOfBirth
          | date: config.regionConfig.dateFormateShort
          }}
        </span>

        <ng-template #elseTemplate>
          <span class="patient-info">-</span>
        </ng-template>
      </h6>

      <h6 class="patient-info d-md-inline-block">
        {{ 'subHeader.patientIdText' | translate }}:
        {{ displayValueInBanner('patientId') }}
      </h6>

      <mat-form-field *ngIf="hideProfileSelection()" class="d-none d-lg-inline-block patient-info" style="width: 284px"
        appearance="outline">
        <mat-label>{{ 'menus.testProfile' | translate }}</mat-label>
        <mat-select [value]="selectedProfile.id" [disabled]="isDisabledProfileChange()"
          (selectionChange)="profileSeceltionChanged($event)">
          <mat-option [value]="'null'" selected>
            {{ 'menus.completeAnalyteProfile' | translate }}
          </mat-option>
          <mat-option *ngFor="let profile of profiles" [value]="profile.id">
            {{ profile.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row m-0 d-lg-none w-100 py-2 px-1" style="background-color: #f5f5f5 !important"
    *ngIf="isSelectedProfileDisplay()" [hidden]="isHidden">
    <div id="mobile-patient-info" class="col-12 d-block d-md-none">
      <h6 class="patient-info">
        {{ 'common.subscriber' | translate }}
        {{ 'subHeader.patientName' | translate }}:
        {{ displayValueInBanner('name') }}
      </h6>

      <h6 class="patient-info">
        <span class="patient-info" style="padding: 0px">
          {{ 'common.dob' | translate }}:
        </span>

        <span class="patient-info" *ngIf="displayValueInBanner('dateOfBirth'); else elseTemplate">
          {{
          userData.userProfile.dateOfBirth
          | date: config.regionConfig.dateFormateShort
          }}
        </span>

        <ng-template #elseTemplate>
          <span class="patient-info">-</span>
        </ng-template>

        <span class="patient-info">
          &nbsp; &nbsp;
          {{ 'subHeader.id' | translate }}:
          {{ displayValueInBanner('patientId') }}
        </span>
      </h6>
    </div>

    <div class="col-12 d-none d-md-block text-center">
      <h6>
        <span class="patient-info">
          {{ 'common.subscriber' | translate }}
          {{ 'subHeader.patientName' | translate }}:
          {{ displayValueInBanner('name') }}
        </span>

        <span class="patient-info" style="padding: 0px">
          &nbsp; &nbsp;{{ 'common.dob' | translate }}:
        </span>

        <span class="patient-info" *ngIf="displayValueInBanner('dateOfBirth'); else elseTemplate">
          {{
          userData.userProfile.dateOfBirth
          | date: config.regionConfig.dateFormateShort
          }}
        </span>

        <ng-template #elseTemplate>
          <span class="patient-info">-</span>
        </ng-template>

        <span class="patient-info">
          &nbsp; &nbsp;
          {{ 'subHeader.id' | translate }}:
          {{ displayValueInBanner('patientId') }}
        </span>
      </h6>
    </div>
  </div>
</div>