import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

import { ResultSetWithScores } from '../../../services/resultSet/types';
import { HealthEvent } from '../../../../types';

interface ILineItem {
  healthId: number;
  immuneScore: number;
  date: string;
  [analyteKey: string]: string | number; // value per analyte
}

interface IChartData {
  chartId: string;
  data: ILineItem[];
  adaptiveScores: {
    DATE: string[];
    SCORE: number[];
  };
  isReport: boolean;
  events: HealthEvent[];
}

@Component({
  selector: 'app-score-over-time',
  templateUrl: './score-over-time.component.html',
  styleUrls: ['./score-over-time.component.css'],
})
export class ScoreOverTimeComponent implements OnInit, OnChanges {
  @Input() resultSets: ResultSetWithScores[] = [];
  @Input() events: HealthEvent[] = [];
  @Input() isLoading: boolean;

  chartData: IChartData;

  constructor(private readonly router: Router) { }

  ngOnInit(): void {
    this.setChartData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resultSets?.currentValue) {
      this.setChartData();
    }
  }

  setChartData() {
    this.chartData = {
      chartId: 'customLineChart',
      data: this.resultSets.map((x) => {
        const item: ILineItem = {
          healthId: x.id,
          date: x.observationDate,
          immuneScore: x.nonAdaptiveScore,
        };

        x.results.analyteResults.forEach(
          (result) => (item[result.analyteKey] = result.analyteValue)
        );

        return item;
      }),
      adaptiveScores: {
        DATE: this.resultSets.map((x) => x.observationDate),
        SCORE: this.resultSets.map((x) => x.adaptiveScores[0]?.score),
      },
      events: this.events,
      isReport: false,
    };
  }

  hasNoData(): boolean {
    return !this.events.length && !this.resultSets.length && !this.isLoading;
  }

  navigateToEvents() {
    this.router.navigate(['/events']);
  }

  navigateToGraph() {
    this.router.navigate(['/new-report/graph/Haemoglobin']);
  }
}
