<div class="container">
  <div class="row">
    <div class="col">
      <h2 class="text-center mt-5">
        {{ 'reports.individualBloodTest' | translate }}
      </h2>
      <div class="row mt-5 mb-3 justify-content-end">
        <mat-form-field class="col-12 col-md-3 float-right">
          <mat-label>{{ 'reports.enterTestDate' | translate }}</mat-label>
          <mat-select
            id="enterTestDateIndividual"
            name="date"
            [disabled]="dates && dates.length === 0"
            [(ngModel)]="dateSelected"
            (ngModelChange)="dateChanged($event)"
          >
            <mat-option
              id="{{
                date | date: config.regionConfig.dateFormatMid
              }}-individual"
              *ngFor="let date of uniqueDates"
              value="{{ date | date: config.regionConfig.dateFormatMid }}"
            >
              {{ date | date: config.regionConfig.dateFormatMid }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="table-responsive">
        <table class="table testData w-100" *ngIf="testData && testData.length">
          <caption
            id="individual-tab-title-date"
            style="
              caption-side: top;
              text-align: center;
              color: black;
              font-size: 24px;
              line-height: normal;
            "
          >
            {{
              testData[0].date | date: config.regionConfig.dateFormatLong
            }}
          </caption>
          <tr>
            <th>{{ 'reports.analyte' | translate }}</th>
            <th *ngFor="let record of testData">
              Value (taken at {{ getTimeForIndividualTest(record.time) }})
            </th>
            <th *ngIf="showRange">
              {{ 'reports.referenceRange' | translate }}
            </th>
          </tr>
          <tr *ngFor="let cell of analytes; let i = index">
            <td>{{ cell }}</td>
            <td
              *ngFor="let record of testData; let j = index"
              [ngClass]="getCellBgColor(j, i, cell) ? 'danger' : ''"
            >
              <span>
                {{ getCount(j, i) }}
              </span>
            </td>
            <td *ngIf="showRange" class="font-weight-bold">
              <span *ngIf="allRanges.reference_range[cell]; else elseTemplate"
                >{{ allRanges.reference_range[cell] }}
                {{ allRanges.reference_unit[cell] }}</span
              >
              <ng-template #elseTemplate>
                <h6>{{ 'common.notApplicableText' | translate }}</h6>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>{{ 'reports.tag' | translate }}</td>
            <td *ngFor="let record of testData; let j = index">
              <ul *ngIf="record.tags && record.tags.length; else elseTemplate">
                <li *ngFor="let tag of record.tags; let i = index">
                  {{ tag.text }}
                </li>
              </ul>
              <ng-template #elseTemplate>
                <h6>{{ 'reports.noTagsErrorMessage' | translate }}</h6>
              </ng-template>
              <div>
                <button
                  type="button"
                  id="individual-edit-tag"
                  class="btn btn-success"
                  style="width: -webkit-fill-available"
                  *ngIf="record.tags && record.tags.length"
                  (click)="handleAddTag(record)"
                >
                  {{ 'reports.editTag' | translate }}
                </button>
                <button
                  type="button"
                  id="individual-add-tag"
                  class="btn btn-primary"
                  style="width: -webkit-fill-available"
                  *ngIf="record.tags === '' || record.tags.length <= 0"
                  (click)="handleAddTag(record)"
                >
                  {{ 'reports.addTag' | translate }}
                </button>
              </div>
            </td>
            <td>{{ 'common.notApplicableText' | translate }}</td>
          </tr>
        </table>
      </div>
      <div class="text-center" *ngIf="testData && testData.length <= 0">
        <h5>{{ 'reports.noDataFound' | translate }}</h5>
      </div>
    </div>
  </div>
</div>
