import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-auth-aside",
  templateUrl: "./auth-aside.component.html",
  styleUrls: ["./auth-aside.component.css"],
})
export class AuthAsideComponent {
  @Input() welcomeText: string;
  @Input() welcomeSubText: string;
  @Input() ctaText: string;
  @Input() ctaUrl: string;

  authorisedUserData: any;
  subscription: Subscription;

  constructor(private router: Router, private authService: AuthService) {
    this.subscription = this.authService.getAuthorisedUserDataObservable().subscribe((user) => {
      this.authorisedUserData = user;
    });
  }

  onCtaClicked() {
    this.router.navigate([this.ctaUrl]);
  }

  signOut() {
    this.authService.logOut();
    this.router.navigate(["/auth/signin"]);
    return;
  }
}