import { Injectable } from '@angular/core';
import { config } from '../../config';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { BehaviorSubject } from 'rxjs';
import {
  FBUserWithId,
  RegisterUserPayload,
  User,
  ResetPasswordRequest,
} from 'src/types';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { initializeApp } from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly API_URL = `${config.baseApiv2Url}/auth`;

  authIsReady = new BehaviorSubject<boolean>(false);
  authorisedUserData = new BehaviorSubject<FBUserWithId | null>(null);

  constructor(
    public router: Router,
    private apiService: ApiService
  ) { }

  initializeFirebase(): Promise<number | null> {
    return new Promise((resolve) => {
      try {
        initializeApp(config.firebaseConfig);

        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
          let loggedUserId = null;

          if (user) {
            const idToken = await user.getIdToken();
            const { userId } = AuthService.decodeJWT(idToken);
            this.authorisedUserData.next({
              ...user,
              userId,
            });
            loggedUserId = userId;
          } else {
            this.authorisedUserData.next(null);
          }
          this.authIsReady.next(true);

          resolve(loggedUserId);
        });
      } catch (ex) {
        console.error(`Firebase failed to initialize: ${ex.message}`);
        resolve(null);
      }
    });
  }

  getAuthIsReadyObservable() {
    return this.authIsReady.asObservable();
  }

  getAuthorisedUserDataObservable() {
    return this.authorisedUserData.asObservable();
  }

  registerUser(payload: RegisterUserPayload): Promise<User> {
    return this.apiService.registerUser(payload).toPromise();
  }

  resendVerificationEmail(): Promise<any> {
    const user = this.authorisedUserData.getValue();
    if (!user) {
      return Promise.reject(
        'You need to be logged in to resend verification email.'
      );
    }

    return this.apiService.resendVerificationEmail(user.email).toPromise();
  }

  async verifyUser(token: string) {
    await this.apiService.verifyUser(token).toPromise();
    await this.reloadCurrentUser();
    return true;
  }

  async reloadCurrentUser() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      await user?.reload();

      const idToken = await user.getIdToken();
      const { userId } = AuthService.decodeJWT(idToken);
      this.authorisedUserData.next({
        ...user,
        userId,
      });
    }
  }

  getAuthorisedUser() {
    return this.authorisedUserData.getValue();
  }

  async logOut() {
    const auth = getAuth();
    await auth.signOut();
    localStorage.clear();
    this.authorisedUserData.next(null);
    this.router.navigate(['/auth/signin']);
  }

  static decodeJWT(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  }

  forgotPassword(email: string) {
    return this.apiService.forgotPassword(email).toPromise();
  }

  resetPassword(payload: ResetPasswordRequest) {
    return this.apiService.resetPassword(payload).toPromise();
  }
}
