import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myfilter',
  pure: false
})
export class MyfilterPipe implements PipeTransform {

  // transform(items: any[], filter: any): any {
  //   if (!items || !filter) {
  //     return items;
  //   }
  //   // filter items array, items which match and return true will be
  //   // kept, false will be filtered out
  //   return items.filter(item => item.viewValue.indexOf(filter.viewValue) !== -1);
  // }

  transform(items: any[], field : string, value : string): any[] {  
    if (!items) return [];
    if (!value || value.length == 0) return items;
    return items.filter(it => 
    it[field].toLowerCase().indexOf(value.toLowerCase()) !=-1);
  }

}
