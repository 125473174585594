<div
  class="row h-100 no-margin order-last order-md-first donut-section section-border-background"
>
  <div *ngIf="selectedResultSet" class="col-12 date-section p-0">
    <div class="col-12 p-0">
      <div id="scoreChartContainer">
        <app-donut [donutData]="donutChartData"></app-donut>
      </div>

      <div id="chartjs-tooltip" class="table w-auto">
        <table class="mat-elevation-z8 w-100"></table>
      </div>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <span>
            <div class="form-row mb-4" style="position: relative">
              <div class="col-12 text-center">
                <span
                  class="rangeLabelTypo top-13"
                  style="font-weight: 700; color: #333"
                  >{{ getCurrentStatus() }}</span
                >
              </div>

              <div class="col-6 text-left no-padding">
                <span class="rangeLabelTypo">{{ getLabel('end') }}</span>
              </div>
              <div class="col-6 text-right no-padding">
                <span class="rangeLabelTypo">{{ getLabel('start') }}</span>
              </div>

              <div class="col-1 no-padding text-center">
                <span id="minValue" class="rangeLabelTypo">{{
                  rangeBarConfig.min
                }}</span>
              </div>
              <div class="col-10 no-padding">
                <input
                  type="range"
                  class="range covid"
                  min="0"
                  max="100"
                  [value]="rangeBarConfig.current * 10"
                  id="rangeBar"
                  disabled
                />
                <output
                  [style.left]="rangeBarConfig.offsetLeft"
                  class="rangeLabelTypo"
                  style="position: absolute; bottom: -18px"
                  >{{ rangeBarConfig.current }}</output
                >
              </div>
              <div class="col-1 no-padding text-center">
                <span id="maxValue" class="rangeLabelTypo">{{
                  rangeBarConfig.max
                }}</span>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="col-1 d-md-none d-lg-none"></div>
    <div class="col-12 d-md-none d-lg-none">
      <hr class="score-details-divider" />
    </div>
    <div class="col-1 d-md-none d-lg-none"></div>
    <div *ngIf="speedOfChange" class="col-12 no-padding" id="speed-of-change">
      <div class="d-none d-lg-block d-md-block padding-left-5 padding-right-5">
        <p
          class="padding-top-5 padding-bottom-5 margin-top-5 no-margin-bottom"
          style="
            font-style: normal;
            font-size: 12px;
            line-height: 21px;
            color: #333;
            font-weight: 700;
          "
        >
          Speed of Change
        </p>
        <p class="mb-0">
          <img
            [src]="
              '../../../../assets/dashboard/score_change/' +
              speedOfChange.score +
              '.png'
            "
            width="100%"
          />
        </p>
        <p
          class="margin-bottom-5 mt-0"
          style="
            font-size: 12px;
            line-height: 21px;
            font-style: normal;
            color: #777;
            text-transform: capitalize;
          "
          [class]="'degree_of_change_' + speedOfChange.score"
        >
          {{ speedOfChange.level }}
        </p>
        <p
          class="margin-bottom-5"
          style="
            font-style: normal;
            font-size: 12px;
            line-height: 21px;
            color: #777;
          "
        >
          {{ speedOfChange.text }}
        </p>
      </div>

      <div class="row d-lg-none d-md-none margin-top-20 padding-10">
        <div class="col-12">
          <p class="padding-left-5">Speed of Change</p>
        </div>
        <div class="col-12">
          <p class="padding-left-5 mb-0">
            <img
              [src]="
                '../../../../assets/dashboard/score_change/' +
                speedOfChange.score +
                '.png'
              "
              width="100%"
              id="speed-of-change-image"
            />
          </p>
          <p
            class="padding-left-5 margin-bottom-5 mt-0"
            [class]="'degree_of_change_' + speedOfChange.score"
          >
            {{ speedOfChange.level }}
          </p>
        </div>
        <div class="col-12">
          <p class="no-margin-bottom">{{ speedOfChange.text }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-1 d-md-none d-lg-none"></div>
  <div class="col-12 d-md-none d-lg-none">
    <hr class="score-details-divider" />
  </div>
  <div class="col-1 d-md-none d-lg-none"></div>

  <div class="col-12 d-md-none d-lg-none">
    <p
      class="sub-heading-text mb-2"
      style="margin-top: 27px; margin-bottom: 15px"
    >
      Personal Reference Range Colors
    </p>

    <table
      style="
        color: var(--Secondary-Black-600, #777);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      "
    >
      <tr *ngFor="let referenceRangeColor of referenceRangeColors">
        <td>
          <div
            [ngClass]="'score-board-bullet ' + referenceRangeColor.colorClass"
          ></div>
        </td>
        <td
          class="donout-info-text"
          style="padding-bottom: 7px; padding-left: 10px"
        >
          {{ referenceRangeColor.text }}
        </td>
      </tr>
    </table>

    <p
      class="sub-heading-text mb-2"
      style="margin-top: 27px; margin-bottom: 15px"
    >
      Trend Indicators
    </p>

    <table
      style="
        color: var(--Secondary-Black-600, #777);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 27px;
      "
    >
      <tbody>
        <tr
          *ngFor="let row of trendsIcons"
          style="margin-bottom: 10px !important"
        >
          <td>
            <div class="circle" [innerHTML]="row.sanitizedContent"></div>
          </td>
          <td
            class="donout-info-text"
            style="padding-bottom: 4px; padding-left: 10px"
          >
            {{ row.text }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    *ngIf="!selectedResultSet"
    class="d-flex align-items-center justify-content-center text-center w-100 px-3"
    style="min-height: 300px"
  >
    <h6>No Health Data Found</h6>
  </div>
</div>
