import { Component, OnInit } from '@angular/core';
import { config } from '../../config';

@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent implements OnInit {
  config = config;

  constructor() {}

  ngOnInit() {}
}
