import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from './../../config';

declare var Pace: any;

@Injectable({
  providedIn: 'root',
})
export class GptService {
  gptBaseUrl: any = config.baseApiv2Url;

  constructor(public http: HttpClient) {}

  getGptTypes() {
    return this.http.get(`${this.gptBaseUrl}/types`);
  }

  getNewRequest(data) {
    let prams: String = '';
    if (data.requester_id) prams = prams + `?requester_id=${data.requester_id}`;
    if (data.subscriber_id)
      prams = prams + `&subscriber_id=${data.subscriber_id}`;
    if (data.type_id) prams = prams + `&type_id=${data.type_id}`;
    if (data.health_id) prams = prams + `&health_id=${data.health_id}`;

    return this.http.get(`${this.gptBaseUrl}/request${prams}`);
  }

  getPrompt(data) {
    let prams: String = '';
    if (data.request_id) prams = prams + `?request_id=${data.request_id}`;
    if (data.requester_id) prams = prams + `&requester_id=${data.requester_id}`;
    if (data.subscriber_id)
      prams = prams + `&subscriber_id=${data.subscriber_id}`;
    prams = prams + `&real_time=${data.real_time}`;

    return this.http.get(`${this.gptBaseUrl}/prompt${prams}`);
  }

  updatePrompt(data) {
    return this.http.patch(`${this.gptBaseUrl}/prompt`, data);
  }

  getSuggestions(data) {
    let prams = '';

    if (data.requester_id) prams = prams + `?requester_id=${data.requester_id}`;
    if (data.subscriber_id)
      prams = prams + `&subscriber_id=${data.subscriber_id}`;
    if (data.request_id) prams = prams + `&request_id=${data.request_id}`;
    // if (data.real_time) prams = prams + `&real_time=${data.real_time}`
    prams = prams + `&real_time=${data.real_time}`;

    return this.http.get(`${this.gptBaseUrl}/suggestions${prams}`);
  }

  postSubmission(data) {
    return this.http.post(`${this.gptBaseUrl}/submission`, data);
  }

  getFormsSubscriber(pageNo, currentSort) {
    return this.http.get(
      `${this.gptBaseUrl}/forms/subscriber/${
        JSON.parse(localStorage.getItem('patient')).UUID
      }?page=${pageNo}&sort_order=${currentSort.sort_order}&sort_by=${
        currentSort.sort_by
      }&per_page=7`
    );
  }

  getForms(pageNo, currentSort) {
    return this.http.get(
      `${this.gptBaseUrl}/forms/provider/${
        JSON.parse(localStorage.getItem('loggedInUser')).UUID
      }?page=${pageNo}&sort_order=${currentSort.sort_order}&sort_by=${
        currentSort.sort_by
      }&per_page=7`
    );
  }

  postAnswer(data) {
    return this.http.post(`${this.gptBaseUrl}/answer`, data);
  }

  deleteResponse(request_id, deleted_by) {
    // {{url}}/algocytegpt/v1/answer?request_id=7305872a-4249-11ee-b4f6-acde48001122&deleted_by=0ba35991-d6eb-4ee0-adfb-ba01cde8bbdb
    return this.http.delete(
      `${this.gptBaseUrl}/answer?request_id=${request_id}&deleted_by=${deleted_by}`
    );
  }

  postTemplate(request_id, data) {
    return this.http.post(
      `${this.gptBaseUrl}/template?designer_id=${request_id}`,
      data
    );
  }

  getTemplates(pageNo, currentSort) {
    return this.http.get(
      `${this.gptBaseUrl}/template?designer_id=${
        JSON.parse(localStorage.getItem('loggedInUser')).UUID
      }`
    );
  }
}
