<div>
  <h2 class="title-text">
    <i class="fa fa-user-md mr-1"></i
    >{{ 'profilePage.clinicianInformation' | translate }}
  </h2>

  <hr />

  <app-provider-form-item
    *ngFor="let form of forms.controls; let i = index"
    [index]="i"
    [total]="forms.controls?.length"
    [form]="getFormGroup(form)"
    [organisationOptions]="userOrganisationOptions"
    [isPendingProvider]="isPendingProvider(form.value)"
    (onRemoveLink)="handleRemoveLink(i, form.value)"
  ></app-provider-form-item>

  <div class="d-flex form-group mt-2 align-items-center" *ngIf="canUpdate">
    <button
      id="addNewProvider"
      type="button"
      class="btn mr-2"
      style="background-color: #00978d; color: #fff"
      [disabled]="this.roleService?.isProvider || !userData.isProfileComplete"
      (click)="onAddNew()"
    >
      {{ 'profilePage.addNew' | translate }}
    </button>

    <button
      id="submitClinicianDetail"
      type="button"
      class="btn mr-3"
      style="background-color: #00978d; color: #fff"
      [disabled]="isSaveDisabled() || this.roleService.isProvider"
      (click)="onSave()"
    >
      {{ 'common.saveBtnText' | translate }}
    </button>

    <mat-spinner *ngIf="isSavingProviders" diameter="20"></mat-spinner>
  </div>
</div>
