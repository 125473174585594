import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { AppMaterialModules } from '../modules/shared/angular-material.module';
import { CachedHttpTranslationLoader } from '../modules/shared/cachedTranslateLoader';
import { ReportComponent } from './report/report.component';
import { TabularComponent } from './report/tabular/tabular.component';
import { IndividualComponent } from './report/individual/individual.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartModule } from '../utility/chart/chart.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from 'ng-pick-datetime-ex';
import { SurveyCreatorModule } from 'survey-creator-angular';
import { SurveyModule } from 'survey-angular-ui';

import { NotificationsModule } from '../utility/notifications/notifications.module';
import { SurveyCreatorComponent } from './survey-creator/survey-creator.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { TagModalComponent } from './report/tag-modal/tag-modal.component';
import { ScoreDetailsComponent } from './dashboard/score-details/score-details.component';
import { AnalytesGraphComponent } from './dashboard/analytes-graph/analytes-graph.component';
import { HistoricTestResultsComponent } from './dashboard/historic-test-results/historic-test-results.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ScoresOverviewComponent } from './dashboard/scores-overview/scores-overview.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ScoreOverTimeComponent } from './dashboard/score-over-time/score-over-time.component';
import { ScoreTrendsComponent } from './dashboard/score-trends/score-trends.component';
import { PopoverModule } from 'ngx-smart-popover';

@NgModule({
  declarations: [
    ReportComponent,
    TabularComponent,
    IndividualComponent,
    SurveyCreatorComponent,
    QuestionnaireComponent,
    TagModalComponent,
    ScoreDetailsComponent,
    AnalytesGraphComponent,
    HistoricTestResultsComponent,
    ScoresOverviewComponent,
    ScoreOverTimeComponent,
    ScoreTrendsComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ChartModule,
    NotificationsModule,
    NgxUiLoaderModule,
    BrowserAnimationsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModules,
    SurveyCreatorModule,
    SurveyModule,
    MatTableExporterModule,
    SlickCarouselModule,
    PopoverModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: CachedHttpTranslationLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
      useDefaultLang: true,
      isolate: true,
    }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    ScoresOverviewComponent,
    ScoreOverTimeComponent,
    ScoreTrendsComponent,
  ],
})
export class ReportModule {}
