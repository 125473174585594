<ngx-ui-loader></ngx-ui-loader>
<div class="container py-3">
  <div class="form-row">
    <div class="demographic-info col-12 pb-3">
      <h2 class="font-weight-bolder">
        {{'manageTestProfile.manageTestProfiles'| translate }}
      </h2>
    </div>
    <!-- <div class="col-12 col-md-6">
      <h4>
        Test profiles
      </h4>
      <hr>
      <div class="row justify-content-start">
        <button mat-icon-button class="mr-3" [disabled]="!selectedProfile"
          (click)="deleteProfile(selectedProfile.id)"><i class="fa-2x far fa-trash-alt"></i></button>
        <button mat-icon-button class="mr-3" [disabled]="!selectedProfile" (click)="editProfie()"><i
            class="fa-2x far fa-edit"></i></button>
        <button mat-icon-button (click)="addProfile()"><i class="fa-2x far fa-plus-square"></i></button>
      </div>
      <div class="row text-left">
        <mat-radio-group class="example-radio-group" [value]="selectedProfile.id"
          (change)="radoiSelectionChanged($event)">
          <mat-radio-button class="example-radio-button" *ngFor="let profile of profiles" [value]="profile.id">
            {{profile.viewValue}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h4>
        Test Profile: <input type="text" maxlength="24" [(ngModel)]="selectedProfile.viewValue" [readonly]="!isEditOrAdd"
          class="border-0 p-0" style="border-bottom: 1px solid black !important;">
      </h4>
      <hr>
      <div class="row text-left" *ngIf="allAnalytes.length">
        <h6>Select Analytes</h6>
        <mat-selection-list [(ngModel)]="selectedProfile.analytes" style="display: flex; flex-wrap: wrap; width: 100%;">
          <mat-list-option class="col-6 col-md-3" [checkboxPosition]="before" [value]="analyte.ANALYTE_KEY"
            [disabled]="!isEditOrAdd" *ngFor="let analyte of allAnalytes">
            {{analyte.ANALYTE_KEY}}
          </mat-list-option>
        </mat-selection-list>
      </div>
      <div class="row">
        <button class="btn btn-success mr-1" [hidden]="!isEditOrAdd" (click)="saveOp()"
          [disabled]="(selectedProfile.viewValue === '' || selectedProfile.analytes.length <= 0) || compareFn()">Save</button>
        <button class="btn btn-danger" [hidden]="!isEditOrAdd" (click)="cancleOp()">Cancel</button>
      </div>
    </div> -->

    <div class="col-12 col-md-3">
      <div class="section-left">
        <div class="p-2 position-relative">
          <input type="text" class="search-input w-100" placeholder="{{'manageTestProfile.search'| translate }}"
            (keyup)="applyFilter($event)">
          <i class="fa fa-search icon-search" aria-hidden="true"></i>
        </div>
        <button mat-raised-button class="create-button" (click)="addProfile()">
          <i class="fa fa-plus mr-2" aria-hidden="true"></i>
          {{'manageTestProfile.createNewProfile'| translate }}
        </button>
        <p class="text-capitalize profile" *ngIf="profiles && profiles.length">{{'manageTestProfile.profile'| translate
          }} ({{profiles.length}})</p>
        <mat-list role="list" *ngIf="profiles && profiles.length">
          <div class="position-relative"
            *ngFor="let profile of profiles | myfilter: 'viewValue' : filterargs; let i = index;">
            <mat-list-item [ngClass]="{'profile-selected': selectedProfile.id == profile.id}" role="listitem"
              (click)="demo(profile.id, i)">{{profile.viewValue}}
            </mat-list-item>
            <!-- <span (click)="deleteProfile(profile.id)">
              <i class="fa fa-trash trash-profile position-absolute" aria-hidden="true"></i>
            </span> -->
          </div>
        </mat-list>
      </div>
    </div>

    <div class="col-12 col-md-9">
      <div class="section-right">
        <div class="container">
          <div class="row pt-5 px-3">
            <div class="col-12 col-md-6">
              <form>
                <fieldset class="border ">
                  <legend class="w-auto ">
                    {{'manageTestProfile.createNewProfile'| translate }}
                  </legend>
                  <input class="w-100 input-legend" maxlength="24" [(ngModel)]="selectedProfile.viewValue"
                    [ngModelOptions]="{standalone: true}" [disabled]="!isEditMode"
                    placeholder="{{'manageTestProfile.createNewProfile'| translate }}" type="text">
                </fieldset>
              </form>
            </div>
            <div class="col-12 col-md-6 text-right">
              <button class="h-100 mr-3 custom" mat-stroked-button *ngIf="isAdding || isEditing"
                [disabled]="selectedProfile.viewValue === '' || selectedProfile.analytes.length === 0"
                (click)="saveOp()">
                <i class="fas fa-save mr-1"></i>
                <b>
                  {{'manageTestProfile.save'| translate }}
                </b>
              </button>
              <button class="h-100 mr-3 custom" mat-stroked-button
                [disabled]="selectedProfile.viewValue === '' || selectedProfile.analytes.length === 0"
                (click)="enableEditing();" *ngIf="isProfileSelected && !isAdding && !isEditing">
                <i class="fas fa-edit mr-1"></i>
                <b>
                  {{'manageTestProfile.edit'| translate }}
                </b>
              </button>
              <button class="h-100 mr-3" *ngIf="isEditing ||  isAdding" (click)="cancelChanges()" mat-stroked-button>
                <i class="fa fa-times mr-1 " style="color: #686868;" aria-hidden="true"></i>
                <b>
                  {{'manageTestProfile.discard'| translate }}
                </b>
              </button>
              <button class="h-100" mat-stroked-button *ngIf="isProfileSelected && !isAdding && !isEditing"
                (click)="deleteProfile(selectedProfile.id)">
                <i class="fa fa-trash " aria-hidden="true"></i>
                <b>
                  {{'manageTestProfile.delete'| translate }}
                </b>
              </button>
            </div>
          </div>
          <div class="row py-3 px-3">
            <div class="col-12">
              <mat-accordion class="example-headers-align">
                <mat-expansion-panel class="expansion-panel" *ngFor="let group of allGroups; let i = index">
                  <mat-expansion-panel-header class="expansion-panel-header">
                    <mat-checkbox [disabled]="!isEditMode" [checked]="group.selected"
                      [indeterminate]="group.indeterminate" (change)="markAllAnalytics($event,i, group.AID)"
                      (click)="$event.stopPropagation(); " id="group{{i}}">
                      {{group.ASTRING_2}}
                    </mat-checkbox>
                  </mat-expansion-panel-header>
                  <mat-list role="list">
                    <ng-container *ngFor="let analyte of allAnalytes">
                      <mat-list-item role="listitem" *ngIf="analyte.ANALYTE_GROUP_ID == group.AID">
                        <mat-checkbox [disabled]="!isEditMode" [checked]="getCheckedValue(analyte.ANALYTE_KEY)"
                          (change)="analyteChange(group.ASTRING_2, analyte, $event, i, group.AID)">
                          {{analyte.ANALYTE_KEY}}
                        </mat-checkbox>
                      </mat-list-item>
                    </ng-container>
                  </mat-list>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>