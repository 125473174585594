import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { commonConfig } from "src/app/config/common/common";
import { AuthService } from "src/app/services/auth/auth.service";
import { config } from "../../../config";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent {
  form = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      Validators.pattern(commonConfig.pattern.email),
    ]),
  });

  error: string;
  config: any = config;
  successMessage: string;

  constructor(
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private authService: AuthService
  ) { }

  goTo(route) {
    this.router.navigate([route]);
  }

  async onSubmit() {
    this.ngxService.start();
    try {
      if (this.isFormValid()) {
        const result = await this.authService.forgotPassword(this.form.value.email)
        this.successMessage = result && result['message'] ? result['message'] : ""
        this.ngxService.stop()
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  isFormValid(): boolean {
    return this.form.valid;
  }

  handleError(error) {
    this.ngxService.stop();
    this.error = error.message
  }

}