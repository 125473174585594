import { environment } from '../environments/environment';

import { registeredDomains } from './config/common/domains';

import { registeredRegion } from './config/common/regions';
import { featureFlags } from './config/common/featureFlags';

const requestedOrigin = window.location.origin;
const selectedDomain = registeredDomains.find(
  (o) => o['name'] == requestedOrigin
);
const region = selectedDomain ? selectedDomain['region'] : 'UK';
const regionConfig = registeredRegion.find((o) => o['name'] == region);
const env = selectedDomain ? selectedDomain['dev_name'] : 'DEV-UK';

let UUID = 'test';
let token = 'test';

export const updateConfigData = (data) => {
  if (data) {
    UUID = data.user.username;
    token = data.user.accessToken;
  }
};

export const config = {
  appVersion: '1.8.1',
  appName: 'Algocyte',
  baseApiv2Url: environment.baseUrl,
  UUID,
  token,
  sentryUrl: 'https://o921639.ingest.sentry.io/5868202',
  regionConfig: regionConfig.config,
  featureFlags: featureFlags,
  env: env,
  firebaseConfig: {
    apiKey: environment.firebaseConfig.apiKey,
    authDomain: environment.firebaseConfig.authDomain,
    projectId: environment.firebaseConfig.projectId,
    storageBucket: environment.firebaseConfig.storageBucket,
    messagingSenderId: environment.firebaseConfig.messagingSenderId,
    appId: environment.firebaseConfig.appId,
  },
  termsAndConditionsLink:
    'http://static.algocyte.com/UK/Patient-Terms-and-Conditions.html',
  privacyPolicyLink: 'http://static.algocyte.com/UK/privacy-policy.html',
  cookiePolicyLink: '/assets/policies/cookie-policy.html',

};
