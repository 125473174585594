<div class="col-12 no-padding section-border-background dashboard-section-2">
  <div class="form-row justify-content-between">
    <div class="col-10 col-md-11 justify-content-start">
      <div class="padding-25">
        <span class="title-score">Your Test Score Over Time</span>
      </div>
    </div>
    <div
      id="score-over-time-menu-button"
      *ngIf="!hasNoData()"
      class="col-2 col-md-1"
    >
      <button
        style="float: right"
        mat-icon-button
        [matMenuTriggerFor]="menu2"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu2="matMenu" xPosition="before">
        <button mat-menu-item (click)="navigateToEvents()">
          <mat-icon class="material-symbols-outline">event_list</mat-icon>
          <span>Events</span>
        </button>
        <button mat-menu-item (click)="navigateToGraph()">
          <mat-icon>insert_chart</mat-icon>
          <span>Trends Graph</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <div id="score-over-time-chart" class="form-row no-padding no-margin">
    <app-line
      *ngIf="!hasNoData()"
      class="col-12 no-padding"
      [lineData]="chartData"
    ></app-line>

    <div *ngIf="hasNoData()" class="col-12 p-5">
      <h4 class="text-center">No Health Data Found</h4>
    </div>
  </div>
</div>
