<div class="form-row margin-top-20 dashboard-section-3">
  <app-mix class="col-12 px-0" [mixedData]="chartData"> </app-mix>
</div>

<div *ngIf="isLoading" class="margin-top-20 dashboard-section-3">
  <div class="col-12 section-border-background px-4 pb-3 pt-3">
    <ngx-skeleton-loader
      count="1"
      appearance="circle"
      [theme]="{
        width: '100%',
        height: '350px',
        'border-radius': '10px',
        margin: '5px 0px'
      }"
    >
    </ngx-skeleton-loader>
  </div>
</div>
