import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TestService } from "../../services/test.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-smart-report",
  templateUrl: "./smart-report.component.html",
  styleUrls: ["./smart-report.component.css"],
})
export class SmartReportComponent implements OnInit {
  // @Output() updateGuestUser = new EventEmitter<boolean>();
  identifier: any;
  codeVerificationForm = new FormGroup({
    code: new FormControl("", [Validators.required]),
    tandc: new FormControl("", [
      (control) => {
        return !control.value ? { required: true } : null;
      },
    ]),
  });
  constructor(
    private testService: TestService,
    private route: ActivatedRoute,
    private ngxUiService: NgxUiLoaderService,
    private translate: TranslateService
  ) {
    route.queryParams.subscribe((res) => {
      this.identifier = res.identifier;
    });
  }

  ngOnInit() { }

  verifyCode() {
    if (this.codeVerificationForm.valid) {
      let formData = new FormData();
      formData.append("identifier", this.identifier);
      formData.append("access_token", this.codeVerificationForm.value.code);
      formData.append("tandc", this.codeVerificationForm.value.tandc);
      formData.append("validate_only", "1");
      this
        .testService
        .validateGuestAndGetData(formData).subscribe(
          (res: any) => {
            if (res.success) {
              localStorage.setItem("identifier", this.identifier);
              localStorage.setItem(
                "access_token",
                this.codeVerificationForm.value.code
              );
              localStorage.setItem("roleId", JSON.stringify(4001));
            }
          },
          (err) => {
            console.log("Error in validating", err);
          });
    }
  }
}
