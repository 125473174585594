import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import Chart from 'chart.js/auto';
import { BarWithErrorBarsController } from 'chartjs-chart-error-bars';
import annotationPlugin from 'chartjs-plugin-annotation';
import { commonConfig } from '../../../config/common/common';
import { TestService } from '../../../services/test.service';
import { BarWithErrorBar } from 'chartjs-chart-error-bars';

Chart.register(BarWithErrorBarsController, annotationPlugin, BarWithErrorBar);

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.css'],
})
export class BarComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() barData: any;
  adaptiveView = JSON.parse(localStorage.getItem('adaptiveView')) || false;
  isGuestUser = JSON.parse(localStorage.getItem('roleId')) === 4001 || false;
  barChart: any = {};
  isHorizontal = false;
  allRanges = {};
  greenColor = commonConfig.colors.green;
  pinkColor = commonConfig.colors.pink;
  yellowColor = commonConfig.colors.yellow;
  whiteColorOp = 'rgba(255,255,255, 0)';
  chartId: string;
  fullResponse: any;
  analyteLength: any;

  trendsObject: any = {};
  covidHight: number = 25000;
  score: any;
  adaptive_score: any;

  constructor(private _testService: TestService) { }

  ngOnInit() {
    this.chartId = this.barData.chartId;
    this.score = this.barData.score;
    this.adaptive_score = this.barData.adaptive_score;
    this.allRanges = this.barData.allRanges;
    this.isHorizontal = this.barData.isHorizontal;
    this.adaptiveView = this.barData.adaptiveView;

    this.score.availableAnalytes.map((d) => {
      if (
        this.score['rangeData']['red']['trend'] &&
        d in this.score['rangeData']['red']['trend']
      ) {
        this.trendsObject[d] = {
          color: 'pink',
          direction: this.score['rangeData']['red']['trend'][d],
        };
      } else if (
        this.score['rangeData']['yellow']['trend'] &&
        d in this.score['rangeData']['yellow']['trend']
      ) {
        this.trendsObject[d] = {
          color: 'yellow',
          direction: this.score['rangeData']['yellow']['trend'][d],
        };
      } else {
        this.trendsObject[d] = {
          color: 'green',
          direction: this.score['rangeData']['green']['trend'][d],
        };
      }
    });
  }

  ngAfterViewInit() {
    let element = document.getElementById(this.chartId);

    if (element !== null) {
      element.innerHTML = '';
    }
    const { labels, values, lows, highs } = this.barData;

    if (element !== null) element = null;
    this.analyteLength = labels.length;

    if (this.analyteLength > 14 && element !== null) {
      element.style.justifyContent = 'flex-start';
    }
    this.renderMixBarChart(labels, values, lows, highs);
  }

  ngOnChanges() {
    this.chartId = this.barData.chartId;
    this.fullResponse = this.barData.data;
    this.allRanges = this.barData.allRanges;
    this.isHorizontal = this.barData.isHorizontal;
    this.adaptiveView = this.barData.adaptiveView;
    this.score = this.barData.score;

    const { labels, values, lows, highs } = this.barData;
    const element = document.getElementById(this.chartId);

    if (element !== null) {
      while (element.hasChildNodes()) {
        element.removeChild(element.firstChild);
      }
    }

    this.analyteLength = labels.length;

    if (this.analyteLength > 14 && element !== null) {
      element.style.justifyContent = 'flex-start';
    }

    this.fullResponse.analytes.map((d) => {
      if (d in this.score.rangeData.green.trend)
        this.trendsObject[d] = {
          color: 'green',
          direction: this.score.rangeData.green.trend[d],
        };
      if (d in this.score.rangeData.yellow.trend)
        this.trendsObject[d] = {
          color: 'yellow',
          direction: this.score.rangeData.yellow.trend[d],
        };
      if (d in this.score.rangeData.red.trend)
        this.trendsObject[d] = {
          color: 'pink',
          direction: this.score.rangeData.red.trend[d],
        };
    });

    this.renderMixBarChart(labels, values, lows, highs);
  }

  // getColorCode Bars :: start
  getBarColor(label: string) {
    if (
      this.score.rangeData.red &&
      this.score.rangeData.red.value &&
      Object.keys(this.score.rangeData.red.value).indexOf(label) !== -1
    ) {
      return this.pinkColor;
    } else if (
      this.score.rangeData.yellow &&
      this.score.rangeData.yellow.value &&
      Object.keys(this.score.rangeData.yellow.value).indexOf(label) !== -1
    ) {
      return this.yellowColor;
    } else return this.greenColor;
  }
  // getColorCode Bars :: end

  //get Trends Details :: start
  getTrendColor(analyte) {
    return this.trendsObject &&
      this.trendsObject[analyte] &&
      this.trendsObject[analyte].color
      ? this.trendsObject[analyte].color
      : null;
  }

  getTrendDirection(analyte) {
    return this.trendsObject &&
      this.trendsObject[analyte] &&
      this.trendsObject[analyte].direction
      ? this.trendsObject[analyte].direction.toLowerCase()
      : null;
  }
  //get Trends Details :: end

  // get Y axis Max val of bargraph ::start
  getYAxesMax(label) {
    if (label !== 'COVS') {
      let min = parseFloat(
        this.allRanges['referenceRange'][label].split('-')[0].trim()
      );
      let max = parseFloat(
        this.allRanges['referenceRange'][label].split('-')[1].trim()
      );
      switch (this.getBarColor(label)) {
        case this.greenColor:
          return max;
        case this.yellowColor:
          return max;
        case this.pinkColor:
          const direction = this.findClosestValueDirection(
            this.score['rangeData']['red']['value'][label],
            max,
            min,
            'red',
            label
          );
          return direction === 'top'
            ? this.score['rangeData']['red']['value'][label]
            : max;
        default:
          break;
      }
    } else {
      return this.covidHight;
    }
  }
  // get Y axis Max val of bargraph ::end

  // get Y axis Max val of bargraph ::start
  getYAxesMin(label) {
    if (label !== 'COVS') {
      let min = parseFloat(
        this.allRanges['referenceRange'][label].split('-')[0].trim()
      );
      let max = parseFloat(
        this.allRanges['referenceRange'][label].split('-')[1].trim()
      );

      switch (this.getBarColor(label)) {
        case this.greenColor:
          return min;
        case this.yellowColor:
          return min;
        case this.pinkColor:
          const direction = this.findClosestValueDirection(
            this.score['rangeData']['red']['value'][label],
            max,
            min,
            'red',
            label
          );
          return direction !== 'top'
            ? this.score['rangeData']['red']['value'][label]
            : min;
        default:
          break;
      }
    } else {
      return 0;
    }
  }

  getMinRange(label) {
    let min = parseFloat(
      this.allRanges['referenceRange'][label].split('-')[0].trim()
    );
    const color = this.getBarColor(label);
    if (color === this.greenColor) {
      return min;
    } else if (color === this.yellowColor) {
      return min;
    } else if (color === this.pinkColor) {
      return min;
    }
  }

  // getErrorBars :: start
  getErrorBars(values, label, ranges) {
    let min = parseFloat(ranges['referenceRange'][label].split('-')[0].trim());
    let max = parseFloat(ranges['referenceRange'][label].split('-')[1].trim());

    const errorBars = {
      backgroundColor: this.whiteColorOp,
      borderColor: this.whiteColorOp,
      borderWidth: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      data: [
        {
          y: values,
          yMin: min,
          yMax: max,
        },
      ],
      errorBars: {
        // Customize the cap size
        capSize: 25,
      },
    };

    return errorBars;
  }
  // getErrorBars :: end

  getTrendPoint(values, image) {
    const trendPoint = {
      pointRadius: 2,
      pointStyle: [image],
      data: [values],
      type: 'line',
    };
    return trendPoint;
  }

  getTrendIcon(label, borderColor) {
    return `../../assets/dashboard/2x_${borderColor}b_${this.getTrendDirection(label)[0]
      }_${this.getTrendColor(label)[0]}.png`;
  }

  async renderMixBarChart(
    labels: string[],
    values: number,
    lows: Record<string, number>,
    highs: Record<string, number>
  ) {
    const div = document.createElement('div');
    div.id = 'legend';

    const element = document.getElementById(this.chartId);

    if (!element) {
      return;
    }

    const containerWidth = element.offsetWidth;

    div.style.maxWidth = !this.isHorizontal
      ? containerWidth / labels.length + 'px'
      : '100%';

    div.style.display = 'flex';
    div.style['-ms-flex-align'] = 'center';
    element.append(div);

    await Promise.all(labels.map(async (label, index) => {
      const innerDiv = document.createElement('canvas');
      innerDiv.id = label;
      innerDiv.style.maxHeight = '345px';
      innerDiv.style.height = '280px';
      innerDiv.style.maxWidth = (this.isGuestUser ? 80 : 94) + 'px';

      label === 'COVS'
        ? element.prepend(innerDiv)
        : index == 0
          ? element.prepend(innerDiv)
          : element.append(innerDiv);

      let dSet = [];

      dSet = await this.labelWiseBarDataSet(label, values[index]);

      if (this.barChart[label]) this.barChart[label].destroy();

      this.displayChart(label, index, dSet, labels, values, lows, highs);
    }));
  }

  async labelWiseBarDataSet(label: string, value: number) {
    let image = new Image();
    const labelBarColor = this.getBarColor(label);

    switch (labelBarColor) {
      case this.greenColor:
        image.src = this.getTrendIcon(label, 'g');
        break;
      case this.yellowColor:
        image.src = this.getTrendIcon(label, 'y');
        break;
      case this.pinkColor:
        image.src = this.getTrendIcon(label, 'p');
        break;
      default:
        break;
    }
    const chartArrayDetails = this.getBarChartData(
      value,
      labelBarColor,
      image,
      label
    );

    return chartArrayDetails;
  }

  async getBarChartData(
    value: number,
    labelBarColor: string,
    image: any,
    label: string
  ) {
    let min = parseFloat(
      this.allRanges['referenceRange'][label].split('-')[0].trim()
    );
    let max = parseFloat(
      this.allRanges['referenceRange'][label].split('-')[1].trim()
    );
    let chartArray = [];
    let direction;

    switch (labelBarColor) {
      case this.greenColor:
        chartArray.push(
          this.getTrendPoint(value, image),
          this.getErrorBars(value, label, this.allRanges),
          this.completeGreenBar('white', label),
          this.completeGreenBar('green', label)
        );
        break;
      case this.yellowColor:
        direction = this.findClosestValueDirection(
          value,
          max,
          min,
          'yellow',
          label
        );
        if (direction == 'top') {
          chartArray.push(
            this.getTrendPoint(value, image),
            this.getErrorBars(value, label, this.allRanges),
            this.completeYellowBar('green', label, 'top'),
            this.completeYellowBar('yellow', label, 'top'),
            this.completeYellowBar('white', label, 'top')
          );
        } else {
          chartArray.push(
            this.getTrendPoint(value, image),
            this.getErrorBars(value, label, this.allRanges),
            this.completeYellowBar('white', label, 'bottom'),
            this.completeYellowBar('yellow', label, 'bottom'),
            this.completeYellowBar('green', label, 'bottom')
          );
        }
        break;
      case this.pinkColor:
        direction = this.findClosestValueDirection(
          value,
          max,
          min,
          'red',
          label
        );

        if (direction == 'top') {
          chartArray.push(
            this.getTrendPoint(value, image),
            this.getErrorBars(value, label, this.allRanges),
            {
              backgroundColor: this.whiteColorOp,
              borderColor: this.whiteColorOp,
              hoverBackgroundColor: this.whiteColorOp,
              hoverBorderColor: this.whiteColorOp,
              barPercentage: 0.40000000001,
              barThickness: 14,
              borderWidth: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              data: [this.score['rangeData']['green']['low'][label]],
              type: 'bar',
            },
            this.completePinkBar('green', label, 'top'),
            this.completePinkBar('yellow', label, 'top'),
            this.completePinkBar('pink', label, 'top')
          );
          break;
        } else {
          chartArray.push(
            this.getTrendPoint(value, image),
            this.getErrorBars(value, label, this.allRanges),
            this.completePinkBar('pink', label, 'bottom'),
            this.completePinkBar('yellow', label, 'bottom'),
            this.completePinkBar('green', label, 'bottom')
          );
          break;
        }
      default:
        break;
    }
    return chartArray;
  }

  async displayChart(label, index, dSet, labels, values, lows, highs) {
    const customTooltips = (e) => {
      try {
        const tooltip = e.tooltip;
        const labelTooltip = tooltip.title[0].replaceAll('"', '').split(',')[0];
        // Tooltip Element
        let tooltipEl = document.getElementById('barChart-tooltip');

        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'barChart-tooltip';
          tooltipEl.style.padding = '12px';
          tooltipEl.innerHTML = '<table></table>';
          this.barChart[labelTooltip].canvas.parentNode.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0 as any;
          return;
        }

        tooltip.yAlign = 'left';
        if (tooltip.yAlign) tooltipEl.classList.add(tooltip.yAlign);
        else tooltipEl.classList.add('no-transform');

        // Set Text
        if (tooltip.body) {
          Promise.all([
            this._testService.getTranslation('dashboard.actualCount'),
            this._testService.getTranslation('dashboard.adaptiveHigh'),
            this._testService.getTranslation('dashboard.adaptiveLow'),
            this._testService.getTranslation('dashboard.sdValue'),
            this._testService.getTranslation('dashboard.range'),
            this._testService.getTranslation('dashboard.range'),
          ]).then((res) => {
            let innerHtml = '<thead>';
            // When only for the FBC profile
            if (labelTooltip !== 'COVS') {
              // based on the trend direction
              innerHtml += `<tr style='padding-bottom:9px;text-align:start;'><th><strong>
                ${this.score.analyteMapping[labelTooltip]} (${labelTooltip}) </strong> </th></tr>`;

              if (
                this.trendsObject &&
                this.trendsObject[labelTooltip] &&
                this.trendsObject[labelTooltip]['direction'] &&
                this.trendsObject[labelTooltip]['color']
              ) {
                let barColor = this.getBarColor(labelTooltip);
                let borderColor;
                if (barColor == this.greenColor) borderColor = 'g';
                else if (barColor == this.yellowColor) borderColor = 'y';
                else if (barColor == this.pinkColor) borderColor = 'p';

                // Create Icon based on the trend values
                let icon = `./../../assets/dashboard/2x_${borderColor}b_${this.trendsObject[
                  labelTooltip
                ]['direction'][0].toLowerCase()}_${this.trendsObject[labelTooltip]['color'][0]
                  }.png`;

                // Check the current value
                innerHtml += `<tr style='padding-bottom:9px;text-align:start;'><td> <img src="${icon}"> Current Value: ${Number(
                  values[labels.indexOf(labelTooltip)]
                ).toFixed(2)} </span> </td></tr>`;
              }
            } else {
              innerHtml +=
                "<tr style='padding-bottom:9px;'><th><strong>" +
                this.score.analyteMapping[labelTooltip] +
                '</strong> </th></tr>';
            }

            innerHtml += '</thead><tbody>';

            if (
              this.score.on_analyte_hover_details &&
              this.score.on_analyte_hover_details[labelTooltip]
                .analyte_result_human_readable_analysis
            ) {
              innerHtml +=
                "<tr><td style='text-align:left;'><strong>" +
                this.score.on_analyte_hover_details[labelTooltip]
                  .analyte_result_human_readable_analysis +
                '</strong> </td></tr>';
            } else {
              innerHtml += `<tr><td style='text-align:left;'><strong> Current Score ${Number(
                values[labels.indexOf(labelTooltip)]
              ).toFixed(2)} </strong> </td></tr>`;
            }

            innerHtml += this.isGuestUser
              ? `<tr><td>${res[4]}: ` +
              Number(lows[labels.indexOf(labelTooltip)]) +
              ' - ' +
              Number(highs[labels.indexOf(labelTooltip)]) +
              '</td>'
              : `<tr><td style="text-align:start;">Lab Range: ` +
              this.allRanges['referenceRange'][labelTooltip] +
              ' ' +
              this.allRanges['referenceUnit'][labelTooltip] +
              '</td>';
            innerHtml += '</tbody>';

            const tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
          });
        }

        const positionY = this.barChart[labelTooltip].canvas.offsetTop - 35;
        const positionX = this.barChart[labelTooltip].canvas.offsetLeft + 80;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1 as any;
        tooltipEl.style.zIndex = 1000 as any;
        tooltipEl.style.width = 'fit-content';
        tooltipEl.style.display = 'table';
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';

        if (-tooltipEl.offsetHeight + tooltip.caretY < 0)
          tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        else
          tooltipEl.style.top = -tooltipEl.offsetHeight + tooltip.caretY + 'px';

        const canvasHeight1 = document.getElementById(labelTooltip);

        tooltipEl.style.fontFamily = 'Open-Sans';
        tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
        tooltipEl.style.padding =
          tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
        return tooltip;
      } catch (error) {
        console.error(' ERROR check here', error);
      }
    };

    if (label !== 'COVS') {
      this.barChart[label] = new Chart(
        (document.getElementById(label) as HTMLCanvasElement).getContext('2d'),
        {
          type: BarWithErrorBarsController.id,
          data: {
            labels: [[label, this.allRanges['referenceUnit'][label]]],
            datasets: dSet,
          },
          options: {
            scales: {
              x: {
                stacked: true,
                display: !this.isHorizontal,
                beginAtZero: label === 'COVS' ? true : false,
                ticks: {
                  font: {
                    weight: 'bold',
                  },
                },
                max: Math.max(values[index], highs[index]),
                grid: {
                  display: label === 'COVID' ? true : false,
                  drawBorder: false,
                },
              },
              y: {
                display: this.isHorizontal,
                stacked: label === 'COVS' ? false : true,
                beginAtZero: label === 'COVS' ? true : false,
                afterDataLimits(scale) {
                  // add 5% to both ends of range
                  const range = scale.max - scale.min;
                  const grace = range * 0.047;
                  scale.max += grace;
                  scale.min -= grace;
                },
                type: label === 'COVS' ? 'logarithmic' : 'linear',
                ticks: {},
                min: this.getYAxesMin(label),
                max: this.getYAxesMax(label),
                grid: {
                  display: false,
                },
              },
            },
            responsive: true,
            interaction: {
              intersect: false,
              mode: 'index',
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                position: 'average',
                external: customTooltips,
              },
              annotation: {
                annotations: [
                  {
                    drawTime: 'afterDatasetsDraw',
                    type: 'line',
                    // mode: "horizontal",
                    scaleID: 'y',
                    value: label === 'COVS' ? 0.8 : this.getMinRange(label),
                    borderWidth: 0,
                    borderColor: 'transparent',
                    label: {
                      xAdjust: -20,
                      yAdjust: 0,
                      content: `${this.getMinRange(label).toFixed(2)}`,
                      backgroundColor: 'transparent',
                      color: '#777777',
                      font: {
                        family: 'Open Sans',
                        size: 10,
                        weight: '400',
                        style: 'normal',
                        lineHeight: 1,
                      },
                      enabled: true,
                      position: 'left',
                      rotation: 0,
                      padding: {
                        right: 12,
                      },
                    },
                  },
                  {
                    drawTime: 'afterDatasetsDraw',
                    type: 'line',
                    scaleID: 'y',
                    value: parseFloat(
                      this.allRanges['referenceRange'][label]
                        .split('-')[1]
                        .trim()
                    ),
                    borderWidth: 0,
                    borderColor: 'transparent',
                    label: {
                      xAdjust: -20,
                      yAdjust: 0,
                      content: `${parseFloat(
                        this.allRanges['referenceRange'][label]
                          .split('-')[1]
                          .trim()
                      ).toFixed(2)}`,
                      backgroundColor: 'transparent',
                      color: '#777777',
                      font: {
                        family: 'Open Sans',
                        size: 10,
                        weight: '400',
                        style: 'normal',
                        lineHeight: 1,
                      },
                      enabled: true,
                      position: 'left',
                      rotation: 0,
                      padding: {
                        right: 13,
                      },
                    },
                  },
                  {
                    drawTime: 'afterDatasetsDraw',
                    type: 'line',
                    // mode: "horizontal",
                    scaleID: 'y',
                    value: values[index],
                    borderWidth: 0,
                    borderColor: 'transparent',
                    label: {
                      xAdjust: 27,
                      yAdjust: 0,
                      content: `${values?.length && values[index] ? values[index].toFixed(2) : 0.00}`,
                      backgroundColor: 'transparent',
                      color: '#333333',
                      font: {
                        family: 'Open Sans',
                        size: 11,
                        weight: '700',
                        style: 'normal',
                        lineHeight: 1,
                      },
                      enabled: true,
                      position: 'right',
                      rotation: 0,
                      padding: {
                        left: values.length && values[index] && values[index].toFixed(2).length > 4 ? 7 : 0,
                      },
                    },
                  },
                ],
              },
            },
          },
        }
      );
    } else {
      this.barChart[label] = new Chart(
        (document.getElementById(label) as HTMLCanvasElement).getContext('2d'),
        {
          type: BarWithErrorBarsController.id,
          data: {
            labels: [label],
            datasets: dSet,
          },
          options: {
            layout: {
              padding: {
                top: 30,
              },
            },
            scales: {
              x: {
                stacked: true,
                display: !this.isHorizontal,
                beginAtZero: true,
                ticks: {},
                max: Math.max(values[index], highs[index]),
                grid: {
                  display: false,
                },
              },
              y: {
                display: this.isHorizontal,
                stacked: true,
                beginAtZero: true,
                type: label === 'COVS' ? 'logarithmic' : 'linear',
                ticks: {},
                suggestedMax: Math.max(values[index], highs[index]) + 5,
                max: this.adaptiveView
                  ? this.getYAxesMax(label)
                  : label === 'COVS'
                    ? this.covidHight
                    : Math.max(values[index], highs[index]) +
                    this.fullResponse.oneSd[label] / 4,
                grid: {
                  display: false,
                },
              },
            },
            responsive: true,
            interaction: {
              intersect: false,
              mode: 'index',
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                position: 'nearest',
                external: customTooltips,
              },
              annotation: {
                annotations: [
                  {
                    drawTime: 'afterDatasetsDraw',
                    type: 'line',
                    // mode: "horizontal",
                    scaleID: 'y',
                    value: label === 'COVS' ? 0.8 : lows[index],
                    borderWidth: 0,
                    borderColor: 'transparent',
                    label: {
                      xAdjust: -20,
                      yAdjust: 10,
                      content: label === 'COVS' ? 0.8 : lows[index].toFixed(2),
                      backgroundColor: 'transparent',
                      color: '#a8a8a8',
                      font: {
                        family: 'Open Sans',
                        size: 10,
                        weight: '400',
                        style: 'normal',
                        lineHeight: 1,
                      },
                      enabled: true,
                      position: 'left',
                      rotation: 270,
                    },
                  },
                  {
                    drawTime: 'afterDatasetsDraw',
                    type: 'line',
                    scaleID: 'y',
                    value: label === 'COVS' ? this.covidHight : highs[index],
                    borderWidth: 0,
                    borderColor: 'transparent',
                    label: {
                      xAdjust: -20,
                      yAdjust: 0,
                      content: highs.length && highs[index] ? highs[index].toFixed(4) : 0.0000,
                      backgroundColor: 'transparent',
                      color: '#a8a8a8',
                      font: {
                        family: 'Open Sans',
                        size: 10,
                        weight: '400',
                        style: 'normal',
                        lineHeight: 1,
                      },
                      enabled: true,
                      position: 'left',
                      rotation: 270,
                    },
                  },
                  {
                    drawTime: 'afterDatasetsDraw',
                    type: 'line',
                    scaleID: 'y',
                    value: values[index],
                    borderWidth: 0,
                    borderColor: 'transparent',
                    label: {
                      xAdjust: 20,
                      yAdjust: 0,
                      content: values[index].toFixed(4) +
                        ' ' +
                        this.allRanges['referenceUnit'][label],
                      backgroundColor: 'transparent',
                      color: '#a8a8a8',
                      font: {
                        family: 'Open Sans',
                        size: 10,
                        weight: '400',
                        style: 'normal',
                        lineHeight: 1,
                      },
                      enabled: true,
                      position: 'right',
                      rotation: 270,
                    },
                  },
                  {
                    drawTime: 'afterDatasetsDraw',
                    type: 'line',
                    // mode: "horizontal",
                    scaleID: 'y',
                    value: 0.8,
                    borderWidth: 0,
                    borderColor: 'transparent',
                    display: label == 'COVS' ? true : false,
                    label: {
                      xAdjust: -20,
                      yAdjust: 10,
                      content: '0.8',
                      backgroundColor: 'transparent',
                      color: '#a8a8a8',
                      font: {
                        family: 'Open Sans',
                        size: 10,
                        weight: '400',
                        style: 'normal',
                        lineHeight: 1,
                      },
                      enabled: true,
                      position: 'left',
                      rotation: 270,
                    },
                  },
                ],
              },
            },
          },
        }
      );
    }
  }

  // Complete Green Bar Case
  completeGreenBar(type: string, label: string) {
    try {
      let result = {};

      switch (type) {
        case 'green':
          result = {
            backgroundColor: this.greenColor,
            borderColor: this.greenColor,
            barPercentage: 0.40000000001,
            barThickness: 13,
            borderRadius: 7,
            borderSkipped: false,
            borderWidth: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            data: [
              this.score['rangeData']['green']['high'][label] -
              this.score['rangeData']['green']['low'][label],
            ],
            type: 'bar',
          };
          break;
        case 'white':
          result = {
            backgroundColor: this.whiteColorOp,
            borderColor: this.whiteColorOp,
            barPercentage: 0.40000000001,
            barThickness: 15,
            borderWidth: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            data: [this.score['rangeData']['green']['low'][label]],
            type: 'bar',
          };
          break;
        default:
          break;
      }
      return result;
    } catch (error) {
      console.error('ERROR in complete Green Bar', error);
    }
  }

  // Complete Yellow Bar Case
  completeYellowBar(type: string, label: string, direction: string) {
    try {
      let result = {};

      switch (type) {
        case 'white':
          result = {
            backgroundColor: this.whiteColorOp,
            borderColor: this.whiteColorOp,
            hoverBackgroundColor: this.whiteColorOp,
            hoverBorderColor: this.whiteColorOp,
            barPercentage: 0.40000000001,
            barThickness: 14,
            borderWidth: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            data:
              direction == 'top'
                ? [this.score['rangeData']['green']['low'][label]]
                : [this.score['rangeData']['yellow']['low'][label]],
            type: 'bar',
          };
          break;
        case 'green':
          result = {
            backgroundColor: this.greenColor,
            borderColor: this.greenColor,
            barPercentage: 0.40000000001,
            barThickness: 13,
            borderWidth: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            borderRadius:
              direction == 'top'
                ? { bottomLeft: 7, bottomRight: 7 }
                : { topLeft: 7, topRight: 7 },
            borderSkipped: false,
            data: [
              this.score['rangeData']['green']['high'][label] -
              this.score['rangeData']['green']['low'][label],
            ],
            type: 'bar',
          };
          break;
        case 'yellow':
          if (direction == 'top') {
            result = {
              backgroundColor: this.yellowColor,
              borderColor: this.yellowColor,
              barPercentage: 0.40000000001,
              barThickness: 13,
              borderRadius: 7,
              data: [
                this.score['rangeData']['yellow']['high'][label] -
                this.score['rangeData']['yellow']['low'][label],
              ],
              type: 'bar',
            };
          } else {
            result = {
              backgroundColor: this.yellowColor,
              borderColor: this.yellowColor,
              barPercentage: 0.40000000001,
              barThickness: 13,
              borderWidth: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              borderRadius: { bottomLeft: 7, bottomRight: 7 },
              borderSkipped: false,
              data: [
                this.score['rangeData']['yellow']['high'][label] -
                this.score['rangeData']['yellow']['low'][label],
              ],
              type: 'bar',
            };
          }
          break;
        default:
          break;
      }

      return result;
    } catch (error) {
      console.error(' ERROR in complete Yellow bar ');
    }
  }

  // Complete Pink Bar Case
  completePinkBar(type: string, label: string, direction: string) {
    try {
      let result = {};

      switch (type) {
        case 'green':
          result = {
            backgroundColor: this.greenColor,
            borderColor: this.greenColor,
            barPercentage: 0.40000000001,
            barThickness: 13,
            borderRadius:
              direction == 'top'
                ? {
                  bottomLeft: 7,
                  bottomRight: 7,
                }
                : {
                  topLeft: 7,
                  topRight: 7,
                },
            borderSkipped: false,
            borderWidth: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            data: [
              this.score['rangeData']['green']['high'][label] -
              this.score['rangeData']['green']['low'][label],
            ],
            type: 'bar',
          };
          break;
        case 'yellow':
          if (direction === 'top') {
            result = {
              backgroundColor: this.yellowColor,
              borderColor: this.yellowColor,
              barPercentage: 0.40000000001,
              barThickness: 13,
              borderWidth: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              data: [
                this.score['rangeData']['yellow']['high'][label] -
                this.score['rangeData']['yellow']['low'][label],
              ],
              type: 'bar',
            };
            break;
          } else {
            result = {
              backgroundColor: this.yellowColor,
              borderColor: this.yellowColor,
              barPercentage: 0.40000000001,
              barThickness: 13,
              borderWidth: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              data: [
                this.score['rangeData']['yellow']['high'][label] -
                this.score['rangeData']['yellow']['low'][label],
              ],
              type: 'bar',
            };
            break;
          }
        case 'pink':
          if (direction == 'top') {
            result = {
              backgroundColor: this.pinkColor,
              borderColor: this.pinkColor,
              barPercentage: 0.40000000001,
              barThickness: 13,
              borderRadius: { topRight: 7, topLeft: 7 },
              borderSkipped: false,
              borderWidth: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              data: [
                this.score['rangeData']['red']['high'][label] -
                this.score['rangeData']['red']['low'][label],
              ],
              type: 'bar',
            };
          } else {
            result = {
              backgroundColor: this.pinkColor,
              borderColor: this.pinkColor,
              barPercentage: 0.40000000001,
              barThickness: 13,
              borderRadius: {
                bottomLeft: 7,
                bottomRight: 7,
              },
              borderSkipped: false,
              borderWidth: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              data: [this.score['rangeData']['red']['high'][label]],
              type: 'bar',
            };
          }
          break;
        default:
          break;
      }

      return result;
    } catch (error) {
      console.error(' ERROR in complete Pink bar ');
    }
  }

  findClosestValueDirection(value, max, min, color, label) {
    if (color === 'red') {
      // Red
      if (value < min) {
        return 'bottom';
      } else if (value > max) {
        return 'top';
      }
    } else if (color === 'yellow') {
      // Yellow
      if (value > this.score['rangeData']['green']['high'][label]) {
        return 'top';
      } else if (value < this.score['rangeData']['green']['low'][label]) {
        return 'bottom';
      }
    }
  }
}
