import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginatorIntl, MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";
import { animate, state, style, transition, trigger } from "@angular/animations";
import exportFromJSON from "export-from-json";
import { jsPDF } from "jspdf";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { TestService } from "../../../services/test.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import 'jspdf-autotable';

const commaSepEmail = (
  control: AbstractControl
): { [key: string]: any } | null => {
  if (control.value) {
    const emails = control.value.split(",").map((e) => e.trim());
    const forbidden = emails.some((email) =>
      Validators.email(new FormControl(email))
    );
    return forbidden ? { toAddress: { value: control.value } } : null;
  }
};

@Component({
  selector: "app-tabular",
  templateUrl: "./tabular.component.html",
  styleUrls: ["./tabular.component.css"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed, void",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state("expanded", style({ height: "*", visibility: "visible" })),
      transition(
        "expanded <=> collapsed, void <=> *",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class TabularComponent implements OnInit, OnChanges {
  @Output() openSingleTestModal: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  displayedColumns: string[] = [];
  secondDisplayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  dataSourceNull: MatTableDataSource<any> = new MatTableDataSource(null);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  analytes: any;
  tableData: any;
  rangeData: any;
  currentDate: Date;
  fileName: string;
  currentPatient: any = JSON.parse(localStorage.getItem("patient")) || {};
  formatForEmail: FormControl = new FormControl("", [Validators.required]);
  toEmails: FormControl = new FormControl("", [
    Validators.required,
    commaSepEmail,
  ]);
  ccEmails: FormControl = new FormControl("", [commaSepEmail]);
  sendEmailForm: FormGroup = new FormGroup({});
  downloadButtons = [
    {
      'text': 'reports.csv',
      'type': 'csv'
    },
    {
      'text': 'reports.json',
      'type': 'json'
    },
    {
      'text': 'reports.excel',
      'type': 'xls'
    },
    {
      'text': 'reports.pdf',
      'type': 'pdf'
    }
  ]
  constructor(private translate: TranslateService, public customPaginatorIntl: MatPaginatorIntl, private dateFilter: DatePipe, private changeDetectorRef: ChangeDetectorRef, private _testService: TestService, private _ngxService: NgxUiLoaderService) {
    this.patientSubscription();
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    this.customPaginatorIntl.itemsPerPageLabel = this.translate.instant("common.itemPerPage");
    this.analytes = this.data.analytes;
    this.displayedColumns = ["date", ...this.analytes, "Supplementary"];
    this.secondDisplayedColumns = this.displayedColumns.map((i) => i + "-1");
    this.tableData = this.data.tableData;
    this.rangeData = this.data.rangeData;
    this.dataSource = new MatTableDataSource(this.data.tableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.changeDetectorRef.detectChanges();
  }

  downloadTestReportResults(type) {
    try {
      this.setFileName();
      if (type === 'csv' || type === 'xls' || type === 'json') this.downloadFile(this.dataSource.data, type);
      else this.convertPdfData(this.dataSource.data);
    } catch (error) {
    }
  }

  downloadFile(arrayOfJson, ext) {
    const data = JSON.parse(JSON.stringify(arrayOfJson));
    const newData = [];
    data.forEach((v) => {
      delete v.immunescore;
      delete v.Feedback;
    });
    const firstRow = JSON.parse(JSON.stringify(data[0]));
    const rangeDataRow = {};
    if (this.displayedColumns.includes("COVS") && !firstRow["COVS"]) firstRow["COVS"] = "1.1";
    Object.keys(firstRow).forEach((key) => {
      const index = this.rangeData.findIndex(o => o.analytes === key);
      if (index > -1 && this.rangeData[index] && this.rangeData[index].label) rangeDataRow[key] = this.rangeData[index].label.replace(key + ": ", "");
    });
    delete firstRow.COVS;
    newData.push(rangeDataRow);
    data.forEach((element) => { newData.push(element); });
    exportFromJSON({ data: newData, fileName: `${this.fileName}`, exportType: exportFromJSON.types[ext] });
  };

  setFileName() {
    this.currentDate = new Date();
    this.fileName = `${this.currentPatient.forename}_${this.currentPatient.surname}_${this.dateFilter.transform(this.currentDate, "yyyyMMdd-HHmmss")}_blood_results`;
  }

  displayRow(element) {
    this.openSingleTestModal.next(element);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getDatesForAllBrowser(date) {
    return this.dateFilter.transform(date.replace(" ", "T"), "dd-MMM-yyyy");
  }

  getRangeValues(label, i) {
    const matchingElement = this.rangeData.find(element => element.analytes === label.split("-")[0]);
    return matchingElement ? matchingElement.unit : "";
  }

  getCellBgColor(element, column) {
    const index = this.rangeData.findIndex(item => item.analytes === column);

    if (index > -1) {
      const range = this.rangeData[index];
      const value = Number(element[column]);

      if (
        value !== -1.0 &&
        value !== -1 &&
        !Number.isNaN(value) &&
        (value > range.max || value < range.min)
      ) {
        return true;
      }
    }

    return false;
  }

  convertPdfData(arrayOfJson) {
    try {
      let data = JSON.parse(JSON.stringify(arrayOfJson));
      data = data.filter((d) => d.health_id !== undefined && d.health_id !== null);
      let headers = [];
      let rangeDataRow = {};
      data.forEach((element) => {
        delete element.health_id;
        delete element.immunescore;
        delete element.tags;
        delete element.Feedback;
        element.date = this.getDatesForAllBrowser(element.date)
        headers = [...new Set([...headers, ...Object.keys(element)])];
      });
      headers = headers.sort();
      headers.forEach((header) => {
        const index = this.rangeData.findIndex((o) => o.analytes === header);
        if (index > -1 && this.rangeData[index] && this.rangeData[index].label) rangeDataRow[header] = this.rangeData[index].label.replace(header + ": ", "");
      });

      if (headers && headers.length > 2) {
        rangeDataRow['date'] = ''
      }

      const newData = [rangeDataRow, ...data.map((element) => {
        const newElement = headers.reduce((acc, header) => {
          acc[header] = element.hasOwnProperty(header) ? element[header] : "-1";
          return acc;
        }, {});
        return newElement;
      })];

      const excelData = newData.map((v) =>
        Object.values(v).map((u) => (u === "-1" || u === "-1.0" ? "N/A" : u))
      );

      const doc = new jsPDF("l", "px", "a3", true);
      doc.setFontSize(18);
      doc.text("Patient Data", 20, 20);
      doc.setFontSize(5);
      doc.setTextColor(100);
      (doc as any).autoTable({
        head: [Object.keys(rangeDataRow)],
        body: excelData,
      });

      doc.save(this.fileName);
    } catch (error) {
    }
  }

  patientSubscription() {
    this._testService.patient.subscribe((res: boolean) => {
      if (res) {
        this.currentPatient = JSON.parse(localStorage.getItem("patient"));
      }
    });
  }

  whenNoTags(element, column) {
    return element[column] !== '-1.0' && element[column] !== '-1' && element[column] !== 'null' && element[column] !== undefined;
  }
}
