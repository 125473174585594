<ngx-ui-loader></ngx-ui-loader>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div *ngIf="dispatchQuestions && dispatchQuestions.length && selectedPatient.all_found === true">
        <h3 class="text-center mt-5"> {{ 'myPatient.dispatchTitle'| translate}} </h3>
        <h5> {{ 'myPatient.newDispatchTitle'| translate}} </h5>

        <div *ngIf="dispatchQuestions && dispatchQuestions.length">
          <form [formGroup]="dispatchForm">
            <div class="row">
              <div class="form-group col-12 col-md-6" *ngFor="let question of dispatchQuestions">
                <ng-select [placeholder]="question.description" [items]="question.suggestedAns" [multiple]="false"
                  bindLabel="label_3" bindValue="aid" [closeOnSelect]="true"
                  formControlName="{{ question.formControlName }}" id="{{ question.formControlName }}"
                  *ngIf="question.inputType === 'select' && question.formControlName ==='selectTest'"
                  [(ngModel)]="question.givenAns" (change)="getMinEndDate()">
                  <ng-template ng-option-tmp let-item="item">
                    <p style="white-space: normal">Title : {{ item.label_3 }}</p>
                    <p style="white-space: normal">Description : {{ item.label_2 }}</p>
                  </ng-template>
                </ng-select>
                <ng-select [placeholder]="question.description" [items]="question.suggestedAns"
                  id="{{ question.formControlName }}" [multiple]="false" bindLabel="label_2" bindValue="aid"
                  [closeOnSelect]="true" formControlName="{{ question.formControlName }}"
                  *ngIf="question.inputType === 'select' && question.formControlName ==='dispatchFrequency'"
                  [(ngModel)]="question.givenAns" (change)="getMinEndDate()">
                  <ng-template ng-option-tmp let-item="item">
                    <p style="white-space: normal">{{ item.label_2 }}</p>
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-group col-12 col-md-6" *ngIf="
                  dispatchQuestions[1].givenAns &&
                  dispatchQuestions[1].givenAns.length &&
                  dispatchQuestions[1].givenAns !== '1068'
                ">
                <mat-form-field class="example-full-width" *ngIf="dispatchQuestions[1].givenAns === '1069'">
                  <input matInput formControlName="startDate" id="{{ question.formControlName }}"
                    placeholder="{{ 'common.selectDate'| translate}}" [matDatepicker]="myDatepickerStartDate"
                    autocomplete="off" [min]="today" (dateChange)="getMinEndDate()" />
                  <mat-datepicker-toggle matSuffix [for]="myDatepickerStartDate"></mat-datepicker-toggle>
                  <mat-datepicker #myDatepickerStartDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="example-full-width" *ngIf="dispatchQuestions[1].givenAns !== '1069'">
                  <input matInput formControlName="startDate" id="{{ question.formControlName }}"
                    placeholder="{{ 'common.selectStartDate'| translate}}" [matDatepicker]="myDatepickerStartDate"
                    autocomplete="off" [min]="today" (dateChange)="getMinEndDate()" />
                  <mat-datepicker-toggle [for]="myDatepickerStartDate"></mat-datepicker-toggle>
                  <mat-datepicker #myDatepickerStartDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-12 col-md-6" *ngIf="
                  dispatchForm.value.startDate &&
                  dispatchQuestions[1].givenAns !== '1068' &&
                  dispatchQuestions[1].givenAns !== '1069'
                ">
                <mat-form-field class="example-full-width">
                  <input matInput id="endDate" formControlName="endDate"
                    placeholder="{{ 'common.selectEndDate'| translate}}" [matDatepicker]="myDatepickerEndDate"
                    autocomplete="off" [min]="minEndDate" />
                  <mat-datepicker-toggle matSuffix [for]="myDatepickerEndDate"></mat-datepicker-toggle>
                  <mat-datepicker #myDatepickerEndDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-6 col-md-2">
                <button type="button" id="selection-changes" class="btn btn-success" [disabled]="dispatchForm.invalid"
                  (click)="selectionChanges()">
                  {{ 'common.saveBtnText'| translate}}
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>

      <div class="mt-5 mb-5" *ngIf="allRequests && allRequests.length && selectedPatient.all_found === true">
        <h5 class="mt-5"> {{ 'myPatient.dispatchRequestScheduleText'| translate}} </h5>
        <div class="mt-5">
          <mat-form-field class="w-100">
            <mat-label> {{ 'common.filterText'| translate}} </mat-label>
            <input matInput (keyup)="applyFilter($event)" />
          </mat-form-field>

          <div class="mat-elevation-z8 table-responsive" *ngIf="allRequests && allRequests.length">
            <table mat-table matSort [dataSource]="dataSource" class="w-100">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                  <mat-select class="w-auto mx-2" panelClass="example-panel" (selectionChange)="querySelected($event)">
                    <mat-option *ngFor="let query of allQueries | orderBy: 'query'" [value]="query">
                      {{ query }}
                    </mat-option>
                  </mat-select>
                  <span style="cursor: pointer" (click)="deleteRecords()">
                    <i class="fas fa-trash-alt fa-2x"></i>
                  </span>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                <td style="white-space: nowrap" mat-cell *matCellDef="let element">
                  {{ element.date | date: config.regionConfig.dateFormatMid }}
                </td>
              </ng-container>
              <ng-container matColumnDef="test">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'myPatient.testKitTypeText'| translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ element.test }}</td>
              </ng-container>
              <ng-container matColumnDef="requester">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'myPatient.requesterText'| translate}}</th>
                <td mat-cell *matCellDef="let element">{{ element.requester }}</td>
              </ng-container>
              <ng-container matColumnDef="request">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'myPatient.dateOfRequestText'| translate}}
                </th>
                <td style="white-space: nowrap" mat-cell *matCellDef="let element">
                  {{ element.request | date: config.regionConfig.dateFormatMid }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'myPatient.statusText'| translate}}
                  <mat-select class="w-auto mx-2" [formControl]="statuses" panelClass="example-panel"
                    (selectionChange)="statusSelected($event)" multiple>
                    <mat-option *ngFor="let status of allStatus | orderBy: 'status'" [value]="status">
                      {{ status }}
                    </mat-option>
                  </mat-select>
                </th>
                <td class="text-capitalize" mat-cell *matCellDef="let element">
                  {{ element.status }}
                </td>
              </ng-container>

              <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'myPatient.timeStampText'| translate}}
                </th>
                <td style="white-space: nowrap" mat-cell *matCellDef="let element">
                  {{ element.timestamp ? getTimeStamp(element.timestamp) : "N/A" }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
            </table>

            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </div>

      <div *ngIf="selectedPatient && (selectedPatient.all_found === 'false' || selectedPatient.all_found === false )">
        <div class="missing-fields px-5 py-3">
          <p class="mb-3">
            {{ 'myPatient.testKitNotAvalibleText'| translate}}
          </p>
          <p>
            {{ 'myPatient.infoMissingText'| translate}} :
          </p>
          <ul>
            <li *ngFor="let item of selectedPatient.missingFields">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>