import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from "@angular/cdk/collections";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TestService } from '../../services/test.service';
import { config } from '../../config';
import { DatePipe } from '@angular/common';
import * as moment from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";


export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MMM-YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: 'app-order-kit',
  templateUrl: './order-kit.component.html',
  styleUrls: ['./order-kit.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class OrderKitComponent implements OnInit {
  config: any = config;

  selection = new SelectionModel<any>(true, []);

  displayedColumns: string[] = ["select", "date", "test", "requester", "request", "status", "timestamp"];
  dispatchForm: FormGroup;
  dispatchQuestions = [];

  selectedPatient = JSON.parse(localStorage.getItem("patient")) ? JSON.parse(localStorage.getItem("patient")) : {};

  allRequests = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  allQueries = [];
  allStatus = [];
  dataSource: MatTableDataSource<any>;
  minEndDate: any;
  today = new Date(moment().utcOffset("+00:00").format("YYYY-MM-DD"));



  statuses = new FormControl();

  constructor(private ngxService: NgxUiLoaderService, private translate: TranslateService, private testService: TestService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    // get Dispatch Question Form 
    this.getAllDispatchQuestion();
  }

  // get Dispatch Question Form :: start
  getAllDispatchQuestion() {
    this.ngxService.start();
    this.testService.getQuestionaryInfo(10).subscribe(
      (res: any) => {
        this.dispatchQuestions = res;
        console.log("oia :: orderkit :: dispatchQuestions :: ", this.dispatchQuestions);
        this.initOrderKitForm(this.dispatchQuestions);
        this.getAllDispatchRequests();
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }
  // get Dispatch Question Form :: end


  // get already scheduled kits for datatable ::start
  getAllDispatchRequests() {
    this.testService.getDispatchRequests().subscribe(
      async (res: any) => {
        console.log(res);
        this.allRequests = await JSON.parse(JSON.stringify(res));
        const queries = [];
        const status = [];

        this.allRequests.map((req: any) => {
          queries.push(req.identifier);
          status.push(req.status);
        });

        this.allQueries = Array.from(new Set(queries));
        this.allStatus = Array.from(new Set(status));
        this.statuses.setValue(this.allStatus);
        this.dataSource = new MatTableDataSource(this.allRequests);
        console.log("this.dataSource ::", this.dataSource);
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }, 1000);
        this.ngxService.stop();
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }
  // get already scheduled kits for datatable ::end


  // Dynamic Initialization of Dispatch Question Form :: start
  initOrderKitForm(dispatchQuestions) {
    this.dispatchForm = new FormGroup(this.getControls(dispatchQuestions));
  }
  // Dynamic Initialization of Dispatch Question Form :: end


  // Dynamic Assign Form Controls of Dispatch Question Form :: start
  getControls(controllsArray) {
    const controls: any = {};
    controllsArray.forEach(control => {
      controls[control.formControlName] = new FormControl("", Validators.required);
    });
    controls.startDate = new FormControl();
    controls.endDate = new FormControl();
    return controls;
  }
  // Dynamic Assign Form Controls of Dispatch Question Form :: end


  //get time stamp for display in table ::start
  getTimeStamp(timestamp) {
    return this.datePipe.transform(timestamp, "HH:mm dd-MMM-yyyy");
  }
  //get time stamp for display in table ::end




  // get all dates as user selected date frequency ::start
  getDatesArray(counts, unit) {
    let dates = [];
    let monday: any;
    monday = this.dispatchForm.value.startDate
      ? moment(this.dispatchForm.value.startDate)
      : moment();

    while (monday.month()) {
      if (this.dispatchForm.value.endDate) {
        if (
          monday.toDate() <= moment(this.dispatchForm.value.endDate).toDate()
        ) {
          dates.push(monday.format("YYYY-MM-DD"));
        }
      } else {
        dates.push(monday.format("YYYY-MM-DD"));
      }
      monday.add(counts, unit);
    }
    console.log(dates);
    return dates;
  }
  // get all dates as user selected date frequency ::end


  // Submit form data to backend on click save button ::start
  async selectionChanges(submit?) {
    let dates: any = [];
    switch (this.dispatchQuestions[1].givenAns) {
      case "1068":
        // moment().utcOffset('+00:00').hour() > 9
        //   ? await dates.push(moment().add(1, 'd').format('YYYY-MM-DD'))
        //   : await dates.push(moment().format('YYYY-MM-DD'));
        // await dates.push(moment().utcOffset("+00:00").format("YYYY-MM-DD"));
        dates.push(moment().utcOffset("+00:00").format("YYYY-MM-DD"));
        break;

      case "1069":
        // await dates.push(
        //   moment(this.dispatchForm.value.startDate).format("YYYY-MM-DD")
        // );
        dates.push(
          moment(this.dispatchForm.value.startDate).format("YYYY-MM-DD")
        );
        break;

      case "1070":
        dates = this.getDatesArray(1, "d");
        break;

      case "1071":
        dates = this.getDatesArray(7, "d");
        break;

      case "1072":
        dates = this.getDatesArray(14, "d");
        break;

      case "1073":
        dates = this.getDatesArray(28, "d");
        break;

      case "1074":
        dates = this.getDatesArray(1, "M");
        break;

      case "1075":
        dates = this.getDatesArray(3, "M");
        break;

      default:
        break;
    }

    console.log(dates, this.dispatchForm.value.selectTest, this.dispatchForm.value.dispatchFrequency);

    if (dates.length && this.dispatchForm.value.selectTest) {
      this.testService
        .requestDispatchSeries(
          JSON.stringify(dates),
          this.dispatchForm.value.selectTest,
          this.dispatchForm.value.dispatchFrequency
        )
        .subscribe(
          (res) => {
            console.log(res);
            this.getAllDispatchQuestion();
            this.getAllDispatchRequests();
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }
  // Submit form data to backend on click save button ::end


  // get minimum date to disable all past dates according to date frequency :: start
  async getMinEndDate() {
    switch (this.dispatchQuestions[1].givenAns) {
      case "1068":
        this.minEndDate = moment().utcOffset("+00:00").format("YYYY-MM-DD");
        break;

      case "1069":
        this.minEndDate = moment(this.dispatchForm.value.startDate).format("YYYY-MM-DD");
        break;

      case "1070":
        this.minEndDate = moment(this.dispatchForm.value.startDate).add(1, "d").format("YYYY-MM-DD");
        break;

      case "1071":
        this.minEndDate = moment(this.dispatchForm.value.startDate).add(7, "d").format("YYYY-MM-DD");
        break;

      case "1072":
        this.minEndDate = moment(this.dispatchForm.value.startDate).add(14, "d").format("YYYY-MM-DD");
        break;

      case "1073":
        this.minEndDate = moment(this.dispatchForm.value.startDate).add(28, "d").format("YYYY-MM-DD");
        break;

      case "1074":
        this.minEndDate = moment(this.dispatchForm.value.startDate).add(1, "M").format("YYYY-MM-DD");
        break;

      case "1075":
        this.minEndDate = moment(this.dispatchForm.value.startDate).add(3, "M").format("YYYY-MM-DD");
        break;

      default:
        break;
    }
    console.log("this.minEndDate :: ", this.minEndDate);
  }
  // get minimum date to disable all past dates according to date frequency :: end




  // Below All methods are used enhanced feture of data table :: start
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1
      }`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async statusSelected(event) {
    console.log(event, this.statuses.value);
    let filterArray = [];
    if (this.statuses.value && this.statuses.value.length) {
      this.allRequests.forEach(req => {
        this.statuses.value.forEach(eve => {
          if (req.status === eve) {
            filterArray.push(req);
          }
        });
      });
    } else {
      filterArray = [];
    }
    console.log(filterArray);
    this.dataSource = await new MatTableDataSource(filterArray);
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 200);
  }

  deleteRecords() {
    console.log(this.selection.selected);
    const filtered = this.selection.selected.filter(
      (el) => el.status === "Scheduled"
    );
    const permittedValues = filtered.map(item => item.id);
    console.log(permittedValues);
    if (permittedValues.length) {
      this.testService
        .deleteDispatchRequests(JSON.stringify(permittedValues))
        .subscribe(
          (res) => {
            console.log(res);
            this.selection.clear();
            this.getAllDispatchRequests();
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }
  // Below All methods are used enhanced feture of data table :: end

}
