<div class="container">
  <div class="row">
    <div class="col-12">
      <p>
        <strong>{{ 'subscriptionManagement.packageName'| translate}}:</strong>
        <span> {{packageData?packageData.package_name:''}}</span>
      </p>
      <p>
        <strong>{{ 'subscriptionManagement.purchaseDate'| translate}}:</strong>
        <span> {{packageData?packageData.purchase_date:''}}</span>
      </p>
      <p>
        <strong>{{ 'subscriptionManagement.noOfBundles'| translate}}:</strong>
        <span> {{packageData?packageData.no_of_bundles:''}}</span>
      </p>
      <p>
        <strong>{{ 'subscriptionManagement.noOfRedeemedCoupons'| translate}}:</strong>
        <span> {{packageData?packageData.no_of_redeemed_coupons:''}}</span>
      </p>
      <p>
        <strong>{{ 'subscriptionManagement.expiryDate'| translate}}:</strong>
        <span> {{packageData?packageData.expiry_date:''}}</span>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table mat-table matSort [dataSource]="dataSource" class="w-100">

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'subscriptionManagement.actions'| translate}} </th>
          <td mat-cell *matCellDef="let element">{{ element.actions }}</td>
        </ng-container>

        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'subscriptionManagement.fullName'| translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.fullName }}</td>
        </ng-container>

        <ng-container matColumnDef="inviteEmailAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'subscriptionManagement.inviteEmailAddress'|
            translate}} </th>
          <td mat-cell *matCellDef="let element">
            <a href="mailto:{{ element.inviteEmailAddress }}" target="_blank" rel="noopener noreferrer">
              {{ element.inviteEmailAddress }}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="registeredEmailAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'subscriptionManagement.registeredEmailAddress'| translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <a href="mailto:{{ element.registeredEmailAddress }}" target="_blank" rel="noopener noreferrer">
              {{ element.registeredEmailAddress }}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="sendLink">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'subscriptionManagement.sendLink'| translate}} </th>
          <td mat-cell *matCellDef="let element">
            <a href="{{ element.sendLink }}" target="_blank" rel="noopener noreferrer">{{ element.sendLink }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'subscriptionManagement.status'| translate}} </th>
          <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'subscriptionManagement.date'| translate}} </th>
          <td mat-cell *matCellDef="let element">{{ element.date | date: config.regionConfig.dateFormatMid }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
      </table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>

    </div>
  </div>
</div>