import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { Subscription, forkJoin } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { BaseComponent } from '../../../modules/shared/base.component';
import { Organisation, Provider, ProviderWithStatus, User } from '../../../../types';
import { OrganisationService } from '../../../services/organisation/organisation.service';
import { ProviderService } from '../../../services/provider/provider.service';
import { IProviderForm } from './types';
import { ApiService } from '../../../services/api.service';
import { UserService } from '../../../services/user/user.service';
import { switchMap } from 'rxjs/operators';
import { RoleService } from 'src/app/services/user/role.service';
@Component({
  selector: 'app-provider-form',
  templateUrl: './provider-form.component.html',
  styleUrls: ['./provider-form.component.css'],
})
export class ProviderFormComponent extends BaseComponent implements OnInit {
  @Input() canUpdate: boolean = true;

  forms: FormArray = new FormArray([]);
  userOrganisationOptions: Organisation[] = [];
  allProviders: ProviderWithStatus[] = [];
  isLoadingData = false;
  isSavingProviders = false;
  canEdit = true;
  private subscription: Subscription = new Subscription();
  userData: User;

  constructor(
    private fb: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private apiService: ApiService,
    private organisationService: OrganisationService,
    private providerService: ProviderService,
    private userService: UserService,
    private roleService: RoleService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addEmptyForm();

    this.subscription.add(this.userService.getUserDataObservable().subscribe((d) => {
      this.userData = d;
    }));

    this.isLoadingData = true;
    this.subscriptions.add(
      forkJoin([
        this.organisationService.getSelectedPatientOrganisations(),
        this.providerService.getSelectedPatientProviders(),
      ]).subscribe({
        next: ([orgs, providers]) => {
          this.userOrganisationOptions = orgs;
          this.allProviders = providers;
          this.isLoadingData = false;

          if (providers.length) {
            this.populateForms(providers);
          }
        },
        error: () => {
          this.isLoadingData = false;
        },
      })
    );
  }

  addEmptyForm() {
    this.forms.push(
      this.fb.group({
        organisationId: [null],
        providerId: [null],
        organisationName: [''],
        providerName: [''],
        isNew: [true],
      })
    );
  }

  populateForms(providers: Provider[]) {
    this.forms.controls = [];

    providers.forEach((provider) => {
      const formGroup = this.fb.group({
        organisationId: provider.organisationId,
        providerId: provider.id,
        organisationName: provider.organisation.name,
        providerName: provider.name,
      });
      this.forms.push(formGroup);
    });
  }

  getFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  isSaveDisabled(): boolean {
    const forms = this.forms.controls;
    return !forms.length || !forms.some(c => c.dirty) || !forms.every(c => c.value?.organisationId && c.value?.providerId);
  }

  isPendingProvider(values: IProviderForm): boolean {
    const provider = this.allProviders.find((p) => p.id === values.providerId);
    return provider?.status === 'pending';
  }

  handleRemoveLink(index: number, values: IProviderForm) {
    const { isNew, providerId } = values;

    if (isNew) {
      this.forms.removeAt(index);
    } else {
      this.ngxService.start();
      this.subscriptions.add(
        this.apiService
          .removeSubscriberFromProvider(providerId, this.userService.user.id)
          .pipe(switchMap(() => this.providerService.getUserProviders()))
          .subscribe({
            next: (providers) => {
              this.populateForms(providers);
              this.allProviders = providers;
              this.ngxService.stop();

              if (!providers.length) {
                this.addEmptyForm();
              }
            },
            error: () => {
              this.ngxService.stop();
            },
          })
      );
    }
  }

  onAddNew() {
    this.addEmptyForm();
  }

  onSave() {


    const confirmMessageText1 = `<p>You are about to share your health data and profile information with the provider(s) listed below</p>`;
    let confirmMessageText2 = '';
    let textString = '';

    const formControls = this.forms.controls;

    formControls.forEach((control) => {
      if (control instanceof FormGroup) {
        const innerControls = control.controls;
        Object.values(innerControls).forEach((innerControl) => {
          innerControl.markAsPristine();
        });
      } else {
        control.markAsPristine();
      }
    });

    const newProviders = this.forms.controls
      .filter((x) => x.value.isNew)
      .map((x) => x.value);

    for (let i = 0; i < newProviders.length; i++) {
      const { providerName, organisationName } = newProviders[i];

      textString = `${organisationName} - ${providerName}`;
      confirmMessageText2 += `<p style="font-weight:500">`;
      confirmMessageText2 += textString;
      confirmMessageText2 += `</p>`;
    }

    const confirmMessageText3 = `<p>By proceeding, you will be giving the provider(s) explicit consent to access your special category data (data relating to your health) so that they may interpret the results of the test and make an assessment as to the frequency of any future test, or any subsequent treatment or intervention as required.</p>`;

    Swal.fire({
      type: 'info',
      showConfirmButton: true,
      showCancelButton: true,
      html: confirmMessageText1 + confirmMessageText2 + confirmMessageText3,
      confirmButtonText: 'Consent to share',
      cancelButtonText: 'Do not share',
    }).then(async (result) => {

      if (result.value) {
        this.isSavingProviders = true;
        return this.subscriptions.add(
          forkJoin(
            newProviders.map((p) =>
              this.apiService.createProviderLinkRequest(
                p.providerId,
                this.userService.user.id
              )
            )
          )
            .pipe(switchMap(() => this.providerService.getUserProviders()))
            .subscribe({
              next: (providers) => {
                this.allProviders = providers;
                this.isSavingProviders = false;
                this.forms.controls.forEach((element) => {
                  if (element.value?.isNew) {
                    element.value.isNew = false
                  }
                })
              },
              error: (error) => {
                this.isSavingProviders = false;
              },
            })
        );
      }
    });
  }
}
