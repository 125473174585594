<form [formGroup]="form" class="w-100 margin-bottom-10">
  <div class="row align-items-center">
    <div class="form-group col-md-5 col-12">
      <ng-select
        [placeholder]="'profilePage.organisation' | translate"
        [items]="organisationOptions"
        bindLabel="name"
        bindValue="id"
        formControlName="organisationId"
        (change)="onOrgChange($event.name)"
        [readonly]="!form.value.isNew || !userData.isProfileComplete"
      >
      </ng-select>
    </div>

    <div class="form-group col-md-5 col-12">
      <ng-select
        [placeholder]="'profilePage.clinicianName' | translate"
        [items]="providerOptions$ | async"
        bindLabel="name"
        bindValue="id"
        formControlName="providerId"
        (change)="onProviderChange($event.name)"
        [readonly]="!form.value.isNew || !userData.isProfileComplete"
      >
      </ng-select>
    </div>

    <div class="form-group col-md-2 col-12">
      <button
        *ngIf="isPendingProvider && !form.value.isNew"
        [disabled]="true"
        class="btn btn-warning w-100"
      >
        <span class="mr-2">&#9203;</span
        >{{ 'profilePage.requestSent' | translate }}
      </button>

      <button
        *ngIf="!isPendingProvider && !form.value.isNew"
        [disabled]="isRemoveDisabled()"
        (click)="handleRemoveLink()"
        class="btn btn-danger w-100"
      >
        {{ 'profilePage.removeLink' | translate }}
      </button>

      <button
        *ngIf="form.value.isNew"
        (click)="handleCancel()"
        [disabled]="!userData.isProfileComplete || (index === 0 && total === 1)"
        class="btn btn-danger w-100"
      >
        {{ 'common.cancelBtnText' | translate }}
      </button>
    </div>
  </div>
</form>
