export const testProfileData: any = [
  { analytes: ['NA', 'UREA', 'CREA'], id: '27', viewValue: 'Lipid' },
  {
    analytes: ['NEUT', 'EOS', 'BASO', 'LYMPH', 'MONO', 'WBC'],
    id: '9',
    viewValue: 'WBC',
  },
];

export const referenceRangeData: any = {
  analyteKeys: [
    'RBC',
    'WBC',
    'NEUT',
    'EOS',
    'BASO',
    'LYMPH',
    'MONO',
    'HGB',
    'PLT',
    'MCV',
    'MCH',
    'MCHC',
    'HCT',
  ],
  referenceRange: {
    BASO: '0.0 - 0.2',
    EOS: '0.1 - 0.7',
    HCT: '0.36 - 0.47',
    HGB: '115.0 - 165.0',
    LYMPH: '0.5 - 4.5',
    MCH: '27.0 - 32.0',
    MCHC: '300.0 - 370.0',
    MCV: '79.0 - 101.0',
    MONO: '0.2 - 1.2',
    NEUT: '2.0 - 8.0',
    PLT: '150.0 - 450.0',
    RBC: '3.9 - 5.4',
    WBC: '4.0 - 11.0',
  },
  referenceUnit: {
    BASO: 'x10-9/L',
    EOS: 'x10-9/L',
    HCT: 'L/L',
    HGB: 'g/L',
    LYMPH: 'x10-9/L',
    MCH: 'pg',
    MCHC: 'g/L',
    MCV: 'fL',
    MONO: 'x10-9/L',
    NEUT: 'x10-9/L',
    PLT: 'x10-9/L',
    RBC: 'x10-12/L',
    WBC: 'x10-9/L',
  },
  dictionary: {
    ALB: 'Albumin',
    ALP: 'Alkaline Phosphatase',
    ALT: 'Alanine Transferase',
    BASO: 'Basophils',
    BILI: 'Bilirubin',
    CA: 'Calcium',
    CREA: 'CREATININE',
    EOS: 'Eosinophils',
    GGT: 'Gamma GT',
    GLOB: 'Globulin',
    HCT: 'Haematocrit',
    HGB: 'Haemoglobin',
    K: 'Potassium',
    LYMPH: 'Lymphocytes',
    MCH: 'Mean Cell Haemoglobin',
    MCHC: 'Mean Cell Haemoglobin Concentration',
    MCV: 'Mean Cell Volume',
    MONO: 'Monocytes',
    NA: 'Sodium',
    NEUT: 'Neutrophils',
    PHOS: 'Phosphate',
    PLT: 'Platelet',
    PROT: 'Total Protein',
    RBC: 'Red Blood Cell',
    UREA: 'UREA',
    VIDC: 'Vitamin D (25 OH)',
    WBC: 'White Blood Cell',
  },
};

export const immuneScoreData: any = {
  adaptive: 'false',
  tests: [],
  analytes: [
    'RBC',
    'WBC',
    'NEUT',
    'EOS',
    'BASO',
    'LYMPH',
    'MONO',
    'HGB',
    'PLT',
    'MCV',
    'MCH',
    'MCHC',
    'HCT',
    'NA',
    'K',
    'UREA',
    'CREA',
    'BILI',
    'ALP',
    'ALT',
    'GGT',
    'PROT',
    'ALB',
    'GLOB',
    'CA',
    'PHOS',
    'VIDC',
  ],
  date: '2021-05-20T11:39:00.000000000Z',
  dictionary: {
    ALB: 'Albumin',
    ALP: 'Alkaline Phosphatase',
    ALT: 'Alanine Transferase',
    BASO: 'Basophils',
    BILI: 'Bilirubin',
    CA: 'Calcium',
    CREA: 'CREATININE',
    EOS: 'Eosinophils',
    GGT: 'Gamma GT',
    GLOB: 'Globulin',
    HCT: 'Haematocrit',
    HGB: 'Haemoglobin',
    K: 'Potassium',
    LYMPH: 'Lymphocytes',
    MCH: 'Mean Cell Haemoglobin',
    MCHC: 'Mean Cell Haemoglobin Concentration',
    MCV: 'Mean Cell Volume',
    MONO: 'Monocytes',
    NA: 'Sodium',
    NEUT: 'Neutrophils',
    PHOS: 'Phosphate',
    PLT: 'Platelet',
    PROT: 'Total Protein',
    RBC: 'Red Blood Cell',
    UREA: 'UREA',
    VIDC: 'Vitamin D (25 OH)',
    WBC: 'White Blood Cell',
  },
  green: {
    BASO: 0.05000000000000001,
    HCT: 0.431,
    HGB: 141.00000000000003,
    LYMPH: 1.44,
    MCH: 30.5,
    MCV: 93.20000000000005,
    MONO: 0.4,
    PLT: 188.0,
    RBC: 4.6,
  },
  greenDonut: {
    HGB: 141.00000000000003,
    LYMPH: 1.44,
    MCH: 30.5,
    MCV: 93.20000000000005,
    PLT: 188.0,
    RBC: 4.6,
  },
  greenHighs: {
    BASO: 0.2,
    HCT: 0.47,
    HGB: 165.0,
    LYMPH: 4.5,
    MCH: 32.0,
    MCV: 101.0,
    MONO: 1.2,
    PLT: 450.0,
    RBC: 5.4,
  },
  greenLows: {
    BASO: 0.0,
    HCT: 0.36,
    HGB: 115.0,
    LYMPH: 0.5,
    MCH: 27.0,
    MCV: 79.0,
    MONO: 0.2,
    PLT: 150.0,
    RBC: 3.9,
  },
  immuneScore: 1.5437224518328456,
  oneSd: {
    BASO: 0.0975,
    EOS: 0.3,
    HCT: 0.05499999999999999,
    HGB: 24.999999999999964,
    LYMPH: 2.0,
    MCH: 2.5,
    MCV: 11.000000000000014,
    MONO: 0.5,
    NEUT: 2.999999999999999,
    PLT: 150.00000000000006,
    RBC: 0.7500000000000004,
    WBC: 3.500000000000001,
  },
  red: {},
  redDonut: {},
  redHighs: {},
  redLows: {},
  yellow: { EOS: 0.07, NEUT: 9.819999999999999, WBC: 11.9 },
  yellowDonut: { NEUT: 9.819999999999999, WBC: 11.9 },
  yellowHighs: { EOS: 0.7, NEUT: 8.0, WBC: 11.0 },
  yellowLows: { EOS: 0.1, NEUT: 2.0, WBC: 4.0 },
};

export const allDatesData: any = [
  { DATE: '2020-09-24T11:39:00.000000000', HEALTH_ID: 1689 },
  { DATE: '2020-10-01T11:39:00.000000000', HEALTH_ID: 1690 },
  { DATE: '2020-10-08T11:39:00.000000000', HEALTH_ID: 1691 },
  { DATE: '2020-10-15T11:39:00.000000000', HEALTH_ID: 1692 },
  { DATE: '2020-10-22T11:39:00.000000000', HEALTH_ID: 1693 },
  { DATE: '2020-10-29T11:39:00.000000000', HEALTH_ID: 1694 },
  { DATE: '2020-11-05T11:39:00.000000000', HEALTH_ID: 1695 },
  { DATE: '2020-11-12T11:39:00.000000000', HEALTH_ID: 1696 },
  { DATE: '2020-11-19T11:39:00.000000000', HEALTH_ID: 1697 },
  { DATE: '2020-11-26T11:39:00.000000000', HEALTH_ID: 1698 },
  { DATE: '2020-12-03T11:39:00.000000000', HEALTH_ID: 1699 },
  { DATE: '2020-12-10T11:39:00.000000000', HEALTH_ID: 1700 },
  { DATE: '2020-12-17T11:39:00.000000000', HEALTH_ID: 1701 },
  { DATE: '2020-12-24T11:39:00.000000000', HEALTH_ID: 1702 },
  { DATE: '2020-12-31T11:39:00.000000000', HEALTH_ID: 1703 },
  { DATE: '2021-01-07T11:39:00.000000000', HEALTH_ID: 1704 },
  { DATE: '2021-01-14T11:39:00.000000000', HEALTH_ID: 1705 },
  { DATE: '2021-01-21T11:39:00.000000000', HEALTH_ID: 1706 },
  { DATE: '2021-01-28T11:39:00.000000000', HEALTH_ID: 1707 },
  { DATE: '2021-02-04T11:39:00.000000000', HEALTH_ID: 1708 },
  { DATE: '2021-02-11T11:39:00.000000000', HEALTH_ID: 1709 },
  { DATE: '2021-02-18T11:39:00.000000000', HEALTH_ID: 1710 },
  { DATE: '2021-02-25T11:39:00.000000000', HEALTH_ID: 1711 },
  { DATE: '2021-03-04T11:39:00.000000000', HEALTH_ID: 1712 },
  { DATE: '2021-03-11T11:39:00.000000000', HEALTH_ID: 1713 },
  { DATE: '2021-03-18T11:39:00.000000000', HEALTH_ID: 1714 },
  { DATE: '2021-03-25T11:39:00.000000000', HEALTH_ID: 1715 },
  { DATE: '2021-04-01T11:39:00.000000000', HEALTH_ID: 1716 },
  { DATE: '2021-04-08T11:39:00.000000000', HEALTH_ID: 1717 },
  { DATE: '2021-04-15T11:39:00.000000000', HEALTH_ID: 1718 },
  { DATE: '2021-04-22T11:39:00.000000000', HEALTH_ID: 1719 },
  { DATE: '2021-04-29T11:39:00.000000000', HEALTH_ID: 1720 },
  { DATE: '2021-05-06T11:39:00.000000000', HEALTH_ID: 1721 },
  { DATE: '2021-05-13T11:39:00.000000000', HEALTH_ID: 1722 },
  { DATE: '2021-05-20T11:39:00.000000000', HEALTH_ID: 1723 },
];
