<div class="row m-0 p-0 flex-row-reverse">
  <div class="col-12 col-md-7 d-flex flex-wrap align-content-center justify-content-center py-4" id="custom-section">
    <div class="col-12 m-0 p-0">
      <div style="margin:auto;">
        <ngx-ui-loader></ngx-ui-loader>
        <div class="col-12">
          <div class="algocyte_logo_square mx-auto" (click)="goTo(['/home'])">
            <img src="assets/algocyte/logo.png" alt="company-logo" class="img-fluid" />
          </div>
        </div>

        <div class="col-12">
          <div class="title mx-auto margin-bottom-20">
            {{'common.forgotPassword' | translate}}
          </div>
        </div>

        <form [formGroup]="form" id="form" (ngSubmit)="onSubmit()" class="mx-auto" style="max-width:515px; margin:auto;"
          *ngIf="!successMessage">
          <mat-form-field appearance="outline">
            <mat-label for="email"> {{'common.email' | translate}} </mat-label>
            <input matInput id="email" type="email" formControlName="email"
              placeholder="{{'common.email' | translate}}" />
            <mat-error class="margin-bottom-15" *ngIf="
                          form.get('email').errors &&
                          form.get('email').hasError('required')
                        ">
              {{'common.emailMandatory' | translate}}
            </mat-error>
            <mat-error class="margin-bottom-15" *ngIf="
                          form.get('email').errors &&
                          form.get('email').hasError('pattern')
                        ">
              {{ 'common.inValidEmail'| translate}}
            </mat-error>
          </mat-form-field>

          <p class="error" *ngIf="error">{{error}}</p>

          <div class="w-100 text-center">
            <button class="text-uppercase" mat-button class="mx-auto" type="submit" [disabled]="!form.valid">
              {{'common.submitBtnText' | translate}}
            </button>
            <app-resource-links></app-resource-links>
          </div>
        </form>

        <div class="col-12 text-center" *ngIf="successMessage">
          <div class="text-center mt-4" style="max-width:515px; margin:auto;">
            <div class="mb-2">
              {{successMessage}}
            </div>
            <div class="mb-2">
              <a routerLink="/auth/signin" class="back-to-signin-link">Back to Sign In</a>
            </div>
            <div class="w-100 text-center">
              <app-resource-links></app-resource-links>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="col-12 col-md-5" style="padding: 0">
    <app-auth-aside welcomeText="{{'signIn.infoStatement' | translate}}"
      welcomeSubText="{{'signIn.infoStatement1' | translate}}" ctaText=" {{'common.signUp' | translate}}"
      ctaUrl="/auth/signup">
    </app-auth-aside>
  </div>

</div>