import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import {
  HealthEventQueryParams,
  HealthEvent,
  HealthEventType,
} from '../../../types';

@Injectable({
  providedIn: 'root',
})
export class HealthEventService {
  constructor(private apiService: ApiService) {}

  getHealthEvents(options?: HealthEventQueryParams): Observable<HealthEvent[]> {
    return this.apiService.getHealthEvents(options);
  }

  getHealthEventTypes(): Observable<HealthEventType[]> {
    return this.apiService.getHealthEventTypes();
  }

  deleteHealthEvent(id): Observable<HealthEvent> {
    return this.apiService.deleteHealthEvent(id);
  }

  createHealthEvent(data): Observable<HealthEvent> {
    return this.apiService.createHealthEvent(data);
  }

  updateHealthEvent(id: number, data): Observable<HealthEvent> {
    return this.apiService.updateHealthEvent(id, data);
  }

  getHealthEventForm() {
    return {
      eventDate: {
        type: 'datetime',
        required: true,
        read_only: false,
        label: 'Event date',
        help_text: 'Enter the date of the event',
      },
      wellnessRating: {
        type: 'choice',
        required: false,
        read_only: false,
        label: 'Feeling well',
        help_text:
          'I feel well (rate this answer: 1 is not good, 10 is excellent)',
      },
      painRating: {
        type: 'choice',
        required: false,
        read_only: false,
        label: 'In pain',
        help_text:
          'I am in pain (rate this answer: 1 is not good, 10 is excellent)',
      },
      energyRating: {
        type: 'choice',
        required: false,
        read_only: false,
        label: 'Have energy',
        help_text:
          'I have energy (rate this answer: 1 is not good, 10 is excellent)',
      },
      eventTypes: {
        type: 'field',
        required: true,
        read_only: false,
        multiple: false,
        label: 'Event id',
        help_text: 'Select relevant event from the list',
      },
      details: {
        type: 'string',
        required: false,
        read_only: false,
        label: 'Description',
        help_text: 'Enter other information',
      },
    };
  }
}
