import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BaseComponent } from '../../../modules/shared/base.component';
import {
  BloodTestPurposeType,
  QuestionnaireResponse,
} from '../../../services/resultSet/types';
import { BehaviorSubject } from 'rxjs';
import { TestService } from '../../../services/test.service';

type TDialogData = {
  homeTestKitId: number;
  questionnaireResponse: QuestionnaireResponse;
};

@Component({
  selector: 'questionnaire-modal',
  templateUrl: './questionnaire-modal.component.html',
  styleUrls: ['./questionnaire-modal.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionnaireModalComponent
  extends BaseComponent
  implements OnInit {
  currentResponses?: QuestionnaireResponse = undefined;
  currentHomeKitId?: number = undefined;
  isSavingResponses = false;
  bloodTestPurposeOptions = Object.values(BloodTestPurposeType);

  constructor(
    private dialogRef: MatDialogRef<QuestionnaireModalComponent>,
    private testService: TestService,
    @Inject(MAT_DIALOG_DATA) private data: TDialogData
  ) {
    super();
    this.currentResponses = data?.questionnaireResponse;
    this.currentHomeKitId = data?.homeTestKitId;
  }

  ngOnInit(): void { }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave() {
    this.isSavingResponses = true;
    this.subscriptions.add(
      this.testService
        .updateTestKitQuestionnaire(this.currentHomeKitId, this.currentResponses)
        .subscribe({
          next: () => {
            this.isSavingResponses = false;
            this.dialogRef.close(true);
          },
          error: (error) => {
            this.isSavingResponses = false;
          },
        })
    );
  }

  onMouseOver(event) {
    // if (this.eventForm.value[id]) {
    const value = this.currentResponses?.wellnessRating;

    setTimeout(() => {
      const currentBar = document.getElementById('wellnessRating');
      const currentBarUnits = currentBar.getElementsByClassName('br-units');

      for (let i = 0; i < currentBarUnits[0].childNodes.length; i++) {
        const element: any = currentBarUnits[0].childNodes[i];
        if (value) {
          if (value - 1 != i) {
            if (
              element.classList &&
              element.classList.contains('br-selected')
            ) {
              element.classList.remove('br-selected');
            }

            if (element.classList && element.classList.contains('br-active')) {
              element.classList.remove('br-active');
            }
          } else {
            element.classList.add('br-selected');
          }
        } else if (
          element.classList &&
          element.classList.contains('br-active')
        ) {
          element.classList.remove('br-active');
        }
      }
    }, 3);
  }

  onMouseOut(event) {
    setTimeout(() => {
      const currentBar = document.getElementById('wellnessRating');
      const currentBarUnits = currentBar.getElementsByClassName('br-units');

      for (let i = 0; i < currentBarUnits[0].childNodes.length; i++) {
        if (this.currentResponses?.wellnessRating - 1 > i) {
          const element: any = currentBarUnits[0].childNodes[i];
          if (element.classList && element.classList.contains('br-active')) {
            element.classList.remove('br-active');
          }

          if (element.classList && element.classList.contains('br-selected')) {
            element.classList.remove('br-selected');
          }
        }
      }
    }, 3);
  }

  handleFieldChange(event, key) {
    this.currentResponses[key] = event.value;
  }

  isInValid() {
    const { currentResponses } = this;
    if (
      !currentResponses?.wellnessRating ||
      !currentResponses?.bloodTestPurpose ||
      (currentResponses?.isRequestedByDoctor == null && currentResponses?.isRequestedByDoctor == undefined) ||
      (currentResponses?.hadRigorousExercise == null && currentResponses?.hadRigorousExercise == undefined) ||
      (currentResponses?.hadAlcohol == null && currentResponses?.hadAlcohol == undefined)
    ) {
      return true;
    }
    return false;
  }

  onRatingChange(value) {
    if (value) {
      const currentBar = document.getElementById('wellnessRating');
      const currentBarUnits = currentBar.getElementsByClassName('br-units');
      this.currentResponses.wellnessRating = value;
      for (let i = 0; i < currentBarUnits[0].childNodes.length; i++) {
        if (value - 1 > i) {
          const element: any = currentBarUnits[0].childNodes[i];
          element.classList.remove('br-active');
          element.classList.remove('br-selected');
        }
      }
    }
  }

  protected readonly undefined = undefined;
}
