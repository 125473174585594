import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitcherComponent } from 'src/app/utility/language-switcher/language-switcher.component';
import { HttpClient } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
} from '@ngx-translate/core';
import { CachedHttpTranslationLoader } from 'src/app/modules/shared/cachedTranslateLoader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppMaterialModules } from './angular-material.module';
import { MyMissingTranslationHandler } from './myMissingTranslationHandler';
import { BaseComponent } from './base.component';

@NgModule({
  declarations: [LanguageSwitcherComponent, BaseComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CachedHttpTranslationLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
      useDefaultLang: true,
      isolate: true,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    AppMaterialModules,
  ],
  exports: [
    LanguageSwitcherComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    AppMaterialModules,
  ],
})
export class SharedModule {}
