<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row mt-5 mb-3 mx-0">
                <mat-form-field class="px-3 py-1 w-100">
                    <input matInput (keyup)="applyFilter($event.target.value)"
                        placeholder="{{ 'common.filterText' | translate }}" autocomplete="off" />
                </mat-form-field>
                <div class="elevation-z8 table-responsive example-full-width"
                    [hidden]="dataSource && dataSource.data.length <= 0">
                    <div class="example-container">
                        <table class="table-with-sticky-rows w-100 " mat-table matTableExporter
                            [dataSource]="dataSource" multiTemplateDataRows #exporter="matTableExporter">
                            <ng-container [matColumnDef]="column" [stickyEnd]="l"
                                *ngFor="let column of displayedColumns;let f = first;let l = last" [sticky]="f">
                                <th mat-header-cell *matHeaderCellDef class="font-weight-bold">
                                    {{ column | uppercase }}
                                </th>
                                <span *ngIf="column === 'date'; else elseTemplate">
                                    <td mat-cell *matCellDef="let element" style="white-space: nowrap">
                                        {{getDatesForAllBrowser(element[column]) }}
                                    </td>
                                </span>
                                <ng-template #elseTemplate>
                                    <span *ngIf="column === 'Supplementary'; else noTagsTemplate">
                                        <td mat-cell *matCellDef="let element">
                                            <span *ngIf="element['tags'] && element['tags'].length;else noTagTemplate">
                                                <i class="fas fa-ellipsis-v" style="cursor: pointer !important"></i>
                                            </span>
                                            <ng-template #noTagTemplate>
                                                <span>{{ "reports.noTags" | translate }}</span>
                                            </ng-template>
                                        </td>
                                    </span>
                                    <ng-template #noTagsTemplate>
                                        <td mat-cell *matCellDef="let element"
                                            [ngClass]="getCellBgColor(element, column) ? 'danger' : ''">
                                            <span *ngIf="whenNoTags(element, column)">
                                                {{ element[column] }}
                                            </span>
                                            <span *ngIf="!(whenNoTags(element, column))">
                                                <span *ngIf="element['health_id'] != undefined">
                                                    {{"common.notApplicableText"| translate }}
                                                </span>
                                                <span *ngIf="element['health_id'] == undefined">
                                                    {{ '' }}
                                                </span>
                                            </span>
                                        </td>
                                    </ng-template>
                                </ng-template>
                            </ng-container>
                            <ng-container [matColumnDef]="colunm" [sticky]="f" [stickyEnd]="l"
                                *ngFor="let colunm of secondDisplayedColumns;let i = index;let f = first;let l = last;">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ getRangeValues(colunm) }}
                                </th>
                            </ng-container>
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                    <div class="example-element-detail"
                                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <ul *ngIf="element['tags'].length">
                                            <li *ngFor="let tag of element['tags']">{{ tag }}</li>
                                        </ul>
                                    </div>
                                </td>
                            </ng-container>
                            <tr class="header-1" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr class="header-2" mat-header-row *matHeaderRowDef="secondDisplayedColumns; sticky: true"
                                style="border-bottom: 1px solid #0000001f"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row"
                                [class.example-expanded-row]="expandedElement === element"
                                (click)="displayRow(element)"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row">
                            </tr>
                        </table>
                    </div>
                    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
                    </mat-paginator>
                </div>
                <ng-template *ngIf="dataSource && dataSource.data.length <= 0">
                    <div class="text-center w-100">
                        <h5>{{ "reports.noDataFound" | translate }}</h5>
                    </div>
                </ng-template>
                <div class="mt-3 w-100 text-center justify-content-between">
                    <p class="ml-3">{{ "reports.exportDataAs" | translate }}</p>
                    <mat-tab-group mat-align-tabs="center">
                        <mat-tab label="{{ 'reports.download' | translate }}">
                            <ng-template matTabContent>
                                <div class="py-5">
                                    <button class="mx-3" id="{{downloadButton.type}}" mat-raised-button
                                        [disabled]="dataSource && dataSource.data.length === 0"
                                        (click)="downloadTestReportResults(downloadButton.type)"
                                        *ngFor="let downloadButton of downloadButtons">
                                        {{downloadButton.text | translate}}
                                    </button>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>