import { Component, OnInit } from '@angular/core';
import { TestService } from "../../services/test.service";
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-demographic-info-form',
  templateUrl: './demographic-info-form.component.html',
  styleUrls: ['./demographic-info-form.component.css']
})
export class DemographicInfoFormComponent implements OnInit {

  public myForm: FormGroup;

  constructor(private fBuilder: FormBuilder, private testService: TestService) { }

  ngOnInit() {
    this.createForm();
    this.fillInForm();
  }

  createForm()
  {
    this.myForm = this.fBuilder.group({
      questionnaire: this.fBuilder.array([])
    });
  }

  isAidQuestion(element){

    if ((element.inputType === "select"))
    {
      if ((element.formControlName === "FORENAME") && (element.formControlName === "SURNAME") && (element.formControlName === "SEX") && (element.formControlName === "DOB") && (element.formControlName === "autosuggestion") && (element.formControlName === "postTown") && (element.formControlName === "postCode"))
      {
        return true
      }
      else
      {
        return false
      }
    }
    else
    {
      return false
    }
    
  }

  isFeedbackQuestion(element){

    if ((element.inputType != "select"))
    {
      if ((element.formControlName === "FORENAME") && (element.formControlName === "SURNAME") && (element.formControlName === "SEX") && (element.formControlName === "DOB") && (element.formControlName === "autosuggestion") && (element.formControlName === "postTown") && (element.formControlName === "postCode"))
      {
        return true
      }
      else
      {
        return false
      }
    }
    else
    {
      return false
    }
    
  }

  buildSuggestedAnswers(record: any){
    let resultArr: any = [];
    record.forEach((x: any) => {
            resultArr.push(this.fBuilder.group({
                aid: [x.aid],
                label: [x.label_2]
            }))
        });
        return this.fBuilder.array(resultArr);
  }



  fillInForm(){
    this.testService.getQuestionaryInfo(1).subscribe(
      (res: any) => {

        console.log(" Check the response of getQuestionaryInfo User", res)

        res.forEach(element => {
          (<FormArray>this.myForm.get('questionnaire')).push(this.fBuilder.group({
              QID: [element.qid],
              AID: [null, this.isAidQuestion(element) === true ? Validators.required : undefined],
              FEEDBACK: [element.givenAns[0], this.isFeedbackQuestion(element) === true ? Validators.required : undefined],

              /* All of these values are just for presentation START */
              description: [element.description],
              formControlName: [element.formControlName],
              order: [parseInt(element.order)],
              inputType: [element.inputType],
              multiple: [JSON.parse(element.multiple)],
              inputLength: [element.inputLength],
              suggestedAnswers: this.buildSuggestedAnswers(element.suggestedAns)
              /* All of these values are just for presentation END */
          }));
        });

        console.log(this.myForm)

        
      },
      (err) => {
        console.log(err);
        // this.ngxService.stop();
      }
    );
  }

  save(model){
    console.log(model.value)
  }

}
