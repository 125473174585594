import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';

import { ResultSetWithScores } from '../../../services/resultSet/types';
import { commonConfig } from '../../../config/common/common';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

interface ISliderConfig {
  infinite: boolean;
  slidesToShow: number;
  slidesToScroll: number;
  initialSlide: number;
  nextArrow: string;
  prevArrow: string;
  dots: boolean;
  customPaging: (slider: any, i: number) => string;
  responsive: IResponsiveSetting[];
}

interface IResponsiveSetting {
  breakpoint: number;
  settings: {
    slidesToShow: number;
    slidesToScroll: number;
    infinite: boolean;
    dots: boolean;
  };
}

@Component({
  selector: 'app-historic-test-results',
  templateUrl: './historic-test-results.component.html',
  styleUrls: ['./historic-test-results.component.css'],
})
export class HistoricTestResultsComponent implements OnInit, OnChanges {
  @ViewChild('carousel', { static: false }) carousel: SlickCarouselComponent;

  @Input() resultSets: ResultSetWithScores[] = [];
  @Input() selectedResultSet: ResultSetWithScores;
  @Input() isLoading: boolean;

  @Output() onResultSetSelect = new EventEmitter<ResultSetWithScores>();

  sliderConfig: ISliderConfig;

  // date selector
  yearOptions: number[] = [];
  monthOptions: number[] = [];
  yearMonthMap = {};
  selectedYear: number;
  selectedMonth: number;

  constructor() { }

  ngOnInit(): void {
    this.setSliderConfig(this.resultSets);
    this.setDateSelectorOptions(this.resultSets);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resultSets?.currentValue?.length) {
      this.setSliderConfig(changes.resultSets.currentValue);
      this.setDateSelectorOptions(changes.resultSets.currentValue);
    }
  }

  setSliderConfig(data: ResultSetWithScores[]) {
    let latestResultIndex = data.length - 1 || 0;
    const screenWidth = screen.width;
    const point = screenWidth > 1025 ? 5 : screenWidth > 600 ? 3 : 2;

    const generateTooltip = (index: number): string => {
      if (!data.length) return '';
      const month = commonConfig.month;
      const observationDate = new Date(data[index]?.observationDate);
      return `${month[observationDate.getMonth()]} ${observationDate.getFullYear()}`;
    };

    this.sliderConfig = {
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      nextArrow: '<button type="button" class="btn-next"><i class="fa fa-angle-right"></i></button>',
      prevArrow: '<button type="button" class="btn-prev"><i class="fa fa-angle-left"></i></button>',
      dots: false,
      customPaging: (_slider, i) => {
        const tooltip = generateTooltip(i === 0 ? i : i * point);
        return `<button class="slick-tooltip"><span class="slick-tooltip-text">${tooltip}</span></button>`;
      },
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            infinite: false,
            slidesToScroll: 2,
            dots: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            infinite: false,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };

    setTimeout(async () => {

      const selectedResultId = JSON.parse(localStorage.getItem('resultId'))

      if (selectedResultId) {
        const index = data.findIndex(obj => obj.id === selectedResultId);
        latestResultIndex = index > -1 ? index : latestResultIndex
      }

      const latestObservationDate = new Date(data[latestResultIndex].observationDate);
      this.selectedYear = latestObservationDate.getFullYear();
      await this.onSelectYear(this.selectedYear, latestResultIndex);
      this.selectedMonth = latestObservationDate.getMonth();
      this.carousel.slickGoTo(latestResultIndex >= 0 ? latestResultIndex : 0);
    }, 50);
  }

  setDateSelectorOptions(resultSets: ResultSetWithScores[]) {
    const dates = resultSets.map(set => moment(set.observationDate, 'YYYY-MM-DD'));

    this.yearMonthMap = dates.reduce((acc, date) => {
      const year = date.year();
      const month = date.month();
      if (!acc[year]) {
        acc[year] = new Set();
      }
      acc[year].add(month);
      return acc;
    }, {});

    this.yearOptions = [...new Set(dates.map(date => date.year()))];
    const latestYear = this.yearOptions[this.yearOptions.length - 1];
    this.monthOptions = this.getMonthsByYear(latestYear, this.yearMonthMap);
  }

  displayMonth(month: number): string {
    return commonConfig.month[month];
  }

  getMonthsByYear = (selectedYear, yearMonthMap) => {
    return [...(yearMonthMap[selectedYear] || new Set())];
  };

  onSelectYear(year: number, selectedIndex?: number) {
    this.selectedYear = year;
    const index = selectedIndex !== null && selectedIndex !== undefined
      ? selectedIndex
      : this._findClosestDateIndex();

    this.carousel.slickGoTo(index > 0 ? index : 0);
    this.selectedMonth = null
    this.monthOptions = this.getMonthsByYear(this.selectedYear, this.yearMonthMap);
  }

  onSelectMonth(month: number) {
    this.selectedMonth = month;
    const index = this._findClosestDateIndex();
    this.carousel.slickGoTo(index >= 0 ? index : 0);
  }

  onResultSetClick(resultSet: ResultSetWithScores, selectedIndex?: number) {
    localStorage.setItem('resultId', JSON.stringify(resultSet.id))
    const observationDate = new Date(resultSet.observationDate);
    this.selectedYear = observationDate.getFullYear();
    this.onSelectYear(this.selectedYear, selectedIndex);
    this.selectedMonth = observationDate.getMonth();
    this.onResultSetSelect.emit(resultSet);
  }

  isSelected(resultSet: ResultSetWithScores): boolean {
    return this.selectedResultSet?.id === resultSet.id;
  }

  getDayOfWeek(date: string): string {
    return moment(date).format('dddd');
  }

  getFormattedDate(date: string): string {
    return moment(date).format('DD MMM YYYY');
  }

  _findClosestDateIndex(): number {
    const datesWithIndex = this.resultSets.map((set, index) => ({
      date: new Date(set.observationDate),
      index,
    }));

    let filteredByYear = datesWithIndex;
    if (this.selectedYear) {
      filteredByYear = datesWithIndex.filter(
        ({ date }) => date.getFullYear() === this.selectedYear
      );
    }

    let filteredByMonthAndYear = filteredByYear;
    if (this.selectedMonth !== undefined && this.selectedMonth !== null) {
      filteredByMonthAndYear = filteredByYear.filter(
        ({ date }) => date.getMonth() === this.selectedMonth
      );
    }

    if (filteredByMonthAndYear.length === 0) return -1;

    return filteredByMonthAndYear[0].index;
  }
}
