import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { config } from '../../config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  config = config;
  appConfig = config;
  childVisible = true;
  currentYear = new Date().getFullYear();
  constructor(
    public change: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {}

  doRouting(path) {
    this.router.navigate(path);
  }

  ngAfterContentChecked() {
    this.change.detectChanges();
  }

  langChanged() {
    this.childVisible = false;
    this.change.detectChanges();
    setTimeout(() => {
      this.childVisible = true;
      this.change.detectChanges();
    }, 1000);
  }
}
