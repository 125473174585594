import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../config';
import {
  AuthorisedByUser,
  AuthorisedUser,
  LinkRequest,
  Organisation,
  PagedQueryResponse,
  Provider,
  RegisterUserPayload,
  Subscriber,
  User,
  UserLinkingCode,
  UserProfile,
  ResetPasswordRequest,
  ClinicalReferenceEnums,
  Tag,
  HealthEventQueryParams,
  HealthEvent,
  HealthEventType,
  CreateHealthEventBody,
} from 'src/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResultSetResponse } from './resultSet/types';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  UUID: string = localStorage.getItem('UUID')
    ? JSON.parse(localStorage.getItem('UUID'))
    : '';
  patientId: string = localStorage.getItem('patientId')
    ? localStorage.getItem('patientId')
    : '';

  constructor(private _http: HttpClient) {}

  registerUser(payload: RegisterUserPayload) {
    return this._http.post<User>(
      `${config.baseApiv2Url}/auth/register`,
      payload
    );
  }

  resendVerificationEmail(email: string) {
    return this._http.post(
      `${config.baseApiv2Url}/auth/resend-verification-email`,
      { email }
    );
  }

  forgotPassword(email: string) {
    return this._http.post(`${config.baseApiv2Url}/auth/forgot-password`, {
      email: email,
    });
  }

  resetPassword(payload: ResetPasswordRequest) {
    return this._http.post(
      `${config.baseApiv2Url}/auth/reset-password`,
      payload
    );
  }

  verifyUser(token: string) {
    return this._http.post(`${config.baseApiv2Url}/auth/verify-email`, {
      token,
    });
  }

  generateUserLinkingCode(userId: number) {
    return this._http.get<UserLinkingCode>(
      `${config.baseApiv2Url}/users/${userId}/linking-code`,
      {}
    );
  }

  useLinkingCode(userId: number, code: number) {
    return this._http.post(
      `${config.baseApiv2Url}/users/${userId}/linking-code/${code}`,
      {}
    );
  }

  // User
  getUser(userId: number) {
    return this._http.get<User>(`${config.baseApiv2Url}/users/${userId}`);
  }

  getUserProfile(userId: number) {
    return this._http.get<UserProfile>(
      `${config.baseApiv2Url}/users/${userId}/profile`
    );
  }

  updateUserProfile(
    userId: number,
    data: Partial<UserProfile>
  ): Observable<UserProfile> {
    return this._http.patch<UserProfile>(
      `${config.baseApiv2Url}/users/${userId}/profile`,
      data
    );
  }

  getAuthorisedUsers(userId: number) {
    return this._http.get<AuthorisedUser[]>(
      `${config.baseApiv2Url}/users/${userId}/authorised-users`
    );
  }

  deleteAuthorisedUser(userId: number, authorisedUserId: number) {
    return this._http.delete(
      `${config.baseApiv2Url}/users/${userId}/authorised-users/${authorisedUserId}`
    );
  }

  getAuthorisedByUsers(userId: number) {
    return this._http.get<AuthorisedByUser[]>(
      `${config.baseApiv2Url}/users/${userId}/authorised-by-users`
    );
  }

  deleteAuthorisedByUser(userId: number, authorisedByUserId: number) {
    return this._http.delete(
      `${config.baseApiv2Url}/users/${userId}/authorised-by-users/${authorisedByUserId}`
    );
  }

  removeLinkedAccount(accountId) {
    return this._http.patch(
      `${config.baseApiv2Url}/users/disassociate-user/${accountId}`,
      {}
    );
  }

  getUsersOrganisation(userId: number) {
    return this._http.get<Organisation[]>(
      `${config.baseApiv2Url}/users/${userId}/organisations`
    );
  }

  getProvidersByOrganisation(orgId: number): Observable<Provider[]> {
    return this._http
      .get<Provider[]>(
        `${config.baseApiv2Url}/providers/?organisationId=${orgId}`
      )
      .pipe(
        map((providers) =>
          providers.map((provider) => {
            provider.name = provider.user?.userProfile
              ? `${provider.user.userProfile.firstName} ${provider.user.userProfile.lastName}`
              : '';
            return provider;
          })
        )
      );
  }

  getProvidersBySubscriberId(subscriberId) {
    return this._http.get<Provider[]>(
      `${config.baseApiv2Url}/providers/?subscriberId=${subscriberId}`
    );
  }

  getMyProviderData() {
    return this._http.get<Provider>(`${config.baseApiv2Url}/providers/me`);
  }

  getProviderSubscribers(providerId) {
    return this._http.get<PagedQueryResponse<Subscriber>>(
      `${config.baseApiv2Url}/providers/${providerId}/subscribers`
    );
  }

  getAssociatedProviders() {
    return this._http.get(`${config.baseApiv2Url}/providers/associated-list`);
  }

  createProviderLinkRequest(providerId: number, subscriberId: number) {
    return this._http.post(
      `${config.baseApiv2Url}/providers/${providerId}/link-request`,
      { subscriberId }
    );
  }

  createSubscriberLinkRequest(providerId: number, subscriberId: number) {
    return this._http.post<LinkRequest>(
      `${config.baseApiv2Url}/users/${subscriberId}/link-request`,
      {
        providerId,
      }
    );
  }

  removeSubscriberFromProvider(providerId: number, subscriberId: number) {
    return this._http.delete(
      `${config.baseApiv2Url}/providers/${providerId}/subscribers/${subscriberId}`
    );
  }

  getSubscribersForProvider(providerId) {
    return this._http.get(
      `${config.baseApiv2Url}/providers/${providerId}/subscribers`
    );
  }

  findOrganisationSubscriberByPatientId(patientId, organisationId) {
    return this._http.get(
      `${config.baseApiv2Url}/organisations/${organisationId}/subscribers?patientId=${patientId}`
    );
  }

  addSubscriberToOrganisation(
    organisationId: number,
    userId: number
  ): Observable<string> {
    return this._http.put(
      `${config.baseApiv2Url}/organisations/${organisationId}/subscribers`,
      { userId },
      { responseType: 'text' }
    );
  }

  removeSubscriberFromOrganisation(
    organisationId: number,
    userId: number
  ): Observable<string> {
    return this._http.delete(
      `${config.baseApiv2Url}/organisations/${organisationId}/subscribers/${userId}`,
      { responseType: 'text' }
    );
  }

  associatedOrganisations(userId: number): Observable<Organisation[]> {
    return this._http.get<Organisation[]>(
      `${config.baseApiv2Url}/users/${userId}/organisations`
    );
  }

  acceptProviderLinkRequest(token: string) {
    return this._http.get(
      `${config.baseApiv2Url}/providers/link-request/accept?token=${token}`
    );
  }

  getClinicalDataEnums(): Observable<ClinicalReferenceEnums> {
    return this._http.get<ClinicalReferenceEnums>(
      `${config.baseApiv2Url}/clinical-reference-data/enums`
    );
  }

  acceptSubscriberLinkRequest(token: string) {
    return this._http.get(
      `${config.baseApiv2Url}/users/link-request/accept?token=${token}`
    );
  }

  getHealthEvents(options?: HealthEventQueryParams): Observable<HealthEvent[]> {
    return this._http.get<HealthEvent[]>(
      `${config.baseApiv2Url}/health-events`,
      {
        params: options,
      }
    );
  }

  createHealthEvent(data?: CreateHealthEventBody): Observable<HealthEvent> {
    return this._http.post<HealthEvent>(
      `${config.baseApiv2Url}/health-events`,
      data
    );
  }

  updateHealthEvent(
    id: number,
    data?: CreateHealthEventBody
  ): Observable<HealthEvent> {
    return this._http.put<HealthEvent>(
      `${config.baseApiv2Url}/health-events/${id}`,
      data
    );
  }

  deleteHealthEvent(id: number): Observable<HealthEvent> {
    return this._http.delete<HealthEvent>(
      `${config.baseApiv2Url}/health-events/${id}`
    );
  }

  getHealthEventTypes(): Observable<HealthEventType[]> {
    return this._http.get<HealthEventType[]>(
      `${config.baseApiv2Url}/health-event-types`
    );
  }

  getTags(): Observable<Tag[]> {
    return this._http.get<Tag[]>(`${config.baseApiv2Url}/tags`);
  }

  createTag(data: Tag): Observable<Tag> {
    return this._http.post<Tag>(`${config.baseApiv2Url}/tags`, data);
  }

  deleteTag(id: number) {
    return this._http.delete<void>(`${config.baseApiv2Url}/tags/${id}`);
  }

  assignTagsToResultSet(
    resultSetId: number,
    tags: Tag[]
  ): Observable<ResultSetResponse> {
    return this._http.put<ResultSetResponse>(
      `${config.baseApiv2Url}/result-sets/${resultSetId}/tags`,
      tags
    );
  }

  generateResultSetReport(resultSetId?: number) {
    return this._http.get(
      `${config.baseApiv2Url}/result-sets/${resultSetId}/report`,
      { responseType: 'blob' as 'json' }
    );
  }

  /**
   * ONLY FOR TESTING
   * @param userId
   * @param roleName
   */
  testUpdateUserRole(userId: number, roleName: string) {
    return this._http.patch(
      `${config.baseApiv2Url}/test-utils/user/${userId}/role`,
      {
        role: roleName,
      }
    );
  }

  testUploadBloodTestResult(
    sampleCount: number
  ): Observable<Record<string, any>> {
    return this._http.post(`${config.baseApiv2Url}/test-utils/result-sets`, {
      sampleCount,
    });
  }
}
