import { AbstractControl } from '@angular/forms';
import { commonConfig } from '../../config/common/common';

export const passwordValidator = (
  control: AbstractControl
): { [key: string]: any } | null =>
  control.value && !commonConfig.pattern.password.test(control.value)
    ? { invalidPassword: true }
    : null;

export const confirmPasswordValidator = (
  control: AbstractControl
): { [key: string]: any } | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('cpassword');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value !== passwordConfirm.value) {
    return { passwordsNotMatch: true };
  }

  return null;
};
