<mat-form-field appearance="none" class="viewCaseA" style="color: #a8a8a8 !important;" *ngIf="viewCase === 'A'">
  <span matPrefix>
    <mat-icon style="display: inherit;">language</mat-icon>
  </span>
  <mat-select [(ngModel)]="userSelectedLanguageId" (selectionChange)="switchLanguage($event)">
    <mat-option *ngFor="let lang of laguages" [value]="lang.id">
      {{ lang.viewValue }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="outline" style="color: #a8a8a8 !important" *ngIf="viewCase === 'B'">

  <span matPrefix style="margin-right: 8px;">
    <mat-icon>language</mat-icon>
  </span>

  <mat-select [(ngModel)]="userSelectedLanguageId" (selectionChange)="switchLanguage($event)">

    <mat-option *ngFor="let lang of laguages" [value]="lang.id">
      {{ lang.viewValue }}
    </mat-option>

  </mat-select>

</mat-form-field>

<mat-form-field appearance="outline" class="viewCaseC" style="color: #a8a8a8 !important" *ngIf="viewCase === 'C'">

  <mat-label for="selectLanguage"> Select Language </mat-label>

  <mat-select id="selectLanguage" [(ngModel)]="userSelectedLanguageId" (selectionChange)="switchLanguage($event)">

    <mat-option *ngFor="let lang of laguages" [value]="lang.id">
      {{ lang.viewValue }}
    </mat-option>

  </mat-select>

</mat-form-field>