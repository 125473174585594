import { Injectable } from '@angular/core';
import {
  AuthorisedByUser,
  AuthorisedUser,
  Option,
  Role,
  User,
  UserProfile,
} from '../../../types';

import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, skip, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ApiService } from '../api.service';

const ROLE_MAPPING: { [roleName: string]: number } = {
  admin: 3001,
  provider: 2001,
  subscriber: 1001,
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userData = new BehaviorSubject<User | null>(null);
  private authorisedUserData = new BehaviorSubject<AuthorisedUser[] | null>(
    null
  );
  private authorisedByUserData = new BehaviorSubject<AuthorisedByUser[] | null>(
    null
  );

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) { }

  get user(): User | null {
    return this.userData.getValue();
  }

  subscribeToAuthChanges() {
    // skip initial emission of behaviour subject as the data is already fetched from app.module
    this.authService
      .getAuthorisedUserDataObservable()
      .pipe(skip(1))
      .subscribe((user) => {
        this.fetchFullUserData(user?.userId).subscribe();
      });
  }

  fetchFullUserData(userId?: number): Observable<User | null> {
    if (!userId) {
      this.userData.next(null);
      this.authorisedUserData.next([]);
      this.authorisedByUserData.next([]);
      return of(null);
    }

    return forkJoin([
      this.userData$(userId),
      this.authorisedUserData$(userId),
      this.authorisedByUserData$(userId),
    ]).pipe(
      switchMap(([user, authorisedUserData, authorisedByUserData]) => {
        this.userData.next(user);
        this.authorisedUserData.next(authorisedUserData);
        this.authorisedByUserData.next(authorisedByUserData);
        return of(user);
      }),
      catchError(() => of(null))
    );
  }

  getUserDataObservable() {
    return this.userData.asObservable();
  }

  isProfileComplete(userProfile: UserProfile): boolean {
    const { patientId, firstName, lastName, dateOfBirth, gender } =
      userProfile || {};
    return !!(patientId && firstName && lastName && dateOfBirth && gender);
  }

  getAuthorisedUserDataObservable() {
    return this.authorisedUserData.asObservable();
  }

  getAuthorisedByUserDataObservable() {
    return this.authorisedByUserData.asObservable();
  }

  userData$(userId?: number): Observable<User> {
    if (!userId) {
      return of(null);
    }
    return forkJoin({
      user: this.apiService.getUser(userId),
      userProfile: this.apiService.getUserProfile(userId),
    }).pipe(
      map(({ user, userProfile }): User => {
        const role = this.getLegacyRoleIdFromUserRoles(user.roles);
        return {
          ...user,
          primaryRole: role,
          userProfile,
          isProfileComplete:
            role === ROLE_MAPPING.subscriber
              ? this.isProfileComplete(userProfile)
              : true,
        };
      }),
      catchError((error) => {
        console.error('Error fetching user data', error);
        return of(null);
      })
    );
  }

  authorisedUserData$(userId?: number): Observable<AuthorisedUser[]> {
    userId = userId || this.userData.getValue()?.id;

    if (userId === null) {
      this.authorisedUserData.next([]);
      return of([]);
    }

    return this.apiService.getAuthorisedUsers(userId);
  }

  fetchAuthorisedUserData(userId?: number) {
    return this.authorisedUserData$(userId).subscribe(
      (data) => this.authorisedUserData.next(data),
      (error) => console.error(error)
    );
  }

  authorisedByUserData$(userId?: number): Observable<AuthorisedByUser[]> {
    userId = userId || this.userData.getValue()?.id;

    if (userId === null) {
      this.authorisedByUserData.next([]);
      return of([]);
    }

    return this.apiService.getAuthorisedByUsers(userId);
  }

  fetchAuthorisedByUserData(userId?: number) {
    return this.authorisedByUserData$(userId).subscribe(
      (data) => this.authorisedByUserData.next(data),
      (error) => console.error(error)
    );
  }

  removeAuthorisedByUser(authorisedByUserId: number) {
    const userId = this.userData.getValue()?.id;
    if (userId === null) {
      this.authorisedByUserData.next([]);
      return;
    }

    return this.apiService
      .deleteAuthorisedByUser(userId, authorisedByUserId)
      .subscribe(
        () => {
          const authorisedByUsers = this.authorisedByUserData.getValue();
          const newAuthorisedByUsers = authorisedByUsers.filter(
            (user) => user.id !== authorisedByUserId
          );
          this.authorisedByUserData.next(newAuthorisedByUsers);
        },
        (error) => console.error(error)
      );
  }

  removeAuthorisedUser(authorisedUserId: number): Promise<AuthorisedUser[]> {
    const promise = new Promise(
      (resolve: (value: AuthorisedUser[]) => void, reject) => {
        const userId = this.userData.getValue()?.id;
        if (userId === null) {
          this.authorisedUserData.next([]);
          resolve([] as AuthorisedUser[]);
        }

        this.apiService
          .deleteAuthorisedUser(userId, authorisedUserId)
          .subscribe(
            () => {
              const authorisedUsers = this.authorisedUserData.getValue();
              const newAuthorisedUsers = authorisedUsers.filter(
                (user) => user.id !== authorisedUserId
              );
              this.authorisedUserData.next(newAuthorisedUsers);
              resolve(newAuthorisedUsers);
            },
            (error) => {
              console.error(error);
              reject(error);
            }
          );
      }
    );

    return promise;
  }

  getUser(userId: number): Promise<User> {
    return this.apiService.getUser(userId).toPromise();
  }

  getSelectedUser(userId: number): Promise<User> {
    return this.apiService.getUser(userId).toPromise();
  }

  getUserProfile(userId: number): Promise<UserProfile> {
    return this.apiService.getUserProfile(userId).toPromise();
  }

  refreshUserProfile(userId: number): Observable<User> {

    return this.userData$(userId).pipe(
      switchMap((user) => {

        const currentUserId = JSON.parse(localStorage.getItem('UUID'))
        if (user.id == currentUserId) {
          this.userData.next(user);
        }
        return of(user);
      }),
      catchError(() => {
        this.userData.next(null);
        return of(null);
      })
    );
  }

  updateUserProfile(
    userId: number,
    data: Partial<UserProfile>
  ): Observable<UserProfile> {
    return this.apiService.updateUserProfile(userId, data);
  }

  getLegacyRoleIdFromUserRoles = (roles: Role[]): number => {
    for (const roleName of Object.keys(ROLE_MAPPING)) {
      if (roles.some((role) => role === roleName)) {
        return ROLE_MAPPING[roleName];
      }
    }
  };

  getGenderOptions(): Observable<Option[]> {
    return this.apiService.getClinicalDataEnums().pipe(
      map((enums) => enums.gender.map((x) => ({ label: x, value: x }))),
      catchError(() => of([]))
    );
  }
}
