import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Observable, Subscription, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { Organisation, Provider, User } from '../../../../../types';
import { ApiService } from '../../../../services/api.service';
import swal from 'sweetalert2';
import { RoleService } from 'src/app/services/user/role.service';
import { SelectedSubscriberService } from 'src/app/services/selected-subscriber/selected-subscriber.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-provider-form-item',
  templateUrl: './provider-form-item.component.html',
  styleUrls: ['./provider-form-item.component.css'],
})
export class ProviderFormItemComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() organisationOptions: Organisation[] = [];
  @Input() isPendingProvider: boolean = false;
  @Input() index: number;
  @Input() total: number;

  @Output() onRemoveLink: EventEmitter<void> = new EventEmitter();
  providerOptions$: Observable<Provider[]>;
  selectedSubscriber: User | null;
  private subscription: Subscription = new Subscription();
  userData: User;

  constructor(
    private apiService: ApiService,
    private roleService: RoleService,
    private userService: UserService,
    private selectedSubscriberService: SelectedSubscriberService,
  ) {

    this.selectedSubscriberService.getUserDataObservable().subscribe((d) => {
      if (d) {
        this.selectedSubscriber = d;
      }
    });
    this.subscription.add(this.userService.getUserDataObservable().subscribe((d) => {
      this.userData = d;
    }));
  }

  ngOnInit(): void {
    const organisationControl = this.form.get('organisationId');
    const providerControl = this.form.get('providerId');

    this.providerOptions$ = organisationControl.valueChanges.pipe(
      startWith(organisationControl.value),
      switchMap((organisationId) => {
        if (organisationId) {
          providerControl.enable();
          return this.apiService.getProvidersByOrganisation(organisationId);
        } else {
          providerControl.disable();
          return of([]);
        }
      })
    );
  }

  onOrgChange(name: string) {
    this.form.get('organisationName').setValue(name, { emitEvent: false });
  }

  onProviderChange(name: string) {
    this.form.get('providerName').setValue(name, { emitEvent: false });
  }

  isRemoveDisabled(): boolean {
    return !this.form.value.organisationId || !this.form.value.providerId;
  }

  async handleRemoveLink() {

    if (this.roleService.isProvider) {
      let message = "<p>You are about to remove access to your health data and profile information with the subscriber(s) listed below</p>";

      const providerInfo = `<p>${this.form.value.organisationName} - ${this.selectedSubscriber.userProfile?.firstName} ${this.selectedSubscriber.userProfile?.lastName}</p>`;
      message += `${providerInfo}<p>This change means that they will no longer have access to your special category data, particularly the data related to your health. Consequently, they will no longer be able to interpret test results, assess future test scheduling, or make decisions regarding any necessary treatment or intervention.</p>`;

      const result = await this.showWarningDialog(message);
      if (result.value) {
        this.onRemoveLink.emit();
      } else {
        return false;
      }
    }

    if (this.roleService.isSubscriber) {
      let message = "<p>You are about to remove access to your health data and profile information with the provider(s) listed below</p>";
      this.providerOptions$.subscribe(async (providers) => {
        const provider = providers.find((p) => p.id === this.form.value.providerId);
        if (provider) {
          const providerInfo = `<p>${this.form.value.organisationName} - ${provider.name}</p>`;
          message += `${providerInfo}<p>This change means that they will no longer have access to your special category data, particularly the data related to your health. Consequently, they will no longer be able to interpret test results, assess future test scheduling, or make decisions regarding any necessary treatment or intervention.</p>`;

          const result = await this.showWarningDialog(message);
          if (result.value) {
            this.onRemoveLink.emit();
          } else {
            return false;
          }
        }
      });
    }
  }

  async handleCancel() {
    this.onRemoveLink.emit();
  }
  async showWarningDialog(message) {
    return swal.fire({
      type: 'warning',
      html: message,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: "Remove Link",
    });
  }
}
