import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  AdaptiveScoreRecordDto,
  GetScoresInput,
  IRangeData,
  ScoreResults,
} from './types';
import { config } from './../../config';
import { AnalyteResult } from '../resultSet/types';

@Injectable({
  providedIn: 'root',
})
export class ScoreService {
  private readonly API_URL = `${config.baseApiv2Url}/score`;

  constructor(public http: HttpClient) {}

  getScores(options: GetScoresInput): Observable<ScoreResults> {
    const { userId, resultSetId, date, dateRangeStart, dateRangeEnd } = options;
    let params = new HttpParams();

    params = params.append('userId', userId);

    if (resultSetId) {
      params = params.append('resultSetId', resultSetId);
    }
    if (dateRangeStart) {
      params = params.append('dateRangeStart', dateRangeStart);
    }
    if (dateRangeEnd) {
      params = params.append('dateRangeEnd', dateRangeEnd);
    }
    if (date) {
      params = params.append('date', date);
    }

    return this.http.get<ScoreResults>(this.API_URL, { params });
  }

  calculateRangeData(
    adaptiveScores: AdaptiveScoreRecordDto[],
    results: AnalyteResult[]
  ): IRangeData {
    const rangeData: IRangeData = {
      green: {
        value: {},
        high: {},
        low: {},
        trend: {},
      },
      red: {
        value: {},
        high: {},
        low: {},
        trend: {},
      },
      yellow: {
        value: {},
        high: {},
        low: {},
        trend: {},
      },
    };

    adaptiveScores.forEach((scoreRecord) => {
      const analyteResult = results.find(
        (x) => x.analyteKey === scoreRecord.analyteKey
      );

      const value = +analyteResult.analyteValue;
      const { personalRangeMin, personalRangeMax, analyteKey, trend } =
        scoreRecord;
      const [labRangeMin, labRangeMax] = analyteResult.referenceRange
        .split('-')
        .map(Number);

      const isBeyondPersonalRange = value > personalRangeMax;
      const isBelowPersonalRange = value < personalRangeMin;
      const isBeyondLabRange = value > labRangeMax;
      const isBelowLabRange = value < labRangeMin;

      // Always populate green range for personal min/max and trend
      rangeData.green.high[analyteKey] =
        personalRangeMax > labRangeMax ? labRangeMax : personalRangeMax;
      rangeData.green.low[scoreRecord.analyteKey] =
        personalRangeMin < labRangeMin ? labRangeMin : personalRangeMin;
      rangeData.green.trend[scoreRecord.analyteKey] = trend;
      rangeData.green.value[scoreRecord.analyteKey] = analyteResult
        ? value
        : null;

      if (!analyteResult) {
        // If there's no matching analyteResult, skip further processing for this record
        return;
      }

      if (isBeyondPersonalRange) {
        rangeData.yellow.high[analyteKey] = isBeyondLabRange
          ? labRangeMax
          : value;
        rangeData.yellow.low[analyteKey] = personalRangeMax;
        rangeData.yellow.value[analyteKey] = value;
        rangeData.yellow.trend[analyteKey] = scoreRecord.trend;

        if (isBeyondLabRange) {
          rangeData.red.low[analyteKey] = labRangeMin;
          rangeData.red.high[analyteKey] = value;
          rangeData.red.value[analyteKey] = value;
          rangeData.red.trend[analyteKey] = scoreRecord.trend;
        }
      }

      if (isBelowPersonalRange) {
        rangeData.yellow.high[analyteKey] = personalRangeMin;
        rangeData.yellow.low[analyteKey] = isBelowLabRange
          ? labRangeMin
          : value;
        rangeData.yellow.value[analyteKey] = value;
        rangeData.yellow.trend[analyteKey] = scoreRecord.trend;

        if (isBelowLabRange) {
          rangeData.red.low[analyteKey] = value;
          rangeData.red.high[analyteKey] = labRangeMin;
          rangeData.red.value[analyteKey] = value;
          rangeData.red.trend[analyteKey] = scoreRecord.trend;
        }
      }
    });

    return rangeData;
  }
}
