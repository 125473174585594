export enum TestConfigName {
  MBC = 'MBC',
  FBC = 'FBC',
  SBC = 'SBC',
}

export enum TestConfigStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export interface TestConfig {
  id: number;
  name: TestConfigName;
}

export interface TestConfigFilter {
  status?: TestConfigStatus;
  version?: string;
}
