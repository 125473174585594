<ngx-ui-loader></ngx-ui-loader>
<div class="form-container" align="center">
  <form [formGroup]="codeVerificationForm">
    <a href="javascript:void(0)" class="cursor-pointer">
      <img src="assets/algocyte/logo.png" height="100" class="d-block align-top mb-3" alt="" />
    </a>
    <mat-form-field appearance="fill">
      <mat-label>{{'smartReport.securityCode'| translate }} *</mat-label>
      <input matInput placeholder="{{'smartReport.securityPlaceholder'| translate }}" formControlName="code" />
      <mat-error *ngIf="
          codeVerificationForm.get('code').errors &&
          codeVerificationForm.get('code').hasError('required')
        ">
        {{'smartReport.required'| translate }}.
      </mat-error>
    </mat-form-field>

    <div class="mt-2">
      <mat-checkbox formControlName="tandc" class="custom">
        {{'smartReport.tncConfirmationMsg'| translate }}
        <!-- I read and agree to the
        <a
          href="https://customepageforuat.blob.core.windows.net/customepageforuat/terms.html"
          target="_blank"
          class="web-links"
        >
          Terms & Conditions
        </a>
        and the
        <a
          href="https://customepageforuat.blob.core.windows.net/customepageforuat/privacy.html"
          target="_blank"
          class="web-links"
        >
          Privacy Policy
        </a> -->
        {{'smartReport.willingMsg'| translate }}

      </mat-checkbox>
    </div>

    <button mat-raised-button color="algo1" (click)="verifyCode()" [disabled]="!codeVerificationForm.valid">
      {{'smartReport.submit'| translate }}
    </button>
  </form>
</div>