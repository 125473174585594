import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { Tag } from '../../../types';
import { ResultSetResponse } from '../resultSet/types';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(public apiService: ApiService) {}

  getTags(): Observable<Tag[]> {
    return this.apiService.getTags();
  }

  createTag(data: Tag): Observable<Tag> {
    return this.apiService.createTag(data);
  }

  deleteTag(id: number): Observable<void> {
    return this.apiService.deleteTag(id);
  }

  assignTagsToResultSet(
    tags: Tag[],
    resultSetId: number
  ): Observable<ResultSetResponse> {
    return this.apiService.assignTagsToResultSet(resultSetId, tags);
  }
}
