<div class="container">
    <div class="row">
        <div class="col-md-10 col-lg-10 col-12" style="text-align: left;" *ngIf="currentPage === 'followups'">
            <h2 class="title-text"> My Follow-up Questions </h2>
            <!-- <h2 class="title-text" *ngIf="currentPage !== 'followups'"> My Questionnaires</h2> -->
        </div>
        <div class="col-6 text-center" *ngIf="currentPage !== 'followups'" (click)="changeMode('template')">
            <h5 class="title-text-2"> My Questionnaires</h5>
        </div>
        <div class="col-6 text-center" *ngIf="currentPage !== 'followups'" (click)="changeMode('questionnaires')">
            <h5 class="title-text-2"> My Template</h5>
        </div>
    </div>
    <div class="row" *ngIf="this.currentMode ==='questionnaires'">
        <div class=" col-12 mb-4 d-none d-md-block d-lg-block" *ngIf="questionnaires && questionnaires.length">
            <div class="mat-elevation-z8 margin-bottom-20">
                <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>
                            <span *ngIf="currentPage !== 'followups'">
                                Questionnaire Title
                            </span>
                            <span *ngIf="currentPage === 'followups'">
                                Follow-up Questions
                            </span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                    </ng-container>
                    <ng-container matColumnDef="requester_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by Subscriber Name / Requester Name">
                            <span *ngIf="currentPage !== 'followups'">
                                Subscriber Name
                            </span>
                            <span *ngIf="currentPage === 'followups'">
                                Requester Name
                            </span>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="currentPage === 'followups'"> {{element.requester_name}} </span>
                            <span *ngIf="currentPage !== 'followups'">{{element.subscriber_name}}</span>
                        </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="published_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by Published On">
                            Published On </th>
                        <td mat-cell *matCellDef="let element"> {{ element.published_at | date: 'dd-MM-yyyy h:mm' }}
                        </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="answered_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by Published On">
                            <span *ngIf="currentPage !== 'followups'">
                                Response Received On
                            </span>
                            <span *ngIf="currentPage === 'followups'">
                                Answer Submitted On
                            </span>
                        </th>
                        <td mat-cell *matCellDef="let element"> <span *ngIf="element.answered_at"> {{element.answered_at
                                |
                                date:
                                'dd-MM-yyyy h:mm' }}
                            </span> <span *ngIf="!element.answered_at"> Pending </span> </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">

                            <!-- This only comes when page is Questionnaire -->
                            <button mat-stroked-button class="action-button next"
                                *ngIf="currentPage !== 'followups' && (element.questionnaire !== 'null' || element.questionnaire == null)"
                                (click)="viewEditorForm(element)">
                                View Questionnaire </button>

                            <!-- This only comes when page is followups -->
                            <button mat-stroked-button class="action-button next"
                                *ngIf="currentPage === 'followups' && (element.questionnaire !== 'null' || element.questionnaire == null) && element.answered_at"
                                (click)="viewAnswerForm(element)">
                                View Responses </button>

                            <!-- This only comes when page is followups -->
                            <button mat-stroked-button class="action-button next"
                                *ngIf="currentPage === 'followups' && (element.questionnaire !== 'null' || element.questionnaire == null) && !element.answered_at"
                                (click)="viewSubmissionForm(element)">
                                Submit Answer </button>

                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu"
                                *ngIf="currentPage === 'followups' && element.answered_at">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" xPosition="after">
                                <button mat-menu-item (click)="deleteResponse(element)">
                                    <mat-icon class="material-symbols-outlined"
                                        style="color: #E35F6C;">do_not_disturb_on_outlined</mat-icon>
                                    <span style="color: #E35F6C;">Delete Answer</span>
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="mat-paginator-container">
                    <div class="mat-paginator-range-label">
                        {{totalRecords}} Results Found
                    </div>
                    <div class="mat-paginator-navigation">
                        <button mat-button class="custom-page" [disabled]="pageNo === 0" (click)="goToPreviousPage()">
                            <mat-icon>arrow_left</mat-icon>
                            Prev
                        </button>
                        <button mat-button class="custom-page" *ngFor="let pageNumber of getPageNumbers()"
                            [disabled]="pageNumber + 1 === pageNo" (click)="goToPage(pageNumber + 1)">
                            {{ pageNumber + 1 }}
                        </button>
                        <button mat-button class="custom-page" [disabled]="pageNo === (getTotalPages() - 1)"
                            (click)="goToNextPage()">
                            Next <mat-icon>arrow_right</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-paginator style="visibility:hidden; display: none;" [pageSize]="limit" [pageIndex]="pageNo"
                    [showTotalPages]="3" [length]="totalRecords" (page)="pageEvents($event)">
                </mat-paginator>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="this.currentMode !=='questionnaires'">
        <div class=" col-12 mb-4 d-none d-md-block d-lg-block" *ngIf="questionnaires && questionnaires.length">
            <div class="mat-elevation-z8 margin-bottom-20">
                <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>
                            <span *ngIf="currentPage !== 'followups'">
                                Questionnaire Title
                            </span>
                            <span *ngIf="currentPage === 'followups'">
                                Follow-up Questions
                            </span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="requester_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by Subscriber Name / Requester Name">
                            <span *ngIf="currentPage !== 'followups'">
                                Subscriber Name
                            </span>
                            <span *ngIf="currentPage === 'followups'">
                                Requester Name
                            </span>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="currentPage === 'followups'"> {{element.requester_name}} </span>
                            <span *ngIf="currentPage !== 'followups'">{{element.subscriber_name}}</span>
                        </td>
                    </ng-container> -->

                    <!-- Weight Column -->
                    <ng-container matColumnDef="created_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by Created At">
                            Created At </th>
                        <td mat-cell *matCellDef="let element"> {{ element.created_at | date: 'dd-MM-yyyy h:mm' }}
                        </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <!-- <ng-container matColumnDef="answered_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by Published On">
                            <span *ngIf="currentPage !== 'followups'">
                                Response Received On
                            </span>
                            <span *ngIf="currentPage === 'followups'">
                                Answer Submitted On
                            </span>
                        </th>
                        <td mat-cell *matCellDef="let element"> <span *ngIf="element.answered_at"> {{element.answered_at
                                |
                                date:
                                'dd-MM-yyyy h:mm' }}
                            </span> <span *ngIf="!element.answered_at"> Pending </span> </td>
                    </ng-container> -->

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">

                            <!-- This only comes when page is Questionnaire -->
                            <button mat-stroked-button class="action-button next"
                                *ngIf="currentPage !== 'followups' && (element.questionnaire !== 'null' || element.questionnaire == null)"
                                (click)="viewEditorForm(element)">
                                View Questionnaire </button>

                            <!-- This only comes when page is followups -->
                            <button mat-stroked-button class="action-button next"
                                *ngIf="currentPage === 'followups' && (element.questionnaire !== 'null' || element.questionnaire == null) && element.answered_at"
                                (click)="viewAnswerForm(element)">
                                View Responses </button>

                            <!-- This only comes when page is followups -->
                            <button mat-stroked-button class="action-button next"
                                *ngIf="currentPage === 'followups' && (element.questionnaire !== 'null' || element.questionnaire == null) && !element.answered_at"
                                (click)="viewSubmissionForm(element)">
                                Submit Answer </button>

                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu"
                                *ngIf="currentPage === 'followups' && element.answered_at">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" xPosition="after">
                                <button mat-menu-item (click)="deleteResponse(element)">
                                    <mat-icon class="material-symbols-outlined"
                                        style="color: #E35F6C;">do_not_disturb_on_outlined</mat-icon>
                                    <span style="color: #E35F6C;">Delete Answer</span>
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                </table>
                <div class="mat-paginator-container">
                    <div class="mat-paginator-range-label">
                        {{totalRecords}} Results Found
                    </div>
                    <div class="mat-paginator-navigation">
                        <button mat-button class="custom-page" [disabled]="pageNo === 0" (click)="goToPreviousPage()">
                            <mat-icon>arrow_left</mat-icon>
                            Prev
                        </button>
                        <button mat-button class="custom-page" *ngFor="let pageNumber of getPageNumbers()"
                            [disabled]="pageNumber + 1 === pageNo" (click)="goToPage(pageNumber + 1)">
                            {{ pageNumber + 1 }}
                        </button>
                        <button mat-button class="custom-page" [disabled]="pageNo === (getTotalPages() - 1)"
                            (click)="goToNextPage()">
                            Next <mat-icon>arrow_right</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-paginator style="visibility:hidden; display: none;" [pageSize]="limit" [pageIndex]="pageNo"
                    [showTotalPages]="3" [length]="totalRecords" (page)="pageEvents($event)">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>

<div class="modal fade p-0" id="open-question" tabindex="-1" role="dialog" aria-labelledby="patientToPatientLinkTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <mat-icon class="material-symbols-outlined" style="cursor: pointer;float: right;"
                    (click)="closeModal('view')" aria-hidden="false" aria-label="Example home icon"
                    fontIcon="close"></mat-icon>
            </div>
            <div class="modal-body">
                <survey [model]="surveyModel"></survey>
            </div>
        </div>
    </div>
</div>


<div class="modal fade p-0" id="open-edit-question" tabindex="-1" role="dialog"
    aria-labelledby="patientToPatientLinkTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <mat-icon class="material-symbols-outlined" style="cursor: pointer;float: right;"
                    (click)="closeModal('edit')" aria-hidden="false" aria-label="Example home icon"
                    fontIcon="close"></mat-icon>
            </div>
            <div class="modal-body">
                <survey-creator [model]="model"></survey-creator>
            </div>
        </div>
    </div>
</div>