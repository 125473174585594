import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TestService } from 'src/app/services/test.service';
import { config } from 'src/app/config';
import { commonConfig } from 'src/app/config/common/common';
declare const grecaptcha: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { updateConfigData } from 'src/app/config';
import { UserProfile, User, Role } from 'src/types';
import { MappedProfile } from '../sign-in/sign-in.component';
import {
  confirmPasswordValidator,
  passwordValidator,
} from '../../shared/validators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent implements OnInit {
  @Input() isManaged: boolean;
  hide = true;
  chide = true;
  signUpForm: FormGroup;
  email = '';
  password = '';
  user: any;
  config: any = config;
  createManagedAccountForm: FormGroup;
  private subscriptions: Subscription = new Subscription();
  error: string;
  isCreatingAccount: boolean = false;

  constructor(
    private router: Router,
    private testService: TestService,
    private authService: AuthService,
    private userService: UserService,
  ) {

    this.signUpForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-z ,.'-]+$/i),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-z ,.'-]+$/i),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(commonConfig.pattern.email),
      ]),
      password: new FormControl('', [Validators.required, passwordValidator]),
      cpassword: new FormControl('', [
        Validators.required,
        confirmPasswordValidator,
      ]),
      tandc: new FormControl('', [Validators.required, this.termsAndCond]),
      hasBeta: new FormControl('true', [
        Validators.required,
        this.termsAndCond,
      ]),
    });

    this.createManagedAccountForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-z ,.'-]+$/i),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-z ,.'-]+$/i),
      ]),
      displayName: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(commonConfig.pattern.email),
      ]),
    });
  }

  ngOnInit(): void {
    grecaptcha.ready(function () {
      document
        .getElementById('signup-form')
        .removeEventListener('submit', this.onSignIn);
      document
        .getElementById('signup-form')
        .addEventListener('submit', this.onSignIn);
    });

    this.subscriptions.add(
      this.signUpForm.get('password').valueChanges.subscribe(() => this.signUpForm.get('cpassword').updateValueAndValidity({ emitEvent: false }))
    )

    this.subscriptions.add(
      this.signUpForm.get('cpassword').valueChanges.subscribe(() => this.signUpForm.get('password').updateValueAndValidity({ emitEvent: false }))
    )

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  termsAndCond = (control: FormControl) => {
    const tnc = control.value;
    if (!tnc) {
      return {
        tnc: {
          passmismatch: 'Not Accepted',
        },
      };
    }
    return null;
  };

  async onSignup() {
    if (this.signUpForm.valid) {

      this.isCreatingAccount = true

      const { email, password, firstName, lastName } = this.signUpForm.value;

      const registerPayload = {
        email,
        password,
        firstName,
        lastName,
      };

      try {
        await this.authService.registerUser(registerPayload);

        localStorage.setItem('requestedEmail', this.signUpForm.value.email);
        this.testService.signUpSuccess.emit(true);
        this.testService.verifyUser.emit(true);

        const userCredentials = await signInWithEmailAndPassword(
          getAuth(),
          this.signUpForm.value.email,
          this.signUpForm.value.password
        );
        const idToken = await userCredentials.user.getIdToken(true);
        const { userId } = this.decodeJWT(idToken);
        const user = await this.userService.getUser(userId);

        const userProfile = await this.setUserProfile(userCredentials);
        this.setLocalStorageData(user, userProfile);
        this.isCreatingAccount = false
        this.router.navigate(['/']);
      } catch (error) {
        this.handleError(error);
      }
    }
  }

  handleError(error) {
    this.isCreatingAccount = false
    this.error = this.getFriendlyErrorMessage(error.status)
  }

  getFriendlyErrorMessage(errorCode: number): string {
    const errorMessages = {
      409: 'An account with this email address already exists.',
      default: 'An unexpected error occurred. Please try again later.',
    };
    return errorMessages[errorCode] || errorMessages.default;
  }

  decodeJWT(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  }

  setLocalStorageData(user: User, userProfile: UserProfile) {
    const { userId } = userProfile;
    localStorage.setItem('loggedInUser', JSON.stringify(this.mapUserProfileToLegacyUser(userProfile)));
    localStorage.setItem('patient', JSON.stringify(this.mapUserProfileToLegacyUser(userProfile)));
    localStorage.setItem('UUID', JSON.stringify(userId));
    localStorage.setItem('patientId', JSON.stringify(userId));
    localStorage.setItem('roleId', JSON.stringify(this.mapRoleToLegacyRoleId(user.roles)));
  }

  mapUserProfileToLegacyUser(profile: UserProfile): MappedProfile {
    return {
      forename: profile.firstName,
      surname: profile.lastName,
      DOB: profile.dateOfBirth,
    };
  }

  mapRoleToLegacyRoleId = (roles: Role[]): number => {
    const roleMapping: { [roleName: string]: number } = {
      admin: 3001,
      provider: 2001,
      subscriber: 1001,
    };

    for (const roleName of Object.keys(roleMapping)) {
      if (roles.some((role) => role === roleName)) {
        return roleMapping[roleName];
      }
    }
  };

  async setUserProfile(userCredential) {
    const idToken = await userCredential.user.getIdToken(true);
    const { userId } = this.decodeJWT(idToken);
    const userProfile = await this.userService.getUserProfile(userId);

    this.user = {
      username: `${userProfile.firstName} ${userProfile.lastName}`,
      displayName: `${userProfile.firstName} ${userProfile.lastName}`,
      email: userProfile.email,
      roleId: 1,
      UUID: userId,
      userId,
      newUser: '',
      fname: userProfile.firstName,
      sname: userProfile.lastName,
    };

    updateConfigData(userCredential);

    return userProfile;
  }

  doRouting(path) {
    this.testService.doRouting(path);
  }
}
