<div class="auth-background">
  <div class="text-center" style="max-width:470px !important " *ngIf="!authorisedUserData">
    <p class="new_text">
      {{ welcomeText }}
    </p>
    <p class="margin-top-50 text-center margin-bottom-40 sub_note">
      {{ welcomeSubText }}
    </p>
    <button class="text-uppercase aside_button" href="javascript:void();" (click)="onCtaClicked()">
      <span class="aside_button_text">
        {{ ctaText}}
      </span>
    </button>
  </div>

  <div class="text-center" style="max-width:470px !important " *ngIf="authorisedUserData">
    <p class="new_text">
      Welcome back
    </p>
    <p class="margin-top-50 text-center margin-bottom-40 sub_note">
      You're signed in as {{authorisedUserData.email}}
    </p>
    <button class="text-uppercase aside_button" href="javascript:void();" (click)="signOut()">
      <span class="aside_button_text">
        Sign Out
      </span>
    </button>
  </div>
</div>